<template>
    <svg
        viewBox="0 0 14 14"
        version="1.1"
        :width="width"
        :height="height"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <g :fill="color" fill-rule="nonzero">
            <path
                d="M8.8163938,7 L13.8123517,2.00491706 C14.0625494,1.75422121 14.0625494,1.34829097 13.8123517,1.09759511 L12.9024049,0.187648317 C12.651709,-0.062549439 12.2457788,-0.062549439 11.9950829,0.187648317 L7,5.18448115 L2.00491706,0.187648317 C1.75422121,-0.062549439 1.34829097,-0.062549439 1.09759511,0.187648317 L0.187648317,1.09759511 C-0.062549439,1.34829097 -0.062549439,1.75422121 0.187648317,2.00491706 L5.18448115,7 L0.188523266,11.9950829 C-0.0618759858,12.2463785 -0.0618759858,12.6528592 0.188523266,12.9041548 L1.09847006,13.8114767 C1.34916592,14.0616745 1.75509616,14.0616745 2.00579201,13.8114767 L7,8.8163938 L11.9950829,13.8123517 C12.2457788,14.0625494 12.651709,14.0625494 12.9024049,13.8123517 L13.8123517,12.9024049 C14.0625494,12.651709 14.0625494,12.2457788 13.8123517,11.9950829 L8.81551885,7"
                id="Shape"
            />
        </g>
    </svg>
</template>
<script>
import baseSvgImg from './baseSvgImg';
export default {
    extends: baseSvgImg,
    props: {
        color: {
            type: String,
            default: '#999'
        }
    }
};
</script>
