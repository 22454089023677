<template>
    <svg
        version="1.1"
        id="图层_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 48 48"
        style="enable-background:new 0 0 48 48;"
        xml:space="preserve"
        :width="width"
        :height="height"
    >
        <path
            :fill="color"
            d="M33.4,42.9H14.6c-1.9,0-3.5-1.7-3.5-3.7v-3.4c0-2,1.6-3.7,3.5-3.7h18.8c1.9,0,3.5,1.7,3.5,3.7v3.4
	C36.9,41.3,35.4,42.9,33.4,42.9z M14.6,35.1c-0.2,0-0.5,0.3-0.5,0.7v3.4c0,0.4,0.3,0.7,0.5,0.7h18.8c0.3,0,0.5-0.3,0.5-0.7v-3.4
	c0-0.4-0.3-0.7-0.5-0.7H14.6z"
        />
        <path
            :fill="color"
            d="M38.5,39h-2.3c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5h2.3c0.7,0,1.2-0.1,1.5-0.2v-8.9
	c-0.3-0.1-0.8-0.2-1.5-0.2h-29c-0.7,0-1.2,0.1-1.5,0.2v8.9C8.3,35.9,8.8,36,9.5,36h2.8c0.8,0,1.5,0.7,1.5,1.5S13.1,39,12.3,39H9.5
	C6.8,39,5,37.9,5,36.3v-9.9c0-1.6,1.9-2.7,4.5-2.7h29c2.7,0,4.5,1.1,4.5,2.7v9.9C43,37.9,41.1,39,38.5,39z"
        />
        <path
            :fill="color"
            d="M34,25.1c-0.8,0-1.5-0.7-1.5-1.5v-15c0-0.3-0.2-0.5-0.5-0.5H15.9c-0.3,0-0.5,0.2-0.5,0.5v15
	c0,0.8-0.7,1.5-1.5,1.5s-1.5-0.7-1.5-1.5v-15c0-1.9,1.6-3.5,3.5-3.5H32c1.9,0,3.5,1.6,3.5,3.5v15C35.5,24.4,34.8,25.1,34,25.1z"
        />
        <path
            :fill="color"
            d="M28.5,14.7h-9.8c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5h9.8c0.8,0,1.5,0.7,1.5,1.5S29.3,14.7,28.5,14.7z"
        />
        <path
            :fill="color"
            d="M28.5,20.8h-9.8c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5h9.8c0.8,0,1.5,0.7,1.5,1.5S29.3,20.8,28.5,20.8z"
        />
    </svg>
</template>
<script>
import baseSvgImg from './baseSvgImg';
export default {
    extends: baseSvgImg,
    props: {
        color: {
            type: String,
            default: '#007aff'
        }
    }
};
</script>
