import Gikam from '../core/gikam-core.js';
import confirm from './template/confirm.vue';
import Base from './base.js';
import Vue from 'vue';
import jQuery from 'jquery';

let defaultOptions = {
    message: void 0,
    title: '请确认',
    subTitle: void 0,
    onYesClick: Gikam.emptyFunction,
    onNoClick: Gikam.emptyFunction,
    yesText: void 0,
    noText: void 0
};

export default class Confirm extends Base {

    constructor(props) {
        super(props);
        this.initialize(props, defaultOptions).init();
    }

    createModel() {
        let _this = this;
        return new Vue({
            el: jQuery('<confirm :options="options"></confirm>').appendTo('body')[0],
            components: { confirm },
            data() {
                return {
                    options: _this.options
                };
            }
        });
    }

    init() {
        this.createModel();
    }
}
