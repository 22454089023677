<template>
    <svg
        version="1.1"
        id="图层_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 44 44"
        style="enable-background:new 0 0 44 44;"
        xml:space="preserve"
    >
        <g :fill="color">
            <path
                class="st0"
                d="M35.7,43c-0.4,0-0.8-0.1-1.1-0.4L16.6,24.5c-1.4-1.4-1.4-3.6,0-4.9L34.7,1.4c0.6-0.6,1.5-0.6,2.1,0
            c0.6,0.6,0.6,1.5,0,2.1L18.7,21.6c-0.1,0.1-0.1,0.3-0.1,0.4s0,0.2,0.1,0.4l18.1,18.1c0.6,0.6,0.6,1.5,0,2.1
            C36.5,42.9,36.1,43,35.7,43z"
            />
            <path
                class="st0"
                d="M8.3,44L8.3,44c-0.8,0-1.5-0.7-1.5-1.5v-41C6.8,0.7,7.5,0,8.3,0h0c0.8,0,1.5,0.7,1.5,1.5v41
            C9.8,43.3,9.1,44,8.3,44z"
            />
        </g>
    </svg>
</template>

<script>
import baseSvgImg from './baseSvgImg';
export default {
    extends: baseSvgImg,
    props: {
        color: {
            type: String,
            default: '#d9d9d9'
        }
    }
};
</script>
