import Base from './base.js';
import Gikam from '../core/gikam-core';
import Vue from 'vue';

// 样品储存位置
let defaultOptions = {
    data: [],
    innerTextList: [],
    url: void 0,
    rowNum: void 0,
    colNum: void 0,
    xSerialType: 'number',
    ySerialType: 'letter',
    width: void 0,
    height: void 0,
    bgIcon: void 0,
    bgColor: void 0,
    onClick: void 0,
    onDblClick: void 0,
    onLoadSuccess: void 0, // 接口加载完成
    onRendered: void 0, // 组件渲染完成
    contextMenu: [],
    onBeforeContextMenu: Gikam.emptyFunction //右键点击事件
};

export default class SampleLocation extends Base {
    constructor(options) {
        super(options);
        this.listeners = {
            beforeContextMenu: Gikam.emptyFunction
        };
        this.initialize(options, defaultOptions).init();
    }

    createViewModel() {
        const _this = this;
        _this.options.instance = this;

        this.sampleLoc = new Vue({
            el: Gikam.createDom('div', this.options.renderTo),
            provide: {
                sampleLocationInstance: _this
            },
            render() {
                return (
                    <sample-location
                        ref="sampleLocation"
                        onMounted={this.mountedHandle}
                        options={this.options}
                    ></sample-location>
                );
            },
            components: {
                sampleLocation: () => import('@/gikam/js/components/template/sampleLocation/sampleLocation.vue')
            },
            data() {
                return {
                    options: _this.options
                };
            },
            methods: {
                mountedHandle() {
                    _this.bindInstance(_this.sampleLoc.$el);
                }
            }
        });

        this.options.url && this.urlPostData();
    }

    // [公用方法]——设置数据
    setData(data) {
        this.options.data = data;
    }

    // [公用方法]——添加到某个位置
    addSample(data) {
        this.sampleLoc.$refs.sampleLocation.addSampleFn(data);
    }

    // [公用方法]——动态改变坐标显示内容
    setSerialType(option) {
        this.sampleLoc.$refs.sampleLocation.setSerialType(option);
    }

    // 调用接口-初始化完成触发
    urlPostData() {
        let _this = this;
        let sampleUrl = this.options.url;
        Gikam.getJson(sampleUrl).done(data => {
            _this.setData(data.rows);
            if (_this.options.onLoadSuccess) {
                _this.options.onLoadSuccess(data.rows);
            }
        });
    }

    /**
     * 选中事件
     * @public
     * @param { String } nodeId 节点id
     * @memberof SampleLocation
     */
    sampleSelect(id, type) {
        let data = this.options.data.filter(item => item.id === id)[0];
        data && this.sampleLoc.$refs.sampleLocation.sampleActive(data, type);
        !data && this.sampleLoc.$refs.sampleLocation.sampleActive(data, false);
        if (this.options.onClick) {
            this.options.onClick(data, type);
        }
    }

    // 通过id激活事件
    activateItemById(id) {
        let data = this.options.data.filter(item => item.id === id)[0];
        data && this.sampleLoc.$refs.sampleLocation.sampleActive(data, true);
    }

    // 获取激活的信息
    getActivatedItem() {
        let active = this.sampleLoc.$refs.sampleLocation.activeId;
        if (active.x === null) {
            return;
        }
        return this.options.data.filter(item => {
            return item.x === active.x && item.y === active.y;
        })[0];
    }

    //获取激活全部信息
    getActivatedItems() {
        let activeSample = this.sampleLoc.$refs.sampleLocation.activeSample;
        if (Gikam.isEmpty(activeSample)) {
            return;
        }
        return activeSample;
    }

    //通过id设为空白
    cleanSampleById(id) {
        let data = this.options.data.filter(item => item.id === id)[0];
        if (data) {
            data.stockType = '';
            data.sampleType = '';
            this.sampleLoc.$refs.sampleLocation.showBgColor(data);
            this.sampleLoc.$refs.sampleLocation.showBgIcon(data);
        }
    }

    //将所选板格设置为空白
    cleanSelectedSamples() {
        let _this = this;
        let activeSample = this.sampleLoc.$refs.sampleLocation.activeSample;
        if (Gikam.isEmpty(activeSample)) {
            return;
        }
        activeSample.forEach(sample => {
            _this.cleanSampleById(sample.id);
        });
    }

    moveRow() {
        this.sampleLoc.$refs.sampleLocation.moveRow();
        return this.options.data;
    }

    moveCol() {
        this.sampleLoc.$refs.sampleLocation.moveCol();
        return this.options.data;
    }

    init() {
        this.createViewModel();
    }
}
