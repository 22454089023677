import Gikam from 'gikam';

export const addLeftTreeTip = function addLeftTreeTip(expendIds, list, p_index, parentIds) {
    let _index = -1;
    let downgradeId = null;
    list.forEach((item, index) => {
        if (item.unitCategory === 'chapter') {
            //降级参数
            item.downgradeId = downgradeId;
            downgradeId = item.id;
            //树节点排序参数
            item.index = p_index ? `${p_index}.${index - _index}` : index - _index;
            //树节点是否展开参数
            const oldExpendsStates = expendIds.get(item.id);
            item.expend = oldExpendsStates === true || !item.children ? true : false;
        } else {
            _index += 1;
        }
        //记录父节点id 数组
        item.parentIds = parentIds ? parentIds : [];

        if (item.children && item.children.length > 0) {
            const p_ids = parentIds ? Gikam.deepExtend(parentIds) : [];
            p_ids.push(item.id);
            addLeftTreeTip(expendIds, item.children, item.index, p_ids);
        }
    });
};

export const addMoveIdentification = function addMoveIdentification(list) {
    const maxLength = list.length - 1;
    list.forEach((item, index, arr) => {
        if (index !== 0) {
            item.preId = arr[index - 1].id;
        }
        if (index !== maxLength) {
            item.nextId = arr[index + 1].id;
        }
        if (item.children && item.children.length > 0) {
            addMoveIdentification(item.children);
        }
    });
};

export const getLinearList = function getLinearList(list) {
    const _list = [];
    list.forEach(item => {
        _list.push(item);
        if (item.children) {
            _list.push(...getLinearList(item.children));
        }
    });
    return _list;
};

export function addComp(list, currentId, target) {
    let index;
    Gikam.each(list, (_index, item) => {
        if (item.id === currentId) {
            index = _index + 1;
            return false;
        }
    });
    list.splice(index, 0, target);
}

//获取传入数据源页面的参数
export function getDataSourceParams(data) {
    if (!data.length || data.length < 1) {
        return '?dsSingle=1&dsFieldSingle=0';
    }
    const _data = {};
    data.forEach(rows => {
        if (_data[rows.lesDsId]) {
            _data[rows.lesDsId].push(rows);
        } else {
            _data[rows.lesDsId] = [rows];
        }
    });

    const params = [];
    for (const key in _data) {
        const ids = _data[key].map(item => item.id);
        params.push({
            lesDsId: key,
            ids: ids
        });
    }

    return '?dsSingle=1&dsFieldSingle=0&params=' + encodeURI(JSON.stringify(params));
}

//根据id合并两个数组去除重复数据
export function margeArray(oldData, data) {
    const _oldData = Gikam.deepExtend(oldData);
    const oldIds = _oldData.map(item => item.id);
    const newData = data.filter(item => !oldIds.includes(item.id));
    return _oldData.concat(newData);
}

//根据id删除数组中的成员
export function deleteArray(targetData, data) {
    const _data = Gikam.deepExtend(targetData);
    const selectIds = data.map(item => item.id);
    return _data.filter(item => !selectIds.includes(item.id));
}

export function arrayToObject(array, keyFiled, valueFiled) {
    const obj = {};
    array.forEach(item => {
        const key = item[keyFiled] ? item[keyFiled].toLowerCase() : 'id';
        obj[key] = item[valueFiled];
    });
    return obj;
}

//给form表单补充空白字段
export function getFillFormFields(fields, columns) {
    if (fields.length == 0 || columns === 1) {
        return fields;
    }

    const _fields = Gikam.deepExtend(fields);
    const fieldsLength = _fields.reduce((total, field) => {
        if (field.colspan) {
            const ownColSpan = field.colspan <= columns ? field.colspan : columns;
            return total + parseInt(ownColSpan);
        } else {
            return total + 1;
        }
    }, 0);

    const surplusLen = fieldsLength % columns;
    const remainder = surplusLen > 0 ? columns - surplusLen : 0;
    if (remainder > 0) {
        for (let i = 0; i < remainder; i++) {
            _fields.push({
                title: '',
                field: 'field_' + i + fieldsLength,
                config: true,
                readonly: true
            });
        }
    }
    return _fields;
}

export function getPageHtml(targetHtml) {
    const $dom = document.createElement('div');
    targetHtml.querySelectorAll('.les-comp').forEach(comp => {
        $dom.appendChild(comp.cloneNode(true));
    });

    const lesSpread = targetHtml.querySelectorAll('.les-spread');
    $dom.querySelectorAll('.les-spread').forEach((spread, index) => {
        const image = lesSpread[index].querySelector('iframe').contentWindow.exportImg();
        spread.innerHTML = `<img src="${image}" width="100%" class=''/>`;
    });

    const lesDiagramDraw = targetHtml.querySelectorAll('.les-diagramDraw');
    $dom.querySelectorAll('.les-diagramDraw').forEach((diagramDraw, index) => {
        if (lesDiagramDraw[index]) {
            const src = lesDiagramDraw[index].querySelector('canvas').toDataURL();
            diagramDraw.innerHTML = `<img src="${src}" alt="" >`;
        }
    }); 
    $dom.querySelectorAll('.les-comp').forEach(comp => {
        comp.classList.remove('active');
        comp.querySelector('.les-tool') && comp.querySelector('.les-tool').remove();
        comp.querySelector('.save-toolbar') && comp.querySelector('.save-toolbar').remove();
        comp.querySelector('.editing') && comp.querySelector('.editing').remove();
    });
    $dom.querySelectorAll('div').forEach(comp => {
        if (comp.style.display === 'none') {
            comp.remove();
        }
    });
    return $dom;
}

export function getHtmlCompType($html) {
    let resultWidth = 0;
    $html.querySelectorAll('.headerCell').forEach(comp => {
        const _widthNum = Number(comp.style.width.replace('px', ''));
        resultWidth < _widthNum && (resultWidth = _widthNum);

        const idealWidth = _widthNum > 1440 ? 978 : 649;
        let zoom;
        if (_widthNum === 0 || idealWidth > _widthNum) {
            zoom = 1;
        } else {
            zoom = idealWidth / _widthNum;
        }

        comp.parentNode.style.transform = `scale( ${zoom})`;
        comp.parentNode.style['transform-origin'] = 'top left';
    });

    let gridWidth = 0;
    $html.querySelectorAll('.grid-header').forEach(comp => {
        const _widthNum = Number(comp.style.width.replace('px', ''));
        gridWidth < _widthNum && (gridWidth = _widthNum);

        const idealWidth = _widthNum > 1440 ? 978 : 649;
        let zoom;
        if (_widthNum === 0 || idealWidth > _widthNum) {
            zoom = 1;
        } else {
            zoom = idealWidth / _widthNum;
        }

        comp.parentNode.style.transform = `scale( ${zoom})`;
        comp.parentNode.style['transform-origin'] = 'top left';
    });

    const formWidth = $html.querySelectorAll('.form .panel') ? 1 : 0;

    return {
        resultInput: resultWidth,
        grid: gridWidth,
        form: formWidth,
        maxWidth: gridWidth > resultWidth ? gridWidth : resultWidth
    };
}

export async function getPageCss(CompType) {
    const { resultInput, grid, form, maxWidth } = CompType;

    const head = document.createElement('head');
    const $style = window.document.querySelectorAll('style');
    $style.forEach(style => {
        head.appendChild(style.cloneNode(true));
    });
    const $css = window.document.querySelectorAll('link');
    const promiseArr = [];
    $css.forEach(css => {
        if (css.href.lastIndexOf('.css') > -1) {
            const p = new Promise(resolve => {
                Gikam.getText(css.href)
                    .done(res => {
                        resolve(res);
                    })
                    .fail(() => {
                        Gikam.getText(Gikam.IFM_CONTEXT + css.href)
                            .done(res => {
                                resolve(res);
                            })
                            .fail(() => {
                                resolve();
                            });
                    });
            });
            promiseArr.push(p);
        }
    });
    await Promise.all(promiseArr).then(res => {
        res.forEach(item => {
            const style = document.createElement('style');
            style.innerHTML = item;
            head.appendChild(style);
        });
    });

    const { initCss, formCss, gridCss, resultInputCss } = await import('./printCss');

    {
        const style = document.createElement('style');
        style.setAttribute('type', 'text/css');
        style.innerHTML = initCss(maxWidth);
        head.appendChild(style);
    }

    if (resultInput) {
        const style = document.createElement('style');
        style.setAttribute('type', 'text/css');
        style.innerHTML = resultInputCss();
        head.appendChild(style);
    }

    if (grid) {
        const style = document.createElement('style');
        style.setAttribute('type', 'text/css');
        style.innerHTML = gridCss();
        head.appendChild(style);
    }

    if (form) {
        const style = document.createElement('style');
        style.setAttribute('type', 'text/css');
        style.innerHTML = formCss();
        head.appendChild(style);
    }

    return Promise.resolve(head);
}

export function printPage(head, body) {
    const _window = window.open('');
    _window.document.write(head.innerHTML + body.innerHTML);
    setTimeout(() => {
        _window.print();
        _window.close();
    }, 100);
}
