<template>
    <div></div>
</template>

<script>
import validator from '../../../core/validator.js';
import Gikam from '../../../core/gikam-core.js';
import Vue from 'vue';

export default {
    props: {
        dataId: [String, Number],
        validateArg: Object
    },

    inject: {
        form: {
            default: null
        },
        grid: {
            default: null
        }
    },

    data() {
        return {
            errorPanel: void 0,
            error: []
        };
    },

    mounted() {
        this.validatorsList = this.getValidators();
    },

    watch: {
        error(value) {
            const parent = this.form || this.grid;
            //清空Form和Grid记录的校验字段信息
            this.cleanParentErrors();
            if (Gikam.isNotEmpty(value)) {
                value.forEach(item => {
                    parent.errorFields.push({
                        field: this.field,
                        title: Gikam.propI18N(item.title),
                        message: item.message,
                        rowIndex: this.rowIndex
                    });
                });
            }
        }
    },

    methods: {
        getValidators() {
            const validators = [];
            if (!this.options.validators) {
                return validators;
            }
            this.options.validators.forEach(name => {
                let validator = {};
                if (Gikam.isPlainObject(name)) {
                    Gikam.extend(validator, name);
                } else if (name.indexOf('strLength') === 0) {
                    Gikam.extend(validator, {
                        type: 'strLength',
                        rule: name.replace('strLength', '')
                    });
                } else if (name.indexOf('numRange') === 0) {
                    Gikam.extend(validator, {
                        type: 'numRange',
                        rule: name.replace('numRange', '')
                    });
                } else if (name.indexOf('remote') === 0) {
                    Gikam.extend(validator, {
                        type: 'remote',
                        rule: name.replace(/remote|\[|]/g, '')
                    });
                } else if (name.indexOf('unique') === 0) {
                    Gikam.extend(validator, {
                        type: 'unique',
                        rule: name.replace(/unique/, '').replace(/\[|]/g, '')
                    });
                } else {
                    Gikam.extend(validator, {
                        type: name
                    });
                }
                validator.title = this.options.title;
                validators.push(validator);
            });
            return validators;
        },

        validate(value) {
            if (this.options._isHeader === true) {
                return true;
            }
            const error = [];
            const _this = this;
            let dataId = _this.dataId;
            let res = true;
            let data = this.validateArg;
            if (Gikam.isNotEmpty(this.rowIndex)) {
                if (dataId === undefined) {
                    dataId = data.id;
                }
            }
            // form 组件去掉字段的校验之后
            if (Gikam.isNotEmpty(this.form)) {
                const validators = this.form.validators[this.field];
                this.validatorsList = validators ? this.form.validators[this.field] : [];
            }

            Gikam.each(this.validatorsList, function() {
                //唯一性校验无论任何时候，光标失焦都校验
                if (
                    (_this.form || _this.grid).options.autoSave === false &&
                    !['unique', 'remote'].includes(this.type)
                ) {
                    return true;
                }
                let r = validator[this.type](
                    value || _this.value,
                    this.rule,
                    _this.field,
                    dataId,
                    Gikam.deepExtend(this, { data })
                );
                if (r.result === false) {
                    error.push({
                        field: _this.field,
                        title: this.title,
                        message: r.message
                    });
                    res = false;
                    return false;
                }
            });
            this.error = error;
            return Gikam.isFalse(res) ? false : this.validateOutRules();
        },

        validateOutRules() {
            const validateArg = this.validateArg ? Gikam.deepExtend(this.validateArg) : {};
            Gikam.setFieldValue(validateArg, this.field, this.value);
            const projectValidate = (this.form || this.grid).trigger('validating', validateArg);

            if (
                Gikam.isPlainObject(projectValidate) &&
                Gikam.isFalse(projectValidate.result) &&
                Gikam.isNotEmpty(projectValidate.message)
            ) {
                this.error.push(projectValidate);
                return false;
            } else {
                !Gikam.isPlainObject(projectValidate) ||
                    (Gikam.isEmpty(projectValidate.message) &&
                        Gikam.error('校验器必须返回带有result和message属性的对象'));
                return true;
            }
        },

        showErrorPanel(event) {
            if (this.error.length <= 0) {
                return;
            }
            const mountedDom = document.createElement('div');
            document.body.appendChild(mountedDom);
            const position = event.target.parentNode.parentNode.getBoundingClientRect();
            let top = position.top + position.height + 1;
            const left = position.left;
            //当高度超出时，提示框放在上面
            let errorPanelClass = 'error-message-panel';
            if (top + position.height > document.body.clientHeight) {
                top = position.top - position.height - 1;
                errorPanelClass = 'error-message-panel error-message-panel-top';
            }
            this.errorPanel = new Vue({
                el: mountedDom,
                render: () => {
                    return (
                        <div
                            class={errorPanelClass}
                            style={{
                                top: top + 'px',
                                left: left + 'px'
                            }}
                        >
                            {Gikam.propI18N(this.error[0].message)}
                        </div>
                    );
                }
            });
        },

        removeErrorPanel() {
            if (!this.errorPanel) {
                return;
            }
            Gikam.removeDom(this.errorPanel.$el);
            this.errorPanel.$destroy();
        },

        override() {},

        errorIconMouseenter(event) {
            this.showErrorPanel(event);
        },

        errorIconMouseleave(event) {
            this.removeErrorPanel(event);
        },

        cleanParentErrors() {
            let existsErrorFieldIndex;
            const parent = this.form || this.grid;
            const existsErrorFields = parent.errorFields.filter((errorField, index) => {
                if (errorField.field === this.field && this.rowIndex === errorField.rowIndex) {
                    existsErrorFieldIndex = index;
                    return true;
                }
                return false;
            });
            if (Gikam.isNotEmpty(existsErrorFields)) {
                parent.errorFields.splice(existsErrorFieldIndex, 1);
            }
        }
    }
};
</script>

<style scoped>
@keyframes fadeIn {
    from {
        opacity: 0.3;
    }
    to {
        opacity: 1;
    }
}

.error-message-panel {
    position: absolute;
    background-color: #666;
    max-width: 220px;
    /* height: 30px; */
    word-break: break-all;
    word-wrap: break-word;
    border-radius: 4px;
    font-size: 12px;
    line-height: 22px;
    color: #fff;
    padding: 4px 8px;
    animation: fadeIn 0.3s;
    z-index: 11;
}

.error-message-panel::before {
    content: '';
    display: block;
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: #666;
    left: 10px;
    top: -2px;
    transform: rotate(45deg);
    border-radius: 2px;
}

.error-message-panel-top::before {
    top: unset !important;
    bottom: -2px;
}
</style>
