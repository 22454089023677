<template>
    <svg
        version="1.1"
        id="图层_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 48 48"
        style="enable-background:new 0 0 48 48;"
        xml:space="preserve"
        :width="width"
        :height="height"
    >
        <path
            class="st0"
            :fill="color"
            d="M15.6,21.6c-0.4,0-0.8-0.2-1.1-0.5l-3.1-3.2c-0.6-0.6-0.6-1.5,0-2.1c0.6-0.6,1.5-0.6,2.1,0l3.1,3.2
	c0.6,0.6,0.6,1.5,0,2.1C16.4,21.5,16,21.6,15.6,21.6z"
        />
        <path
            class="st0"
            :fill="color"
            d="M15.6,21.6c-0.4,0-0.8-0.1-1.1-0.4c-0.6-0.6-0.6-1.5,0-2.1l6.4-6.4c0.6-0.6,1.5-0.6,2.1,0
	c0.6,0.6,0.6,1.5,0,2.1l-6.4,6.4C16.4,21.5,16,21.6,15.6,21.6z"
        />
        <path
            class="st0"
            :fill="color"
            d="M26.1,43c-1.1,0-2.4-1-2.4-2.3l0.1-2.5c0-0.1,0-0.8,0.5-1.3L37,24.2c0.4-0.4,1-0.6,1.5-0.6c0.7,0,1.4,0.4,1.9,1
	l1.5,1.5c1.1,1.1,1.2,2.6,0.4,3.4L29.8,42.4c-0.5,0.5-1.1,0.5-1.3,0.5L26.1,43C26.1,43,26.1,43,26.1,43z M28.4,41.4l0-1.5
	c0,0,0,0,0,0V41.4z M26.8,38.7l0,1.2l1.2-0.1l11.8-11.8L38.5,27L26.8,38.7z M26.5,39.1L26.5,39.1C26.5,39.1,26.5,39.1,26.5,39.1z
	 M26.8,38.3L26.8,38.3C26.8,38.3,26.8,38.3,26.8,38.3z"
        />
        <path
            class="st0"
            :fill="color"
            d="M18.3,43H9.5C7,43,5,41,5,38.5v-29C5,7,7,5,9.5,5h29C41,5,43,7,43,9.5V18c0,0.8-0.7,1.5-1.5,1.5S40,18.8,40,18
	V9.5C40,8.6,39.4,8,38.5,8h-29C8.7,8,8,8.7,8,9.5v29C8,39.3,8.7,40,9.5,40h8.8c0.8,0,1.5,0.7,1.5,1.5S19.1,43,18.3,43z"
        />
        <path
            class="st0"
            :fill="color"
            d="M35.4,16.2h-7.6c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5h7.6c0.8,0,1.5,0.7,1.5,1.5S36.2,16.2,35.4,16.2z"
        />
        <path
            class="st0"
            :fill="color"
            d="M31.6,22.6h-3.8c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5h3.8c0.8,0,1.5,0.7,1.5,1.5S32.4,22.6,31.6,22.6z"
        />
        <path
            class="st0"
            :fill="color"
            d="M15.6,36.2c-0.4,0-0.8-0.1-1.1-0.4l-3.1-3.1c-0.6-0.6-0.6-1.5,0-2.1s1.5-0.6,2.1,0l3.1,3.1
	c0.6,0.6,0.6,1.5,0,2.1C16.4,36.1,16,36.2,15.6,36.2z"
        />
        <path
            class="st0"
            :fill="color"
            d="M15.6,36.2c-0.4,0-0.8-0.1-1.1-0.4c-0.6-0.6-0.6-1.5,0-2.1l6.4-6.3c0.6-0.6,1.5-0.6,2.1,0
	c0.6,0.6,0.6,1.5,0,2.1l-6.4,6.3C16.4,36.1,16,36.2,15.6,36.2z"
        />
    </svg>
</template>

<script>
import baseSvgImg from './baseSvgImg';
export default {
    extends: baseSvgImg,
    props: {
        color: {
            type: String,
            default: '#007AFF'
        }
    }
};
</script>
