<template>
    <svg
        version="1.1"
        id="图层_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        :width="width"
        :height="height"
        viewBox="0 0 38 38"
        style="enable-background:new 0 0 38 38;"
        xml:space="preserve"
    >
        <path
            :fill="color"
            id="XMLID_2684_"
            class="st0"
            d="M19.5,39c10.5,0,19-8.5,19-19S30,1,19.5,1C12.8,1,6.6,4.6,3.2,10.3C2.7,11,3,11.9,3.7,12.4
            c0.7,0.4,1.6,0.2,2.1-0.5C8.6,7,13.9,4,19.5,4c8.8,0,16,7.2,16,16s-7.2,16-16,16c-7.1,0-13.3-4.6-15.3-11.3c-0.2-0.8-1.1-1.2-1.9-1
            c-0.8,0.2-1.2,1.1-1,1.9C3.8,33.6,11.1,39,19.5,39z"
        />
        <path
            :fill="color"
            id="XMLID_2676_"
            class="st0"
            d="M4,15h7c0.8,0,1.5-0.7,1.5-1.5S11.8,12,11,12H4c-0.3,0-0.5-0.2-0.5-0.5v-7C3.5,3.7,2.8,3,2,3
            S0.5,3.7,0.5,4.5v7C0.5,13.4,2.1,15,4,15z"
        />
    </svg>
</template>

<script>
import baseSvgImg from './baseSvgImg';
export default { extends: baseSvgImg };
</script>
