/* eslint-disable complexity */
import Gikam from '../core/gikam-core.js';

const numRegExp = /^-?\d+.?\d*$/;

const tosingle = function(num) {
    let resultArray = [];
    let tempValue = 0;
    // 遍历传入数组
    for (let i = 0; i < num.length; i++) {
        // 将进位数值与当前位数值相加，取个位存储
        resultArray.push((num[i] + tempValue) % 10);
        // 将进位存储位临时值
        tempValue = parseInt((num[i] + tempValue) / 10);
    }
    // 最终临时值如为两位，存储个位及进位
    while (tempValue > 9) {
        resultArray.push(tempValue % 10);
        tempValue = parseInt(tempValue / 10);
    }
    if (tempValue != 0) resultArray.push(tempValue);
    return resultArray;
};

const arrplus = function(array) {
    // 记录最终结果
    let resultArray = [];
    // 记录当前计算数字的位数
    let numberIndex = 0;
    // 记录传入参数长度
    let arrayLength = array.length;
    // 标记运算数组位置
    let arrayIndex = 0;

    while (arrayIndex < arrayLength) {
        // 记录当前位数数值计算结果
        let tempValue = 0;
        arrayIndex = 0;
        for (let i = 0; i < arrayLength; i++) {
            // 如果当前数字末位不为0，则进行更换数组标记位进行加法运算
            if (!array[i][numberIndex] && array[i][numberIndex] != 0) arrayIndex++;
            // 将当前位数字符转换为数字
            tempValue += array[i][numberIndex] ? Number(array[i][numberIndex]) : 0;
        }
        // 依据当前数组位置判断是否记录数据
        if (arrayIndex < arrayLength) resultArray.push(tempValue);
        // 数字换位
        numberIndex++;
    }
    return resultArray;
};

const tosingle2 = function(a) {
    let resultArray = [];
    // 记录进位数值信息
    let tempValue = 0;
    // 遍历传入数组
    let i;
    for (i = 0; i < a.length; i++) {
        // 记录当前数值加进位数值信息
        let nowValue = a[i] + tempValue;
        if (nowValue >= 0) {
            // 取个位存储
            resultArray.push(nowValue % 10);
            // 记录进位值
            tempValue = parseInt(nowValue / 10);
        } else {
            // 如当前值个位为0
            if (nowValue % 10 == 0) {
                // 记录个位
                resultArray.push(nowValue % 10);
                // 记录进位值
                tempValue = parseInt(nowValue / 10);
            } else {
                resultArray.push(10 + (nowValue % 10));
                tempValue = parseInt(nowValue / 10 - 1);
            }
        }
    }
    // 如最终剩余值为两位数
    while (tempValue > 9 || tempValue < -9) {
        // 将最高位与最终值相加
        let nowValue = a[i] + tempValue;
        if (nowValue >= 0) {
            // 如最终结果值大于0，记录个位
            resultArray.push(nowValue % 10);
            tempValue = parseInt(nowValue / 10);
        } else {
            if (nowValue % 10 == 0) {
                resultArray.push(nowValue % 10);
                tempValue = parseInt(nowValue / 10);
            } else {
                resultArray.push(10 + (nowValue % 10));
                tempValue = parseInt(nowValue / 10 - 1);
            }
        }
    }
    // 记录最终值
    if (tempValue != 0) resultArray.push(tempValue);
    // 去除无效0
    while (resultArray[resultArray.length - 1] == 0) {
        resultArray.pop();
    }
    // 返回结果
    return resultArray;
};

const arrminute = function(a, b) {
    let resultArray = [];
    // 判断传入数组长度，记录最长数组
    let maxLength = a.length > b.length ? a.length : b.length;
    // 遍历传入数组
    for (let i = 0; i < maxLength; i++) {
        // 判断参数a，并将其转换为数字
        let aValue;
        if (!a[i] && a[i] != 0) {
            aValue = 0;
        } else {
            aValue = Number(a[i]);
        }
        // 判断参数b，并将其转换为数字
        let bValue;
        if (!b[i] && b[i] != 0) {
            bValue = 0;
        } else {
            bValue = Number(b[i]);
        }
        // 计算当前位数差
        resultArray.push(aValue - bValue);
    }
    return tosingle2(resultArray);
};

const contrast = function(a, b) {
    let aLength = a.length;
    let bLength = b.length;
    // 对比传入参数长度，如长度相等判断参数a是否大于参数b
    if (aLength < bLength) {
        return false;
    } else if (aLength == bLength) {
        for (let i = aLength - 1; i > -1; i--) {
            if (Number(a[i]) < Number(b[i])) {
                return false;
            } else if (Number(a[i]) > Number(b[i])) {
                return true;
            }
        }
    }
    return true;
};

const validate = function(numbers) {
    for (let i = 0; i < numbers.length; i++) {
        if (!numRegExp.test(numbers[i])) {
            Gikam.alert(`${numbers[i]}不是一个数字`);
            return false;
        }
    }
    return true;
};

const numberUtils = {
    plus(a, b) {
        // 将传入参数转换为字符串
        a = '' + a;
        b = '' + b;

        // 数字校验
        if (!validate([a, b])) return '';

        // 判断是否存在负数，如存在使用减法计算
        if (a.charAt(0) == '-' && b.charAt(0) != '-') {
            a = a.substring(1);
            return this.minus(b, a);
            // 如均为负数，去除负号后按照加法计算
        } else if (a.charAt(0) == '-' && b.charAt(0) == '-') {
            a = a.substring(1);
            b = b.substring(1);
            return '-' + this.plus(a, b);
        } else if (a.charAt(0) != '-' && b.charAt(0) == '-') {
            b = b.substring(1);
            return this.minus(a, b);
            // 如均不存在负数，进行加法运算
        } else if (a.charAt(0) != '-' && b.charAt(0) != '-') {
            // 记录指定参数小数点后数字所在位置
            let numIndex = 0;
            // 记录传入参数小数点位置
            let aIndex = a.indexOf('.');
            let bIndex = b.indexOf('.');
            // 记录传入参数反转内容
            let aReverseArray = a.split('').reverse();
            let bReverseArray = b.split('').reverse();
            // 处理小数点后数字的精度问题
            // 如果a参数与b参数均不存在小数，则不做任何操作
            if (aIndex == -1 && bIndex == -1) {
                // 如参数a存在小数点， b不存在小数点
            } else if (aIndex != -1 && bIndex == -1) {
                // 计算a参数小数点后数字位置
                let tempNumIndex = (numIndex = a.length - aIndex - 1);
                // 去除参数反转后数组的小数点
                aReverseArray.splice(tempNumIndex, 1);
                // 在b参数后添加0，用于精确a存在的小数位数
                for (let i = 0; i < numIndex; i++) {
                    bReverseArray.unshift(0);
                }
            } else if (aIndex == -1 && bIndex != -1) {
                let tempNumIndex = (numIndex = b.length - bIndex - 1);
                bReverseArray.splice(tempNumIndex, 1);
                for (let i = 0; i < numIndex; i++) {
                    aReverseArray.unshift(0);
                }
                // 计算a、b参数均存在小数的情况
            } else if (aIndex != -1 && bIndex != -1) {
                let tempAIndex = a.length - aIndex - 1;
                let tempBIndex = b.length - bIndex - 1;
                aReverseArray.splice(tempAIndex, 1);
                bReverseArray.splice(tempBIndex, 1);
                if (tempAIndex > tempBIndex) {
                    numIndex = tempAIndex;
                    for (let i = 0; i < tempAIndex - tempBIndex; i++) {
                        bReverseArray.unshift(0);
                    }
                } else {
                    numIndex = tempBIndex;
                    for (let i = 0; i < tempBIndex - tempAIndex; i++) {
                        aReverseArray.unshift(0);
                    }
                }
            }

            // 将处理后的数据进行加法运算并转换为字符串
            let resultArray = tosingle(arrplus([aReverseArray, bReverseArray]));

            // 计算新的小数点位置
            let pointIndex = numIndex - resultArray.length + 1;
            // 如不满足小数点位置，则添加0
            if (pointIndex > 0) {
                for (let i = 0; i < pointIndex; i++) {
                    resultArray.push(0);
                }
            }
            // 源数据是否存在小数点
            if (numIndex) {
                // 添加小数点
                resultArray.splice(numIndex, 0, '.');
                // 记录去除小数个数
                let tempNum = 0;
                for (let i = 0; i < numIndex; i++) {
                    // 如最后一位为0，则去除
                    if (!resultArray[0]) {
                        resultArray.shift();
                        tempNum++;
                    } else {
                        break;
                    }
                }
                // 如小数位均为0则移除小数点
                if (tempNum == numIndex) {
                    resultArray.shift();
                }
            }
            // 反转结果
            resultArray.reverse();
            // 将结果数组转换为结果字符串
            let result = resultArray.join('');
            // 如结果字符串位为空返回0
            if (result == '') {
                return 0;
            } else {
                return result;
            }
        }
    },

    // 减法运算
    minus(a, b) {
        // 将传入参数转换为字符串
        a = '' + a;
        b = '' + b;
        // 数字校验
        if (!validate([a, b])) return '';
        // 判断是否存在负数，如存在使用加法运算
        if (a.charAt(0) == '-' && b.charAt(0) != '-') {
            a = a.substring(1);
            return '-' + this.plus(a, b);
            // 如均为负数，去除减号后运行减法运算
        } else if (a.charAt(0) == '-' && b.charAt(0) == '-') {
            a = a.substring(1);
            b = b.substring(1);
            return this.minus(b, a);
        } else if (a.charAt(0) != '-' && b.charAt(0) == '-') {
            b = b.substring(1);
            return this.plus(a, b);
            // 如均不存在负数，进行减法运算
        } else if (a.charAt(0) != '-' && b.charAt(0) != '-') {
            // 记录指定参数小数点后数字所在位置
            let numIndex = 0;
            // 记录传入参数小数点位置
            let aIndex = a.indexOf('.');
            let bIndex = b.indexOf('.');
            // 记录传入参数反转数组
            let aReverseArray = a.split('').reverse();
            let bReverseArray = b.split('').reverse();
            // 如均不存在小数点，则不做任何操作
            if (aIndex == -1 && bIndex == -1) {
                // 如传入参数a存在小数点，b不存在
            } else if (aIndex != -1 && bIndex == -1) {
                // 为保证精度，需要在b传入参数后添加0，以保证正常运算
                let tempNumIndex = (numIndex = a.length - aIndex - 1);
                aReverseArray.splice(tempNumIndex, 1);
                for (let i = 0; i < numIndex; i++) {
                    bReverseArray.unshift(0);
                }
                // 如传入参数b存在小数点，a不存在
            } else if (aIndex == -1 && bIndex != -1) {
                let tempNumIndex = (numIndex = b.length - bIndex - 1);
                bReverseArray.splice(tempNumIndex, 1);
                for (let i = 0; i < numIndex; i++) {
                    aReverseArray.unshift(0);
                }
                // 如传入参数均存在小数点
            } else if (aIndex != -1 && bIndex != -1) {
                let tempAIndex = a.length - aIndex - 1;
                let tempBIndex = b.length - bIndex - 1;
                aReverseArray.splice(tempAIndex, 1);
                bReverseArray.splice(tempBIndex, 1);
                if (tempAIndex > tempBIndex) {
                    numIndex = tempAIndex;
                    for (let i = 0; i < tempAIndex - tempBIndex; i++) {
                        bReverseArray.unshift(0);
                    }
                } else {
                    numIndex = tempBIndex;
                    for (let i = 0; i < tempBIndex - tempAIndex; i++) {
                        aReverseArray.unshift(0);
                    }
                }
            }
            // 记录结果数组
            let resultArray;
            // 判断传入参数a是否大于参数b
            let contrastRes = contrast(aReverseArray, bReverseArray);
            // 依据参数大小，执行减法操作
            if (contrastRes) {
                resultArray = arrminute(aReverseArray, bReverseArray);
            } else {
                resultArray = arrminute(bReverseArray, aReverseArray);
            }

            // 小数点操作
            let pointIndex = numIndex - resultArray.length + 1;
            if (pointIndex > 0) {
                for (let i = 0; i < pointIndex; i++) {
                    resultArray.push(0);
                }
            }
            if (numIndex) {
                resultArray.splice(numIndex, 0, '.');
                // 记录去除小数个数
                let tempNum = 0;
                for (let i = 0; i < numIndex; i++) {
                    if (!resultArray[0]) {
                        resultArray.shift();
                        tempNum++;
                    } else {
                        break;
                    }
                }
                if (tempNum == numIndex) {
                    resultArray.shift();
                }
            }
            // 反转数组，并转换为数值字符串
            resultArray.reverse();
            let result = resultArray.join('');
            // 判断最终数值正负
            if (!contrastRes) {
                result = '-' + result;
            }
            // 返回结果
            if (result == '') {
                return 0;
            } else {
                return result;
            }
        }
    },

    // 乘法运算
    multiply(a, b) {
        // 将传入参数转换为字符串
        a = '' + a;
        b = '' + b;
        // 数值校验
        if (!validate([a, b])) return '';
        // 记录最终数值单位
        let unit = 1;
        // 计算结果最终单位
        if (a.charAt(0) == '-') {
            a = a.substring(1);
            unit *= -1;
        }
        if (b.charAt(0) == '-') {
            b = b.substring(1);
            unit *= -1;
        }

        // 记录指定参数小数点后数字所在位置
        let numIndex = 0;
        // 记录传入参数小数点位置
        let aIndex = a.indexOf('.');
        let bIndex = b.indexOf('.');
        // 记录传入参数反转数组
        let aReserveArray = a.split('').reverse();
        let bReserveArray = b.split('').reverse();
        // 计算传入参数小数点后数字位置并去除小数点
        if (aIndex != -1) {
            let tempNumIndex = a.length - aIndex - 1;
            numIndex += tempNumIndex;
            aReserveArray.splice(tempNumIndex, 1);
        }
        if (bIndex != -1) {
            let tempNumIndex = b.length - bIndex - 1;
            numIndex += tempNumIndex;
            bReserveArray.splice(tempNumIndex, 1);
        }
        let tempArray = [];
        // 遍历b参数反转数组
        for (let i = 0; i < bReserveArray.length; i++) {
            let temp = [];
            // 如未记录数字，则设置为0
            for (let j = 0; j < i; j++) {
                temp.push(0);
            }
            // 将a数组每位数字与b数组当前位相乘，并记录
            for (let j = 0; j < aReserveArray.length; j++) {
                temp.push(bReserveArray[i] * aReserveArray[j]);
            }
            // 记录b数组每位数字计算结果
            tempArray.push(tosingle(temp));
        }
        // 将计算结果相加，并将最终结果转换为数值数组
        let resultArray = tosingle(arrplus(tempArray));

        // 记录小数点位置
        let pointIndex = numIndex - resultArray.length + 1;
        if (pointIndex > 0) {
            for (let i = 0; i < pointIndex; i++) {
                resultArray.push(0);
            }
        }
        // 如小数点存在添加小数点
        if (numIndex) {
            resultArray.splice(numIndex, 0, '.');
            let t = 0;
            for (let i = 0; i < numIndex; i++) {
                if (!resultArray[0]) {
                    resultArray.shift();
                    t++;
                } else {
                    break;
                }
            }
            if (t == numIndex) {
                resultArray.shift();
            }
        }
        resultArray.reverse();
        // 将结果数组转为数值字符串
        let result = resultArray.join('');
        if (unit == -1) {
            result = '-' + result;
        }
        if (result == '') {
            return 0;
        } else {
            return result;
        }
    },

    // 除法运算
    divide(a, b, c) {
        // 如未获取到传入精确度，则设置默认精确度
        c = typeof c == 'undefined' ? 30 : c;
        // 排除被除数为0的情况
        if (b == 0) {
            Gikam.alert('被除数不可以为0');
            return '';
        }
        // 将传入参数转为字符串
        a = '' + a;
        b = '' + b;
        // 数值校验
        if (!validate([a, b])) return '';
        // 记录最终数值单位
        let unit = 1;
        // 计算最终数值单位
        if (a.charAt(0) == '-') {
            a = a.substring(1);
            unit *= -1;
        }
        if (b.charAt(0) == '-') {
            b = b.substring(1);
            unit *= -1;
        }
        // 记录指定参数小数点后数字所在位置
        let numIndex = 0;
        // 记录传入参数小数点位置
        let aIndex = a.indexOf('.');
        let bIndex = b.indexOf('.');
        // 记录传入参数反转数组
        let aReverseArray = a.split('').reverse();
        let bReverseArray = b.split('').reverse();
        // 计算小数点最终位置
        if (aIndex != -1) {
            let tempNumIndex = a.length - aIndex - 1;
            numIndex += tempNumIndex;
            aReverseArray.splice(tempNumIndex, 1);
        }
        if (bIndex != -1) {
            let tempNumIndex = b.length - bIndex - 1;
            numIndex -= tempNumIndex;
            bReverseArray.splice(tempNumIndex, 1);
        }

        // 为传入数字末尾添加小数，保证精度
        let tempArray = [];
        if (numIndex > 0) {
            for (let i = 0; i < numIndex; i++) {
                bReverseArray.unshift(0);
            }
        } else if (numIndex < 0) {
            for (let i = 0; i < -numIndex; i++) {
                aReverseArray.unshift(0);
            }
        }
        //去除参数无效小数
        while (aReverseArray[aReverseArray.length - 1] == 0) {
            aReverseArray.pop();
        }
        while (bReverseArray[bReverseArray.length - 1] == 0) {
            bReverseArray.pop();
        }

        // 记录精确度
        let fix = c;
        for (let i = 0; i < fix; i++) {
            aReverseArray.unshift(0);
        }
        // 计算状态
        let state = true;
        while (state) {
            // 记录当前数值长度
            let aLength = aReverseArray.length;
            let bLength = bReverseArray.length;
            //判断数值长度大小用于计算最终结果
            if (aLength > bLength) {
                let t = aLength - bLength;
                let temp = [];
                if (Number(aReverseArray[aLength - 1]) > Number(bReverseArray[bLength - 1])) {
                    for (let i = 0; i < t; i++) {
                        temp.push(0);
                    }
                    temp.push(1);
                } else {
                    for (let i = 0; i < t - 1; i++) {
                        temp.push(0);
                    }
                    temp.push(1);
                }
                tempArray.push(temp);
                let u = temp.slice(0, temp.length - 1).concat(bReverseArray);
                aReverseArray = tosingle2(arrminute(aReverseArray, u));
            } else if (aLength == bLength) {
                let index = 0;
                for (let i = 0; i < aLength; i++) {
                    if (aReverseArray[aLength - 1 - i] > bReverseArray[bLength - 1 - i]) {
                        tempArray.push([1]);
                        aReverseArray = tosingle2(arrminute(aReverseArray, bReverseArray));
                        break;
                    } else if (aReverseArray[aLength - 1 - i] == bReverseArray[bLength - 1 - i]) {
                        index++;
                    } else if (aReverseArray[aLength - 1 - i] < bReverseArray[bLength - 1 - i]) {
                        state = false;
                        tempArray.push([0]);
                        break;
                    }
                }
                if (index == aLength) {
                    tempArray.push([1]);
                    aReverseArray = tosingle2(arrminute(aReverseArray, aReverseArray));
                    state = false;
                }
            } else if (aLength < bLength) {
                tempArray.push([0]);
                state = false;
            }
        }
        let resultArray = tosingle2(arrplus(tempArray));
        let x = fix - resultArray.length + 1;
        if (x > 0) {
            for (let i = 0; i < x; i++) {
                resultArray.push(0);
            }
        }

        // 依据传入精确位数处理结果值
        if (fix) {
            resultArray.splice(fix, 0, '.');
            let index = 0;
            for (let i = 0; i < fix; i++) {
                if (!resultArray[0]) {
                    resultArray.shift();
                    index++;
                } else {
                    break;
                }
            }
            if (index == fix) {
                resultArray.shift();
            }
        }

        // 反转数组并计算最终数值字符串
        resultArray.reverse();
        let result = resultArray.join('');
        // 依据计算单位正负计算最终正负值
        if (unit == -1) {
            result = '-' + result;
        }
        if (result == '') {
            return 0;
        } else {
            return result;
        }
    }
};
// 去除calculator的隐式原型，用于去除Object对象的默认函数
// 只提供calculator的加、减、乘和除四个函数
numberUtils.__proto__ = null;
export default numberUtils;
