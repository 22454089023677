<template>
    <div
        class="time-picker"
        :class="{ circular: circular }"
        @click.stop
        @mousedown.stop
        @mousewheel.stop
        @DOMMouseScroll.stop
    >
        <div class="time-select">
            <div class="time-icon">
                <clock color="#999" />
            </div>
            <div class="time-value">{{ selectTime }}</div>
        </div>
        <div class="time-container">
            <div class="title">
                <div class="hour">{{ $i18n('time.hour') }}</div>
                <div class="minute">{{ $i18n('time.minute') }}</div>
            </div>
            <div class="toolbar">
                <div class="time-btn up" @click="timeReduce('hour')">
                    <timeUpImg />
                </div>
                <div class="time-btn up" @click="timeReduce('minute')">
                    <timeUpImg />
                </div>
            </div>
            <div class="time" @dblclick="handleTimeDblclick" @click.stop.prevent="handleTimeClick">
                <div class="time-scroll-container" @wheel="scrollWheel('hour', $event)">
                    <div class="selected-time hour" :data-hour="activeHour" v-text="activeHour"></div>
                    <scroll
                        :scrollingX="false"
                        :scrollingY="!circular"
                        class="time-scroll"
                        ref="hour-scroll"
                        :distance="30"
                        :innerPanel="true"
                    >
                        <div v-show="circular" class="time-item hour" data-hour="21">21</div>
                        <div v-show="circular" class="time-item hour" data-hour="22">22</div>
                        <div v-show="circular" class="time-item hour" data-hour="23">23</div>
                        <div
                            v-for="item in hourArray"
                            :key="item"
                            :data-hour="item"
                            :class="['time-item hour', { selected: item == activeHour }]"
                        >
                            {{ item }}
                        </div>
                        <div v-show="circular" class="time-item hour" data-hour="00">00</div>
                        <div v-show="circular" class="time-item hour" data-hour="01">01</div>
                        <div v-show="circular" class="time-item hour" data-hour="02">02</div>
                    </scroll>
                </div>
                <div class="time-scroll-container" @wheel="scrollWheel('minute', $event)">
                    <div class="selected-time minute" :data-minute="activeMinute" v-text="activeMinute"></div>
                    <scroll
                        :scrollingX="false"
                        :scrollingY="!circular"
                        scroll
                        class="time-scroll"
                        ref="minute-scroll"
                        :distance="30"
                        :innerPanel="true"
                    >
                        <div v-show="circular" class="time-item minute" data-minute="57">57</div>
                        <div v-show="circular" class="time-item minute" data-minute="58">58</div>
                        <div v-show="circular" class="time-item minute" data-minute="59">59</div>
                        <div
                            v-for="item in minuteArray"
                            :key="item"
                            :data-minute="item"
                            :class="['time-item minute', { selected: item == activeMinute }]"
                        >
                            {{ item }}
                        </div>
                        <div v-show="circular" class="time-item minute" data-minute="00">00</div>
                        <div v-show="circular" class="time-item minute" data-minute="01">01</div>
                        <div v-show="circular" class="time-item minute" data-minute="02">02</div>
                    </scroll>
                </div>
            </div>
            <div class="toolbar">
                <div class="time-btn down" @click="timeRaise('hour')">
                    <timeUpImg />
                </div>
                <div class="time-btn down" @click="timeRaise('minute')">
                    <timeUpImg />
                </div>
            </div>
        </div>
        <div class="select-nowtime">
            <div class="btn-container">
                <div class="clean-btn" @click.stop="clean">{{ $i18n('time.clean') }}</div>
                <div class="nowtime-btn" @click.stop="selectNowTime">{{ $i18n('time.now') }}</div>
                <div class="confirm-btn" @click="confirm">{{ $i18n('time.confirm') }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import jQuery from 'jquery';
import Gikam from '../../../../core/gikam-core';

export default {
    props: {
        timeInput: Object
    },

    computed: {
        //计算已选择时间
        selectTime() {
            //排除未选择时间时展示:号的情况
            if (!this.activeHour && !this.activeMinute) return '';
            return this.activeHour + ':' + this.activeMinute;
        },

        //获取小时数,用户控件小时部分的展示
        hourArray() {
            let hourArrayTemp = [];
            for (let index = 0; index <= this.hour; index++) {
                hourArrayTemp.push(this.formatterNumber(index));
            }
            return hourArrayTemp;
        },

        //获取分钟数,用于控件分钟部分的展示
        minuteArray() {
            let minuteArrayTemp = [];
            for (let index = 0; index <= this.minute; index++) {
                minuteArrayTemp.push(this.formatterNumber(index));
            }
            return minuteArrayTemp;
        }
    },

    mounted() {
        this.computedPosition();
        this.initScroll();
    },

    data() {
        return {
            hour: 23,
            minute: 59,
            activeHour: null,
            activeMinute: null,
            circular: this.timeInput.circular,
            $selectedTimeDom: void 0
        };
    },

    created() {
        this.activeHour = Gikam.DateUtils.formatter(this.timeInput.time, 'hh') || 0;
        this.activeMinute = Gikam.DateUtils.formatter(this.timeInput.time, 'mm') || 0;
        Object.assign(this, this.options);
        if (Gikam.isNotEmpty(this.propValue)) {
            this.value = this.propValue;
        }
    },

    watch: {
        activeHour(val) {
            let formatterVal = parseInt(val);
            if (isNaN(formatterVal)) return;
            if (formatterVal < 0) formatterVal = 23;
            if (formatterVal > 23) formatterVal = 0;
            //解决当用户只选择小时时，分钟为空的情况
            if (isNaN(parseInt(formatterVal))) {
                this.activeMinute = '00';
            }
            this.activeHour = this.formatterNumber(formatterVal);
            this.$nextTick(() => {
                this.scrollToSelected('hour');
            });
        },

        activeMinute(val) {
            let formatterVal = parseInt(val);
            if (isNaN(formatterVal)) return;
            if (formatterVal < 0) formatterVal = 59;
            if (formatterVal > 59) formatterVal = 0;
            //解决当用户只选择分钟时，小时为空的情况
            if (isNaN(parseInt(formatterVal))) {
                this.activeHour = '00';
            }
            this.activeMinute = this.formatterNumber(formatterVal);
            this.$nextTick(() => {
                this.scrollToSelected('minute');
            });
        }
    },

    methods: {
        //监听鼠标滚轮时间，时间为循环模式时执行
        scrollWheel(state, e) {
            if (!this.circular) return;
            const deltaY = e.deltaY || e.wheelDelta;
            if (deltaY > 0) {
                this.timeRaise(state);
            } else {
                this.timeReduce(state);
            }
        },

        //减少时间
        timeReduce(state) {
            if (state === 'hour') this.activeHour--;
            else this.activeMinute--;
        },

        //增加时间
        timeRaise(state) {
            if (state === 'hour') this.activeHour++;
            else this.activeMinute++;
        },

        //触发时间点击事件
        handleTimeClick(e) {
            //获取当前点击元素的class内容，用于判断当前点击元素为时或分
            let className = e.target.className;
            this.$selectedTimeDom = e.target;
            if (className.indexOf('hour') >= 0) {
                this.activeHour = e.target.dataset.hour;
            } else if (className.indexOf('minute') >= 0) {
                this.activeMinute = e.target.dataset.minute;
            } else {
                this.$selectedTimeDom = null;
            }
        },

        //双击时间
        handleTimeDblclick() {
            this.confirm();
        },

        //将选中元素滚动至控件中间
        scrollToSelected(type) {
            if (!type) return;

            let scrollTop = 0;
            let scrollType;

            if (type === 'hour') {
                scrollType = 'hour-scroll';
            } else if (type === 'minute') {
                scrollType = 'minute-scroll';
            }

            this.$selectedTimeDom = this.$refs[scrollType].$el.querySelector('.selected');

            if (this.circular) {
                //重置时间选项样式
                this.$refs[scrollType].$el.querySelectorAll('.time-item').forEach(element => {
                    element.classList.remove('grey');
                });
                //指定时间选项置灰
                this.$selectedTimeDom.nextSibling.nextSibling.classList.add('grey');
                this.$selectedTimeDom.previousSibling.previousSibling.classList.add('grey');
            }

            //获取选中元素的高度
            let selectedHeight = this.$selectedTimeDom.offsetHeight;
            //获取滚动控件的高度
            let scrollHeight = this.$refs[scrollType].$el.offsetHeight;
            //计算具体滚动位置
            scrollTop = this.$selectedTimeDom.offsetTop - scrollHeight / 2 + selectedHeight / 2 || 0;
            //滚动选中元素 time-item
            this.$refs[scrollType].scrollTo({ y: scrollTop });

            this.$selectedTimeDom = null;
        },

        //选中当前时间
        selectNowTime() {
            const time = new Date();
            this.activeHour = Gikam.DateUtils.formatter(time, 'hh');
            this.activeMinute = Gikam.DateUtils.formatter(time, 'mm');
            this.confirm();
        },

        //依据选中事件重新计算time最终值
        confirm() {
            this.timeInput.value = this.selectTime;
            this.destroy();
            this.timeInput.focus(true);
        },

        clean() {
            this.timeInput.value = '';
            this.destroy();
            this.timeInput.focus(true);
        },

        destroy() {
            this.timeInput.cleanTimePicker();
        },

        //传入参数为单位数时,在数字前添加0
        formatterNumber(number) {
            if (isNaN(number)) return;
            let tempNumber = parseInt(number);
            if (tempNumber < 10) {
                tempNumber = '0' + tempNumber;
            }
            return tempNumber;
        },

        //初始化 - 依据默认时间，将scroll滚动至已存在数据位置
        initScroll() {
            if (this.activeHour) this.scrollToSelected('hour');
            if (this.activeMinute) this.scrollToSelected('minute');
        },

        //初始化 - 定位timePicker
        computedPosition() {
            let $timeInput = jQuery(this.timeInput.$refs['input']);

            //用于绑定timePicker的style
            let pickerStyle = {
                position: 'absolute'
            };

            pickerStyle.top = $timeInput.offset().top + $timeInput.outerHeight() + 6;

            //计算时间控件水平位置
            //如果当前输入组件左偏移 + 时间组件宽度 < 显示宽度
            if ($timeInput.offset().left + jQuery(this.$el).width() < window.top.document.body.clientWidth) {
                //时间控件最终位置为输入控件左侧初始位置
                pickerStyle.left = $timeInput.offset().left;
            } else {
                ////时间控件最终位置为body显示位置偏移右偏8
                pickerStyle.right = 8;
            }
            if (window !== window.top) {
                const iframeRect = window.top.document.body
                    .querySelector(`[name=${window.name}]`)
                    .getBoundingClientRect();
                pickerStyle.right && (pickerStyle.right += iframeRect.right);
                pickerStyle.left && (pickerStyle.left += iframeRect.left);
                pickerStyle.top += iframeRect.top;
            }

            //为时间组件绑定定位样式
            let $dom = jQuery(this.$el).css(pickerStyle);

            //如果当前输入控件上方显示大小不足以展示时间控件则切换展示方向
            if ($dom.outerHeight() + $dom.offset().top > jQuery(window.top).height()) {
                $dom.addClass('top').css({
                    top: $timeInput.offset().top - $dom.outerHeight() - 8
                });
            }
        }
    }
};
</script>

<style scoped>
@keyframes time-slide-bottom {
    from {
        transform: translate3d(0, 20px, 0);
        opacity: 0.3;
    }
    to {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

@keyframes time-slide-top {
    from {
        transform: translate3d(0, -20px, 0);
        opacity: 0.3;
    }
    to {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

.time-picker {
    user-select: none;
    background-color: #fff;
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    font-family: 'Microsoft YaHei', serif;
    width: 248px;
    position: absolute;
    z-index: 10;
    animation: time-slide-bottom 0.3s;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.16);
}

.time-picker.top {
    animation: time-slide-top 0.3s;
}

.time-picker:before {
    content: '';
    position: absolute;
    top: -7px;
    left: 30px;
    width: 10px;
    height: 10px;
    background-color: #fff;
    transform: rotate(45deg);
    border: 1px solid #e5e5e5;
    border-bottom: none;
    border-right: none;
}

.time-picker.top:before {
    bottom: -7px;
    top: auto;
    transform: rotate(-135deg);
}

.time-picker > .time-select {
    height: 36px;
    border-bottom: 1px solid #f0f0f0;
    display: flex;
    align-items: center;
    overflow: hidden;
    padding: 0 16px;
}

.time-picker > .time-select > .time-icon {
    margin-right: 6px;
}
.time-select > .time-icon svg,
.time-select > .time-icon {
    width: 18px;
    height: 18px;
}

.time-picker > .time-select > .time-value {
    line-height: 36px;
    font-size: 14px;
}

.time-picker > .select-nowtime {
    height: 36px;
    display: flex;
    align-items: center;
    padding: 0 16px;
    justify-content: flex-end;
}

.time-picker > .select-nowtime > a {
    color: #007aff;
    font-size: 12px;
    text-decoration: none;
}

.time-picker .time-container {
    height: 184px;
    padding: 0 16px;
}

.time-picker .time-container > .title {
    height: 32px;
    line-height: 32px;
    display: flex;
    justify-content: space-around;
    overflow: hidden;
}
.time-picker .time-container .toolbar {
    display: none;
}

.time-picker .time-container > .time {
    width: 216px;
    height: 152px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
}

.time-picker .time-container > .time .time-item {
    height: 30px;
    line-height: 30px;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
}

.time-picker .time-container > .time .time-item:hover {
    background-color: #eee;
    color: #000;
}

.time-picker .time-container > .time .time-item.selected {
    background-color: #007aff;
    color: #fff;
}

.time-picker .time-scroll-container {
    display: flex;
    width: 107px;
    height: 100%;
    border-radius: 4px;
    border: 1px solid #d6d6d6;
}

.time-picker .time-scroll-container > .selected-time {
    display: none;
}

.time-picker .time-scroll-container > .time-scroll {
    width: 100%;
    height: 100%;
}

.time-picker .hour-input,
.time-picker .minute-input {
    width: 50px;
    border: none;
    text-align: center;
    font-size: 14px;
    color: #000;
}

.confirm-btn,
.clean-btn,
.nowtime-btn {
    height: 24px;
    width: 52px;
    background-color: #007aff;
    color: #fff;
    border: 1px solid #d9d9d9;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    cursor: pointer;
}

.clean-btn,
.nowtime-btn {
    background-color: #fff;
    border: 1px solid #d9d9d9;
    margin-right: 10px;
    color: rgba(0, 0, 0, 0.65);
}

.time-picker .btn-container {
    display: flex;
    align-items: center;
}

/* 时间循环样式 */
.time-picker.circular .time-container {
    padding: 0;
    height: auto;
}

.time-picker.circular .time-container > .title {
    border-bottom: 1px solid #f0f0f0;
    padding-left: 16px;
    padding-right: 16px;
}

.time-picker.circular .time-container > .time {
    margin-left: 16px;
    margin-right: 16px;
}

.time-picker.circular .time-container > .toolbar {
    height: 30px;
    line-height: 30px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    overflow: hidden;
    padding-left: 16px;
    padding-right: 16px;
}

.time-picker.circular .time-container > .toolbar > .time-btn {
    cursor: pointer;
    width: 30px;
    height: 30px;
    padding: 6px;
}
.time-picker.circular .time-container > .toolbar > .time-btn.down {
    transform: rotate(180deg);
}

.time-picker.circular .time-scroll-container {
    position: relative;
    border: none;
}

.time-picker.circular .select-nowtime {
    border-top: 1px solid #f0f0f0;
}

.time-picker.circular .time-container > .time .time-item {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.65);
}

.time-picker.circular .time-container > .time .time-item.grey {
    color: rgba(0, 0, 0, 0.45);
}

.time-picker.circular .time-container > .time .time-item.selected {
    background-color: transparent;
    color: transparent;
}

.time-picker.circular .time-scroll-container > .selected-time {
    position: absolute;
    display: block;
    top: 50%;
    margin-top: -16px;
    right: 16px;
    left: 16px;
    height: 28px;
    line-height: 28px;
    color: rgb(0, 122, 255);
    text-align: center;
    border: 1px solid #eee;
    border-left: none;
    border-right: none;
    background-color: #fff;
    z-index: 1;
    font-size: 12px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.06);
}

.time-picker.circular .time-scroll-container > .selected-time.hour {
    right: 12px;
}

.time-picker.circular .time-scroll-container > .selected-time.minute {
    left: 12px;
}

.time-picker.circular .time-container > .time .time-item:hover {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.65);
}

.time-picker.circular .time-container > .time .time-item.selected:hover {
    background-color: transparent;
    color: transparent;
}

.time-picker.circular .time-container > .time .time-item.grey:hover {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.45);
}
</style>
