<template>
    <svg
        viewBox="0 0 6 10"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        :width="width"
        :height="height"
    >
        <g id="表格" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g :fill="color" id="表格-行数据分组" transform="translate(-550.000000, -1409.000000)" fill-rule="nonzero">
                <g id="示例二--收起状态（悬停）" transform="translate(124.000000, 880.000000)">
                    <g id="Group-24" transform="translate(426.000000, 522.000000)">
                        <g id="分页---下一页" transform="translate(0.000000, 7.000000)">
                            <g id="Group-5">
                                <g id="Group-7">
                                    <path
                                        d="M6,5.02746548 L0.772655231,10 L0.067698782,9.33037275 L5.29504355,4.35783824 L6,5.02746548 Z M5.2910647,5.70088873 L0,0.670581798 L0.706928371,0 L5.99401422,5.03220493 L5.29007291,5.70183218 L5.2910647,5.70088873 Z"
                                        id="Shape"
                                        transform="translate(3.000000, 5.000000) rotate(-180.000000) translate(-3.000000, -5.000000) "
                                    ></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
import baseSvgImg from './baseSvgImg';
export default {
    extends: baseSvgImg,
    props: {
        color: {
            default: '#d9d9d9',
            type: String
        }
    }
};
</script>

<style scoped>
svg {
    display: block;
}
</style>
