<template>
    <div
        v-if="readonly || invisible"
        class="year-input readonly"
        :class="{ invisible: invisible }"
        @mousedown.stop
        @click="clickHandle"
    >
        <div class="readonly-text">{{ value }}</div>
    </div>
    <div v-else class="year-input" :class="{ 'validate-error': !validateResult }" @mousedown.stop @click="clickHandle">
        <input :name="options.field" v-model="value" :readonly="inputEditable" ref="input" />
        <div class="year-input-icon" @click.stop="focus"></div>
    </div>
</template>
<script>
import GikamCore from '../../../../core/gikam-core.js';
import jQuery from 'jquery';
import Vue from 'vue';
import yearPicker from './yearPicker.vue';

const Gikam = top === window ? GikamCore : top.Gikam;

export default {
    name: 'yearField',
    props: {
        options: Object,
        propValue: String,
        rowIndex: Number,
        cellIndex: Number,
        propInvisible: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        inputEditable() {
            return this.options.editable === true ? false : 'readonly';
        }
    },

    data() {
        return {
            yearPicker: void 0,
            validateResult: true,
            readonly: void 0,
            field: void 0,
            value: void 0,
            year: void 0,
            nowYear: void 0,
            isChooseYear: void 0,
            invisible: this.propInvisible
        };
    },

    created() {
        Object.assign(this, this.options);
        if (Gikam.isNotEmpty(this.propValue)) {
            this.value = this.propValue;
        }
    },

    watch: {
        value() {
            this.validateResult = true;
            this.$emit('change', this.options.field, this.value, this.rowIndex);
        },

        propValue(val) {
            this.value = val;
        }
    },

    methods: {
        cleanYearPicker() {
            Gikam.yearPicker.destroy();
        },

        popYearPicker() {
            if (this.yearPicker) {
                return;
            }

            let _this = this;
            if (Gikam.yearPicker) {
                this.cleanYearPicker();
            }

            if (!Gikam.isTrue(this.propInvisible)) {
                Gikam.simulatedEvent(document, 'mousedown');
            }

            if (this.readonly) {
                return;
            }

            const date = new Date();
            this.year = this.value ? Gikam.DateUtils.toDate(this.value + '-01-01') : date;
            this.isChooseYear = this.value ? Gikam.DateUtils.toDate(this.value + '-01-01') : 'null';
            this.nowYear = date.getFullYear();

            if (this.options.onBeforeSelect) {
                this.options.onBeforeSelect.apply(this.form || this.grid);
            }
            Gikam.yearPicker = new Vue({
                el: Gikam.createDom('div', window.top.document.body),
                components: { yearPicker },
                provide: {
                    yearInput: this
                },
                methods: {
                    destroy() {
                        jQuery(this.$el).remove();
                        this.$destroy();
                        Gikam.yearPicker = null;
                    }
                },
                render() {
                    return (
                        <year-picker
                            years={_this.year}
                            isChooseYears={_this.isChooseYear}
                            nowYear={_this.nowYear}
                            yearRange={_this.options.yearRange}
                        />
                    );
                }
            });
        },

        setYearRange(yearRange) {
            this.options.yearRange = yearRange;
        },

        dumpActiveCell() {
            Gikam.yearPicker && this.cleanYearPicker();
            this.$refs.input && this.$refs.input.blur();
        },

        activeCell() {
            if (this.invisible) {
                this.$emit('click', this);
            } else {
                this.focus();
            }
        },

        focus() {
            this.$refs.input && this.$refs.input.focus();
            this.popYearPicker();
            this.$emit('saveCoordinate');
        },

        //点击时保存当前单元格的坐标
        clickHandle() {
            if (!this.invisible) {
                this.$emit('saveCoordinate');
            } else {
                this.$emit('click', this);
            }
        }
    }
};
</script>

<style scoped>
.year-input {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: stretch;
    color: #666;
    font-size: 12px;
}

.year-input.readonly {
    display: flex;
    align-items: center;
}

.year-input input,
.year-input .readonly-text {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    padding-right: 30px;
    padding-left: 8px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 12px;
}

.year-input .readonly-text {
    background-color: #f4f4f4;
    display: flex;
    align-items: center;
}

.year-input.validate-error input {
    border-color: #ff6e6e;
}

.year-input input:focus {
    border: 1px solid rgba(0, 122, 255, 0.5);
}

.year-input .year-input-icon {
    width: 30px;
    background: url(../../../../../img/date/date.svg) no-repeat center;
    cursor: pointer;
    margin-left: -30px;
    background-size: 18px;
}

.invisible .readonly-text {
    background-color: transparent;
    border: none;
}
</style>
