<template>
    <svg
        version="1.1"
        id="图层_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 42 42"
        style="enable-background:new 0 0 42 42;"
        xml:space="preserve"
        :width="width"
        :height="height"
    >
        <g>
            <path
                :fill="color"
                class="st0"
                d="M32,14.5h6.5c0.8,0,1.5-0.7,1.5-1.5s-0.7-1.5-1.5-1.5H32c-0.8,0-1.5-0.7-1.5-1.5V3.5C30.5,2.7,29.8,2,29,2
		s-1.5,0.7-1.5,1.5V10C27.5,12.5,29.5,14.5,32,14.5z"
            />
            <path
                :fill="color"
                class="st0"
                d="M38.5,27.5H32c-2.5,0-4.5,2-4.5,4.5v6.5c0,0.8,0.7,1.5,1.5,1.5s1.5-0.7,1.5-1.5V32c0-0.8,0.7-1.5,1.5-1.5h6.5
		c0.8,0,1.5-0.7,1.5-1.5S39.3,27.5,38.5,27.5z"
            />
            <path
                :fill="color"
                class="st0"
                d="M13,2c-0.8,0-1.5,0.7-1.5,1.5V10c0,0.8-0.7,1.5-1.5,1.5H3.5C2.7,11.5,2,12.2,2,13s0.7,1.5,1.5,1.5H10
		c2.5,0,4.5-2,4.5-4.5V3.5C14.5,2.7,13.8,2,13,2z"
            />
            <path
                :fill="color"
                class="st0"
                d="M10,27.5H3.5C2.7,27.5,2,28.2,2,29s0.7,1.5,1.5,1.5H10c0.8,0,1.5,0.7,1.5,1.5v6.5c0,0.8,0.7,1.5,1.5,1.5
		s1.5-0.7,1.5-1.5V32C14.5,29.5,12.5,27.5,10,27.5z"
            />
        </g>
    </svg>
</template>

<script>
import baseSvgImg from './baseSvgImg';
export default {
    extends: baseSvgImg,
    props: {
        color: {
            default: '#ffffff',
            type: String
        }
    }
};
</script>

<style scoped>
svg {
    display: block;
}
</style>
