<template>
    <div v-if="readonly" class="simple-checkbox readonly" :style="styleFormatter()">
        <div :name="options.field" class="checkbox" :class="{ checked: value === '1' }">
            <tickImg v-if="value === '1'" v-bind="{ color: '#B4B4B4', width: '14px', height: '14px' }" />
        </div>
    </div>
    <div v-else class="simple-checkbox" :class="{ 'validate-error': !validateResult }" :style="styleFormatter()">
        <div :name="options.field" class="checkbox" :class="{ checked: value === '1' }" @click.stop="clickHandle">
            <tickImg v-if="value === '1'" v-bind="{ color: '#fff', width: '14px', height: '14px' }" />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        options: Object,
        propValue: String,
        rowIndex: Number,
        propReadonly: Boolean
    },

    data() {
        return {
            validateResult: true,
            readonly: this.propReadonly || this.options.readonly,
            field: this.options.field,
            value: this.propValue
        };
    },

    watch: {
        value() {
            this.$emit('change', this.options.field, this.value, this.rowIndex);
        },
        propValue(val) {
            this.value = val;
        }
    },

    methods: {
        clickHandle() {
            this.value = this.value === '1' ? '0' : '1';
        },

        styleFormatter() {
            if (this.options.styleFormatter) {
                return this.options.styleFormatter();
            }
        }
    }
};
</script>

<style scoped>
.simple-checkbox {
    width: 100%;
    height: 100%;
    display: flex;
    min-height: auto;
    align-items: center;
    padding-left: 10px;
}

.checkbox {
    width: 14px;
    height: 14px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    transition: border 0.2s;
    background-color: #fff;
}

.checkbox.checked {
    background-color: #007aff;
    background-repeat: no-repeat;
    background-position: center;
    border: none;
}

.checkbox:hover {
    border: 1px solid #007aff;
    box-shadow: 0 0 3px #007aff;
}

.checkbox.checked:hover {
    border: none;
}

.readonly .checkbox {
    background-color: #eee;
}

.readonly .checkbox:hover {
    box-shadow: none;
    border: 1px solid #d9d9d9;
}

.readonly .checkbox.checked {
    background-repeat: no-repeat;
    background-position: center;
    border: 1px solid #d9d9d9;
}

.readonly .checkbox.checked >>> svg {
    position: relative;
    top: -1px;
    left: -1px;
}
</style>
