import Gikam from './gikam-core';

export function openFile(fileId, readonly, otherParam) {
    if (!fileId) return;
    const supportType = [
        {
            code: 'text',
            typeArray: [
                'doc',
                'docm',
                'docx',
                'dot',
                'dotm',
                'dotx',
                'epub',
                'fodt',
                'htm',
                'html',
                'mht',
                'odt',
                'ott',
                'pdf',
                'rtf',
                'txt',
                'djvu',
                'xps'
            ]
        },
        {
            code: 'spreadsheet',
            typeArray: ['csv', 'fods', 'ods', 'ots', 'xls', 'xlsm', 'xlsx', 'xlt', 'xltm', 'xltx']
        },
        {
            code: 'presentation',
            typeArray: ['fodp', 'odp', 'otp', 'pot', 'potm', 'potx', 'pps', 'ppsm', 'ppsx', 'ppt', 'pptm', 'pptx']
        }
    ];
    let typeStr = '';
    Gikam.getJson(Gikam.IFM_CONTEXT + '/core/module/item/files/' + fileId).done(file => {
        supportType.forEach(item => {
            if (item.typeArray.indexOf(file.fileExt) !== -1) {
                typeStr = item.code;
            }
        });

        let pdfView = Gikam.pdf && Gikam.pdf.view;
        if (pdfView === 'pdfjs' && file.fileExt.toLocaleLowerCase() === 'pdf') {
            Gikam.printFile(fileId, readonly);
        } else if (typeStr) {
            Gikam.openOfficeFile({
                fileId: fileId,
                readonly: readonly,
                otherParam: otherParam ? otherParam : null
            });
        } else {
            Gikam.printFile(fileId, readonly);
        }
    });
}

export function openOfficeFile(param) {
    const documentParam = {
        fileId: param.fileId,
        mode: param.readonly ? 'view' : 'edit'
    };
    Gikam.isNotEmpty(param.otherParam)
        ? Gikam.openFileByOffice(documentParam, param.otherParam)
        : Gikam.openFileByOffice(documentParam);
}

/**
 * @description 打开office编辑页面
 * @public
 * @param {Object, Object} 编辑器参数
 * @memberof this
 */

export function openFileByOffice(options, value) {
    if (Gikam.isNotEmpty(value)) {
        options.otherParam = value;
    }
    return new Gikam.EditorOffice(options);
}

export function printFile(fileId, readonly, waterText, printFlag, pdfWaterFlag) {
    const pageUrl = Gikam.IFM_CONTEXT + '/core/module/item/files/page/open-file';
    const imgFile = [
        'jpg',
        'jpeg',
        'png',
        'gif',
        'svg',
        'webp',
        'bmp',
        'JPG',
        'JPEG',
        'PNG',
        'GIF',
        'SVG',
        'WEBP',
        'BMP'
    ];
    const param = {
        fileId: fileId, // 文件id
        readonly: readonly, // 是否只读，office文件只读的话不能加水印不能有打印按钮；
        waterText: escape(Gikam.propI18N(waterText)), // readonly为false的时候,office文件才会加上水印,office水印内容为所传的值；pdf水印内容后台处理
        printFlag: printFlag, // readonly为
        // false的时候才执行，是否打印，文件显示打印按钮的话为true
        waterFlag: pdfWaterFlag
    };
    let url = pageUrl + Gikam.param(param);

    Gikam.getConstantValue('FILESERVER_CLUSTER')
        .done(rt => {
            url += '&fileserverCluster=' + rt.constantValue;
        })
        .then(() => {
            return Gikam.getJson(Gikam.IFM_CONTEXT + '/core/module/item/files/' + fileId);
        })
        .done(file => {
            if (imgFile.indexOf(file.fileExt.toLocaleLowerCase()) > -1) {
                const modal = Gikam.create('modal', {
                    title: file.name,
                    toolbarTop: true,
                    showMaxBtn: false
                });
                Gikam.create('viewer', {
                    renderTo: modal.window.$dom,
                    images: [Gikam.IFM_CONTEXT + decodeURIComponent(file.downloadUrl)]
                });
            } else {
                Gikam.create('modal', {
                    title: file.name,
                    iframe: true,
                    isFullScreen: true,
                    src: url
                });
            }
        });
}
