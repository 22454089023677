<template>
    <div class="color-picker" ref="picker">
        <div class="color-picker__mask" v-if="readonly"></div>
        <div class="color-picker__trigger">
            <span
                class="color-picker__color"
                :class="{ 'no-color': noValue }"
                :style="{
                    backgroundColor: value
                }"
            ></span>
        </div>
        <span class="color-picker__choose" @click.stop="handleTrigger">
            <colorImg width="14px" height="14px" color="#666666" />
        </span>
    </div>
</template>

<script>
import Gikam from 'gikam';
import Color from './util/color';
export default {
    name: 'colorPicker',
    props: {
        options: Object,
        propValue: String,
        rowIndex: Number,
        cellIndex: Number,
        propReadonly: {
            type: Boolean,
            default: void 0
        },
        propInvisible: {
            type: Boolean,
            default: false
        }
    },

    data() {
        const showAlpha = this.options.showAlpha ? this.options.showAlpha : false;
        const color = new Color({
            enableAlpha: showAlpha,
            format: showAlpha ? 'rgba' : 'rgb'
        });
        return {
            color,
            showAlpha,
            colorFormat: this.options.colorFormat,
            value: '',
            readonly: this.propReadonly ? this.propReadonly : this.options.readonly ? this.options.readonly : false
        };
    },

    computed: {
        noValue() {
            return !this.value || this.value === 'transparent' ? true : false;
        }
    },

    watch: {
        propValue(val) {
            this.$nextTick(() => {
                this.color.fromString(val);
                this.value = this.displayedRgb(this.color, this.showAlpha);
            });
        }
    },

    methods: {
        displayedRgb(color, showAlpha) {
            if (!(color instanceof Color)) {
                return;
            }
            const { r, g, b } = color.toRgb();
            return showAlpha ? `rgba(${r}, ${g}, ${b}, ${color.get('alpha') / 100})` : `rgb(${r}, ${g}, ${b})`;
        },

        handleTrigger() {
            if (this.readonly) {
                return;
            }
            const _this = this;
            new Gikam.Vue({
                el: Gikam.createDom('div', document.body),
                components: {
                    picker: () => import('./components/pickerDropdown')
                },
                render() {
                    return (
                        <picker
                            target={_this.$refs.picker}
                            showAlpha={_this.showAlpha}
                            currentValue={_this.noValue ? 'transparent' : ''}
                            format={_this.colorFormat}
                            options={_this.options}
                            color={_this.color}
                            onConfirm={_this.confirmValue}
                            onClear={_this.clearValue}
                        />
                    );
                }
            });
        },

        confirmValue() {
            const _value = this.displayedRgb(this.color, this.showAlpha);
            const currentValueColor = new Color({
                enableAlpha: this.showAlpha,
                format: this.colorFormat
            });
            currentValueColor.fromString(_value);
            const currentValueColorRgb = this.displayedRgb(currentValueColor, this.showAlpha);
            if (this.value !== currentValueColorRgb) {
                this.$emit('change', this.options.field, currentValueColor.value, this.rowIndex, this.cellIndex);
            }
        },

        clearValue() {
            this.$nextTick(() => {
                if (this.value) {
                    this.color.fromString(this.value);
                } else {
                    this.color.fromString('');
                }
            });
        }
    },

    mounted() {
        if (this.propValue && this.propValue !== 'transparent') {
            this.color.fromString(this.propValue);
            this.value = this.displayedRgb(this.color, this.showAlpha);
        } else {
            this.color.fromString('#fff');
        }
    }
};
</script>

<style scoped>
.color-picker {
    width: 100%;
    height: 30px;
    padding: 2px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    position: relative;
}

.color-picker__trigger {
    position: relative;
    min-height: 20px !important;
    width: 25px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.color-picker__color {
    position: absolute;
    top: 5px;
    bottom: 5px;
    left: 6px;
    right: 5px;
}

.color-picker__color.no-color {
    background-image: url('./img/mosaic.png');
}

.color-picker__choose {
    cursor: pointer;
    position: absolute;
    right: 8px;
    top: 8px;
}

.color-picker__mask {
    position: absolute;
    top: 1px;
    bottom: 1px;
    left: 1px;
    right: 1px;
    z-index: 1;
    cursor: not-allowed;
    background-color: rgba(255, 255, 255, 0.7);
}
</style>
