<template>
    <svg
        version="1.1"
        id="图层_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 42 42"
        style="enable-background:new 0 0 42 42;"
        xml:space="preserve"
        :width="width"
        :height="height"
    >
        <g>
            <path
                :fill="color"
                class="st0"
                d="M2,29L2,29v6.5C2,38,4,40,6.5,40H13l0,0c0.8,0,1.5-0.7,1.5-1.5S13.8,37,13,37l0,0H6.5C5.7,37,5,36.3,5,35.5V29
		l0,0l0,0c0-0.8-0.7-1.5-1.5-1.5S2,28.2,2,29L2,29z"
            />
            <path
                :fill="color"
                class="st0"
                d="M2,13c0,0.8,0.7,1.5,1.5,1.5S5,13.8,5,13l0,0V6.5C5,5.7,5.7,5,6.5,5H13l0,0c0.8,0,1.5-0.7,1.5-1.5S13.8,2,13,2
		l0,0H6.5C4,2,2,4,2,6.5V13L2,13z"
            />
            <path
                :fill="color"
                class="st0"
                d="M29,40L29,40h6.5c2.5,0,4.5-2,4.5-4.5V29l0,0l0,0c0-0.8-0.7-1.5-1.5-1.5S37,28.2,37,29l0,0l0,0v6.5
		c0,0.8-0.7,1.5-1.5,1.5H29l0,0c-0.8,0-1.5,0.7-1.5,1.5S28.2,40,29,40z"
            />
            <path
                :fill="color"
                class="st0"
                d="M29,5L29,5h6.5C36.3,5,37,5.7,37,6.5V13l0,0c0,0.8,0.7,1.5,1.5,1.5S40,13.8,40,13l0,0V6.5C40,4,38,2,35.5,2H29
		l0,0c-0.8,0-1.5,0.7-1.5,1.5S28.2,5,29,5z"
            />
        </g>
    </svg>
</template>

<script>
import baseSvgImg from './baseSvgImg';
export default {
    extends: baseSvgImg,
    props: {
        color: {
            default: '#ffffff',
            type: String
        }
    }
};
</script>

<style scoped>
svg {
    display: block;
}
</style>
