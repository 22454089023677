import Vue from 'vue';
import elnAudit from './template/elnAuditTrail.vue';
import Base from './base.js';

let defaultOptions = {
    list: []
}

export default class ElnAuditTrail extends Base {

    constructor(options) {
        super(options)
        this.initialize(options, defaultOptions).init();
    }

    createModel() {
        let _this = this;
        const auditDom = document.createElement('div');
        this.options.renderTo.appendChild(auditDom);

        let elnAuditTrail = new Vue({
            el: auditDom,
            components: {
                elnAudit
            },
            data() {
                return {
                    options: _this.options
                }
            },
            render() {
                return <eln-audit options={this.options}></eln-audit>
            },
        })
        this.bindInstance(elnAuditTrail.$el);
    }

    setData(list) {
        this.options.list = list;
    }


    init() {
        this.createModel();
    }
}