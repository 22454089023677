<template>
    <svg
        version="1.1"
        id="图层_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="8 -8 48 48"
        style="enable-background:new 8 -8 48 48;"
        xml:space="preserve"
        :width="width"
        :height="height"
    >
        <path
            class="st0"
            :fill="color"
            d="M41.1,4.7c-0.8,0-1.5-0.7-1.5-1.5V0.6c0-0.3-0.2-0.5-0.5-0.5h-14c-0.3,0-0.5,0.2-0.5,0.5v2.5
	c0,0.8-0.7,1.5-1.5,1.5s-1.5-0.7-1.5-1.5V0.6c0-1.9,1.6-3.5,3.5-3.5h14c1.9,0,3.5,1.6,3.5,3.5v2.6C42.6,4,41.9,4.7,41.1,4.7z"
        />
        <path
            class="st0"
            :fill="color"
            d="M49.5,6.1h-35c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5h35c0.8,0,1.5,0.7,1.5,1.5S50.3,6.1,49.5,6.1z"
        />
        <path
            class="st0"
            :fill="color"
            d="M43.7,35.1H20.3c-1.9,0-3.5-1.6-3.5-3.5v-26c0-0.8,0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5v26c0,0.3,0.2,0.5,0.5,0.5
	h23.4c0.3,0,0.5-0.2,0.5-0.5V5.7c0-0.8,0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5v25.9C47.2,33.5,45.6,35.1,43.7,35.1z"
        />
        <path
            class="st0"
            :fill="color"
            d="M25.4,27.6c-0.8,0-1.5-0.7-1.5-1.5V10.6c0-0.8,0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5v15.5
	C26.9,26.9,26.2,27.6,25.4,27.6z"
        />
        <path
            class="st0"
            :fill="color"
            d="M32.1,27.6c-0.8,0-1.5-0.7-1.5-1.5V10.6c0-0.8,0.7-1.5,1.5-1.5c0.8,0,1.5,0.7,1.5,1.5v15.5
	C33.6,26.9,32.9,27.6,32.1,27.6z"
        />
        <path
            class="st0"
            :fill="color"
            d="M38.7,27.6c-0.8,0-1.5-0.7-1.5-1.5V10.6c0-0.8,0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5v15.5
	C40.2,26.9,39.5,27.6,38.7,27.6z"
        />
    </svg>
</template>

<script>
import baseSvgImg from './baseSvgImg';
export default {
    extends: baseSvgImg,
    props: {
        color: {
            default: '#f9762b',
            type: String
        },
        width: {
            type: String,
            default: '16px'
        },
        height: {
            type: String,
            default: '16px'
        }
    }
};
</script>

<style scoped>
svg {
    display: block;
}
</style>
