import Gikam from '../../core/gikam-core.js';
import layout from './vue/layout.vue';
import Base from '../base.js';
import Vue from 'vue';
import { Position } from './position';

let defaultOptions = {
    renderTo: void 0,
    id: void 0,
    //控制是否可以拖动,默认false
    resize: true,
    //页面是否只读
    readonly: false,
    north: {
        title: void 0,
        shrinkTitle: [],
        height: 0,
        resize: false,
        padding: '',
        items: [],
        $children: []
    },
    south: {
        title: void 0,
        shrinkTitle: [],
        height: 0,
        resize: false,
        padding: '',
        items: [],
        $children: []
    },
    west: {
        title: void 0,
        shrinkTitle: [],
        width: 0,
        resize: false,
        padding: '',
        items: [],
        $children: []
    },
    east: {
        title: void 0,
        shrinkTitle: [],
        width: 0,
        resize: false,
        padding: '',
        items: [],
        $children: []
    },
    center: {
        title: void 0,
        items: [],
        padding: '',
        $children: []
    }
};

export default class Layout extends Base {
    constructor(props) {
        super('Layout');
        this.listeners = {
            rendered: Gikam.emptyFunction
        };
        this.readonly = false;
        this.initParam(props);
        this.initialize(props, defaultOptions).init(props);
    }

    init(options) {
        if (options) {
            this.options = Gikam.deepExtend(defaultOptions, options);
        }
        //组件总的宽度，初始化时用来计算每个区域的宽度值
        this.allowWidth = this.options.renderTo.clientWidth || this.options.allowWidth;
        this.formatterSize(this.options);
        this.createModel();
        this.formatterRegion();
        this.$dom = this.model.$el;
        this.model.$nextTick(() => {
            this.initItems();
            this.bindInstance(this.$dom);
            this.trigger('rendered');
        });
    }

    createModel() {
        let _this = this;
        this.model = new Vue({
            el: Gikam.createDom('div', this.options.renderTo),
            components: { layout },
            data() {
                return {
                    options: _this.options
                };
            },
            render() {
                return <layout ref="vm" options={this.options} onResize={_this.onResize.bind(_this)}></layout>;
            }
        });
    }

    /**
     * @description 尺寸变更时触发,同时触发center区域
     * @private
     * @param {String} region north/south/east/west
     * @param {Object} size {width:100,height:100}
     * @memberof Layout
     */
    onResize() {
        const regions = ['west', 'center', 'east', 'north', 'south'];
        regions.forEach(item => {
            const region = this.options[item];
            region.$width = region.$dom.clientWidth;
            region.$height = region.$dom.clientHeight;
            region.$children.forEach(comp => {
                comp.onResize && comp.onResize();
            });
        });
    }

    formatterSize(props) {
        let region = ['north', 'south', 'west', 'east', 'center'];
        const _this = this;
        Gikam.each(props, (key, value) => {
            if (region.indexOf(key) > -1) {
                if (key === 'center') {
                    value.width = _this.allowWidth - parseInt(props.west.width) - parseInt(props.east.width);
                    if (Gikam.isMobilePhone()) {
                        value.width = _this.allowWidth;
                    }
                    return false;
                }
                Gikam.each(value, (k, v) => {
                    if (k === 'width') {
                        let width = value[k];
                        if (Gikam.isString(v) && v.includes('%')) {
                            width = (_this.allowWidth * parseFloat(v)) / 100;
                        }
                        if (Gikam.isMobilePhone()) {
                            width = _this.allowWidth;
                        }
                        if (Gikam.isEmpty(this.options[key].items)) {
                            width = 0;
                        }
                        value[k] = width + 'px';
                    }
                    if (k === 'height') {
                        value[k] = Gikam.isNumber(v) ? v + 'px' : v;
                    }
                });
            }
        });
    }

    formatterRegion() {
        let region = ['north', 'south', 'west', 'east', 'center'];
        region.forEach(item => {
            this.options[item].$dom = this.model.$refs.vm.$refs[item];
            this.options[item].$updateChildren = child => {
                this.$updateChildren(child);
            };
            this.options[item].$updateChildrenReadyNum = () => {
                this.$updateChildrenReadyNum();
            };
        });
    }

    initItems() {
        let region = ['north', 'south', 'west', 'east', 'center'];
        region.forEach(item => {
            if (Gikam.isEmpty(this.options[item].items)) {
                return;
            }
            const items = this.options[item].items;
            this.initChildrenHeight(items);
            items.forEach(subComp => {
                subComp.renderTo = this.options[item].$dom;
                subComp.$root = this.options.renderTo;
                subComp.$window = this.$window;
                subComp.$parent = this.options[item];
                subComp.allowWidth = parseInt(this.options[item].width) || this.options.allowWidth;
                this.options[item].$children.push(Gikam.create(Gikam.toInitialUpperCase(subComp.type), subComp));
            });
        });
    }

    /**
     * @description 初始化各区域下子元素的高度,一个元素时填充方式为fill，多个元素设置默认高度是400（需要排除掉btnToolbar）
     * @private
     * @param {Object} items 子元素的配置参数
     * @memberof Layout
     */
    initChildrenHeight(items) {
        const subComps = items.filter(item => item.type !== 'btnToolbar');
        if (subComps.length === 1 && Gikam.isEmpty(subComps[0].fill)) {
            subComps[0].fill = true;
        }
    }

    /**
     * 初始化layout只读状态和父容器参数
     * @private
     * @param {Object} props
     * @memberof Layout
     */
    initParam(props) {
        const container = Gikam.getInstance(props.renderTo);
        if (!container) {
            return;
        }
        if (container.readonly) {
            props.readonly = true;
            this.readonly = true;
        }
        this.$window = this.$window || container.$window;
    }

    /**
     * @description 动态设置layout各区域尺寸
     * @public
     * @param {Object} props 各区域宽、高参数，例如{east:{width:200}}}
     * @memberof Layout
     */
    setSize(props) {
        this.formatterSize(props);
        Gikam.deepAssign(this.options, props);
        setTimeout(() => {
            this.$children.forEach(comp => {
                comp.onResize && comp.onResize();
            });
        }, 300);
    }

    /**
     * @description 获取访问
     * @param {*} param: "north" | "south" | "east" | "west" | "center"
     * @memberof Layout
     */
    getPosition(param) {
        return new Position(param, this);
    }
}
