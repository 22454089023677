import Vue from 'vue';
import scroll from './template/scroll/vue/scroll.vue';
import vuescroll from 'vuescroll/dist/vuescroll-native';
import button from './template/button.vue';
import selectField from './template/fields/select/select.vue';
import insertableSelectField from './template/fields/insertableSelect/insertableSelect.vue';
import ckeditor from './template/fields/ckeditor.vue';
import scriptInput from './template/fields/script.vue';
import scriptChooseInput from './template/fields/scriptChoose.vue';
import numberInput from './template/fields/numberInput';
import dateTimeInput from './template/fields/dateTimeInput';
import link from './template/fields/link.vue';
import textarea from './template/fields/textarea.vue';
import richText from './template/fields/richText.vue';
import timeInput from './template/fields/time/timeInput';
import checkbox from './template/checkbox/checkbox';
import dropDownMenu from './template/dropDownMenu/dropDownMenu.vue';
import radio from './template/radio/radio';
import arrowDown from './template/img/arrowDown';
import chooseImg from './template/img/chooseImg';
import editImg from './template/img/editImg';
import fork from './template/img/fork';
import tick from './template/img/tick';
import clock from './template/img/time';
import maxBox from './template/img/maxBox';
import restoreBox from './template/img/restoreBox';
import linkImg from './template/img/link';
import generalGroup from './template/img/generalGroup';
import exportImg from './template/img/export';
import importImg from './template/img/import';
import clearImg from './template/img/clear';
import searchImg from './template/img/search';
import refreshImg from './template/img/refresh';
import colorPickerField from './template/fields/colorPicker/main';
import yearField from './template/fields/year/yearInput.vue';
import textField from './template/fields/textInput.vue';
import passwordField from './template/fields/password/passwordInput.vue';
import dateField from './template/fields/date/dateInput.vue';
import chooseField from './template/fields/chooseInput.vue';
import customChooseField from './template/fields/customChoose.vue';
import comboBoxField from './template/fields/comboBox/comboBox.vue';
import cronField from './template/fields/cron/cronInput.vue';
import formulaField from './template/fields/formula/formula';
import simpleCheckbox from './template/fields/simpleCheckbox';
import imageField from './template/fields/image';
import signField from './template/fields/sign';
import radioGroupField from './template/fields/radioGroup/radioGroup.vue';
import checkboxField from './template/fields/checkbox';
import frontConfigurationCheckboxField from './template/fields/checkbox/checkbox';
import uploadButtonField from './template/fields/uploadButton/uploadButton';
import more from './template/img/more';
import fileImg from './template/img/file';
import roundAddImg from './template/img/roundAdd.vue';
import roundMinusImg from './template/img/roundMinus.vue';
import blockAddImg from './template/img/blockAdd.vue';
import blockMinusImg from './template/img/blockMinus.vue';
import tickImg from './template/img/tickImg.vue';
import lastPageImg from './template/img/lastPage.vue';
import nextPageImg from './template/img/nextPage.vue';
import closeNodeImg from './template/img/closeNode';
import openNodeImg from './template/img/openNode';
import timeLeftImg from './template/img/timeLeft.vue';
import timeRightImg from './template/img/timeRight.vue';
import homeImg from './template/img/homeImg';
import menuSwitchImg from './template/img/menuSwitchImg';
import showPasswordImg from './template/img/showPassword';
import hidePasswordImg from './template/img/hidePassword';
import shortcutMenuImg from './template/img/shortcutMenu';
import menuSwitchShortcutImg from './template/img/menuSwitchShortcut';
import userImg from './template/img/user';
import configImg from './template/img/configImg';
import dragImg from './template/img/dragImg';
import platformImg from './template/img/platform';
import charactersImg from './template/img/characters';
import shrinkImg from './template/img/shrinkImg';
import uploadImg from './template/img/upload';
import cleanImg from './template/img/clean';
import closeImg from './template/img/close';
import tipImg from './template/img/tip';
import shutdownImg from './template/img/shutdown';
import maximizeImg from './template/img/maximize';
import recoveryImg from './template/img/recovery';
import completeImg from './template/img/complete';
import projectImg from './template/img/project';
import reportImg from './template/img/report';
import resultEntryImg from './template/img/resultEntry';
import sampleImg from './template/img/sample';
import samplingImg from './template/img/sampling';
import noticeImg from './template/img/noticeImg';
import bellImg from './template/img/bell';
import successImg from './template/img/success';
import helpImg from './template/img/help';
import helpExclamationImg from './template/img/help-exclamation';
import removeImg from './template/img/removeImg';
import moveUpImg from './template/img/moveUpImg';
import viewImg from './template/img/viewImg';
import moveDownImg from './template/img/moveDownImg';
import printImg from './template/img/printImg';
import tabFlipImg from './template/img/tabFlip';
import timeUpImg from './template/img/timeUp';
import postilImg from './template/img/postil';
import auditImg from './template/img/audit';
import menuDownImg from './template/img/menuDown';
import menuUpImg from './template/img/menuUp';
import enterFullScreenImg from './template/img/enterFullScreen';
import exitFullScreenImg from './template/img/exitFullScreen';
import colorImg from './template/img/color';
import pageEditImg from './template/img/pageEdit';
import columnResizeImg from './template/img/columnResize';
import linkFileImg from './template/img/linkFile';
import fileField from './template/fields/file/file';
import textAddonField from './template/fields/textAddon/textAddon';
import helpManualImg from './template/img/helpManual';
import caption from './template/caption';
import rollingImg from './template/img/rolling';
import buttonGroupField from './template/fields/buttonGroup/buttonGroup';
import pictureField from './template/fields/picture/picture';

export default {
    install() {
        this.installImage();
        this.installFieldEditor();
        this.installCommonComp();
        this.installVueScroll();
    },

    /**
     * 注册图标，统一使用Img结尾
     */
    installImage() {
        Vue.component('arrowDown', arrowDown);
        Vue.component('fork', fork);
        Vue.component('tick', tick);
        Vue.component('maxBox', maxBox);
        Vue.component('restoreBox', restoreBox);
        Vue.component('clock', clock);
        Vue.component('linkImg', linkImg);
        Vue.component('chooseImg', chooseImg);
        Vue.component('editImg', editImg);
        Vue.component('moreImg', more);
        Vue.component('generalGroup', generalGroup);
        Vue.component('userImg', userImg);
        Vue.component('exportImg', exportImg);
        Vue.component('importImg', importImg);
        Vue.component('clearImg', clearImg);
        Vue.component('searchImg', searchImg);
        Vue.component('refreshImg', refreshImg);
        Vue.component('roundAddImg', roundAddImg);
        Vue.component('roundMinusImg', roundMinusImg);
        Vue.component('blockAddImg', blockAddImg);
        Vue.component('blockMinusImg', blockMinusImg);
        Vue.component('tickImg', tickImg);
        Vue.component('lastPageImg', lastPageImg);
        Vue.component('nextPageImg', nextPageImg);
        Vue.component('fileImg', fileImg);
        Vue.component('closeNodeImg', closeNodeImg);
        Vue.component('openNodeImg', openNodeImg);
        Vue.component('timeLeftImg', timeLeftImg);
        Vue.component('timeRightImg', timeRightImg);
        Vue.component('homeImg', homeImg);
        Vue.component('menuSwitchImg', menuSwitchImg);
        Vue.component('shortcutMenuImg', shortcutMenuImg);
        Vue.component('menuSwitchShortcutImg', menuSwitchShortcutImg);
        Vue.component('showPasswordImg', showPasswordImg);
        Vue.component('hidePasswordImg', hidePasswordImg);
        Vue.component('configImg', configImg);
        Vue.component('dragImg', dragImg);
        Vue.component('platformImg', platformImg);
        Vue.component('charactersImg', charactersImg);
        Vue.component('shrinkImg', shrinkImg);
        Vue.component('uploadImg', uploadImg);
        Vue.component('cleanImg', cleanImg);
        Vue.component('closeImg', closeImg);
        Vue.component('tipImg', tipImg);
        Vue.component('shutdownImg', shutdownImg);
        Vue.component('maximizeImg', maximizeImg);
        Vue.component('recoveryImg', recoveryImg);
        Vue.component('completeImg', completeImg);
        Vue.component('projectImg', projectImg);
        Vue.component('reportImg', reportImg);
        Vue.component('resultEntryImg', resultEntryImg);
        Vue.component('sampleImg', sampleImg);
        Vue.component('samplingImg', samplingImg);
        Vue.component('noticeImg', noticeImg);
        Vue.component('bellImg', bellImg);
        Vue.component('successImg', successImg);
        Vue.component('helpImg', helpImg);
        Vue.component('removeImg', removeImg);
        Vue.component('moveUpImg', moveUpImg);
        Vue.component('moveDownImg', moveDownImg);
        Vue.component('printImg', printImg);
        Vue.component('tabFlipImg', tabFlipImg);
        Vue.component('timeUpImg', timeUpImg);
        Vue.component('postilImg', postilImg);
        Vue.component('auditImg', auditImg);
        Vue.component('menuDownImg', menuDownImg);
        Vue.component('menuUpImg', menuUpImg);
        Vue.component('enterFullScreenImg', enterFullScreenImg);
        Vue.component('exitFullScreenImg', exitFullScreenImg);
        Vue.component('colorImg', colorImg);
        Vue.component('pageEditImg', pageEditImg);
        Vue.component('columnResizeImg', columnResizeImg);
        Vue.component('linkFileImg', linkFileImg);
        Vue.component('viewImg', viewImg);
        Vue.component('helpManualImg', helpManualImg);
        Vue.component('rollingImg', rollingImg);
        Vue.component('helpExclamationImg', helpExclamationImg);
    },

    /**
     * 注册字段属性，统一使用Field结尾
     */
    installFieldEditor() {
        Vue.component('ckeditorField', ckeditor);
        Vue.component('scriptField', scriptInput);
        Vue.component('scriptChooseField', scriptChooseInput);
        Vue.component('numberField', numberInput);
        Vue.component('yearField', yearField);
        Vue.component('timeField', timeInput);
        Vue.component('linkField', link);
        Vue.component('textField', textField);
        Vue.component('passwordField', passwordField);
        Vue.component('dateField', dateField);
        Vue.component('textareaField', textarea);
        Vue.component('richTextField', richText);
        Vue.component('selectField', selectField);
        Vue.component('insertableSelectField', insertableSelectField);
        Vue.component('chooseField', chooseField);
        Vue.component('customChooseField', customChooseField);
        Vue.component('comboBoxField', comboBoxField);
        Vue.component('cronField', cronField);
        Vue.component('dateTimeField', dateTimeInput);
        Vue.component('simpleCheckboxField', simpleCheckbox);
        Vue.component('imageField', imageField);
        Vue.component('signField', signField);
        Vue.component('checkboxField', checkboxField);
        Vue.component('frontConfigurationCheckboxField', frontConfigurationCheckboxField);
        Vue.component('formulaField', formulaField);
        Vue.component('colorField', colorPickerField);
        Vue.component('radioGroupField', radioGroupField);
        Vue.component('uploadButtonField', uploadButtonField);
        Vue.component('fileField', fileField);
        Vue.component('textAddonField', textAddonField);
        Vue.component('buttonGroupField', buttonGroupField);
        Vue.component('pictureField', pictureField);
    },

    /**
     * 注册通用组件
     */
    installCommonComp() {
        Vue.component('gbutton', button);
        Vue.component('combo-box', selectField);
        Vue.component('checkbox', checkbox);
        Vue.component('radio', radio);
        Vue.component('dropDownMenu', dropDownMenu);
        Vue.component('scroll', scroll);
        Vue.component('sunwayCaption', caption);
    },

    /**
     * 注册滚动条组件
     */
    installVueScroll() {
        Vue.use(vuescroll, {
            ops: {
                bar: {
                    background: '#bbb',
                    opacity: 0.8,
                    size: '6px',
                    onlyShowBarOnScroll: false,
                    keepShow: true,
                },
                detectResize: true,
            },
            name: 'vuescroll',
        });
    },
};
