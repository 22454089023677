<template>
    <svg
        version="1.1"
        id="图层_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        :width="width"
        :height="height"
        viewBox="0 0 42 42"
        style="enable-background:new 0 0 42 42;"
        xml:space="preserve"
    >
        <path
            :fill="color"
            d="M20.7,2c-10.5,0-19,8.5-19,19s8.5,19,19,19s19-8.5,19-19S31.2,2,20.7,2z M29.8,27.6c0.7,0.7,0.7,1.7,0,2.4
    c-0.3,0.3-0.8,0.5-1.2,0.5s-0.9-0.2-1.2-0.5l-6.6-6.6L14.1,30c-0.3,0.3-0.8,0.5-1.2,0.5S12,30.4,11.7,30c-0.7-0.7-0.7-1.7,0-2.4
    l6.6-6.6l-6.6-6.6c-0.7-0.7-0.7-1.7,0-2.4c0.7-0.7,1.7-0.7,2.4,0l6.6,6.6l6.6-6.6c0.7-0.7,1.7-0.7,2.4,0c0.7,0.7,0.7,1.7,0,2.4
    L23.1,21L29.8,27.6z"
        />
    </svg>
</template>
<script>
import baseSvgImg from './baseSvgImg';
export default {
    extends: baseSvgImg,
    props: {
        color: {
            type: String,
            default: '#007aff'
        }
    }
};
</script>
<style scoped>
svg {
    display: block;
}
</style>
