import Gikam from '../core/gikam-core.js';
import Base from './base.js';
import jQuery from 'jquery';

let defaultOptions = {
    url: [],
    language: 'zh-cn',
    success: Gikam.emptyFunction
};

export default class I18N extends Base {
    constructor(options) {
        super();
        this.start = new Date();
        this.def = jQuery.Deferred();
        this.initialize(options, defaultOptions).init();
        return this.def.resolve();
    }

    static map = {};

    static prop(key) {
        return I18N.map[key] || key;
    }

    getContent(copyUrl) {
        if (Gikam.isEmpty(copyUrl)) {
            return this.def.resolve();
        }
        let _this = this;
        Gikam.getTextSync(
            Gikam.printf(copyUrl[0], {
                language: this.options.language
            })
        ).done(function(content) {
            _this.parseData(content);
            copyUrl.splice(0, 1);
            if (Gikam.isEmpty(copyUrl)) {
                const end = new Date();
                Gikam.info((end.getTime() - _this.start.getTime()) / 1000);
            } else {
                _this.getContent(copyUrl);
            }
        });
    }

    parseData(data) {
        let lines = data.split(/\n/);
        lines.forEach(function(line, i) {
            line = line.trim();
            if (line.length > 0 && line.match('^#') !== '#') {
                let pair = line.split('=');
                if (pair.length <= 0) {
                    return;
                }
                let name = decodeURI(pair[0]).trim(),
                    value = pair.length === 1 ? '' : pair[1];
                while (value.match(/\\$/) === '\\') {
                    value = value.substring(0, value.length - 1);
                    value += lines[++i].trimRight();
                }
                for (let s = 2; s < pair.length; s++) {
                    value += '=' + pair[s];
                }
                value = value.trim();
                I18N.map[name] = value;
            }
        });
    }

    initI18NMessage() {
        const url = Gikam.IFM_CONTEXT + `/open/core/module/sys/i18n-messages?locale=${this.options.language}`;
        Gikam.getJson(url).done(data => {
            I18N.map = data;
        });
    }

    init() {
        if (Gikam.isString(this.options.url)) {
            this.options.url = this.options.url.split(',');
        }
        this.initI18NMessage();
    }

    static async initMessage(locale) {
        return new Promise(resolve => {
            const url = Gikam.IFM_CONTEXT + `/open/core/module/sys/i18n-messages?locale=${locale}`;
            Gikam.getJson(url).done(data => {
                I18N.map = data;
                resolve();
            });
        });
    }
}
