import Gikam from '@/gikam/js/core/gikam-core.js';
import Les from './les/js/les';
// import HolidaySetting from './holidaySetting/js/holidaySetting';
import IndexPanelConfig from './indexPanelConfig/js/indexPanelConfig';
import TodoPanel from './todoPanel/js/todoPanel.js';
import BusinessProcessDiagram from './businessProcessDiagram/js/businessProcessDiagram';
import OrganizeTree from './organizeFormation/js/organizeTree';
import DiagramDraw from './topologyDiagram/js/topologyDiagram';

export default () => {
    Gikam.component.Les = Les;
    // Gikam.component.HolidaySetting = HolidaySetting;
    Gikam.component.IndexPanelConfig = IndexPanelConfig;
    Gikam.component.TodoPanel = TodoPanel;
    Gikam.component.BusinessProcessDiagram = BusinessProcessDiagram;
    Gikam.component.OrganizeTree = OrganizeTree;
    Gikam.component.DiagramDraw = DiagramDraw;
};
