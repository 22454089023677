import { JSEncrypt } from 'jsencrypt';
import Gikam from '@/gikam/js/core/gikam-core';

/**
 * @description Rsa加密方式
 * @export
 * @class RsaEncryptUtils
 */
export class RsaEncryptUtils {
    static async getPublicKey() {
        return new Promise(resolve => {
            Gikam.getText(`${Gikam.IFM_CONTEXT}/open/security/public-key`).done(key => {
                localStorage.setItem('rsa-public-key', key);
                resolve(key);
            });
        });
    }

    static encode(publicKey, content) {
        var encrypt = new JSEncrypt();
        encrypt.setPublicKey(publicKey);
        return encrypt.encrypt(content);
    }
}
