<template>
    <svg
        version="1.1"
        id="图层_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 42 42"
        style="enable-background:new 0 0 42 42;"
        xml:space="preserve"
        :width="width"
        :height="height"
    >
        <path
            id="XMLID_2669_"
            :fill="color"
            d="M33.5,22.6H9c-0.6,0-1-0.7-1-1.5s0.5-1.5,1-1.5h24.5c0.6,0,1,0.7,1,1.5S34.1,22.6,33.5,22.6z"
        />
        <path
            id="XMLID_2666_"
            :fill="color"
            d="M35.7,40.1h-29c-2.5,0-4.5-2-4.5-4.5v-29c0-2.5,2-4.5,4.5-4.5h29c2.5,0,4.5,2,4.5,4.5v29
	C40.2,38.1,38.2,40.1,35.7,40.1z M6.7,5.1c-0.8,0-1.5,0.7-1.5,1.5v29c0,0.8,0.7,1.5,1.5,1.5h29c0.8,0,1.5-0.7,1.5-1.5v-29
	c0-0.8-0.7-1.5-1.5-1.5H6.7z"
        />
    </svg>
</template>

<script>
export default {
    props: {
        color: {
            type: String,
            default: '#333333'
        },
        width: {
            type: [Number, String],
            default: '12px'
        },
        height: {
            type: [Number, String],
            default: '12px'
        }
    }
};
</script>
