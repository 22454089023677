<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38.06 30.38" :width="width" :height="height">
        <g id="图层_2" data-name="图层 2">
            <g id="图层_1-2" data-name="图层 1">
                <rect
                    :fill="color"
                    id="_矩形_"
                    data-name="&lt;矩形&gt;"
                    x="17"
                    y="-17"
                    width="4"
                    height="38"
                    rx="2"
                    ry="2"
                    transform="translate(21 -17) rotate(90)"
                />
                <rect
                    :fill="color"
                    id="_矩形_2"
                    data-name="&lt;矩形&gt;"
                    x="17.06"
                    y="-3.81"
                    width="4"
                    height="38"
                    rx="2"
                    ry="2"
                    transform="translate(34.25 -3.87) rotate(90)"
                />
                <rect
                    :fill="color"
                    id="_矩形_3"
                    data-name="&lt;矩形&gt;"
                    x="17.06"
                    y="9.38"
                    width="4"
                    height="38"
                    rx="2"
                    ry="2"
                    transform="translate(47.44 9.31) rotate(90)"
                />
            </g>
        </g>
    </svg>
</template>

<script>
import baseSvgImg from './baseSvgImg';
export default {
    extends: baseSvgImg,
    props: {
        color: {
            default: '#007AFF',
            type: String
        }
    }
};
</script>

<style scoped>
svg {
    display: block;
}
</style>
