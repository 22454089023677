import Base from '../../../base';
import Gikam from '../../../../core/gikam-core';
import Vue from 'vue';

let defaultOptions = {
    data: [],
    bizId: String
};

export default class BusinessProcessDiagram extends Base {
    constructor(options) {
        super(options);
        this.initialize(options, defaultOptions).init();
    }

    createViewModel() {
        const _this = this;
        this.model = new Vue({
            el: Gikam.createDom('div', _this.options.renderTo),
            render() {
                return (
                    <business-process-diagram
                        options={_this.options}
                        onMounted={this.mountedHandle}
                    ></business-process-diagram>
                );
            },
            data() {
                return {
                    options: _this.options
                };
            },
            methods: {
                mountedHandle() {
                    _this.bindInstance(this.$el);
                }
            },
            components: {
                businessProcessDiagram: () => import('../vue/businessProcessDiagram.vue')
            }
        });
        this.model.$nextTick(() => {
            this.refresh(this.options);
            this.notify();
        });
    }

    refresh(param) {
        Gikam.post(param.url, Gikam.getJsonWrapper({
            bizId: param.bizId
        })).done(option => {
            this.options.data = option.masterConfList;
            if (Gikam.isEmpty(this.options.bizId)) { this.options.bizId = param.bizId }
            this.options.bizId = param.bizId
        }).fail(() => {
            this.options.data = [];
        });
    }

    init() {
        this.createViewModel();
    }
}
