import Base from "./base";
import Vue from 'vue';
import btn from './template/button.vue';


export default class Button extends Base {
    constructor(options) {
        super();
        this.initialize(options).init();
    }

    createViewModel() {
        const _this = this;
        const dom = document.createElement('div');
        this.options.renderTo.appendChild(dom);
        new Vue({
            el: dom,
            components: { btn },
            render() {
                return <btn options={_this.options}></btn>;
            }
        });
    }

    init() {
        this.createViewModel();
    }
}