<template>
    <svg
        version="1.1"
        id="图层_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 42 42"
        style="enable-background:new 0 0 42 42;"
        xml:space="preserve"
        :width="width"
        :height="height"
    >
        <path
            class="st0"
            :fill="color"
            d="M38,38.5H4c-1.9,0-3.5-1.6-3.5-3.5V9.5H38c1.9,0,3.5,1.6,3.5,3.5v22C41.5,36.9,39.9,38.5,38,38.5z M3.5,12.5V35
	c0,0.3,0.2,0.5,0.5,0.5h34c0.3,0,0.5-0.2,0.5-0.5V13c0-0.3-0.2-0.5-0.5-0.5H3.5z"
        />
        <g>
            <path
                class="st0"
                :fill="color"
                d="M32.7,21H15.4c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5h17.2c0.8,0,1.5,0.7,1.5,1.5S33.5,21,32.7,21z"
            />
            <circle class="st0" :fill="color" cx="9.4" cy="19.5" r="1.5" />
            <path
                class="st0"
                :fill="color"
                d="M32.7,30H15.4c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5h17.2c0.8,0,1.5,0.7,1.5,1.5S33.5,30,32.7,30z"
            />
            <circle class="st0" :fill="color" cx="9.4" cy="28.5" r="1.5" />
        </g>
        <path
            class="st0"
            :fill="color"
            d="M20.3,12.5H0.5V7c0-1.9,1.6-3.5,3.5-3.5h10.2c2.7,0,4.6,4.4,5.4,7L20.3,12.5z M3.5,9.5H16
	c-0.7-1.5-1.4-2.8-1.9-3H4C3.7,6.5,3.5,6.7,3.5,7V9.5z M14.1,6.5L14.1,6.5L14.1,6.5z"
        />
    </svg>
</template>

<script>
import baseSvgImg from './baseSvgImg';
export default {
    extends: baseSvgImg,
    props: {
        color: {
            type: String,
            default: '#007aff'
        },
        width: {
            type: String,
            default: '15px'
        },
        height: {
            type: String,
            default: '15px'
        }
    }
};
</script>

<style scoped>
svg {
    display: block;
}
</style>
