<template>
    <svg
        version="1.1"
        id="图层_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        :width="width"
        :height="height"
        viewBox="0 0 48 48"
        style="enable-background:new 0 0 48 48;"
        xml:space="preserve"
    >
        <path
            :fill="color"
            class="st0"
            d="M32.7,43H8.5C6.6,43,5,41.4,5,39.5v-31C5,6.6,6.6,5,8.5,5h24.2c0.8,0,1.5,0.7,1.5,1.5S33.5,8,32.7,8H8.5
        C8.2,8,8,8.2,8,8.5v31C8,39.8,8.2,40,8.5,40h24.2c0.8,0,1.5,0.7,1.5,1.5S33.5,43,32.7,43z"
        />
        <path
            :fill="color"
            id="XMLID_197_"
            class="st0"
            d="M18.4,24L18.4,24c0-0.9,0.7-1.5,1.5-1.5H41c0.8,0,1.5,0.7,1.5,1.5l0,0c0,0.8-0.7,1.5-1.5,1.5
        H19.9C19,25.5,18.4,24.8,18.4,24z"
        />
        <path
            :fill="color"
            class="st0"
            d="M36,31.9c-0.4,0-0.7-0.1-1-0.4c-0.6-0.6-0.6-1.5,0-2.1l4.9-5.1c0.2-0.2,0.2-0.5,0-0.7l-4.9-4.9
        c-0.6-0.6-0.6-1.5,0-2.1s1.5-0.6,2.1,0l4.9,4.9c1.4,1.4,1.4,3.5,0,4.9l-4.9,5.1C36.8,31.7,36.4,31.9,36,31.9z"
        />
    </svg>
</template>

<script>
import baseSvgImg from './baseSvgImg';
export default { extends: baseSvgImg };
</script>
