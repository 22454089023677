<template>
    <div class="tree" :id="id">
        <div class="tree-container">
            <div
                class="tree-header"
                @mouseenter="mouseenterHandle"
                @mouseleave="mouseleaveHandle"
                @click="headClick"
                v-show="options.name"
            >
                {{ name }}
                <div
                    class="insert-btn"
                    @click.stop="insertRootNode"
                    :title="$i18n('tree.insertRootNode')"
                    v-show="options.edit && showInsertButton"
                ></div>
            </div>
            <div class="tree-body">
                <div
                    class="scrollTop"
                    :class="{ top: dragType === 'top' }"
                    @dragover="allowDrop('top')"
                    @dragleave="dragLeave"
                ></div>
                <div
                    class="scrollBottom"
                    :class="{ bottom: dragType === 'bottom' }"
                    @dragover="allowDrop('bottom')"
                    @dragleave="dragLeave"
                ></div>
                <scroll ref="vs" maxWidth="100%" :ops="{ scrollPanel: { scrollingX: false } }">
                    <node
                        :propOptions="options"
                        :propNodeList="nodeList"
                        :propCheckbox="checkbox"
                        :propLevel="1"
                        :propStringId="''"
                        :isLastNodes="isLastNodes"
                    ></node>
                </scroll>
            </div>
        </div>
    </div>
</template>

<script>
import Gikam from 'gikam';
import node from './node.vue';

export default {
    props: {
        options: Object
    },

    inject: ['tree'],
    data() {
        return {
            nodeList: this.options.data,
            checkbox: this.options.checkbox,
            showInsertButton: false,
            id: this.options.id,
            isLastNodes: [],
            dragType: null // 往上滑动还是往下滑动
        };
    },

    watch: {
        'options.data'(value) {
            this.nodeList = value;
        }
    },

    components: { node },

    computed: {
        name() {
            return Gikam.propI18N(this.options.name);
        }
    },

    methods: {
        mouseenterHandle() {
            this.showInsertButton = true;
        },

        mouseleaveHandle() {
            this.showInsertButton = false;
        },

        insertRootNode() {
            if (this.tree.editing) {
                return;
            }
            let newNode = {
                text: '新建节点',
                id: null,
                editable: true,
                toolbar: false,
                value: '',
                childQty: 0
            };
            this.nodeList.push(newNode);
            this.tree.editing = true;
        },

        headClick() {
            this.$emit('headClick');
        },

        // 拖动事件，向上/向下 滚动。
        allowDrop(type) {
            this.dragType = type;
            if (type === 'top') {
                this.$refs['vs'] && this.$refs['vs'].scrollBy({ dy: -100 }, 300);
            }
            if (type === 'bottom') {
                this.$refs['vs'] && this.$refs['vs'].scrollBy({ dy: 100 }, 300);
            }
        },

        dragLeave() {
            this.dragType = null;
        }
    }
};
</script>

<style scoped>
.tree {
    padding: 8px;
    font-family: 'Microsoft YaHei', serif;
    background-color: #fff;
    height: 100%;
}

.tree .tree-container {
    border: 1px solid #eee;
    height: 100%;
}

.tree .tree-container .tree-header {
    height: 30px;
    background-color: #fafafa;
    border-bottom: 1px solid #f4f4f4;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65);
    padding-left: 16px;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
}

.tree .tree-container .tree-body {
    height: calc(100% - 30px);
    position: relative;
}

.tree .tree-container .tree-body .scrollTop,
.tree .tree-container .tree-body .scrollBottom {
    width: 100%;
    height: 8px;
    z-index: 1;
}

.tree .tree-container .tree-body .scrollTop {
    position: absolute;
    top: 0;
    left: 0;
}

.tree .tree-container .tree-body .scrollTop.top {
    background-image: linear-gradient(#999, #fff);
}

.tree .tree-container .tree-body .scrollBottom {
    position: absolute;
    bottom: 0;
    left: 0;
}

.tree .tree-container .tree-body .scrollBottom.bottom {
    background-image: linear-gradient(#fff, #999);
}

.insert-btn {
    width: 16px;
    height: 16px;
    cursor: pointer;
    padding-left: 15px;
    background: url('../img/plus.svg') no-repeat center;
}
</style>
