<template>
    <svg
        version="1.1"
        id="图层_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        :width="width"
        :height="height"
        viewBox="8 -8 48 48"
        style="enable-background:new 8 -8 48 48;"
        xml:space="preserve"
    >
        <path
            :fill="color"
            d="M32,9.8c-3.5,0-6.4-2.9-6.4-6.4S28.5-3,32-3c3.5,0,6.4,2.9,6.4,6.4S35.5,9.8,32,9.8z M32,0
	c-1.9,0-3.4,1.5-3.4,3.4s1.5,3.4,3.4,3.4c1.9,0,3.4-1.5,3.4-3.4S33.9,0,32,0z"
        />
        <path :fill="color" d="M36.9,21h-9.9l1.3-14.3h7.3L36.9,21z M30.3,18h3.3l-0.7-8.3h-1.9L30.3,18z" />
        <path
            :fill="color"
            d="M41.4,28.2H22.6c-1.9,0-3.5-1.6-3.5-3.5v-3.1c0-1.9,1.6-3.5,3.5-3.5h18.8c1.9,0,3.5,1.6,3.5,3.5v3.1
	C44.9,26.6,43.3,28.2,41.4,28.2z M22.6,21.1c-0.3,0-0.5,0.2-0.5,0.5v3.1c0,0.3,0.2,0.5,0.5,0.5h18.8c0.3,0,0.5-0.2,0.5-0.5v-3.1
	c0-0.3-0.2-0.5-0.5-0.5H22.6z"
        />
        <path
            :fill="color"
            d="M49.5,35h-35c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5h35c0.8,0,1.5,0.7,1.5,1.5S50.3,35,49.5,35z"
        />
    </svg>
</template>

<script>
import baseSvgImg from './baseSvgImg';
export default {
    extends: baseSvgImg,
    props: {
        color: {
            type: String,
            default: '#007aff'
        }
    }
};
</script>
