import { render, staticRenderFns } from "./generalButtonPanel.vue?vue&type=template&id=121030a6&scoped=true&"
import script from "./generalButtonPanel.vue?vue&type=script&lang=js&"
export * from "./generalButtonPanel.vue?vue&type=script&lang=js&"
import style0 from "./generalButtonPanel.vue?vue&type=style&index=0&id=121030a6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "121030a6",
  null
  
)

export default component.exports