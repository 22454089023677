<template>
    <basePopPanel :target="target" :width="propWidth" :propStyle="style" ref="panel">
        <ul class="drop-down-picker" :style="{ height: height }">
            <scroll>
                <li
                    class="btn-drop-down"
                    v-for="(item, index) in propDropDownMenu"
                    :key="index"
                    @click.stop="clickHandle(item)"
                >
                    {{ item.text }}
                </li>
            </scroll>
        </ul>
    </basePopPanel>
</template>

<script>
import basePopPanel from '@/gikam/js/components/template/basePopPanel/basePopPanel';

export default {
    props: {
        target: {
            type: Node,
            required: true
        },
        propWidth: {
            type: Number,
            default: 150
        },
        propHeight: {
            type: Number
        },
        propDropDownMenu: Array
    },

    created() {
        const domSize = this.target.getBoundingClientRect();
        this.style = { left: domSize.left - this.propWidth + 14 + 'px !important', overflow: 'hidden' };
    },

    components: { basePopPanel },

    computed: {
        height() {
            return this.propHeight ? this.propHeight + 'px' : 'auto';
        }
    },

    data() {
        return {
            style: {}
        };
    },

    methods: {
        clickHandle(item) {
            this.$refs.panel.clean();
            item.onClick && item.onClick();
        }
    }
};
</script>

<style scoped>
.drop-down-picker {
    list-style: none;
    margin: 0;
    padding: 0;
    background-color: white;
    min-height: 30px;
}

.drop-down-picker li {
    height: 30px;
    font-family: 'Microsoft YaHei', serif;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.65);
    display: flex;
    align-items: center;
    padding: 0 8px;
    cursor: pointer;
    white-space: nowrap;
}

.drop-down-picker li:hover {
    background: #eee;
}
</style>
