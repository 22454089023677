<template>
    <div
        class="select"
        ref="select"
        :class="{ 'validate-error': !validateResult, readonly, invisible, focus: focusFlag, active: isActive }"
        @mousedown.stop
        @dblclick="dblclickHandle"
        @click.stop="clickHandle"
        :style="inputStyle"
    >
        <div v-if="readonly || invisible" class="readonly-text" :style="inputStyle">
            <div v-if="multiple" :title="multipleText">
                <div class="tag" v-for="txt in text" :key="txt">{{ txt }}</div>
            </div>
            <div v-else>{{ text }}</div>
        </div>
        <div v-else class="select-container" @click="showItems($event)">
            <input type="text" ref="input" readonly :value="value" />
            <div class="text" :name="field" v-if="text">
                <div class="text-ellipsis" :title="multipleText" v-if="multiple">
                    <span v-show="isActive">{{
                        $i18n('select.multiple') + multipleLength + $i18n('select.item')
                    }}</span>
                    <div v-show="!isActive">
                        <div class="tag" v-for="txt in text" :key="txt">{{ txt }}</div>
                    </div>
                </div>
                <div class="text-ellipsis" :title="text" v-html="text" v-else></div>
            </div>
            <div class="placeholder" v-else>{{ placeholder }}</div>
            <div class="validate-error-icon" v-if="validateResult === false" @click.stop>
                <info-image @mouseenter.stop="errorIconMouseenter" @mouseleave.stop="errorIconMouseleave"></info-image>
            </div>
            <div class="icon">
                <arrow-down v-bind="{ color: '#666666', width: '8px', height: '8px' }" />
            </div>
        </div>
    </div>
</template>
<script>
import GikamCore from 'gikam';
import BaseField from '../../baseField/baseField.vue'; //校验相关的逻辑
import infoImage from '../../img/info.vue';
import pinyinMatch from 'pinyin-match';
import jQuery from 'jquery';
import Vue from 'vue';

const defaultOptions = {
    placeholder: ''
};

const Gikam = top === window ? GikamCore : top.Gikam;

export default {
    name: 'selectField',
    props: {
        options: Object,
        rowIndex: Number,
        cellIndex: Number,
        propValue: String,
        // 如果为true则不依赖于Form或Grid
        alone: Boolean,
        propInvisible: {
            type: Boolean,
            default: false
        },
        propReadonly: {
            type: Boolean,
            default: void 0
        },
        inputStyle: {
            type: Object,
            default: () => ({})
        }
    },
    extends: BaseField,
    components: { infoImage },

    data() {
        return {
            selectPicker: void 0,
            validateResult: true,
            readonly: Gikam.isNotEmpty(this.propReadonly) ? this.propReadonly : this.options.readonly,
            items: this.options.items || [],
            placeholder: this.options.placeholder || defaultOptions.placeholder,
            value: this.getDefaultValue(),
            field: this.options.field,
            maxHeight: 200,
            invisible: this.propInvisible,
            focusFlag: false,
            isActive: false,
            multiple: this.options.multiple === true ? true : false,
            multipleText: ''
        };
    },

    mounted() {
        this.$emit('rendered', this.field, this.value, this.rowIndex);
    },

    watch: {
        value(val, oldValue) {
            this.validateResult = Gikam.isNotEmpty(this.form || this.grid) ? this.validate() : true;
            if (Gikam.isTrue(this.validateResult)) {
                this.$emit('change', this.field, this.value, this.rowIndex, oldValue, this.text, this.activeItem);
            }
            // 不依赖于Form或Grid,单独存在
            if (this.alone) {
                Gikam.trigger(this.options, 'onChange', null, this.field, this.value);
            }
        },

        propValue(val) {
            this.value = val;
        }
    },

    computed: {
        text() {
            return this.getSelectText(this.value);
        },

        multipleLength() {
            return this.text.length;
        },

        activeItem() {
            return this.getSelectItem(this.value);
        }
    },

    methods: {
        getSelectText(val) {
            let text;
            if (this.multiple) {
                this.multipleText = '';
                if (!this.value) {
                    text = [];
                } else {
                    this.value.split(',').forEach(value => {
                        this.items.forEach(item => {
                            if (value + '' === item.value + '') {
                                this.multipleText += item.text + ',';
                            }
                        });
                    });
                    this.multipleText = this.multipleText.substr(0, this.multipleText.length - 1);
                    text = this.multipleText ? this.multipleText.split(',') : [];
                }
            } else {
                Gikam.each(this.items, function() {
                    if (this.value + '' === val + '') {
                        text = this.text;
                        return false;
                    }
                });
            }
            return text;
        },

        getSelectItem(value) {
            if (this.multiple) {
                return;
            }
            const filterItems = this.items.filter(item => item.value + '' === value + '');
            if (Gikam.isNotEmpty(filterItems)) {
                return Gikam.deepExtend(filterItems[0]);
            }
        },

        cleanSelectPicker() {
            jQuery(Gikam.selectPicker.$el).remove();
            Gikam.selectPicker.$destroy();
            Gikam.selectPicker = null;
            this.isActive = false;
        },

        clean() {
            this.items.length = 0;
            if (Gikam.isNotEmpty(this.value)) {
                this.$emit('change', this.field, null);
            }
            this.value = null;
        },

        showMultipleItems() {
            this.isActive = true;
            const _this = this;
            const target = this.$refs.select;

            if (Gikam.selectPicker) {
                this.cleanSelectPicker();
            }
            Gikam.selectPicker = new Vue({
                el: Gikam.createDom('div', window.top.document.body),
                components: {
                    picker: () => import('./picker')
                },
                data() {
                    return {
                        search: _this.options.search === true ? true : false
                    };
                },
                methods: {
                    selectHandle(value) {
                        _this.value = value;
                    },
                    changeIsActive() {
                        _this.isActive = !_this.isActive;
                    },
                    close() {
                        this.$refs.vm.$refs.basePicker.clean();
                        Gikam.selectPicker = null;
                        jQuery(this.$el).remove();
                        this.$destroy();
                        _this.isActive = false;
                    }
                },
                render() {
                    return (
                        <picker
                            target={target}
                            propItems={_this.items}
                            propValue={_this.value}
                            propSearch={this.search}
                            onselectItem={value => this.selectHandle(value)}
                            onchangeIsActive={() => this.changeIsActive()}
                            ref={'vm'}
                        />
                    );
                }
            });
        },

        // eslint-disable-next-line complexity
        showItems() {
            // Gikam.simulatedEvent(document, 'mousedown');
            Gikam.insertablePicker && Gikam.insertablePicker.close();
            Gikam.selectPicker && Gikam.selectPicker.close();
            Gikam.textareaPicker && Gikam.textareaPicker.close();
            Gikam.datePicker && Gikam.datePicker.destroy();
            Gikam.yearPicker && Gikam.yearPicker.destroy();
            Gikam.timePicker && Gikam.timePicker.destroy();
            this.$refs.input && this.$refs.input.focus();
            Gikam.comboboxPicker && Gikam.comboboxPicker.combobox.cleanPicker();
            this.$emit('saveCoordinate');
            if (this.multiple) {
                if (Gikam.selectPicker) {
                    this.cleanSelectPicker();
                }
                this.showMultipleItems();
                return;
            }

            if (Gikam.selectPicker) {
                if (this.selectPicker === Gikam.selectPicker) {
                    this.cleanSelectPicker();
                    return;
                } else {
                    this.cleanSelectPicker();
                }
            }
            let _this = this;
            this.isActive = true;

            const search = _this.options.search || false;

            const $select = jQuery(this.$refs.select);
            const mountedDom = Gikam.createDom('div', window.top.document.body);

            const selectHandle = function(e) {
                e.stopPropagation();
                _this.value = e.currentTarget.getAttribute('data');
                Gikam.selectPicker && _this.cleanSelectPicker();
            };

            let timeoutSearch = null;
            const inputHandle = function(e) {
                e.stopPropagation();
                const _val = e.target.value;
                if (timeoutSearch) clearTimeout(timeoutSearch);
                if (Gikam.isEmpty(_val)) {
                    Gikam.selectPicker.lists = _this.items;
                    Gikam.selectPicker.value = '';
                } else {
                    Gikam.selectPicker.value = _val;
                    timeoutSearch = setTimeout(function() {
                        Gikam.selectPicker.lists = _this.items.filter(function(item) {
                            if (!item.text) return false;
                            return pinyinMatch.match(item.text, _val);
                        });
                    }, 300);
                }
            };
            const selectTop = $select.offset().top;

            const pickerHeight = Math.min((search ? this.items.length + 1 : this.items.length) * 32, this.maxHeight);

            let $top =
                selectTop + this.maxHeight + $select.outerHeight() > jQuery(window.top).height()
                    ? selectTop - pickerHeight - 4
                    : selectTop + $select.outerHeight();

            let $left = $select.offset().left;
            const $width = $select.outerWidth();
            if ($left + $width > document.body.clientWidth) {
                $left = document.body.clientWidth - $width - 10;
            }

            if (window !== window.top) {
                const iframeRect = window.top.document.body
                    .querySelector(`[name=${window.name}]`)
                    .getBoundingClientRect();
                $top += iframeRect.top;
                $left += iframeRect.left;
            }

            this.selectPicker = Gikam.selectPicker = new Vue({
                el: mountedDom,
                data() {
                    return {
                        value: '',
                        lists: _this.items
                    };
                },
                mounted() {
                    this.$el.addEventListener('DOMMouseScroll', function(event) {
                        event.stopPropagation();
                    });
                },
                methods: {
                    close() {
                        jQuery(this.$el).remove();
                        this.$destroy();
                        Gikam.selectPicker = null;
                        _this.isActive = false;
                        _this.focusFlag = false;
                        _this.$refs.input && _this.$refs.input.blur();
                        _this.invisible = false;
                    }
                },
                render() {
                    return (
                        <div
                            class="select-picker"
                            style={{
                                position: 'absolute',
                                top: $top + 'px',
                                left: $left + 'px',
                                width: $width + 'px',
                                height: pickerHeight + 'px'
                            }}
                            onmousedown={function(e) {
                                e.stopPropagation();
                            }}
                            onmousewheel={function(e) {
                                e.stopPropagation();
                            }}
                        >
                            {search && (
                                <input
                                    type="text"
                                    value={this.value}
                                    placeholder="搜索"
                                    oninput={inputHandle}
                                    autocomplete="off"
                                />
                            )}
                            <ul style={search ? { height: ' calc(100% - 32px)' } : { height: '100%' }}>
                                <scroll scrollingX={false} distance={30} innerPanel={true}>
                                    {this.lists.map(item => {
                                        return (
                                            <li
                                                class={{
                                                    'current-value': item.value === _this.value
                                                }}
                                                data={item.value}
                                                onClick={selectHandle}
                                            >
                                                <div
                                                    class="text-ellipsis"
                                                    title={item.text}
                                                    domPropsInnerHTML={item.text}
                                                ></div>
                                            </li>
                                        );
                                    })}
                                </scroll>
                            </ul>
                        </div>
                    );
                }
            });
        },

        setItems(items) {
            this.items = items;
        },

        dblclickHandle() {
            this.$emit('dblclick', this);
        },

        getDefaultValue() {
            if (
                Gikam.isNotEmpty(this.options.selectedIndex) &&
                this.options.items &&
                this.options.items.length > this.options.selectedIndex
            ) {
                return this.options.items[this.options.selectedIndex].value;
            } else if (Gikam.isNotEmpty(this.propValue)) {
                return this.propValue;
            } else {
                return this.options.value;
            }
        },

        dumpActiveCell() {
            if (!this.invisible) {
                this.focusFlag = false;
                this.isActive = false;
                this.$refs.input && this.$refs.input.blur();
                Gikam.selectPicker && this.cleanSelectPicker();
            }
        },

        activeCell() {
            if (!this.invisible) {
                this.focus();
            } else {
                this.$emit('click', this);
            }
        },

        focus() {
            this.focusFlag = true;
            this.isActive = true;
            this.showItems();
            this.$refs.input && this.$refs.input.focus();
            this.$emit('saveCoordinate');
        },

        //点击时保存当前单元格的坐标
        clickHandle() {
            if (!this.invisible) {
                this.$emit('saveCoordinate');
            } else {
                this.$emit('click', this);
            }
        },

        override(props) {
            Gikam.extend(defaultOptions, props);
        }
    }
};
</script>
<style>
.select .validate-error-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s;
}

.select .validate-error-icon svg {
    width: 20px;
    height: 20px;
    cursor: pointer;
}
.select {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: stretch;
    padding: 0;
    overflow: hidden;
    position: relative;
    background-color: #fff;
    color: rgba(0, 0, 0, 0.65);
}

.select.readonly {
    font-family: 'Microsoft YaHei', serif;
    font-size: 12px;
    display: flex;
    align-items: center;
}

.select .select-container {
    cursor: pointer;
}

.select .select-container,
.select .readonly-text {
    border: 1px solid #d9d9d9;
    display: flex;
    width: 100%;
    border-radius: 4px;
    font-family: 'Microsoft YaHei', serif;
    font-size: 12px;
    color: inherit;
}

.select .readonly-text {
    background-color: #f4f4f4;
    display: flex;
    align-items: center;
    height: 100%;
    padding-left: 8px;
}

.select.validate-error .select-container {
    border-color: #ff6e6e;
}

.select .select-container .placeholder {
    width: 100%;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.15);
    display: flex;
    align-items: center;
    padding-left: 14px;
    flex: 1;
}

.select .select-container .icon {
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s;
}

.select.active .select-container {
    border: 1px solid rgba(0, 122, 255, 0.5);
}

.select.focus .select-container {
    border: 1px solid rgba(0, 122, 255, 0.5);
}

.select.active .select-container .icon {
    transform: rotate(180deg);
    color: #4784ff;
}

.select .select-container .text {
    width: calc(100% - 29px);
    flex: 1;
    padding-left: 8px;
    color: inherit;
    font-family: 'Microsoft YaHei', serif;
    font-size: 12px;
    display: flex;
    align-items: center;
}

.select input {
    width: 0;
    opacity: 0;
    float: left;
    z-index: -1;
    border-width: 0;
    padding: 0;
    margin: 0;
    outline: none;
}
.choose-input input:focus {
    border: none;
}

.select .tag {
    padding: 3px;
    margin-right: 2px;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    display: inline-block;
    line-height: 1;
}

.select-picker {
    margin-top: 1px;
    z-index: 11;
    box-sizing: content-box;
    background-color: #fff;
}

.select-picker input {
    width: calc(100% - 4px);
    height: 30px;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    padding-left: 8px;
    margin: 2px;
    margin-bottom: 0;
    color: rgba(0, 0, 0, 0.65);
    font-family: 'Microsoft YaHei', serif;
    font-size: 12px;
}

.select-picker ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    background-color: white;
    min-height: 32px;
}

.select-picker ul li {
    height: 32px;
    font-family: 'Microsoft YaHei', serif;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.65);
    display: flex;
    align-items: center;
    padding-left: 8px;
    cursor: pointer;
}

.select-picker ul li.current-value {
    color: #000;
}

.select-picker ul li:hover {
    background-color: rgba(0, 122, 255, 0.08);
}

.select-picker {
    z-index: 10;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.08);
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    overflow: hidden;
}

/* 字符串截取 xxx... */
.select-picker ul li .text-ellipsis,
.select .select-container .text .text-ellipsis {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.text-ellipsis p {
    margin: 0;
}

.select.invisible,
.select.invisible .readonly-text {
    border: none;
    background-color: transparent;
}

.select.validate-error .select-container {
    border-color: #ff6e6e;
}

.select-picker > .filter-input {
    height: 24px;
    line-height: 24px;
    padding: 0 8px;
    margin-top: 7px;
    position: relative;
}

.select-picker > .filter-input > input {
    margin: 0;
    height: 100%;
    width: 100%;
    padding-left: 20px;
}

.select-picker > .filter-input > .input-icon {
    position: absolute;
    top: 0;
    left: 5px;
    margin-left: 0;
    text-indent: 8px;
}

.select-picker > .filter-input > .input-icon > svg {
    display: inline-block;
    vertical-align: middle;
    margin-top: -2px;
}

.select-picker > .filter-input > .input-icon > .input-placeholder {
    display: none;
}

.select-picker > .filter-input > .input-icon.active > .input-placeholder {
    display: inline-block;
}

.select-picker > .filter-input > .input-icon.active {
    color: rgba(0, 0, 0, 0.65);
    left: 50%;
    margin-left: -26px;
}

.select-picker > .filter-input > .input-clean {
    position: absolute;
    top: 6px;
    right: 16px;
    width: 14px;
    height: 14px;
    cursor: pointer;
    z-index: 1;
}
</style>
