<template>
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 42 42"
        style="enable-background:new 0 0 42 42;"
        xml:space="preserve"
        :width="width"
        :height="height"
    >
        <path
            :fill="color"
            d="M21,31.8c-0.9,0-1.8-0.4-2.5-1L0.4,12.7c-0.6-0.6-0.6-1.5,0-2.1C1,10,2,10,2.6,10.6l18.1,18.1
	c0.1,0.1,0.3,0.1,0.4,0.1s0.2,0,0.4-0.1l18.1-18.1c0.6-0.6,1.5-0.6,2.1,0c0.6,0.6,0.6,1.5,0,2.1L23.5,30.8
	C22.8,31.5,21.9,31.8,21,31.8z"
        />
    </svg>
</template>

<script>
import baseSvgImg from './baseSvgImg';
export default { extends: baseSvgImg };
</script>

<style scoped>
svg {
    display: block;
}
</style>
