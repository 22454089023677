<template>
    <svg
        version="1.1"
        id="图层_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        :width="width"
        :height="height"
        viewBox="8 -8 48 48"
        style="enable-background:new 8 -8 48 48;"
        xml:space="preserve"
    >
        <path
            :fill="color"
            class="st0"
            d="M32,35c-10.5,0-19-8.5-19-19S21.5-3,32-3c6.8,0,12.9,3.5,16.4,9.3C48.8,7,48.6,8,47.9,8.4
            c-0.7,0.4-1.6,0.2-2.1-0.5C42.9,2.9,37.7,0,32,0c-8.8,0-16,7.2-16,16s7.2,16,16,16c6.9,0,13.2-4.7,15.3-11.3c0.2-0.8,1.1-1.2,1.9-1
            s1.2,1.1,1,1.9C47.7,29.5,40.3,35,32,35z"
        />
        <path
            :fill="color"
            class="st0"
            d="M47.5,11h-7c-0.8,0-1.5-0.7-1.5-1.5S39.7,8,40.5,8h7C47.8,8,48,7.8,48,7.5v-7C48-0.3,48.7-1,49.5-1
            S51-0.3,51,0.5v7C51,9.4,49.4,11,47.5,11z"
        />
    </svg>
</template>

<script>
import baseSvgImg from './baseSvgImg';
export default { extends: baseSvgImg };
</script>
