<template>
    <div class="sunway-radio" :class="{ 'sunway-radio_border': hasBorder, readonly: readonly }">
        <label
            class="sunway-radio-option"
            v-for="(item, index) in list"
            :key="item.value"
            :class="{ active: value === item.value }"
            @click.stop="changeValue(index)"
            v-show="!invisible"
        >
            <span class="sunway-radio-input">
                <input
                    :name="field"
                    class="sunway-radio-input_radio"
                    type="radio"
                    aria-hidden="true"
                    :value="item.value"
                    @keydown.left.stop.prevent="changeIndex(-1)"
                    @keydown.right.stop.prevent="changeIndex(1)"
                />
            </span>
            <span class="sunway-radio-label">{{ item.text }}</span>
        </label>
        <span class="sunway-radio_invisible" v-show="invisible">{{ currentText }}</span>
    </div>
</template>

<script>
import Gikam from 'gikam';
export default {
    props: {
        options: Object,
        propValue: String,
        rowIndex: Number,
        cellIndex: Number,
        propReadonly: {
            type: Boolean,
            default: void 0
        },
        propInvisible: {
            type: Boolean,
            default: false
        }
    },

    data() {
        let value, valueIndex;
        if (this.propValue) {
            Gikam.each(this.options.items, (index, item) => {
                if (this.propValue === item.value) {
                    value = item.value;
                    valueIndex = index;
                    return false;
                }
            });
        } else if (this.options.selectedIndex) {
            valueIndex =
                this.options.selectedIndex < 1
                    ? 0
                    : this.options.selectedIndex > this.options.items.length
                    ? this.options.items.length - 1
                    : Number(this.options.selectedIndex) - 1;
            value = this.options.items[valueIndex].value;
        } else {
            value = '';
            valueIndex = -1;
        }

        return {
            list: this.options.items,
            value: value,
            valueIndex: valueIndex,
            changeState: true,
            readonly: Gikam.isNotEmpty(this.propReadonly) ? this.propReadonly : this.options.readonly,
            invisible: this.options.propInvisible
        };
    },

    computed: {
        hasBorder() {
            return this.options.bordered;
        },
        silenceSetData() {
            return this.options.silenceSetData || true;
        },
        field() {
            return this.options.field || 'radio';
        },
        currentText() {
            return this.list[this.valueIndex] ? this.list[this.valueIndex].text : '';
        },
        stopKeydownChange() {
            return this.options.cancelKeydownChange || false;
        }
    },

    watch: {
        propValue(val) {
            this.value = val;
            if (!this.silenceSetData) {
                this.changeState = false;
            }
        },

        valueIndex(val) {
            if (val < 0) {
                return (this.valueIndex = this.list.length - 1);
            }
            if (val >= this.list.length) {
                return (this.valueIndex = 0);
            }
            this.value = this.list[val].value;
        },

        value(val) {
            if (this.changeState) {
                this.$emit('change', this.field, val, this.rowIndex, this.cellIndex);
            }
            this.changeState = true;
        },

        'options.items'(val) {
            this.changeState = false;
            this.value = '';
            this.valueIndex = -1;
            this.list = val;
        }
    },

    methods: {
        changeValue(index) {
            this.valueIndex = index;
        },

        changeIndex(number) {
            if (this.readonly || this.stopKeydownChange) {
                return;
            }
            this.valueIndex += number;
        }
    }
};
</script>

<style scoped>
.sunway-radio .sunway-radio-option {
    display: inline-block;
    height: 30px;
    line-height: 30px;
    margin-right: 30px;
    box-sizing: border-box;
    user-select: none;
}

.sunway-radio .sunway-radio-option .sunway-radio-input {
    position: relative;
    display: inline-block;
    border: 1px solid #dcdfe6;
    border-radius: 100%;
    width: 14px;
    height: 14px;
    vertical-align: middle;
    background-color: #fff;
    cursor: pointer;
    box-sizing: border-box;
}

.sunway-radio .sunway-radio-option .sunway-radio-input:hover {
    border-color: #409eff;
}

.sunway-radio .sunway-radio-option.active .sunway-radio-input {
    border-color: #409eff;
}

.sunway-radio .sunway-radio-option.active .sunway-radio-input::before {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background: #409eff;
    position: absolute;
    left: 2px;
    top: 2px;
}

.sunway-radio-input_radio {
    opacity: 0;
    outline: 0;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
}

.sunway-radio .sunway-radio-option .sunway-radio-label {
    margin-left: 10px;
    cursor: pointer;
}

/* 拓展 */
.sunway-radio.sunway-radio_border {
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    padding-left: 10px;
}
.sunway-radio.readonly {
    position: relative;
}
.sunway-radio.readonly::after {
    content: '';
    position: absolute;
    left: 1px;
    right: 1px;
    top: 1px;
    bottom: 1px;
    border-radius: 4px;
    cursor: not-allowed;
    background: rgba(255, 255, 255, 0.6);
}

.sunway-radio_invisible {
    height: 28px;
    line-height: 28px;
}
</style>
