import errorAlert from '../vue/errorAlert';
import Base from '../../base.js';
import Vue from 'vue';
import Gikam from '../../../core/gikam-core.js';

let defaultOptions = {
    message: void 0
};

let singleAlert = null;

export default class ErrorAlert extends Base {
    constructor(props) {
        super(props);
        if (singleAlert) {
            return;
        }
        this.listeners = {
            confirm: Gikam.emptyFunction
        };
        this.initialize(props, defaultOptions).init();
    }

    createModel() {
        let _this = this;
        return new Vue({
            el: Gikam.createDom('div', document.body),
            components: { errorAlert },
            methods: {
                close() {
                    singleAlert = null;
                    _this.$destroy();
                    _this.trigger('confirm');
                }
            },
            render() {
                return <errorAlert options={_this.options} onClick={this.close} />;
            }
        });
    }

    init() {
        this.model = this.createModel();
    }
}
