<template>
    <svg
        :width="width"
        :height="height"
        viewBox="0 0 20 20"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <!-- Generator: Sketch 51.2 (57519) - http://www.bohemiancoding.com/sketch -->
        <title>Group 2</title>
        <desc>Created with Sketch.</desc>
        <defs />
        <g id="对话框" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="信息提示" transform="translate(-707.000000, -605.000000)">
                <g id="Group-2" transform="translate(707.000000, 605.000000)">
                    <rect id="Rectangle-12" :fill="color" x="0" y="0" :width="width" :height="height" rx="10" />
                    <path
                        d="M16.4376386,5.74318766 L7.42109519,14.7622108 C7.37128004,14.8125964 7.27164972,14.8125964 7.22183457,14.7622108 L3.03736137,10.3786632 C2.98754621,10.3282776 2.98754621,10.2275064 3.03736137,10.1771208 L3.63514325,9.37095116 C3.68495841,9.32056555 3.78458872,9.32056555 3.83440388,9.32056555 L7.22183457,11.940617 C7.27164972,11.9910026 7.37128004,11.9910026 7.42109519,11.940617 L15.6904113,5.0377892 C15.7402264,4.9874036 15.8398567,4.9874036 15.8896719,5.0377892 L16.4376386,5.59203085 C16.4874538,5.59203085 16.4874538,5.69280206 16.4376386,5.74318766 Z"
                        id="XMLID_2288_"
                        fill="#FFFFFF"
                        fill-rule="nonzero"
                    />
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
import baseSvgImg from './baseSvgImg';
export default {
    extends: baseSvgImg,
    props: {
        color: {
            type: String,
            default: '#27986d'
        },
        width: {
            type: String,
            default: '20px'
        },
        height: {
            type: String,
            default: '20px'
        }
    }
};
</script>

<style scoped>
svg {
    display: block;
}
</style>
