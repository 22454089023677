<template>
    <div class="alert" @keydown.enter="keydownHandle">
        <div class="alert-container">
            <div class="alert-error-icon">
                <tipImg width="16px" height="16px" />
            </div>
            <div class="title">{{ title }}</div>
            <section v-html="options.message"></section>
            <footer>
                <div class="confirm-btn" @click="closeHandle">{{ $i18n('alert.confirm') }}</div>
            </footer>
        </div>
        <input type="text" ref="input" v-once />
    </div>
</template>

<script>
export default {
    props: {
        options: Object
    },

    computed: {
        title() {
            return this.options.title || this.$i18n('alert.title');
        }
    },

    methods: {
        closeHandle() {
            this.$emit('click');
        },

        keydownHandle() {
            this.$emit('click');
        }
    },

    mounted() {
        this.$refs.input && this.$refs.input.focus();
    }
};
</script>

<style scoped>
.alert {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Microsoft YaHei';
    z-index: 10;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.65);
}

.alert > .alert-container {
    width: 408px;
    height: 204px;
    background-color: #fff;
    border-radius: 4px;
    position: relative;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.15);
}

.alert > .alert-container > .alert-close-btn {
    color: #444;
    position: absolute;
    top: 10px;
    right: 15px;
    cursor: pointer;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.alert > .alert-container > .alert-error-icon {
    position: absolute;
    left: 24px;
    top: 32px;
}

.title {
    position: absolute;
    left: 50px;
    top: 32px;
    width: 320px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
}

section {
    position: absolute;
    left: 50px;
    right: 24px;
    top: 60px;
    bottom: 60px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 12px;
    overflow: auto;
}

footer {
    position: absolute;
    height: 60px;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: flex-end;
    padding: 0 24px 0 16px;
    align-items: center;
}

.confirm-btn {
    border-radius: 4px;
    width: 64px;
    height: 28px;
    margin-left: 20px;
    border: 1px solid #d9d9d9;
    background-color: #007aff;
    outline: none;
    font-size: 12px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding: 0;
    color: #fff;
    text-align: center;
    align-items: center;
}

input {
    width: 0;
    opacity: 0;
}
</style>
