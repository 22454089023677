<template>
    <svg
        version="1.1"
        id="图层_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        :width="width"
        :height="height"
        viewBox="8 -8 48 48"
        style="enable-background:new 8 -8 48 48;"
        xml:space="preserve"
    >
        <path
            :fill="color"
            d="M22.1,35h-4.6C15,35,13,33,13,30.5V26c0-0.8,0.7-1.5,1.5-1.5S16,25.2,16,26v4.5c0,0.8,0.7,1.5,1.5,1.5h4.6
	c0.8,0,1.5,0.7,1.5,1.5S22.9,35,22.1,35z"
        />
        <path
            :fill="color"
            d="M46.5,35H41c-0.8,0-1.5-0.7-1.5-1.5S40.2,32,41,32h5.5c0.9,0,1.5-0.6,1.5-1.5V26c0-0.8,0.7-1.5,1.5-1.5
	S51,25.2,51,26v4.5C51,33,49,35,46.5,35z"
        />
        <path
            :fill="color"
            d="M49.5,9.5C48.7,9.5,48,8.8,48,8V1.5C48,0.6,47.4,0,46.5,0H41c-0.8,0-1.5-0.7-1.5-1.5S40.2-3,41-3h5.5
	C49-3,51-1,51,1.5V8C51,8.8,50.3,9.5,49.5,9.5z"
        />
        <path
            :fill="color"
            d="M14.5,9.5C13.7,9.5,13,8.8,13,8V1.5C13-1,15-3,17.5-3H23c0.8,0,1.5,0.7,1.5,1.5S23.8,0,23,0h-5.5
	C16.7,0,16,0.7,16,1.5V8C16,8.8,15.3,9.5,14.5,9.5z"
        />
        <path
            :fill="color"
            d="M30.6,23.5c-5.2,0-9.5-4.3-9.5-9.5s4.3-9.5,9.5-9.5c5.2,0,9.5,4.3,9.5,9.5S35.8,23.5,30.6,23.5z M30.6,7.5
	c-3.6,0-6.5,2.9-6.5,6.5s2.9,6.5,6.5,6.5c3.6,0,6.5-2.9,6.5-6.5S34.2,7.5,30.6,7.5z"
        />
        <path
            :fill="color"
            d="M42.6,27.5c-0.4,0-0.8-0.1-1.1-0.4l-6.4-6.3c-0.6-0.6-0.6-1.5,0-2.1c0.6-0.6,1.5-0.6,2.1,0l6.4,6.3
	c0.6,0.6,0.6,1.5,0,2.1C43.4,27.4,43,27.5,42.6,27.5z"
        />
    </svg>
</template>
<script>
import baseSvgImg from './baseSvgImg';
export default {
    extends: baseSvgImg,
    props: {
        color: {
            type: String,
            default: '#ffba00'
        }
    }
};
</script>
<style scoped>
svg {
    display: block;
}
</style>
