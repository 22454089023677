import Gikam from '../core/gikam-core.js';
import jQuery from 'jquery';
export default class Base {
    constructor(name) {
        this.$type = name || 'comp';
        this._model = null;
        this._$childrenReadyNum = 0;
        this.$readyCallback = [];
        this.$state = 'begin';
        this.$children = [];
        this.$storeCompIndex = 0;
    }

    initialize(param, defaultOptions) {
        if (!this.$window) {
            this.$window = param ? param.$window : null;
        }
        this.$parent = param ? param.$parent : null;
        if (param) {
            //页面切换时是否对组件进行刷新
            this.$canRefreshPageSwitch = param.url ? true : false;
            param.$parent && delete param.$parent;
        }
        this.options = Gikam.deepExtend(defaultOptions, param);
        this.registerListeners();
        this.$notifyWindow();
        this.$initParent();
        this.$parent && this.$parent.$updateChildren(this);
        return this;
    }

    registerListeners() {
        let _this = this;
        this.listeners &&
            Gikam.each(this.options, function(property, value) {
                let name = Gikam.toInitialLowerCase(property.replace('on', ''));
                _this.listeners[name] && (_this.listeners[name] = value);
            });
    }

    trigger(event) {
        if (!this.listeners) return;
        let listener = this.listeners[event];
        if (listener) {
            return listener.apply(this, Array.prototype.slice.apply(arguments, [1]));
        }
    }

    bindInstance(dom) {
        return jQuery(dom).data('gikam.comp.data', this);
    }

    /**
     * @description 在window中注册组件
     * @private
     * @memberof Grid
     */
    $notifyWindow() {
        if (!this.$window) {
            return;
        }
        if (this.$window === this) {
            return;
        }
        this.$window.$addStoreComp(this.$type, this);
        this.$storeCompIndex = this.$window.$compStore[this.$type].length - 1;
    }

    /**
     * @description 渲染成功之后，通知组件容器，通知通过id异步获取组件的地方
     * @private
     * @memberof Base
     */
    notify() {
        const pro = Gikam.createdComponent[this.options.id];
        if (pro) {
            pro.resolve(this);
            delete Gikam.createdComponent[this.options.id];
        }
    }

    /**
     * @description 更新子组件渲染成功的数量
     * @private
     * @memberof Base
     */
    $updateChildrenReadyNum() {
        this.$childrenReadyNum++;
    }

    /**
     * @description 绑定事件
     * @param {String} event 事件名称
     * @param {Function} callback 回调
     * @public
     * @memberof Base
     */
    on(event, callback) {
        if (!this.listeners) {
            return;
        }
        if (!this.listeners[name]) {
            Gikam.error(`${event} not exists`);
            return;
        }
        this.listeners[name] = callback;
    }

    set model(model) {
        this._model = model;
        model &&
            model.$nextTick(() => {
                if (this.$lastChild && this.$parent) {
                    this.$parent.$updateChildrenReadyNum();
                }
                this.$state = 'end';
            });
    }

    get model() {
        return this._model;
    }

    set $childrenReadyNum(val) {
        this._$childrenReadyNum = val;
        if (val === this.$children.length) {
            Gikam.trigger(this.options, 'ready', this);
            this.$state = 'end';
            this.$parent && this.$parent.$updateChildrenReadyNum();
            this.$readyCallback.forEach(item => item.call(this));
        }
    }

    get $childrenReadyNum() {
        return this._$childrenReadyNum;
    }

    /**
     * @description 初始化父组件
     * @private
     * @memberof Base
     */
    $initParent() {
        if (this.$parent) {
            return;
        }
        if (!this.options.renderTo) {
            return;
        }
        const container = Gikam.getInstance(this.options.renderTo);
        if (!container) {
            return;
        }
        this.$parent = container;
    }

    /**
     * @description 所有组件加载完毕之后的回调
     * @public
     * @memberof Window
     */
    onReady(callback) {
        this.$state === 'begin' ? this.$readyCallback.push(callback) : callback.apply(this);
    }

    $updateChildren(child) {
        this.$children.push(child);
    }

    /**
     * @description 销毁
     * @returns
     * @memberof Base
     */
    $destroy() {
        if (!this.model) {
            return;
        }
        Gikam.removeDom(this.model.$el);
        this.model.$destroy();
    }
}
