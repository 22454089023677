<template>
    <div class="btn-toolbar">
        <template v-for="(item, index) in options.items">
            <div class="items" :key="index" v-if="item.hidden !== true">
                <ButtonGroup :options="item" v-if="item.type == 'btnGroup'"></ButtonGroup>
                <Btn :options="item" :window="window" v-else-if="item.type == 'button'"></Btn>
                <DropDownMenu :options="item" v-else-if="item.type == 'dropDownMenu'"></DropDownMenu>
            </div>
        </template>
    </div>
</template>
<script>
import ButtonGroup from './buttonGroup.vue';
import DropDownMenu from './dropDownMenu/dropDownMenu';
import Btn from './button.vue';

export default {
    props: {
        options: Object,
        window: Object
    },
    components: {
        ButtonGroup,
        Btn,
        DropDownMenu
    }
};
</script>

<style scoped>
.btn-toolbar {
    background-color: #fff;
    display: flex;
    z-index: 1;
}

.btn-toolbar > .items {
    margin-left: 8px;
    display: flex;
    align-items: center;
}

.btn-toolbar > .items > .button {
    border-radius: 4px;
    height: 28px;
}
</style>
