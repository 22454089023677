<template>
    <svg
        version="1.1"
        id="图层_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        :width="width"
        :height="height"
        viewBox="0 0 38 38"
        style="enable-background:new 0 0 38 38;"
        xml:space="preserve"
    >
        <path
            :fill="color"
            id="XMLID_1324_"
            class="st0"
            d="M23.6,13.2L23.6,13.2c0,0.8-0.7,1.5-1.5,1.5H6c-0.8,0-1.5-0.7-1.5-1.5v0
            c0-0.8,0.7-1.5,1.5-1.5h16.1C22.9,11.7,23.6,12.3,23.6,13.2z"
        />
        <path
            :fill="color"
            id="XMLID_1323_"
            class="st0"
            d="M14.5,20.5L14.5,20.5c0,0.8-0.7,1.5-1.5,1.5H6c-0.8,0-1.5-0.7-1.5-1.5v0C4.5,19.7,5.2,19,6,19
            h7C13.8,19,14.5,19.7,14.5,20.5z"
        />
        <path
            :fill="color"
            id="XMLID_1325_"
            class="st0"
            d="M14.5,30.9L14.5,30.9c0,0.8-0.7,1.5-1.5,1.5H6c-0.8,0-1.5-0.7-1.5-1.5v0
            c0-0.8,0.7-1.5,1.5-1.5h7C13.8,29.4,14.5,30.1,14.5,30.9z"
        />
        <path
            :fill="color"
            id="XMLID_1326_"
            class="st0"
            d="M34,39.1H3c-1.9,0-3.5-1.6-3.5-3.5v-31C-0.5,2.6,1.1,1,3,1h31c1.9,0,3.5,1.6,3.5,3.5v31
            C37.5,37.5,35.9,39.1,34,39.1z M3,4C2.7,4,2.5,4.3,2.5,4.5v31c0,0.3,0.2,0.5,0.5,0.5h31c0.3,0,0.5-0.2,0.5-0.5v-31
            C34.5,4.3,34.3,4,34,4H3z"
        />
        <rect :fill="color" id="XMLID_1327_" x="2" y="3.9" class="st0" width="33.3" height="4.3" />
        <g id="XMLID_2634_">
            <g id="XMLID_2635_">
                <path
                    :fill="color"
                    id="XMLID_2636_"
                    class="st0"
                    d="M32,24.9c-0.1-0.4-0.4-0.7-0.7-0.8L31,24c-0.6-0.2-1.2-0.6-1.5-1.2
                    c-0.3-0.6-0.4-1.3-0.3-1.9l0.1-0.2c0.1-0.3,0-0.8-0.3-1c0,0-0.2-0.2-0.9-0.6c-0.7-0.4-1-0.5-1-0.5c-0.3-0.1-0.8,0-1,0.3L25.8,19
                    c-0.5,0.4-1.1,0.7-1.8,0.7c-0.7,0-1.3-0.3-1.8-0.7l-0.2-0.2c-0.2-0.3-0.7-0.4-1-0.3c0,0-0.3,0.1-1,0.5c-0.7,0.4-0.9,0.6-0.9,0.6
                    c-0.3,0.2-0.4,0.7-0.3,1l0.1,0.2c0.1,0.6,0.1,1.3-0.3,1.9c-0.3,0.6-0.9,1-1.5,1.2l-0.3,0.1c-0.3,0.1-0.7,0.4-0.7,0.8
                    c0,0-0.1,0.3-0.1,1.1c0,0.8,0.1,1.1,0.1,1.1c0.1,0.4,0.4,0.7,0.7,0.8l0.2,0.1c0.6,0.2,1.2,0.6,1.5,1.2c0.3,0.6,0.4,1.3,0.3,1.9
                    l-0.1,0.2c-0.1,0.3,0,0.8,0.3,1c0,0,0.2,0.2,0.9,0.6c0.7,0.4,1,0.5,1,0.5c0.3,0.1,0.8,0,1-0.3l0.2-0.2c0.5-0.4,1.1-0.7,1.8-0.7
                    c0.7,0,1.3,0.3,1.8,0.7h0l0.2,0.2c0.2,0.3,0.7,0.4,1,0.3c0,0,0.3-0.1,1-0.5c0.7-0.4,0.9-0.6,0.9-0.6c0.3-0.2,0.4-0.7,0.3-1
                    L29.2,31c-0.1-0.6-0.1-1.3,0.3-1.9c0.3-0.6,0.9-1,1.5-1.2v0l0.2-0.1c0.3-0.1,0.7-0.4,0.7-0.8c0,0,0.1-0.3,0.1-1.1
                    C32,25.2,32,24.9,32,24.9L32,24.9z M24,28.6c-1.4,0-2.6-1.2-2.6-2.6c0-1.4,1.2-2.6,2.6-2.6c1.4,0,2.6,1.2,2.6,2.6
                    C26.6,27.4,25.5,28.6,24,28.6L24,28.6z M24,28.6"
                />
            </g>
        </g>
    </svg>
</template>

<script>
import baseSvgImg from './baseSvgImg';
export default { extends: baseSvgImg };
</script>
