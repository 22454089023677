import Base from '../../base';
import Gikam from '../../../core/gikam-core';
import notice from '../vue/notice.vue';
import Vue from 'vue';

let defaultOptions = {};

export default class Notice extends Base {
    constructor(options) {
        super(options);
        this.initialize(options, defaultOptions).init();
    }

    createViewModel() {
        const _this = this;
        this.notice = new Vue({
            el: Gikam.createDom('div', this.options.renderTo),
            render() {
                return <notice options={this.options}></notice>;
            },
            components: {
                notice
            },
            data() {
                return {
                    options: _this.options
                };
            }
        });
    }

    init() {
        this.createViewModel();
    }
}
