import Gikam from 'gikam';
let lastHasColspanIndex = null;

function isColumnVisible(fieldOption) {
    const visible = fieldOption.visible;
    return visible === undefined || visible === '1' || visible === true;
}

const handleColumns = function(store, columns) {
    thMergeHide(0, columns);
    const noWidthFields = [];
    const widthFields = [];
    const visibleColumns = [];
    columns.forEach(item => {
        if (item.radio || item.checkbox || item.index === true) {
            item.width = 50;
        }
        if (isColumnVisible(item)) {
            item.width ? widthFields.push(item) : noWidthFields.push(item);
            visibleColumns.push(item);
        }
    });
    const columnsWidth = visibleColumns.reduce((total, item) => {
        total += parseInt(item.width) || defaultColumnWidthHandle(store, item);
        return total;
    }, 0);
    if (store.state.columnsFill) {
        const allowWidth = store.state.allowWidth ? store.state.allowWidth : store.state.renderTo.offsetWidth;
        store.commit('changeColumnsWidth', Math.max(allowWidth - 18, columnsWidth));
        if (columnsWidth > allowWidth) {
            noWidthFields.forEach(field => {
                field.width = defaultColumnWidthHandle(store, field);
            });
        } else {
            if (Gikam.isEmpty(noWidthFields)) {
                widthFields.length && (widthFields[widthFields.length - 1].width = null);
                return;
            }
            noWidthFields.forEach((item, index) => {
                if (noWidthFields.length - 1 > index) {
                    item.width = defaultColumnWidthHandle(store, item);
                }
            });
            mergeLastTh(columns);
        }
    } else {
        store.commit('changeColumnsWidth', columnsWidth);
        if (Gikam.isEmpty(noWidthFields)) {
            return;
        }
        noWidthFields.forEach(field => {
            field.width = defaultColumnWidthHandle(store, field);
        });
    }
};

// 设置默认宽度
function defaultColumnWidthHandle(store, item) {
    let num = item.colspan ? parseInt(item.colspan) : 1;
    return store.state.defaultColumnWidth * num;
}

// 合并表头到最后一个，则不设宽度
function mergeLastTh(columns) {
    if (columns[columns.length - 1].hideHeader && lastHasColspanIndex !== null) {
        delete columns[lastHasColspanIndex].width;
    }
}

// 隐藏被合并的表头
function thMergeHide(start, columns) {
    // 最后一个后面没有合并项
    for (let i = start; i < columns.length; i++) {
        // 区间内第一个有colspan的表头columns[i]和其colspan的值columns[i].colspan
        if (columns[i].colspan) {
            lastHasColspanIndex = i;
            columns[i].fixed = false;
            // delete columns[i].width;
            // 计算宽度和隐藏被合并的表头
            for (let j = 1; j < parseInt(columns[i].colspan); j++) {
                if (columns[i + j]) {
                    // columns[i].width += columns[i+j].width;
                    // columns[i+j].fixed = columns[i].fixed;
                    columns[i + j].fixed = false;
                    columns[i + j].hideHeader = true;
                    delete columns[i + j].colspan;
                } else {
                    // 如果后面合并项个数没有colspan大，则colspan赋值为合并项的个数
                    columns[i].colspan = j;
                    break;
                }
            }
            start = i + 1;
            thMergeHide(start, columns);
            break;
        }
    }
}

export default handleColumns;
