<template>
    <svg
        version="1.1"
        id="图层_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 42 42"
        style="enable-background:new 0 0 42 42;"
        xml:space="preserve"
        :width="width"
        :height="height"
    >
        <g>
            <circle :fill="color" cx="15.8" cy="10.4" r="2.5" />
            <circle :fill="color" cx="26.2" cy="10.4" r="2.5" />
            <circle :fill="color" cx="32.5" cy="18" r="2.5" />
            <path
                :fill="color"
                d="M13.3,31.8c-1.9,0-3.5-1.6-3.5-3.5s1.6-3.5,3.5-3.5s3.5,1.6,3.5,3.5S15.2,31.8,13.3,31.8z M13.3,26.8
        c-0.8,0-1.5,0.7-1.5,1.5s0.7,1.5,1.5,1.5s1.5-0.7,1.5-1.5S14.1,26.8,13.3,26.8z"
            />
            <circle :fill="color" cx="9.5" cy="18" r="2.5" />
            <path
                :fill="color"
                d="M21,40.5C10.2,40.5,1.5,31.7,1.5,21C1.5,10.3,10.2,1.5,21,1.5c5.7,0,11.4,2.5,15.2,6.8
        c3.3,3.7,4.8,8.2,4.3,12.9l0,0c-0.6,4.8-4.8,8.5-9.8,8.7c-1.3,0-2.4,0-3.4,0c-1,0-1.7-0.1-2.3,0c-0.8,0.1-1.2,0.3-1.4,0.5
        c-0.6,0.9,0.3,2.3,0.5,2.5l0.1,0.1c0.7,0.7,1.7,1.6,1.8,3.4c0.1,0.9-0.2,1.8-0.8,2.5C23.8,40.4,21.4,40.5,21,40.5z M21,4.5
        C11.9,4.5,4.5,11.9,4.5,21c0,9.1,7.4,16.5,16.5,16.5c0.6,0,1.7-0.2,2-0.6c0,0,0.1-0.1,0.1-0.2c0-0.6-0.3-0.9-0.9-1.4L22,35.2
        c-1.1-1-2.6-4-1-6.4c0.9-1.3,2.4-1.7,3.5-1.8c0.8-0.1,1.8-0.1,2.8,0c0.9,0,2,0.1,3.2,0c3.6-0.1,6.5-2.7,6.9-6.1l0,0
        c0.4-3.8-0.8-7.5-3.5-10.5C30.7,6.7,25.8,4.5,21,4.5z"
            />
        </g>
    </svg>
</template>

<script>
import baseSvgImg from './baseSvgImg';
export default {
    extends: baseSvgImg,
    props: {
        color: {
            type: String,
            default: '#007AFF'
        }
    }
};
</script>
