import chart from './template/echarts/echarts.vue';
import Base from './base.js';
import Vue from 'vue';
import Gikam from '../core/gikam-core';
import EcStat from 'echarts-stat';

let defaultOptions = {
    option: {},
    config: {
        dropDownMenu: [],
        search: {}
    }
};
export default class Echarts extends Base {
    constructor(options) {
        super(options);
        this.listeners = {
            //echarts初始化完成之后,参数为echarts对象
            ready: Gikam.emptyFunction
        };
        this.initialize(options, defaultOptions).init();
    }

    createViewModel() {
        const _this = this;
        this.model = new Vue({
            el: Gikam.createDom('div', this.options.renderTo),
            render() {
                return (
                    <chart
                        ref="vm"
                        echartsOptions={this.options.option}
                        options={this.options.config}
                        id={this.options.id}
                        onReady={echarts => {
                            _this.trigger('ready', echarts);
                        }}
                    ></chart>
                );
            },
            components: { chart },
            data() {
                return {
                    options: _this.options
                };
            }
        });
        this.model.$nextTick(() => {
            this.bindInstance(this.model.$refs.vm.$el);
        });
    }

    /**
     * @description 调用echarts的setOptions方法更新图标参数
     * @public
     * @param  {} echarts的setOptions方法 原生参数
     * @memberof echarts
     */
    /* 调用echarts的setOptions方法更新图标参数 */
    setOption(options, refreshFlag) {
        this.model.$refs.vm.setOption(options, refreshFlag);
    }

    /**
     * @description 修改组件参数
     * @public
     * @param { Object }  config 配置
     * @memberof echarts
     */
    setConfig(config) {
        this.options.config = Gikam.extend(true, this.options.config, config);
    }

    /**
     * @description 父组件尺寸变更时触发
     * @private
     * @param
     * @memberof echarts
     */
    onResize() {
        this.model.$refs.vm.onResize();
    }

    /**
     * @description 绑定其他三方包
     * @private
     * @param
     * @memberof echarts
     */
    bindingLibrary() {
        this.ecStat = EcStat;
    }

    init() {
        this.bindingLibrary();
        this.createViewModel();
    }
}
