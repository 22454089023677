<script>
import filterDateField from './filterDateField';
export default {
    extends: filterDateField,
    data() {
        return {
            isDateTime: true
        };
    }
};
</script>
