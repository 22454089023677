import dropDownMenu from './template/dropDownMenu/dropDownMenu.vue';
import Vue from 'vue';
import Base from "./base";
import Gikam from '../core/gikam-core';

let defaultOptions = {
    renderTo: void 0
};

export default class DropDownMenu extends Base {

    constructor(param) {
        super();
        this.initialize(param, defaultOptions).init();
    }

    createModel() {
        const _this = this;
        return new Vue({
            el: Gikam.createDom('div', this.options.renderTo),
            components: { dropDownMenu },
            render() {
                return <dropDownMenu options={_this.options} />;
            }
        });
    }

    init() {
        this.createModel();
    }

}



