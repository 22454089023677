<template>
    <!-- 穿梭框 -->
    <div class="shuttle-frame" :id="options.id">
        <div class="left-grid">
            <div class="title" v-if="options.leftTitle">
                <span>{{ options.leftTitle }}</span>
            </div>
            <div class="container" ref="left-grid"></div>
        </div>
        <div class="center-btn">
            <div class="icon" :class="[options.addList.length > 0 ? 'right-active' : 'right-gray']" @click="add"></div>
            <div class="icon" :class="[options.delList.length > 0 ? 'left-active' : 'left-gray']" @click="del"></div>
        </div>
        <div class="right-grid">
            <div class="title" v-if="options.rightTitle">
                <span>{{ options.rightTitle }}</span>
            </div>
            <div class="container" ref="right-grid"></div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'shuttle-frame',
    props: {
        options: Object
    },

    inject: {
        shuttleFrame: {
            default: null
        }
    },

    methods: {
        add() {
            this.options.addList.length > 0 && !this.options.readonly && this.changeData('add');
        },
        del() {
            this.options.delList.length > 0 && !this.options.readonly && this.changeData('del');
        },
        changeData(type) {
            this.shuttleFrame.initData();
            this.options.chooseGrid = true;
            if (type === 'add') {
                if (!this.options.onBeforeAdd(this.options.addList)) {
                    this.options.addList = [];
                    return;
                } else if (this.options.onBeforeAdd(this.options.addList) instanceof Array) {
                    this.options.addList = this.options.onBeforeAdd(this.options.addList);
                }
                this.options.rightData = [...this.options.rightData, ...this.options.addList];
                this.options.data = this.options.data.filter(
                    item =>
                        !this.options.rightData.some(
                            obj => obj[this.options.delRepeatField] === item[this.options.delRepeatField]
                        )
                );
                if (this.options.onAddClick) {
                    this.options.onAddClick(this.options.addList, this.options.data, this.options.rightData);
                }
            } else {
                if (!this.options.onBeforeDel(this.options.delList)) {
                    this.options.delList = [];
                    return;
                }
                this.options.data = [...this.options.data, ...this.options.delList];
                this.options.rightData = this.options.rightData.filter(
                    item =>
                        !this.options.data.some(
                            obj => obj[this.options.delRepeatField] === item[this.options.delRepeatField]
                        )
                );
                if (this.options.onDelClick) {
                    this.options.onDelClick(this.options.delList, this.options.data, this.options.rightData);
                }
            }

            this.refreshData();

            setTimeout(() => {
                this.options.data.forEach((item, index) => {
                    this.shuttleFrame.leftGrid.model.$refs.vm.$refs['checkbox_' + index][0].checked = false;
                });
                this.options.rightData.forEach((item, index) => {
                    this.shuttleFrame.rightGrid.model.$refs.vm.$refs['checkbox_' + index][0].checked = false;
                });

                this.options.addList = [];
                this.options.delList = [];
                this.shuttleFrame.leftOriginalDataHandle();
                this.shuttleFrame.rightOriginalDataHandle();
            });
        },
        refreshData() {
            if (this.shuttleFrame.leftGrid.store.state.totalRecord && this.shuttleFrame.options.page) {
                let total =
                    this.shuttleFrame.leftGrid.store.state.totalRecord -
                    this.options.addList.length +
                    this.options.delList.length;
                this.shuttleFrame.leftTotalRecord = total;
                this.shuttleFrame.leftGrid.refreshByData({
                    total: total,
                    rows: this.options.data
                });
            } else {
                this.shuttleFrame.leftGrid.loadData(this.options.data);
            }

            if (this.shuttleFrame.rightGrid.store.state.totalRecord && this.shuttleFrame.options.page) {
                let total =
                    this.shuttleFrame.rightGrid.store.state.totalRecord -
                    this.options.delList.length +
                    this.options.addList.length;
                this.shuttleFrame.rightTotalRecord = total;
                this.shuttleFrame.rightGrid.refreshByData({
                    total: total,
                    rows: this.options.rightData
                });
            } else {
                this.shuttleFrame.rightGrid.loadData(this.options.rightData);
            }
        }
    }
};
</script>
<style scoped>
.shuttle-frame {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
}

.shuttle-frame .left-grid,
.shuttle-frame .right-grid {
    width: calc(50% - 20px);
    position: relative;
}

.shuttle-frame .left-grid > .title,
.shuttle-frame .right-grid > .title {
    position: absolute;
    z-index: 2;
    left: 8px;
    top: 8px;
    right: 8px;
    height: 40px;
    border: 1px solid #eee;
    background-color: rgb(232, 240, 246);
}

.shuttle-frame .left-grid > .title:after,
.shuttle-frame .right-grid > .title:after {
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    height: 10px;
    bottom: 0;
    background-color: #fff;
}

.shuttle-frame .left-grid > .title > span,
.shuttle-frame .right-grid > .title > span {
    display: inline-block;
    padding: 0 16px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 30px;
    background-color: #fff;
    border-right: 1px solid #eee;
}

.shuttle-frame .left-grid > .container,
.shuttle-frame .right-grid > .container {
    position: relative;
    width: 100%;
    height: 100%;
}

.shuttle-frame .center-btn {
    width: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.shuttle-frame .center-btn .icon {
    display: flex;
    width: 100%;
    height: 28px;
    color: rgba(0, 0, 0, 0.65);
    font-family: 'Microsoft YaHei', serif;
    font-size: 12px;
    border-radius: 4px;
    align-items: center;
}

.shuttle-frame .center-btn .icon:first-child {
    margin-bottom: 16px;
}

.shuttle-frame .center-btn .right-active {
    background: #007aff url(./img/right-arrow-fff.png) no-repeat center;
    cursor: pointer;
}

.shuttle-frame .center-btn .right-gray {
    background: #d9d9d9 url(./img/right-arrow-ccc.png) no-repeat center;
}

.shuttle-frame .center-btn .left-active {
    background: #007aff url(./img/left-arrow-fff.png) no-repeat center;
    cursor: pointer;
}

.shuttle-frame .center-btn .left-gray {
    background: #d9d9d9 url(./img/left-arrow-ccc.png) no-repeat center;
}

.shuttle-frame .left-grid,
.shuttle-frame .center-btn,
.shuttle-frame .right-grid {
    height: 100%;
}

.shuttle-frame >>> .grid .filter .container .icon:first-child {
    display: none;
}
</style>
