<template>
    <div v-if="readonly" class="date-input readonly">
        <div class="readonly-text">{{ value }}</div>
    </div>
    <div v-else class="date-input">
        <input :name="options.field" :value="text" :readonly="editable" />
        <div class="date-input-icon" v-on:click="popDatePicker"></div>
    </div>
</template>
<script>
import Gikam from '../../../core/gikam-core';
import Vue from 'vue';
import filterDatePicker from './filterDatePicker';
import jQuery from 'jquery';

document.addEventListener('click', () => {
    if (Gikam.filterDatePickerPanel) {
        Gikam.filterDatePickerPanel.destroy();
    }
});

export default {
    props: {
        options: Object,
        propValue: String,
        rowIndex: Number,
        filterData: {
            type: Object,
            default: () => ({})
        },
        propReadonly: {
            type: Boolean,
            default: void 0
        }
    },

    inject: ['grid'],

    computed: {
        editable() {
            return this.options.editable === true ? '' : 'readonly';
        },

        text() {
            if (!this.fromValue && !this.toValue) {
                return '';
            }
            return (this.fromValue || '') + '~' + (this.toValue || '');
        }
    },

    data() {
        return {
            validateResult: true,
            readonly: void 0,
            field: void 0,
            value: void 0,
            fromValue: '',
            toValue: '',
            active: false,
            isDateTime: false
        };
    },

    created() {
        if (this.propReadonly) {
            this.options.readonly = this.propReadonly;
        }
        Object.assign(this, this.options);
        if (Gikam.isNotEmpty(this.propValue)) {
            this.value = this.propValue;
        }
    },

    watch: {
        value(val) {
            if (val === '') {
                this.fromValue = '';
                this.toValue = '';
                this.filterData[this.options.field + '_' + 'DGOE'] = '';
                this.filterData[this.options.field + '_' + 'DLOE'] = '';
                this.refresh();
            }
        },

        propValue(val) {
            this.value = val;
        }
    },

    methods: {
        refresh() {
            if (this.options.filter === false) {
                return;
            }
            if (!Gikam.isFalse(this.grid.options.serverSearch) && !Gikam.isFalse(this.options.serverSearch)) {
                this.$store.commit('refresh', { requestData: this.filterData });
            } else {
                const _params = {
                    args: [this.options.field, this.text],
                    column: Gikam.deepExtend(this.options, { type: 'filterDateField' })
                };
                this.$store.commit('pageSearch', _params);
            }
        },
        popDatePicker(e) {
            e.stopPropagation();
            if (Gikam.filterDatePickerPanel) {
                Gikam.filterDatePickerPanel.destroy();
                return;
            }
            const mountedDom = document.createElement('div');
            document.body.appendChild(mountedDom);
            const $container = jQuery(this.$el);
            const top = $container.offset().top + $container.outerHeight() + 2;
            const left = $container.offset().left - 8;
            const width = $container.outerWidth() + 16;
            const isDateTime = this.isDateTime;
            const _this = this;
            Gikam.filterDatePickerPanel = new Vue({
                el: mountedDom,
                components: { filterDatePicker },
                methods: {
                    fromChangeHandle(value) {
                        _this.fromValue = value;
                        _this.filterData[_this.options.field + '_' + 'DGOE'] = value;
                        _this.refresh();
                    },
                    toChangeHandle(value) {
                        _this.toValue = value;
                        _this.filterData[_this.options.field + '_' + 'DLOE'] = value;
                        _this.refresh();
                    },
                    destroy() {
                        this.$refs.panel.$el.parentNode.removeChild(this.$refs.panel.$el);
                        this.$refs.panel.$destroy();
                        Gikam.filterDatePickerPanel = null;
                        _this.active = false;
                    }
                },
                render() {
                    return (
                        <filter-date-picker
                            propTop={top}
                            isDateTime={isDateTime}
                            propLeft={left}
                            propWidth={width}
                            propFromValue={_this.fromValue}
                            propToValue={_this.toValue}
                            onFromChange={this.fromChangeHandle}
                            onToChange={this.toChangeHandle}
                            ref="panel"
                        ></filter-date-picker>
                    );
                }
            });
        }
    }
};
</script>

<style>
.date-input {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: stretch;
    color: #666;
    font-size: 12px;
}

.date-input.readonly {
    display: flex;
    align-items: center;
}

.date-input input,
.date-input .readonly-text {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    padding-right: 40px;
    padding-left: 8px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 12px;
}

.date-input .readonly-text {
    background-color: #f4f4f4;
    color: rgba(0, 0, 0, 0.45);
    display: flex;
    align-items: center;
}

.date-input.validate-error input {
    border-color: #ff6e6e;
}

.date-input input:focus {
    border: 1px solid rgba(0, 122, 255, 0.5);
}

.date-input .date-input-icon {
    width: 30px;
    background: url(../../../../img/date/date.svg) no-repeat center;
    cursor: pointer;
    margin-left: -30px;
    background-size: 18px;
}
</style>
