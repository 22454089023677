<template>
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 38 38"
        style="enable-background:new 0 0 38 38;"
        xml:space="preserve"
    >
        <g :fill="color" fill-rule="nonzero">
            <path
                class="st0"
                d="M34.7,28.6h-6.4c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5h6.4c0.3,0,0.6-0.3,0.6-0.6V2.9c0-0.3-0.3-0.6-0.6-0.6
                H12.6c-0.3,0-0.6,0.3-0.6,0.6v6.4c0,0.8-0.7,1.5-1.5,1.5s-1.5-0.7-1.5-1.5V2.9c0-2,1.6-3.6,3.6-3.6h22.1c2,0,3.6,1.6,3.6,3.6V25
                C38.3,27,36.7,28.6,34.7,28.6z"
            />
            <path
                class="st0"
                d="M26.3,37.2H3.8c-1.9,0-3.5-1.6-3.5-3.5V11.3c0-1.9,1.6-3.5,3.5-3.5h22.4c1.9,0,3.5,1.6,3.5,3.5v22.4
                C29.8,35.6,28.2,37.2,26.3,37.2z M3.8,10.8c-0.3,0-0.5,0.2-0.5,0.5v22.4c0,0.3,0.2,0.5,0.5,0.5h22.4c0.3,0,0.5-0.2,0.5-0.5V11.3
                c0-0.3-0.2-0.5-0.5-0.5H3.8z"
            />
        </g>
    </svg>
</template>

<script>
export default {
    props: {
        color: {
            type: String,
            default: '#999'
        }
    }
};
</script>
