<template>
    <svg
        :width="width"
        :height="height"
        viewBox="0 0 20 20"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <rect id="Rectangle-12" :fill="color" x="0" y="0" width="20" height="20" rx="10" />
        <g id="消息-(4)" transform="translate(4.000000, 3.000000)" :fill="lineColor" fill-rule="nonzero">
            <path
                d="M8.08621469,1.97955959 L7.04333197,1.97955959 C7.04333197,1.62701507 6.63768451,1.04288274 6.20696066,1.04288274 C5.7452601,1.04288274 5.37058937,1.46328101 5.37058937,1.97955959 L4.32770663,1.97955959 C4.32770663,0.88799915 5.17145334,0 6.20696066,0 C7.24836831,0 8.08621469,1.08270993 8.08621469,1.97955959 Z M6.25858852,14 C4.42063677,14 2.92637907,12.5352439 2.92637907,10.7341692 L3.96926179,10.7341692 C3.96926179,11.9599621 4.99591862,12.9571173 6.25858852,12.9571173 C7.52125842,12.9571173 8.54791525,11.9599621 8.54791525,10.7341692 L9.59079799,10.7341692 C9.59079799,12.5352439 8.09654027,14 6.25858852,14 Z"
                id="Shape"
            />
            <path
                d="M11.5733077,11.5705405 L0.824387703,11.5705405 C0.523471047,11.5705405 0.246155697,11.4053314 0.101597686,11.1412918 C-0.0429603125,10.875777 -0.0296845801,10.5542093 0.13404948,10.3019703 L0.991071922,8.98029713 L1.56782886,7.94921504 L2.24341626,3.21715309 C2.40862541,2.43535981 3.10781412,1.8659783 3.90730836,1.86155305 L6.45772455,1.85122748 L8.49038706,1.86155305 C9.28988132,1.8659783 9.98907003,2.43535981 10.1542792,3.21715309 L10.1601795,3.25107997 L10.8298666,7.94921504 L11.4110487,8.98767253 L12.2621709,10.3004952 C12.4259049,10.5542092 12.4391807,10.875777 12.2946227,11.1398167 C12.1515397,11.4053314 11.8742244,11.5705405 11.5733077,11.5705405 Z M1.23003516,10.5276578 L11.1661852,10.5276578 L10.4964981,9.49067537 L9.89761497,8.42124117 C9.8474622,8.33273628 9.81648548,8.23685596 9.80173466,8.13655042 L9.12909743,3.41776419 C9.05976859,3.11979769 8.78982865,2.90443578 8.48301166,2.9029607 L6.45772455,2.89263513 L3.91320869,2.9029607 C3.6063917,2.90443579 3.33792684,3.11979771 3.26859801,3.41776419 L2.59596077,8.13655042 C2.58120996,8.23685598 2.54875816,8.33273628 2.50008046,8.42124117 L1.86727043,9.54820356 L1.23003516,10.5276578 Z"
                id="Shape"
            />
        </g>
    </svg>
</template>

<script>
import baseSvgImg from './baseSvgImg';
export default {
    extends: baseSvgImg,
    props: {
        color: {
            type: String,
            default: '#007AFF'
        },
        lineColor: {
            type: String,
            default: '#fff'
        }
    }
};
</script>
