import Gikam from '../../../core/gikam-core.js';
import Base from '../../base.js';
import Vue from 'vue';

let defaultOptions = {
    id: `map-${new Date().getTime()}`,
    width: '100%',
    height: '100%',
    //离线地图模式下指定图片格式
    imgType: 'png',
    //传入标记点集合
    points: [],
    maxZoom: 16,
    minZoom: 4,
    formatterInfoWindow() {
        return '';
    },
    zoom: 11,
    types: {
        direction: 'column',
        position: 'topLeft',
        data: []
    },
    offline: void 0,
    paths: [],
    // 在线地图的轨迹图是否曲线，默认直线
    curve: false,
    // 增加属性，newMarker
    newMarker: false
};

export default class Map extends Base {
    constructor(options) {
        super(options);
        this.initialize(options, defaultOptions).init();
    }

    createMap() {
        const _this = this;
        const model = new Vue({
            el: Gikam.createDom('div', this.options.renderTo),
            components: {
                AMap: () => {
                    if (Gikam.isTrue(this.options.offline)) {
                        return import('../vue/openLayers.vue');
                    } else if (Gikam.isFalse(this.options.offline)) {
                        return import('../vue/aMap.vue');
                    } else {
                        if (navigator.onLine) return import('../vue/aMap.vue');
                        else return import('../vue/openLayers.vue');
                    }
                }
            },
            data() {
                return {
                    options: _this.options,
                    pointArray: _this.pointDataFilter(_this.options.points),
                    pathArray: _this.options.paths
                };
            },
            render() {
                return (
                    <a-map
                        ref="vm"
                        options={this.options}
                        pointArray={this.pointArray}
                        pathArray={this.pathArray}
                        onReady={() => {
                            _this.bindInstance(this.$el);
                            _this.message();
                        }}
                    ></a-map>
                );
            }
        });

        return model;
    }

    /**
     * @description 刷新标记点
     * @public
     * @param {Array} 标记点集合
     * @memberof map
     */
    refreshPoint(points) {
        if (points instanceof Array) this.model.pointArray = this.pointDataFilter(points);
    }

    /**
     * @description 指定当前地图等级
     * @public
     * @param {Number} 地图等级
     * @memberof map
     */
    setMapZoom(zoom) {
        this.model.$refs.vm.setMapZoom(zoom);
    }

    /**
     * @description
     * @param {*} lng, lat 经纬度
     * @memberof Map
     */
    setMapCenter(lng, lat) {
        this.model.$refs.vm.setMapCenter(lng, lat);
    }

    /**
     * @description 选择经纬度
     * @returns {Object}
     *          {lng: 47.34, lat: 123.78}
     * @memberof Map
     */
    pickLngLat() {
        return this.model.$refs.vm.chosenPointLngLat();
    }

    /**
     * @description
     * @param {*} anchor
     *            {lng: 47.34, lat: 123.78, color: 'blue', url: '', data:[{name: 'key', value: 'value'}]}
     * @memberof Map
     */
    addMarker(anchors) {
        this.model.$refs.vm.addMarker(anchors);
    }

    clearMarks() {
        this.model.$refs.vm.clearMarks();
    }

    message() {
        let _this = this;
        this.model.$refs.vm.$on('levelChange', function(data) {
            if (typeof _this.options.onLevelChange === 'function') {
                _this.options.onLevelChange.call(_this, data);
            }
        });
        this.model.$refs.vm.$on('zoomChange', function(zoom, level) {
            if (typeof _this.options.onZoomChange === 'function') {
                _this.options.onZoomChange.call(_this, zoom, level);
            }
        });
        this.model.$refs.vm.$on('drawCircle', function(center, radius) {
            if (typeof _this.options.onDrawCircle === 'function') {
                _this.options.onDrawCircle.call(_this, center, radius);
            }
        });
    }

    /** 改变地图当前层级
     * @description
     * @param {*} adcode 行政区划码
     * @param {*} level 行政区划等级  'province' 'city'
     * @memberof Map
     */
    changeLevel(adcode, level) {
        this.model.$refs.vm.changeLevel(adcode, level);
    }

    /**
     * @description 过滤锚点
     * @param {*} typeFilter ['type1', 'type2', 'type3']
     * @param {*} timeFilter 1900
     * @memberof Map
     */
    filter(typeFilter, timeFilter) {
        this.model.$refs.vm.filter(typeFilter, timeFilter);
    }

    /**
     * @description 改变某区块颜色
     * @param {*} data
     *            [{ adcode: 140200, color: 'yellow' }, { adcode:141000, color: 'blue'}, { adcode: 140100, color: 'red'}]
     * @memberof Map
     */
    changeAreaColor(data) {
        this.model.$refs.vm.changeAreaColor(data);
    }

    /**
     * @description
     * @param {*} data
     *              [{ adcode: 140100, color: 'red' }]
     * @memberof Map
     */
    setAreaColorBling(data) {
        this.model.$refs.vm.setAreaColorBling(data);
    }

    /**
     * @description
     * @param {*} data [{ adcode: 140100 }] 不传时清楚所有
     * @memberof Map
     */
    clearAreaColorBling(data) {
        this.model.$refs.vm.clearAreaColorBling(data);
    }

    /**
     * @description
     * @param {*} point [lng, lat]
     * @param {*} radius 1000 单位m
     * @memberof Map
     */
    drawCircle(point, radius) {
        this.model.$refs.vm.drawCircle(point, radius);
    }

    /**
     * @description 传入标记点数据过滤，将传入数据过滤为地图组件可用数据
     * @public
     * @param {Array} 标记点集合
     * @memberof map
     */
    pointDataFilter(points) {
        let resultArray = [];
        for (let point of points) {
            let longitude = point['longitude'] || point['LONGITUDE'];
            let latitude = point['latitude'] || point['LATITUDE'];
            // 如果传入对象不包含经纬度，不展示此标记点
            if (isNaN(longitude) || isNaN(latitude)) continue;
            let tempObj = { data: [] };
            tempObj.lat = parseFloat(latitude);
            tempObj.lng = parseFloat(longitude);
            tempObj.ico = point['icon'] || point['ICON'];
            tempObj.imgSize = point['imgSize'];
            tempObj.type = point['type'];
            tempObj.year = point['year'];
            this.fillTempObj(tempObj, point);
            resultArray.push(tempObj);
        }
        return resultArray;
    }

    fillTempObj(tempObj, point) {
        for (let item in point) {
            if (item === 'title') {
                tempObj.title = Gikam.propI18N(point.title);
                continue;
            }
            if (item.toLowerCase() === 'longitude') continue;
            if (item.toLowerCase() === 'latitude') continue;
            if (item.toLowerCase() === 'icon') continue;
            if (item === 'imgSize') continue;
            tempObj.data.push({
                name: Gikam.propI18N(item.toUpperCase()),
                value: Gikam.propI18N(point[item])
            });
        }
    }

    /**
     * @description 离线地图使用，添加一个新的悬浮面板
     * @public
     * @param {options} 数组
     * @memberof map
     */

    addComPanel(options) {
        this.model.$refs.vm.addComPanel(options);
    }

    /**
     * @description 离线地图使用，重新加载尺寸
     * @public
     * @memberof map
     */
    onResize() {
        this.model.$refs.vm.onResize();
    }

    /**
     * @description 离线地图使用，导出地图图片
     * @public
     * @memberof map
     */
    exportMap() {
        this.model.$refs.vm.exportMap();
    }

    refreshPath(pathArray) {
        this.model.pathArray = pathArray;
        this.model.$refs.vm.refreshPath(pathArray);
    }

    init() {
        this.model = this.createMap();
    }
}
