export function getTopButton({ auditTrailEvent, addEvent, backEvent, printEvent, importEvent }) {
    const buttonList = [];
    if (auditTrailEvent) {
        buttonList.push({
            type: 'button',
            text: '审计跟踪',
            icon: 'view-audit',
            onClick: auditTrailEvent
        });
    }
    if (addEvent) {
        buttonList.push({
            type: 'button',
            text: '添加内容',
            icon: 'add',
            onClick: addEvent
        });
    }
    if (printEvent) {
        buttonList.push({
            type: 'button',
            text: '打印',
            icon: 'print',
            color: 'warning',
            onClick: printEvent
        });
    }
    if (importEvent) {
        buttonList.push({
            type: 'button',
            text: '导出PDF',
            icon: 'view-audit',
            color: 'info',
            onClick: importEvent
        });
    }
    if (backEvent) {
        buttonList.push({
            type: 'button',
            text: '返回',
            icon: 'back',
            color: 'danger',
            onClick: backEvent
        });
    }

    return buttonList;
}

export function getLayoutOptions() {
    return {
        north: {
            height: 0,
            title: void 0,
            shrinkTitle: []
        },
        west: {
            title: void 0,
            shrinkTitle: [],
            width: '200px',
            resize: true
        },
        east: {
            title: void 0,
            shrinkTitle: [],
            width: '0',
            resize: true
        },
        south: {
            height: 0,
            title: void 0,
            shrinkTitle: []
        },
        center: {}
    };
}

export function getOutLayoutOptions() {
    return {
        north: {
            height: 0,
            title: void 0,
            shrinkTitle: []
        },
        west: {
            title: void 0,
            shrinkTitle: [],
            width: 0,
            resize: true
        },
        east: {
            title: void 0,
            shrinkTitle: [],
            width: 0,
            resize: true
        },
        south: {
            height: 0,
            title: void 0,
            shrinkTitle: []
        },
        center: {}
    };
}

export function getAddModelOptions() {
    return {
        title: '添加内容',
        id: 'les-modal',
        width: 520,
        isModal: true,
        modal: {
            trigger() {}
        }
    };
}

export function getWestTabOptions() {
    return {
        id: 'les-weat-tab',
        show: true,
        showIndex: 0,
        shrink: false,
        fill: true,
        panels: [
            {
                id: 'section',
                title: '章节'
            },
            {
                id: 'annex',
                title: '附件'
            }
        ]
    };
}

export function getEastTabOptions() {
    return [
        {
            id: 'les-east-tab-affair',
            type: 'affair',
            show: true,
            showIndex: 0,
            shrink: true,
            height: 'auto',
            panels: [
                {
                    id: 'section',
                    title: '事务'
                }
            ]
        },
        {
            id: 'les-east-tab-enclosure',
            show: true,
            type: 'enclosure',
            showIndex: 0,
            shrink: true,
            panels: [
                {
                    id: 'section',
                    title: '附件'
                }
            ]
        }
    ];
}

export function getAuditModelOptions() {
    return {
        title: '审计跟踪',
        id: 'audit-modal',
        isModal: true,
        modal: {
            trigger() {}
        }
    };
}

export function getAuditLayoutOptions() {
    return {
        west: {
            title: void 0,
            shrinkTitle: [],
            width: '500px',
            resize: true
        },
        north: {
            height: 0,
            title: void 0,
            shrinkTitle: []
        },
        east: {
            title: void 0,
            shrinkTitle: [],
            width: '0',
            resize: true
        },
        south: {
            height: 0,
            title: void 0,
            shrinkTitle: []
        },
        center: {}
    };
}
