<template>
    <svg
        version="1.1"
        id="图层_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 200 200"
        style="enable-background:new 0 0 200 200;"
        xml:space="preserve"
    >
        <path
            :fill="color"
            d="M68.9-30.5l77.9,87.4c2.1,2.6,3.2,5.3,3.2,8.9c0,3.2-1.1,6.3-3.2,8.9l-77.9,87.4c-2.1,2.6-4.7,3.7-7.9,3.7
            c-3.2,0-5.8-1.1-7.9-3.7s-3.2-5.3-3.2-8.9V-22.1c0-3.2,1.1-6.3,3.2-8.9c2.1-2.1,4.7-3.2,7.9-3.2C64.2-34.2,66.8-33.2,68.9-30.5z"
        />
    </svg>
</template>
<script>
import baseSvgImg from './baseSvgImg';
export default { extends: baseSvgImg };
</script>
<style scoped>
svg {
    display: block;
}
</style>
