<template>
    <div component="confirm" class="confirm-dialog">
        <div class="confirm-container" :style="{ height: options.height + 'px' }">
            <div class="header">
                {{ title }}
                <div class="close-btn" @click="closeClick"></div>
            </div>
            <div v-if="subTitle" class="sub-title">{{ subTitle }}</div>
            <section>
                <div class="message-body" v-html="message"></div>
            </section>
            <div class="footer">
                <button class="yes button" @click="yesClick()">
                    {{ options.yesText || $i18n('confirm.yes') }}
                </button>
                <button class="no button" @click="noClick()">{{ options.noText || $i18n('confirm.no') }}</button>
            </div>
        </div>
    </div>
</template>

<script>
import Gikam from '../../core/gikam-core';

export default {
    props: {
        options: Object
    },

    created() {
        Gikam.extend(this, this.options);
    },

    data() {
        return {
            title: void 0,
            subTitle: void 0,
            message: void 0
        };
    },

    methods: {
        noClick() {
            Gikam.removeDom(this.$el);
            this.options.onNoClick();
        },

        yesClick() {
            Gikam.buttonText = '确定';
            Gikam.removeDom(this.$el);
            this.options.onYesClick();
            setTimeout(() => {
                Gikam.buttonText = null;
            }, 300);
        },

        closeClick() {
            Gikam.removeDom(this.$el);
        }
    }
};
</script>

<style>
.confirm-dialog {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Microsoft YaHei', serif;
    z-index: 10;
}

.confirm-dialog > .confirm-container {
    width: 408px;
    height: 204px;
    background-color: #fff;
    border-radius: 4px;
    position: relative;
    padding: 0 24px 44px 24px;
    padding-bottom: 44px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.15);
}

.confirm-dialog > .confirm-container > .header {
    height: 48px;
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    padding: 0;
    justify-content: space-between;
    font-weight: bold;
}

.confirm-dialog > .confirm-container > .header > .close-btn {
    width: 15px;
    height: 15px;
    background: url(../../../img/box-close.png) center no-repeat;
    cursor: pointer;
}

.confirm-dialog > .confirm-container > section {
    font-size: 12px;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    flex: 1;
    position: relative;
}

.confirm-dialog > .confirm-container > section > .message-body {
    position: absolute;
    top: 0;
    bottom: 12px;
    left: 0;
    right: 0;
    overflow: auto;
}

.confirm-dialog > .confirm-container > .footer {
    font-size: 14px;
    color: #666;
    height: 28px;
    align-items: flex-start;
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 16px;
    right: 24px;
}

.confirm-dialog > .confirm-container > .footer > button {
    border-radius: 4px;
    width: 64px;
    height: 28px;
    margin-left: 20px;
    border: 1px solid #d9d9d9;
    background-color: #fff;
    outline: none;
    font-size: 12px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding: 0;
}

.confirm-dialog > .confirm-container > .footer > button.yes {
    background-color: #007aff;
    color: #fff;
    text-align: center;
}

.confirm-dialog > .confirm-container > .footer > button.no {
    background-color: #fff;
    color: #666;
    text-align: center;
}

.confirm-dialog > .confirm-container > .sub-title {
    height: 20px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
}
</style>
