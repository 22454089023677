<template>
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 38 38"
        style="enable-background:new 0 0 38 38;"
        xml:space="preserve"
    >
        <g :fill="color" fill-rule="nonzero">
            <path
                class="st0"
                d="M33.5,38h-29C2,38,0,36,0,33.5v-29C0,2,2,0,4.5,0h29C36,0,38,2,38,4.5v29C38,36,36,38,33.5,38z M4.5,3C3.7,3,3,3.7,3,4.5v29C3,34.3,3.7,35,4.5,35h29c0.8,0,1.5-0.7,1.5-1.5v-29C35,3.7,34.3,3,33.5,3H4.5z"
            />
        </g>
    </svg>
</template>

<script>
export default {
    props: {
        color: {
            type: String,
            default: '#999'
        }
    }
};
</script>
