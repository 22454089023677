/**
 * @export
 * @class Sequence
 * @description 获取序列类
 */
export class Sequence {
    constructor() {
        this.currentValue = 0;
    }

    getNextValue() {
        return (this.currentValue += 1);
    }
}
