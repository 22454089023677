<template>
    <div
        class="generalGroup-panel"
        @mouseleave.stop="close"
        @mouseenter="enterPanel = true"
        :class="{ 'mask-position': propMaskPosition }"
    >
        <div class="generalGroup-panel-body">
            <ul>
                <li
                    class="item"
                    @click.stop="generalHandle('refresh')"
                    @touchend="generalHandle('refresh')"
                    @touchmove.stop
                    @mousedown.stop
                    v-if="showToolBtn('refresh')"
                >
                    <refreshImg class="refresh" :color="'#007AFF'" />{{ $i18n('grid.generalButtonRefresh') }}
                </li>
                <li
                    class="item"
                    @click.stop="generalHandle('import')"
                    @touchend="generalHandle('import')"
                    @touchmove.stop
                    @mousedown.stop
                    v-if="showToolBtn('import')"
                >
                    <importImg class="import" :color="'#29CF68'" />{{ $i18n('grid.generalButtonImport') }}
                </li>
                <li
                    class="item"
                    @click.stop="generalHandle('export')"
                    @touchend="generalHandle('export')"
                    @touchmove.stop
                    @mousedown.stop
                    v-if="showToolBtn('export')"
                >
                    <exportImg class="export" :color="'#007AFF'" />{{ $i18n('grid.generalButtonExport') }}
                </li>
                <li
                    class="item"
                    @touchend="generalHandle('columnResize')"
                    @touchmove.stop
                    @mousedown.stop
                    v-if="isMobile()"
                >
                    <columnResizeImg class="column-resize" />
                    {{ $i18n('grid.generalButtonColumnResize') }}
                </li>
                <li
                    class="item"
                    @click.stop="generalHandle('search')"
                    @touchend="generalHandle('search')"
                    @touchmove.stop
                    @mousedown.stop
                    v-if="propFilter"
                >
                    <searchImg class="search" :color="'#29CF68'" />
                    {{ searchText }}
                </li>

                <li
                    class="item"
                    @click.stop="generalHandle('clear')"
                    @touchend="generalHandle('clear')"
                    @touchmove.stop
                    @mousedown.stop
                    v-if="propFilter"
                >
                    <clearImg class="clear" :color="'#F9762B'" />{{ $i18n('grid.generalButtonClear') }}
                </li>
                <li
                    class="item"
                    @click.stop="generalHandle('config')"
                    @touchend="generalHandle('config')"
                    @touchmove.stop
                    @mousedown.stop
                    v-if="showToolBtn('config') && propGridId"
                >
                    <configImg :color="'#007AFF'" />{{ $i18n('grid.generalButtonConfig') }}
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import Gikam from '../../../core/gikam-core';
export default {
    props: {
        propGridId: String,
        generalButtonGroup: [Array, Boolean],
        propFilterOpen: Boolean,
        propFilter: Boolean,
        propMaskPosition: Boolean,
        propEnterButton: Boolean
    },

    data() {
        return {
            enterPanel: false
        };
    },

    computed: {
        searchText() {
            return !this.propFilterOpen
                ? this.$i18n('grid.generalButtonSearchOpen')
                : this.$i18n('grid.generalButtonSearchClose');
        },

        enterButton() {
            return this.propEnterButton;
        }
    },

    mounted() {
        setTimeout(() => {
            if (!this.enterButton && !this.enterPanel && Gikam.generalGroupPanel) {
                Gikam.generalGroupPanel.destroy();
            }
        }, 600);
    },

    methods: {
        isMobile() {
            return Gikam.isMobile();
        },

        generalHandle(name) {
            this.$emit('generalButtonClick', name);
        },

        showToolBtn(name) {
            if (this.generalButtonGroup === false) {
                return false;
            } else {
                return this.generalButtonGroup.indexOf(name) > -1;
            }
        },

        close() {
            this.enterPanel = false;
            Gikam.generalGroupPanel && Gikam.generalGroupPanel.destroy();
        }
    }
};
</script>

<style scoped>
@keyframes slide-in {
    from {
        transform: translate3d(0, 20px, 0);
        opacity: 0.3;
    }
    to {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

.generalGroup-panel {
    width: 120px;
    position: absolute;
    animation: slide-in 0.3s;
    padding-top: 27px;
    z-index: 10;
}

.generalGroup-panel.mask-position {
    padding-top: 0;
}
.generalGroup-panel.mask-position:after {
    content: '';
    width: 25px;
    height: 27px;
    position: absolute;
    bottom: -27px;
    left: 0;
}

.generalGroup-panel > .generalGroup-panel-body {
    position: absolute;
    right: 0;
    min-width: 100px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    background-color: #fff;
    border-radius: 4px;
}

.generalGroup-panel ul {
    margin: 0;
    padding: 4px 0;
}

.generalGroup-panel ul > li {
    display: flex;
    align-items: center;
    font-size: 12px;
    white-space: nowrap;
    color: rgba(0, 0, 0, 0.65);
    height: 25px;
    padding: 0 8px;
    cursor: pointer;
}

.generalGroup-panel ul > li:hover {
    background-color: rgba(0, 122, 255, 0.08);
}

.generalGroup-panel svg {
    width: 14px;
    margin-right: 6px;
}

.generalGroup-panel svg.refresh {
    width: 16px;
    margin-left: -2px;
}

.generalGroup-panel svg.export {
    width: 16px;
    margin-left: -2px;
}

.generalGroup-panel svg.column-resize {
    height: 18px;
}

.generalGroup-panel svg.search {
    width: 16px;
    margin-left: -2px;
}

.generalGroup-panel svg.clear {
    width: 12px;
    margin-right: 7px;
}
</style>
