<template>
    <div
        class="time-input"
        :class="{ readonly: readonly, invisible: invisible }"
        @dblclick="dblclickHandle"
        @mousedown.stop
        @click="clickHandle"
    >
        <div v-if="readonly || invisible" class="readonly-text">{{ value }}</div>
        <template v-else>
            <input :name="options.field" v-model="value" :readonly="inputEditable" ref="input" autocomplete="off" />
            <div class="time-input-icon" v-on:click.stop="focus">
                <clock color="#777" />
            </div>
        </template>
    </div>
</template>
<script>
import jQuery from 'jquery';
import Vue from 'vue';
import GikamCore from 'gikam';
import timePicker from './timePicker';

const defaultOptions = {
    //时间是否循环展示
    circular: true
};

const Gikam  = top === window ? GikamCore : top.Gikam;

export default {
    name: 'timeField',
    props: {
        options: Object,
        propValue: String,
        rowIndex: Number,
        cellIndex: Number,
        //编辑器中的边框等样式是否显示出来
        propInvisible: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        time() {
            let tempDate = Gikam.DateUtils.formatter(new Date(), 'yyyy-MM-dd');
            if (this.value) {
                return Gikam.DateUtils.toDateTime(tempDate + ' ' + this.value.trim());
            } else if (this.options.defaultValue) {
                return Gikam.DateUtils.toDateTime(tempDate + ' ' + this.options.defaultValue.trim());
            } else {
                return '';
            }
        },
        inputEditable() {
            return this.options.editable === true ? false : 'readonly';
        }
    },

    data() {
        return {
            readonly: void 0,
            field: void 0,
            value: void 0,
            invisible: this.propInvisible,
            circular: this.options.circular || defaultOptions.circular
        };
    },

    created() {
        Object.assign(this, this.options);
        if (Gikam.isNotEmpty(this.propValue)) {
            this.value = this.propValue;
        }
    },

    watch: {
        value() {
            this.$emit('change', this.options.field, this.value, this.rowIndex);
        },

        propValue(val) {
            this.value = val;
        }
    },

    methods: {
        cleanTimePicker() {
            Gikam.timePicker.destroy();
        },

        popTimePicker() {
            let _this = this;
            if (Gikam.timePicker) {
                this.cleanTimePicker();
            }

            if (!Gikam.isTrue(this.propInvisible)) {
                Gikam.simulatedEvent(document, 'mousedown');
            }

            if (this.readonly) {
                return;
            }

            Gikam.timePicker = new Vue({
                el: Gikam.createDom('div', window.top.document.body),
                components: { timePicker },
                methods: {
                    destroy() {
                        jQuery(this.$el).remove();
                        this.$destroy();
                        Gikam.timePicker = null;
                    }
                },
                render() {
                    return <time-picker timeInput={_this} />;
                }
            });
        },

        dblclickHandle() {
            this.$emit('dblclick', this);
        },

        override(props) {
            Gikam.extend(defaultOptions, props);
        },

        dumpActiveCell() {
            Gikam.timePicker && this.cleanTimePicker();
            this.$refs.input && this.$refs.input.blur();
        },

        activeCell() {
            if (this.invisible) {
                this.$emit('click', this);
            } else {
                this.focus();
            }
        },

        focus(noPop) {
            this.$refs.input && this.$refs.input.focus();
            this.$emit('saveCoordinate');
            if (noPop !== true) {
                this.popTimePicker();
            }
        },

        //点击时保存当前单元格的坐标
        clickHandle() {
            if (!this.invisible) {
                this.$emit('saveCoordinate');
            } else {
                this.$emit('click', this);
            }
        }
    }
};
</script>

<style scoped>
.time-input {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: stretch;
    color: #666;
    font-size: 12px;
}

.time-input.readonly {
    display: flex;
    align-items: center;
}

.time-input input,
.time-input .readonly-text {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    padding-right: 30px;
    padding-left: 8px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 12px;
}

.time-input .readonly-text {
    background-color: #f4f4f4;
    display: flex;
    align-items: center;
}

.time-input input:focus {
    border: 1px solid rgba(0, 122, 255, 0.5);
}

.time-input .time-input-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    cursor: pointer;
    margin-left: -30px;
}

.time-input .time-input-icon svg {
    width: 16px;
    height: 16px;
}

.invisible .readonly-text {
    background-color: transparent;
    border: none;
}
</style>
