<template>
    <svg
        version="1.1"
        id="图层_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 200 200"
        style="enable-background:new 0 0 200 200;"
        xml:space="preserve"
    >
        <path
            :fill="color"
            d="M131.1,162.1L53.2,74.7c-2.1-2.6-3.2-5.3-3.2-8.9c0-3.2,1.1-6.3,3.2-8.9l77.9-87.4c2.1-2.6,4.7-3.7,7.9-3.7
            s5.8,1.1,7.9,3.7c2.1,2.6,3.2,5.3,3.2,8.9v175.3c0,3.2-1.1,6.3-3.2,8.9c-2.1,2.1-4.7,3.2-7.9,3.2
            C135.8,165.8,133.2,164.7,131.1,162.1z"
        />
    </svg>
</template>
<script>
import baseSvgImg from './baseSvgImg';
export default { extends: baseSvgImg };
</script>
<style scoped>
svg {
    display: block;
}
</style>
