<template>
    <svg
        :width="width"
        :height="height"
        viewBox="0 0 10 16"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g transform="translate(-1250.000000, -238.000000)" :fill="color" fill-rule="nonzero">
                <g transform="translate(1242.000000, 132.000000)">
                    <g
                        transform="translate(12.706999, 112.987659) rotate(44.000000) translate(-12.706999, -112.987659) translate(6.206999, 106.487659)"
                    >
                        <g transform="translate(0.719340, 0.305342)">
                            <path
                                d="M4.89272502,9.78325123 C2.20961775,9.78325123 1.97175609e-13,7.57635468 1.97175609e-13,4.89655172 C1.97175609e-13,2.21674875 2.20961775,0.00985222167 4.89272502,0.00985222167 C7.57583229,0.00985222167 9.78545006,2.21674877 9.78545006,4.89655172 C9.78545006,7.57635467 7.57583231,9.78325123 4.89272502,9.78325123 Z M4.89272502,0.95566503 C2.71467323,0.95566503 0.946979038,2.72118226 0.946979038,4.89655172 C0.946979038,7.07192118 2.71467323,8.83743842 4.89272502,8.83743842 C7.07077681,8.83743842 8.83847102,7.07192118 8.83847102,4.89655172 C8.83847102,2.72118226 7.07077681,0.95566503 4.89272502,0.95566503 Z"
                                id="Shape"
                            ></path>
                            <path
                                d="M11.5215783,11.9901478 C11.3953144,11.9901478 11.2690505,11.9586207 11.1743527,11.8640394 L7.67053021,8.36453202 C7.4811344,8.17536946 7.4811344,7.89162562 7.67053021,7.70246305 C7.85992602,7.51330049 8.14401973,7.51330049 8.33341554,7.70246305 L11.837238,11.2019704 C12.0266338,11.391133 12.0266338,11.6748768 11.837238,11.8640394 C11.774106,11.9586207 11.6478422,11.9901478 11.5215783,11.9901478 L11.5215783,11.9901478 Z"
                                id="Shape"
                            ></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
</template>
<script>
import baseSvgImg from './baseSvgImg';
export default {
    extends: baseSvgImg,
    props: {
        color: {
            type: String,
            default: '#29CF68'
        }
    }
};
</script>
<style scoped>
svg {
    display: block;
}
</style>
