import Vuex from 'vuex';
import Vue from 'vue';

function getState() {
    return {
        activatedNode: null,
        activatedLine: null,
        canvas: null,
        animateState: {}
    };
}

function getMutations() {
    return {
        setActivatedNode(state, value) {
            state.activatedNode = value;
        },

        setActivatedLine(state, value) {
            state.activatedLine = value;
        },

        setCanvas(state, canvas) {
            state.canvas = canvas;
        },

        setAnimateState(state, item) {
            Vue.set(state.animateState, item.id, item.value);
        }
    };
}

function getActions() {
    return {
        activateNode({ commit }, node) {
            commit('setActivatedNode', node);
            commit('setActivatedLine', null);
        },

        activateLine({ commit }, line) {
            commit('setActivatedLine', line);
            commit('setActivatedNode', null);
        }
    };
}

function getGetters() {
    return {
        isActivatedNode(state) {
            return state.activatedNode;
        },

        isActivatedLine(state) {
            return state.activatedLine;
        },

        canvasInstance(state) {
            return state.canvas;
        },

        currentLine(state) {
            return state.activatedLine;
        },

        animateState(state) {
            return state.animateState;
        }
    };
}

export function getStore() {
    return new Vuex.Store({
        state: getState(),
        mutations: getMutations(),
        actions: getActions(),
        getters: getGetters()
    });
}
