<template>
    <div class="toast" v-if="show" :class="position">
        <div class="text">{{ options.message }}</div>
    </div>
</template>

<script>
export default {
    props: {
        options: Object
    },

    data() {
        return {
            show: true
        };
    },

    computed: {
        position() {
            const position = { top: 'top', middle: 'middle', bottom: 'bottom' }[this.options.position];
            return position || 'top';
        }
    },

    methods: {
        closeHandle() {
            setTimeout(() => {
                this.show = false;
            }, this.options.duration * 1000);
        }
    },

    mounted() {
        this.closeHandle();
    }
};
</script>

<style scoped>
@keyframes topHandle {
    from {
        top: 115px;
    }
    to {
        top: 100px;
    }
}

@keyframes bottomHandle {
    from {
        bottom: 39px;
    }
    to {
        bottom: 32px;
    }
}

@keyframes middleHandle {
    from {
        bottom: 47%;
    }
    to {
        bottom: 50%;
    }
}

.toast {
    width: 100%;
    height: 0;
    display: flex;
    justify-content: center;
    z-index: 99;
}

.toast.top {
    position: fixed;
    top: 100px;
    animation: topHandle 0.3s;
}

.toast.bottom {
    position: fixed;
    bottom: 32px;
    animation: bottomHandle 0.3s;
}

.toast.middle {
    position: fixed;
    bottom: 50%;
    animation: middleHandle 0.3s;
}

.toast .text {
    max-width: 90%;
    min-width: 100px;
    height: 24px;
    font-size: 12px;
    color: #fff;
    text-align: center;
    line-height: 24px;
    padding: 0 16px;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 4px;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.5);
}
</style>
