<template>
    <div
        class="general-group-container"
        @mouseenter="panelTimer"
        @touchend="panelTimer"
        @click.stop
        @mouseleave="enterButton = false"
    >
        <generalGroup color="#1597a5" />
    </div>
</template>

<script>
import Vue from 'vue';
import Gikam from '../../../core/gikam-core';
import generalButtonPanel from './generalButtonPanel.vue';
import GridConfig from './gridConfig.js';

export default {
    inject: ['grid'],

    data() {
        return {
            timer: null,
            enterButton: false
        };
    },

    methods: {
        panelTimer() {
            this.enterButton = true;
            clearTimeout(this.timer);
            this.timer = setTimeout(() => {
                if (this.enterButton && !Gikam.generalGroupPanel) {
                    this.showGeneralGroupPanel();
                }
                this.timer = null;
            }, 200);
        },

        showGeneralGroupPanel() {
            let { generalButtonGroup, filter } = this.grid.options;
            if (generalButtonGroup !== false && generalButtonGroup.length === 0) {
                if (this.grid.options.id) {
                    generalButtonGroup = ['refresh', 'export', 'config'];
                } else {
                    generalButtonGroup = ['refresh', 'export'];
                }
            }
            const { top, right, width } = this.$el.getBoundingClientRect();
            const _right = document.body.clientWidth - right + 'px';
            const panelHeight = generalButtonGroup.length * 25 + (filter === false ? 0 : 2 * 25);
            const maskPosition = top + panelHeight > window.innerHeight;
            const _top = (maskPosition ? top - panelHeight - 10 : top) + 'px';
            const _this = this;
            Gikam.generalGroupPanel = new Vue({
                el: Gikam.createDom('div', document.body),
                methods: {
                    destroy() {
                        if (Gikam.generalGroupPanel) {
                            Gikam.removeDom(Gikam.generalGroupPanel.$el);
                            Gikam.generalGroupPanel.$destroy();
                            Gikam.generalGroupPanel = null;
                        }
                    },
                    generalClickHandle(name) {
                        if (name === 'search') {
                            _this.grid.options.filterOpen = !_this.grid.options.filterOpen;
                        } else if (name === 'clear') {
                            _this.grid.model.$refs.vm.$refs.header.resetFilter();
                        } else if (name === 'refresh') {
                            _this.grid.refresh({}, true);
                        } else if (name === 'export') {
                            _this.grid.exportExcel(null, null, true);
                        } else if (name === 'config') {
                            new GridConfig(_this.$store);
                        } else if (name === 'columnResize') {
                            _this.grid._addMask();
                        }
                        this.destroy();
                    }
                },
                components: { generalButtonPanel },
                render() {
                    return (
                        <generalButtonPanel
                            onGeneralButtonClick={this.generalClickHandle}
                            propGridId={_this.grid.options.id}
                            propFilterOpen={_this.grid.options.filterOpen}
                            propFilter={filter}
                            propEnterButton={_this.enterButton}
                            propMaskPosition={maskPosition}
                            generalButtonGroup={generalButtonGroup}
                            style={{ right: _right, top: _top, width: width + 'px', height: panelHeight + 8 + 'px' }}
                        />
                    );
                }
            });
        }
    }
};
</script>

<style scoped>
@keyframes slide-in {
    from {
        transform: translate3d(0, 20px, 0);
        opacity: 0.3;
    }
    to {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

.general-group-container {
    width: 24px;
    height: 24px;
    padding: 2px 4px 4px 4px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    cursor: pointer;
}
</style>
