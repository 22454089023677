<template>
    <!-- Generator: Adobe Illustrator 22.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
    <svg
        version="1.1"
        id="图层_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 38 38"
        style="enable-background:new 0 0 38 38;"
        xml:space="preserve"
    >
        <path
            :fill="color"
            d="M19,3c8.8,0,16,7.2,16,16s-7.2,16-16,16S3,27.8,3,19S10.2,3,19,3 M19,0C8.5,0,0,8.5,0,19s8.5,19,19,19s19-8.5,19-19S29.5,0,19,0L19,0z"
        />
        <path
            :fill="color"
            d="M17.9,9.5c-0.8,0-1.5,0.7-1.5,1.5v9c0,0.8,0.7,1.5,1.5,1.5s1.5-0.7,1.5-1.5v-9C19.4,10.1,18.8,9.5,17.9,9.5L17.9,9.5z"
        />
        <path
            :fill="color"
            d="M29,18.5H18c-0.8,0-1.5,0.7-1.5,1.5s0.7,1.5,1.5,1.5h11c0.8,0,1.5-0.7,1.5-1.5S29.8,18.5,29,18.5L29,18.5z"
        />
    </svg>
</template>
<script>
export default {
    props: {
        color: {
            type: String,
            default: '#666666'
        }
    }
};
</script>
