<template>
    <svg
        version="1.1"
        id="图层_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 48 48"
        style="enable-background:new 0 0 48 48;"
        xml:space="preserve"
        :width="width"
        :height="height"
    >
        <path
            :fill="color"
            d="M39.5,43h-31C6.6,43,5,41.4,5,39.5v-31C5,6.6,6.6,5,8.5,5h31C41.4,5,43,6.6,43,8.5v31C43,41.4,41.4,43,39.5,43z
            M8.5,8C8.2,8,8,8.2,8,8.5v31C8,39.8,8.2,40,8.5,40h31c0.3,0,0.5-0.2,0.5-0.5v-31C40,8.2,39.8,8,39.5,8H8.5z"
        />
        <rect id="XMLID_20_" x="6.8" y="7.8" :fill="color" width="33.3" height="4.3" />
        <path
            :fill="color"
            d="M39.8,22.4H7.9c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5h31.9c0.8,0,1.5,0.7,1.5,1.5S40.6,22.4,39.8,22.4z"
        />
        <path
            :fill="color"
            d="M40.2,32.7H7.8c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5h32.4c0.8,0,1.5,0.7,1.5,1.5S41,32.7,40.2,32.7z"
        />
        <path
            :fill="color"
            d="M18.2,42.1c-0.8,0-1.5-0.7-1.5-1.5V12.1c0-0.8,0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5v28.5
            C19.7,41.4,19,42.1,18.2,42.1z"
        />
        <path
            :fill="color"
            d="M29.9,43c-0.8,0-1.5-0.7-1.5-1.5V12.1c0-0.8,0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5v29.4C31.4,42.3,30.7,43,29.9,43z"
        />
    </svg>
</template>

<script>
import baseSvgImg from './baseSvgImg';
export default {
    extends: baseSvgImg,
    props: {
        color: {
            default: '#007AFF',
            type: String
        }
    }
};
</script>

<style scoped>
svg {
    display: block;
}
</style>
