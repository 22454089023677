<template>
    <svg
        version="1.1"
        id="图层_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="8 -8 48 48"
        style="enable-background:new 8 -8 48 48;"
        xml:space="preserve"
        :width="width"
        :height="height"
    >
        <path
            class="st0"
            :fill="color"
            d="M30.7,35c-1.3,0-2.2-1.1-2.2-2.1l0.1-2.1c0-0.1,0-0.7,0.5-1.3l10.4-10.4c0.5-0.5,1.2-0.7,1.9-0.5
	c0.5,0.1,0.9,0.4,1.3,0.8l1.3,1.3c0.4,0.4,0.6,0.8,0.8,1.3c0.2,0.7,0,1.4-0.5,1.9L34,34.2c-0.3,0.4-0.7,0.7-1.4,0.7L30.7,35
	C30.7,35,30.7,35,30.7,35z M32.6,33.4l0-1.5c0,0,0,0,0,0V33.4z M31.6,31.3l0,0.6l0.5,0l9.5-9.4L41,21.9L31.6,31.3z"
        />
        <g id="XMLID_1469_" :fill="color">
            <path
                class="st0"
                :fill="color"
                d="M40.4,17.9c-0.6,0-1.2-0.4-1.4-1l-1.1-3.5c-0.3-1.1-2.2-7.6-2.2-7.6c-0.2-0.8,0.2-1.6,1-1.9
		c0.8-0.2,1.6,0.2,1.9,1c0.1,0.3,1.9,6.5,2.2,7.6l1.1,3.4c0.2,0.8-0.2,1.6-1,1.9C40.7,17.9,40.5,17.9,40.4,17.9z"
            />
            <g id="XMLID_1470_" :fill="color">
                <path
                    class="st0"
                    :fill="color"
                    d="M24.2,35H20c-1.1,0-2.1-0.5-2.7-1.4c-0.7-0.9-0.8-2.1-0.4-3.3l5.6-18l1.9-7.5l2.9,0.7l-1.9,7.6l-5.6,18.1
			c-0.1,0.3-0.1,0.5,0,0.6C19.8,32,19.9,32,20,32h4.2c0.8,0,1.5,0.7,1.5,1.5S25,35,24.2,35z"
                />
                <path
                    class="st0"
                    d="M35.7,6h-8.3C25.6,6,24,4.4,24,2.6V0.4C24-1.4,25.6-3,27.4-3h8.3c1.8,0,3.4,1.6,3.4,3.4v2.2
			C39.1,4.5,37.6,6,35.7,6z M27.4,0C27.2,0,27,0.2,27,0.4v2.2C27,2.8,27.2,3,27.4,3h8.3c0.3,0,0.4-0.2,0.4-0.4V0.4
			c0-0.2-0.2-0.4-0.4-0.4H27.4z"
                />
                <path
                    id="XMLID_1475_"
                    class="st0"
                    :fill="color"
                    d="M21.6,5.2L21.6,5.2c0,0.8,0.7,1.5,1.5,1.5h17c0.8,0,1.5-0.7,1.5-1.5l0,0
			c0-0.8-0.7-1.5-1.5-1.5h-17C22.3,3.7,21.6,4.4,21.6,5.2z"
                />
                <path
                    id="XMLID_1474_"
                    class="st0"
                    :fill="color"
                    d="M21.6,16.4L21.6,16.4c0,0.8,0.7,1.5,1.5,1.5h17c0.8,0,1.5-0.7,1.5-1.5l0,0
			c0-0.8-0.7-1.5-1.5-1.5h-17C22.3,14.9,21.6,15.6,21.6,16.4z"
                />
            </g>
        </g>
    </svg>
</template>

<script>
import baseSvgImg from './baseSvgImg';
export default {
    extends: baseSvgImg,
    props: {
        color: {
            type: String,
            default: '#007aff'
        },
        width: {
            type: String,
            default: '17px'
        },
        height: {
            type: String,
            default: '17px'
        }
    }
};
</script>

<style scoped>
svg {
    display: block;
}
</style>
