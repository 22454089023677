<template>
    <svg
        x="0px"
        y="0px"
        :width="width"
        :height="height"
        viewBox="0 0 16 16"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <g stroke="none" stroke-width="1" :fill="color" fill-rule="evenodd">
            <g transform="translate(-1103.000000, -22.000000)" :stroke="color">
                <g transform="translate(1103.000000, 23.000000)">
                    <path
                        fill="transparent"
                        d="M10.8373735,6.5 L5.23168229,6.5 L5.23168229,7.58119721 L0.5,7.58119721 L0.5,14.5 L15.5,14.5 L15.5,7.58119721 L10.8373735,7.58119721 L10.8373735,6.5 Z"
                    ></path>
                    <polyline stroke-linejoin="round" points="6 1.85758753 8 0 8 5"></polyline>
                    <polyline stroke-linejoin="round" points="10 1.85758753 8 0 8 5"></polyline>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
import baseSvgImg from './baseSvgImg';
export default {
    extends: baseSvgImg,
    props: {
        color: {
            default: '#ffffff',
            type: String
        }
    }
};
</script>

<style scoped>
svg {
    display: block;
}
</style>
