<template>
    <svg
        version="1.1"
        id="图层_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        :width="width"
        :height="height"
        viewBox="8 -8 48 48"
        style="enable-background:new 8 -8 48 48;"
        xml:space="preserve"
    >
        <path
            :fill="color"
            class="st0"
            d="M28.5,28C20,28,13,21,13,12.5S20-3,28.5-3S44,4,44,12.5S37,28,28.5,28z M28.5,0C21.6,0,16,5.6,16,12.5
            S21.6,25,28.5,25S41,19.4,41,12.5S35.4,0,28.5,0z"
        />
        <path
            :fill="color"
            class="st0"
            d="M49.5,35c-0.4,0-0.8-0.1-1.1-0.4L37.3,23.5c-0.6-0.6-0.6-1.5,0-2.1s1.5-0.6,2.1,0l11.1,11.1
            c0.6,0.6,0.6,1.5,0,2.1C50.3,34.9,49.9,35,49.5,35z"
        />
    </svg>
</template>

<script>
import baseSvgImg from './baseSvgImg';
export default { extends: baseSvgImg };
</script>

<style scoped>
svg {
    display: block;
}
</style>
