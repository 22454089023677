<template>
    <div class="rich-text" @click="clickHandle">
        <div
            v-if="readonly || invisible"
            class="rich-textarea readonly-text"
            :class="{ invisible: invisible }"
            @dblclick="dblclickHandle"
            @mousedown.stop
        >
            <div class="rich-textarea-title">{{ value }}</div>
            <linkFileImg class="rich-textarea-icon" @click.native.stop="showTextarea" />
        </div>
        <div
            v-else
            class="rich-textarea"
            :style="{ borderColor: active ? 'rgba(0, 122, 255, 0.5)' : '' }"
            :class="{ 'validate-error': !validateResult, readonly: readonly }"
            @mousedown.stop
        >
            <div class="rich-textarea-title">{{ value }}</div>
            <linkFileImg class="rich-textarea-icon" @click.native.stop="showTextarea" />
            <div class="validate-error-icon" v-if="validateResult === false" @click.stop>
                <info-image @mouseenter.stop="errorIconMouseenter" @mouseleave="errorIconMouseleave"></info-image>
            </div>
        </div>
    </div>
</template>

<script>
import GikamCore from '../../../core/gikam-core';
import infoImage from '../img/info.vue';
import BaseField from '../baseField/baseField.vue';
import Vue from 'vue';

const Gikam = top === window ? GikamCore : top.Gikam;

export default {
    name: 'richTextField',
    extends: BaseField,
    components: { infoImage },

    props: {
        options: Object,
        propValue: [String, Number],
        propReadonly: Boolean,
        rowIndex: Number,
        cellIndex: Number,
        //编辑器中的边框等样式是否显示出来
        propInvisible: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            readonly: Gikam.isNotEmpty(this.propReadonly) ? this.propReadonly : this.options.readonly,
            value: this.propValue,
            field: this.options.field,
            active: false,
            invisible: this.propInvisible,

            validateResult: true,
            //保存前一次校验状态，用于当输入框没输入值时，blur时，还原校验状态
            oldValidateResult: null,
            //用于判断用户输没输过值
            isInput: false,
            //保存前一次输入框的值，用于判断用户输入之后的值是否与之前的值一致
            oldValue: this.propValue,
            //判断是否为初始化之后的赋值
            setDataState: false,
            isValidate: false
        };
    },

    watch: {
        value(val) {
            if (this.setDataState) {
                this.setDataState = false;
                return;
            }
            this.validateResult = this.validate();
            this.isValidate = true;
            this.oldValue = val;
            if (Gikam.isTrue(this.validateResult)) {
                this.$emit('change', this.options.field, val, this.rowIndex);
            }
        },

        propValue(val) {
            if (this.value !== val) {
                this.setDataState = true;
            }
            this.validateResult = true;
            this.oldValue = val;
            this.value = val;
        },

        invisible(bool) {
            if (bool && Gikam.textareaPicker) {
                Gikam.textareaPicker.close();
            }
        }
    },

    methods: {
        // eslint-disable-next-line complexity
        showTextarea() {
            Gikam.insertablePicker && Gikam.insertablePicker.close();
            Gikam.selectPicker && Gikam.selectPicker.close();
            Gikam.datePicker && Gikam.datePicker.destroy();
            Gikam.yearPicker && Gikam.yearPicker.destroy();
            Gikam.timePicker && Gikam.timePicker.destroy();
            if (Gikam.textareaPicker) {
                if (Gikam.textareaPicker.rowIndex === this.rowIndex) {
                    Gikam.textareaPicker.close();
                    return;
                }
                Gikam.textareaPicker.close();
            }

            this.focusHandle();
            this.invisible = false;

            const _this = this;
            let $el = this.$el;
            while ($el.classList.contains('rich-text')) {
                $el = $el.parentNode;
            }
            const { top, bottom, left, right } = this.$el.getBoundingClientRect();
            const $top = document.body.clientHeight > bottom + 100 ? bottom + 4 : top - 100;
            const $left = document.body.clientWidth - right < 50 ? right - 310 : left;
            this.active = true;

            Gikam.textareaPicker = new Vue({
                el: Gikam.createDom('div', document.body),
                data() {
                    return {
                        value: _this.value,
                        top: $top + 'px',
                        left: $left + 'px',
                        style: {
                            width: '100%',
                            height: '100%',
                            border: 'none',
                            outline: 'none',
                            fontSize: '12px',
                            fontFamily: 'inherit',
                            color: 'rgba(0, 0, 0, 0.65)'
                        }
                    };
                },
                mounted() {
                    const wheel = document.onmousewheel !== undefined ? 'mousewheel' : 'DOMMouseScroll';
                    this.$el.addEventListener(wheel, e => {
                        e.stopPropagation();
                    });
                },
                methods: {
                    close() {
                        _this.invisible = true;
                        _this.value = this.$refs.textarea.value.trim();
                        _this.clearTextareaPicker();
                    }
                },
                render() {
                    return (
                        <div
                            class="textarea-picker"
                            style={{ top: this.top, left: this.left }}
                            onClick={e => e.stopPropagation()}
                            onmousedown={e => e.stopPropagation()}
                        >
                            <div class="close" onClick={this.close}>
                                ×
                            </div>
                            <textarea
                                ref="textarea"
                                value={this.value}
                                style={this.style}
                                autofocus
                                rows="5"
                                cols="45"
                                readonly={_this.readonly}
                                onInput={_this.inputHandle}
                            />
                        </div>
                    );
                }
            });
            Gikam.textareaPicker.rowIndex = this.rowIndex;
            Gikam.textareaPicker.$refs.textarea.focus();
        },

        clearTextareaPicker() {
            this.active = false;
            Gikam.textareaPicker.$destroy();
            Gikam.removeDom(Gikam.textareaPicker.$el);
            Gikam.textareaPicker = null;
            this.blurHandle();
        },

        dblclickHandle() {
            this.$emit('dblclick', this);
        },

        focusHandle() {
            this.oldValidateResult = this.validateResult;
            this.isInput = false;
            this.validateResult = true;
            this.$emit('focus', this.options.field, this.value, this.rowIndex);
        },

        blurHandle() {
            if (Gikam.isFalse(this.isInput)) {
                this.validateResult = this.oldValidateResult;
            } else if (this.oldValue === this.value && this.isInput && !this.isValidate) {
                this.validateResult = this.validate();
            }
            this.isValidate = false;
        },

        inputHandle(e) {
            this.isInput = true;
            this.$emit('input', e);
            if (this.options.onInput) {
                this.options.onInput(e.target.value, this.rowIndex);
            }
        },

        //键盘控制光标移动前 单元格做的事情
        dumpActiveCell() {
            Gikam.textareaPicker && (this.value = Gikam.textareaPicker.$refs.textarea.value.trim());
            Gikam.textareaPicker && this.clearTextareaPicker();
        },

        //键盘控制光标移动后 单元格做的事情
        activeCell() {
            if (this.invisible) {
                this.$emit('click', this);
            } else {
                this.focus();
            }
        },

        //用于 cellEditor 的click事件处理当前单元格
        focus() {
            // this.showTextarea();
            this.active = true;
            this.$emit('saveCoordinate');
        },

        //点击时保存当前单元格的坐标
        clickHandle() {
            if (!this.invisible) {
                this.$emit('saveCoordinate');
            } else {
                this.$emit('click', this);
            }
        }
    }
};
</script>
<style>
.rich-text {
    width: 100%;
}

.rich-textarea {
    display: flex;
    align-items: center;
    width: 100%;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    padding-left: 8px;
    color: rgba(0, 0, 0, 0.65);
    font-family: 'Microsoft YaHei', serif;
    font-size: 12px;
    height: 100%;
    background-color: #fff;
}

.rich-textarea.validate-error {
    border-color: #ff6e6e;
    padding-right: 25px;
}

.rich-textarea .rich-textarea-title {
    width: 100%;
    min-height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 8px 0 0;
}

.rich-textarea .validate-error-icon {
    width: 16px;
    margin: 2px -16px 0 0;
    cursor: pointer;
}

.rich-textarea.readonly-text {
    height: 100%;
    padding-left: 8px;
    border: none;
    background-color: transparent;
}

.rich-textarea-icon {
    width: 16px;
    height: 16px;
    cursor: pointer;
}

.textarea-picker {
    position: absolute;
    z-index: 11;
    padding: 10px 0 2px 10px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 2px 2px 10px 2px rgba(9, 45, 83, 0.4);
}

.textarea-picker textarea {
    padding-right: 10px;
}

.textarea-picker .close {
    position: absolute;
    top: -6px;
    right: -6px;
    width: 16px;
    height: 16px;
    color: #fff;
    font-size: 14px;
    text-align: center;
    line-height: 15px;
    border-radius: 50%;
    cursor: pointer;
    background-color: #999;
    z-index: 11;
}
</style>
