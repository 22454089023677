<template>
    <div
        class="tab"
        :id="options.id"
        v-show="show"
        :class="[{ 'only-one': panels.length === 1, fill: options.fill }, state, { 'no-border': !bordered }]"
        :style="styleFormatter"
        ref="tab"
    >
        <div v-if="options.shrink" class="tab-switch-icon" v-on:click="toggle">
            <shrink-img class="tab-switch-icon" v-bind="{ color: '#999', width: '16px', height: '16px' }" />
        </div>
        <div class="tab-header" :class="{ switch: options.shrink }" ref="tab-header">
            <div
                class="flip left"
                :class="{ use: btnState.left }"
                @click="scrollTab('previous')"
                v-show="btnState.isOperate"
            >
                <tabFlipImg :color="btnState.left ? '#999' : '#d9d9d9'" />
            </div>
            <div class="content-wrapper" ref="content-wrapper">
                <ul class="content" ref="content" :class="{ move: btnState.isOperate }">
                    <li
                        v-for="(item, index) in panels"
                        :class="{ active: showIndex === index }"
                        @click="switchPanel(index)"
                        :key="item.id || item.title || index"
                        v-show="item.show !== false"
                        :id="item.id"
                    >
                        <span> {{ propI18N(item.title) }}</span>
                        <span class="number" v-show="panelsNums[index]">{{ panelsNums[index] }}</span>
                    </li>
                </ul>
            </div>
            <div
                class="flip right"
                :class="{ use: btnState.right }"
                @click="scrollTab('next')"
                v-show="btnState.isOperate"
            >
                <tabFlipImg :color="btnState.right ? '#999' : '#d9d9d9'" />
            </div>
        </div>
        <transition
            v-on:before-enter="beforeEnter"
            v-on:enter="enter"
            v-on:after-enter="afterEnter"
            v-on:before-leave="beforeLeave"
            v-on:leave="leave"
            v-on:after-leave="afterLeave"
        >
            <div class="tab-content" v-show="state !== 'collapsed'" :class="{ 'auto-height': height === 'auto' }">
                <div
                    v-for="(item, index) in panels"
                    class="tab-pane"
                    :class="{ active: showIndex === index }"
                    :key="item.id || item.title || index"
                    v-show="item.show !== false"
                    :id="item.id"
                >
                    <vuescroll :ops="scrollOptions">
                        <div class="panel-body" ref="panelBody" :class="heightAuto">
                            <slot :name="item.id"></slot>
                        </div>
                    </vuescroll>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import Gikam from '../../core/gikam-core';
import Vue from 'vue';
import vuescroll from 'vuescroll/dist/vuescroll-native';

export default {
    components: {
        vuescroll
    },

    props: {
        options: Object
    },

    data() {
        return {
            panels: this.options.panels,
            showIndex: -1,
            state: Gikam.isEmpty(this.options.expand) || this.options.expand ? 'expand' : 'collapsed',
            show: this.options.show,
            //标记当前展示页签
            tabIndex: 0,
            tabSizeTimeout: null,
            panelsNums: this.options.panelsShowNum || new Array(this.options.panels.length),
            btnState: {
                //页签向左按钮是否可用
                left: false,
                //页签向右按钮是否可用
                right: false,
                //标记页签是否出现滚动条
                isOperate: null
            },
            // 是否显示边框
            bordered: this.options.bordered,
            styleFormatter: {},
            scrollOptions: {
                bar: {
                    background: 'rgb(187, 187, 187)',
                    opacity: 0.8,
                    size: '8px',
                    onlyShowBarOnScroll: false,
                    keepShow: true
                },
                scrollPanel: {
                    scrollingX: false
                }
            }
        };
    },

    computed: {
        height() {
            if (this.options.fill) {
                return '100%';
            }
            const height = this.options.height;
            if (!height) {
                return;
            }
            return Gikam.isNumber(height) ? height + 'px' : height;
        },

        heightAuto() {
            return this.options.height == 'auto' && this.options.fill === false ? 'height-auto' : '';
        }
    },

    mounted() {
        this.showIndex = this.options.showIndex;
        this.initTabBtn();
        // 窗口改变事件
        window.addEventListener('resize', () => this.onResize(), false);
        this.notify();
        this.initPanelPosition();
        this.initStyle();
    },

    updated() {
        if (this.btnState.isOperate === null) this.initTabBtn();
    },

    watch: {
        showIndex(index) {
            this.tab.trigger('actived', {
                index: index,
                title: this.panels[index] && Gikam.propI18N(this.panels[index].title)
            });
            this.$nextTick(() => {
                this.$emit('resizeChildren', index);
            });
        },

        'options.panels'(val) {
            this.panels = val;
        }
    },

    inject: ['tab'],

    methods: {
        setPanelBadge(id, number) {
            const { index } = this.getPanelDataById(id);
            if (index === undefined) {
                return;
            }
            this.$set(this.panelsNums, index, number);
        },

        onResize() {
            if (this.tabSizeTimeout) clearTimeout(this.tabSizeTimeout);
            this.tabSizeTimeout = setTimeout(() => {
                this.initTabBtn();
            }, 60);
        },

        //点击页签左右按钮，移动页签位置
        scrollTab(directionStr) {
            //用于计算移动距离
            let direction = 1;

            //点击向左移动页签
            if (directionStr === 'previous') {
                if (!this.btnState.left) return;
                //移动当前展示页签标记位
                this.tabIndex--;
                direction = -1;
                //向右移动标签页
            } else if (directionStr === 'next') {
                if (!this.btnState.right) return;
                this.tabIndex++;
            }

            const wrapperWidth = this.$refs['content-wrapper'].offsetWidth;
            const scrollWidth = this.$refs['content-wrapper'].scrollWidth;

            //获取当前展示页签平均宽度，用于移动滚动条
            const liArray = scrollWidth / this.panels.length;

            //设置滚动条位置
            const scrollLeft = (this.$refs['content-wrapper'].scrollLeft += liArray * direction);

            //改变按钮状态
            this.btnState.left = true;
            this.btnState.right = true;
            //判断按钮极限值
            if (this.tabIndex === 0) this.btnState.left = false;
            else if (scrollLeft >= scrollWidth - wrapperWidth) this.btnState.right = false;
        },

        initTabBtn() {
            //获取页签容器宽度
            let wrapperWidth = this.$refs['content-wrapper'].offsetWidth;
            //获取页签真实宽度
            let scrollWidth = this.$refs['content-wrapper'].scrollWidth;
            if (wrapperWidth === 0 || scrollWidth === 0) return;
            if (this.tabIndex !== 0) this.btnState.left = true;
            //判断右侧页签是否处于可点击状态
            if (scrollWidth > wrapperWidth) {
                this.btnState.right = true;
                this.btnState.isOperate = true;
                //判断页签左右移动按钮是否可点击
            } else {
                this.btnState.isOperate = false;
            }
        },

        switchPanel(index) {
            this.showIndex = index;
        },

        switchTabIndex(index) {
            this.tabIndex = index;
        },

        //开关tab
        toggle() {
            if (!this.state || this.state === 'expand') {
                this.state = 'collapsed';
            } else {
                this.state = 'expand';
            }
        },

        propI18N(text) {
            return Gikam.propI18N(text);
        },

        notify() {
            this.$nextTick(() => {
                if (!this.options.id) {
                    return;
                }
                const pro = Gikam.createdComponent[this.options.id];
                if (pro) {
                    pro.resolve(this);
                    delete Gikam.createdComponent[this.options.id];
                }
            });
        },

        //获取指定id的panel的数据和索引
        getPanelDataById(id) {
            let index;
            let data;
            Gikam.each(this.panels, function(i) {
                if (this.id === id) {
                    index = i;
                    data = this;
                    return false;
                }
            });
            return { index, data };
        },

        //显示相应panel
        showPanel(id) {
            const panel = this.getPanelDataById(id);
            if (Gikam.isNotEmpty(panel.index)) {
                this.showIndex = panel.index;
            } else {
                Gikam.error(`can not find ${id} when use showPanel`);
            }
        },

        //移除tab下的面板
        removePanel(id) {
            // 当加载完成后再执行
            this.$nextTick(() => {
                const panel = this.getPanelDataById(id);
                if (Gikam.isNotEmpty(panel.data)) {
                    Vue.set(panel.data, 'show', false);
                } else {
                    Gikam.error(`can not find ${id} when use removePanel`);
                }
                //如果隐藏当前展示的panel，则隐藏之后需要前面或后面的panle进行展示
                if (panel.index === this.showIndex) {
                    this.showIndex = panel.index === 0 ? 1 : panel.index - 1;
                }
            });
        },

        //显示tab下的面板
        revertPanel(id) {
            let array = this.panels.filter(item => {
                return item.id === id;
            });
            if (Gikam.isNotEmpty(array)) {
                Vue.set(array[0], 'show', true);
            } else {
                Gikam.error(`can not find ${id} when use revertPanel`);
            }
        },

        initPanelPosition() {
            this.options.panels.forEach((panel, index) => {
                const dom = this.$refs['panelBody'][index];
                if (Gikam.isNotEmpty(panel.items)) {
                    if (
                        (panel.items.length === 1 && panel.items[0].type.toLowerCase() === 'form') ||
                        panel.items.length > 1
                    ) {
                        dom.style.position = 'relative';
                    }
                }
            });
        },

        //规避expand:false 高度不为auto场景
        initStyle() {
            const height = this.state === 'collapsed' ? 'auto' : this.height;
            this.styleFormatter = Gikam.deepExtend(this.options.style, { height: height });
        },

        getPanelHeight() {
            if (this.height === '100%') {
                this.$refs['tab'].style.height = '100%';
                const tabHeight = this.$refs['tab'].scrollHeight;
                this.$refs['tab'].style.height = 'auto';
                return tabHeight - this.$refs['tab-header'].scrollHeight - 18 + 'px';
            } else if (this.height === 'auto') {
                const height = this.$refs['panelBody'][this.showIndex].scrollHeight + 5;
                return height + 'px';
            } else {
                return parseFloat(this.height) - this.$refs['tab-header'].scrollHeight + 'px';
            }
        },

        //将panel元素高度设置为0，可以读取scrollHeight;
        beforeEnter(el) {
            this.addClass(el, 'tab-transition fade-in');
            if (!el.dataset) {
                el.dataset = {};
            }
            el.dataset.oldTabHeight = this.height || this.$refs['tab'].scrollHeight;
            el.style.height = '0';
        },

        enter(el) {
            el.dataset.oldOverflow = el.style.overflow;
            el.style.height = this.getPanelHeight();
            this.removeClass(el, 'fade-in');
            this.addClass(el, 'fade-out');
            el.style.overflow = 'hidden';
        },

        afterEnter(el) {
            this.removeClass(el, 'tab-transition');
            el.style.height = '';
            el.style.overflow = el.dataset.oldOverflow;
            this.$refs['tab'].style.height = el.dataset.oldTabHeight;
        },

        beforeLeave(el) {
            if (!el.dataset) {
                el.dataset = {};
            }
            el.dataset.oldOverflow = el.style.overflow;
            this.addClass(el, 'fade-out');
            el.style.height = this.getPanelHeight();
            el.style.overflow = 'hidden';
            this.$refs['tab'].style.height = '';
        },

        leave(el) {
            if (el.scrollHeight !== 0) {
                this.removeClass(el, 'fade-out');
                this.addClass(el, 'tab-transition fade-in');
                el.style.height = 0;
            }
        },

        afterLeave(el) {
            this.removeClass(el, 'tab-transition');
            el.style.height = '';
            el.style.overflow = el.dataset.oldOverflow;
        },

        addClass(el, cls) {
            const curClass = el.className;
            if (curClass.indexOf(cls) < 0) {
                el.className = curClass + ' ' + cls;
            }
        },

        removeClass(el, cls) {
            const curClass = el.className;
            el.className = curClass.replace(cls, '');
        }
    }
};
</script>

<style scoped>
.tab-transition {
    transition: height 0.3s linear, opacity 0.3s linear, transform 0.3s linear;
}

.fade-out {
    opacity: 1;
    transform: 'translate3d(0, 0, 0)';
}

.fade-in {
    opacity: 0;
    transform: 'translate3d(0, -20px, 0)';
}

.tab {
    background-color: #fff;
    position: relative;
    padding: 8px 8px 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.tab .tab-switch-icon {
    transition: transform 0.3s;
}

.tab.collapsed .tab-switch-icon {
    transform: rotate(90deg);
}

.tab > .tab-header {
    padding-right: 22px;
    min-height: 29px;
    display: flex;
    background-color: #fafafa;
    border: 1px solid #eee;
    border-bottom: none;
    /* z-index: 1; */
    width: 100%;
}

.tab > .tab-header.switch {
    padding-right: 46px;
}

.tab > .tab-header > .flip {
    min-height: 29px;
    min-width: 29px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tab > .tab-header > .flip.use {
    cursor: pointer;
}

.tab > .tab-header > .flip.left {
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
}

.tab > .tab-header > .flip.right {
    transform: rotate(180deg);
    border: 1px solid #eee;
    border-bottom: none;
}

.tab > .tab-header > .flip > svg {
    height: 12px;
    width: 12px;
}

.tab > .tab-header > .content-wrapper {
    overflow: hidden;
    flex-grow: 1;
}

.tab > .tab-header > .content-wrapper > ul.content {
    padding: 0;
    margin: 0;
    display: flex;
    /* 解决360浏览器下部分详情页显示异常的情况 */
    list-style-type: none;
}

.tab > .tab-header > .content-wrapper > ul.content.move > li:last-child {
    border-right: none;
}

.tab.collapsed > .tab-header {
    border-bottom: 1px solid #eee;
}

.tab > .tab-switch-icon {
    position: absolute;
    right: 16px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #666;
    cursor: pointer;
    z-index: 2;
}

.tab > .tab-content {
    border: 1px solid #eee;
    border-top: none;
    flex: 1;
    height: 0;
    overflow-y: hidden;
}

.tab > .tab-content.auto-height {
    flex: none;
    height: auto;
}

.tab > .tab-content > .tab-pane {
    display: none;
    height: 100%;
}

.tab > .tab-content > .tab-pane.active {
    display: block;
}

.tab > .tab-content > .tab-pane >>> .form > .panel {
    border-left: none;
    border-right: none;
}

.tab > .tab-header > .content-wrapper > ul > li {
    height: 29px;
    padding: 0 16px;
    line-height: 29px;
    text-align: center;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65);
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
    cursor: pointer;
    font-weight: bold;
    width: auto;
    white-space: nowrap;
}

.tab > .tab-header > .content-wrapper > ul.content li .number {
    font-size: 12px;
    color: #ffffff;
    letter-spacing: 0;
    padding: 0 4px;
    background: rgba(255, 0, 0, 0.63);
    font-weight: normal;
    border-radius: 7px;
    margin-left: 8px;
}

.tab > .tab-header > .content-wrapper > ul > li.active {
    background-color: #fff;
    position: relative;
    color: #007aff;
    font-weight: normal;
}

.tab:not(.only-one) > .tab-header > .content-wrapper > ul > li.active {
    box-shadow: 0 -2px 0 #007aff inset;
}

.tab.only-one > .tab-header > .content-wrapper > ul > li.active {
    color: rgba(0, 0, 0, 0.65);
    font-weight: bold;
}

.tab.only-one > .tab-header > .content-wrapper > ul > li.active:before {
    content: '';
    display: block;
    position: absolute;
    background-color: #fff;
    height: 1px;
    bottom: -1px;
    left: 0;
    right: 0;
}

.panel-body {
    /* width: 100%; */
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow-x: auto;
}

.panel-body.height-auto {
    position: static;
}

/* 解决tab下iframe出现滚动条的问题 */
.panel-body >>> .iframe iframe {
    height: 99%;
}

.tab.no-border .tab-content,
.tab.no-border .tab-header,
.tab.no-border > .tab-header > .content-wrapper > ul > li {
    border: none;
}
.tab.no-border .tab-header {
    background-color: #fff;
}
</style>
