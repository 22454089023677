import btnGroup from './template/buttonGroup.vue';
import Vue from 'vue';
import Base from "./base";

let defaultOptions = {
    renderTo: void 0,
    padding: void 0,
    margin: void 0
};

export default class BtnGroup extends Base {

    constructor(param) {
        super();
        this.initialize(param, defaultOptions).init();
    }

    createModel() {
        const _this = this;
        const mountDom = document.createElement('div');
        this.options.renderTo.appendChild(mountDom);
        return new Vue({
            el: mountDom,
            components: { btnGroup },
            render() {
                return <btn-group options={_this.options} />;
            }
        });
    }

    init() {
        this.model = this.createModel();
    }

}



