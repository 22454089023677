import Gikam from '../../../core/gikam-core';

export class GridColumn {
    constructor(grid, fieldName) {
        this.fieldName = fieldName;
        this.grid = grid;
    }

    /**
     * @description
     * @param {*} options
     * @returns
     * @memberof GridColumn
     * @public
     */
    setOptions(options) {
        const columns = this.grid.store.state.columns;
        const column = columns.filter(item => item.field === this.fieldName)[0];
        const def = Gikam.getDeferred();
        if (!column) {
            Gikam.error(`can not find column by ${this.fieldName}`);
            return;
        }
        if (column.type === 'select') {
            this.setSelectItems(column, options);
        } else {
            Object.assign(column, options);
        }
        return def;
    }

    /**
     * @description
     * @memberof GridColumn
     * @private
     */
    assignSelectItems(column) {
        this.grid.getData().forEach((row, i) => {
            this.grid.getField(i, column.field).setItems(column.items);
        });
        const headField = this.grid.getField(column.field);
        headField && headField.setItems(column.items);
    }

    /**
     * @description
     * @param {*} options
     * @returns
     * @memberof GridColumn
     * @private
     */
    setSelectItems(column, options) {
        if (column.category && column.items) {
            delete column.items;
        }
        Object.assign(column, options);
        if (Gikam.isNotEmpty(options.items)) {
            this.assignSelectItems(column);
        } else {
            Gikam.select.cacheItems([column]).done(() => {
                this.assignSelectItems(column);
            });
        }
    }
}
