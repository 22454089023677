<template>
    <svg
        version="1.1"
        id="图层_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 42 42"
        style="enable-background:new 0 0 42 42;"
        xml:space="preserve"
        :width="width"
        :height="height"
    >
        <path
            class="st0"
            :fill="color"
            d="M33.9,33.4c-0.3,0-0.7-0.1-1-0.4l-26-21.9c-0.6-0.5-0.7-1.5-0.2-2.1c0.5-0.6,1.5-0.7,2.1-0.2l26,21.9
            c0.6,0.5,0.7,1.5,0.2,2.1C34.7,33.2,34.3,33.4,33.9,33.4z"
        />
        <path
            class="st0"
            :fill="color"
            d="M20.9,27c-3.3,0-6-2.7-6-6s2.7-6,6-6s6,2.7,6,6S24.2,27,20.9,27z M20.9,17c-2.2,0-4,1.8-4,4c0,2.2,1.8,4,4,4
            c2.2,0,4-1.8,4-4C24.9,18.8,23.1,17,20.9,17z"
        />
        <g>
            <path
                class="st0"
                :fill="color"
                d="M21,34C10,34,0,24.6,0,21c0-2.4,4-6.7,8.8-9.5c0.7-0.4,1.6-0.2,2.1,0.5c0.4,0.7,0.2,1.6-0.5,2.1
                c-4.6,2.7-7.2,6.1-7.4,7c0.3,1.6,8.2,10,18,10c3.2,0,6.5-0.9,9.8-2.6c0.7-0.4,1.6-0.1,2,0.6c0.4,0.7,0.1,1.6-0.6,2
                C28.5,33,24.7,34,21,34z"
            />
            <path
                class="st0"
                :fill="color"
                d="M35.7,28.4c-0.4,0-0.9-0.2-1.2-0.6c-0.5-0.6-0.4-1.6,0.2-2.1l0.3-0.3c2.8-2.3,3.8-4.1,3.9-4.5
                c-0.3-1.6-8.2-10-18-10c-1.5,0-3,0.2-4.6,0.6c-0.2,0-0.3,0.1-0.5,0.1c-0.8,0.2-1.6-0.2-1.8-1c-0.2-0.8,0.3-1.6,1-1.8
                c0.2,0,0.4-0.1,0.5-0.1C17.5,8.2,19.3,8,21,8c11,0,21,9.4,21,13c0,2-2.6,4.8-4.9,6.8l-0.4,0.3C36.4,28.3,36.1,28.4,35.7,28.4z"
            />
        </g>
    </svg>
</template>

<script>
import baseSvgImg from './baseSvgImg';
export default { extends: baseSvgImg };
</script>
