<template>
    <svg
        version="1.1"
        id="图层_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 48 48"
        style="enable-background:new 0 0 48 48;"
        xml:space="preserve"
        :width="width"
        :height="height"
    >
        <path
            id="XMLID_634_"
            class="st0"
            d="M27.1,15.2L27.1,15.2c0,0.8-0.7,1.5-1.5,1.5h-12c-0.8,0-1.5-0.7-1.5-1.5v0
	c0-0.8,0.7-1.5,1.5-1.5h12C26.4,13.7,27.1,14.4,27.1,15.2z"
        />
        <path
            id="XMLID_633_"
            class="st0"
            d="M22.1,23.2L22.1,23.2c0,0.8-0.7,1.5-1.5,1.5h-7c-0.8,0-1.5-0.7-1.5-1.5v0
	c0-0.8,0.7-1.5,1.5-1.5h7C21.4,21.7,22.1,22.4,22.1,23.2z"
        />
        <path
            id="XMLID_632_"
            class="st0"
            d="M19.1,31.2L19.1,31.2c0,0.8-0.7,1.5-1.5,1.5h-4c-0.8,0-1.5-0.7-1.5-1.5v0
	c0-0.8,0.7-1.5,1.5-1.5h4C18.4,29.7,19.1,30.3,19.1,31.2z"
        />
        <path
            id="XMLID_631_"
            class="st1"
            d="M22.7,42.7H9.6c-1.1,0-2-1.1-2-2.4V7.2c0-1.3,0.9-2.4,2-2.4h22c1.1,0,2,1.1,2,2.4v14.1"
        />
        <g id="XMLID_626_">
            <path
                id="XMLID_630_"
                class="st0"
                d="M28.8,33.7c-0.5-0.5-0.4-1.3,0.2-1.9l0.1-0.1c0.6-0.6,1.4-0.6,1.9-0.2l5.4,5.4
		c0.5,0.5,0.4,1.3-0.2,1.9L36,38.9c-0.6,0.6-1.4,0.6-1.9,0.2"
            />
            <g id="XMLID_627_">
                <path
                    id="XMLID_629_"
                    class="st1"
                    d="M27.9,36.3l-2-2c-1.6-1.6-1.6-4.1,0-5.7l0,0c1.6-1.6,4.1-1.6,5.7,0l2,2"
                />
                <path
                    id="XMLID_628_"
                    class="st1"
                    d="M31.5,40l2,2c1.6,1.6,4.1,1.6,5.7,0l0,0c1.6-1.6,1.6-4.1,0-5.7l-2-2"
                />
            </g>
        </g>
    </svg>
</template>

<script>
import baseSvgImg from './baseSvgImg';
export default {
    extends: baseSvgImg,
    props: {
        color: {
            default: '#007AFF',
            type: String
        }
    }
};
</script>

<style scoped>
svg {
    display: block;
}
.st0 {
    fill: #007aff;
}
.st1 {
    fill: none;
    stroke: #007aff;
    stroke-width: 3;
    stroke-linecap: round;
    stroke-miterlimit: 10;
}
</style>
