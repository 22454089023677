import Gikam from '../core/gikam-core.js';
import tab from './template/tab.vue';
import Vue from 'vue';
import Base from './base';

let defaultOptions = {
    renderTo: void 0,
    showIndex: 0,
    //组件高度，支持百分数、数字、像素字符串
    height: 'auto',
    panels: [],
    panelsShowNum: null,
    show: true,
    // 允许的最大宽度，由父组件传值
    allowWidth: 0,
    // 是否填充满父容器
    fill: false,
    // 自定义tab样式
    style: void 0,
    // 是否显示收缩icon
    shrink: true,
    // 是否展开
    expand: true,
    // 是否显示边框
    bordered: true
};

export default class Tab extends Base {
    constructor(props) {
        super('Tab');
        this.listeners = {
            rendered: Gikam.emptyFunction,
            actived: Gikam.emptyFunction
        };
        this.initialize(props, defaultOptions).init(props);
    }

    createModel() {
        const _this = this;
        this.model = new Vue({
            el: Gikam.createDom('div', this.options.renderTo),
            provide: {
                tab: this
            },
            data() {
                return {
                    options: _this.options
                };
            },
            render() {
                return <tab ref="vm" options={this.options} onResizeChildren={_this.resizeChildren.bind(_this)}></tab>;
            }
        });

        this.model.$nextTick(() => {
            this.trigger('rendered');
        });
    }

    createItems() {
        const panelBodyList = this.model.$refs.vm.$refs.panelBody;
        this.options.panels.forEach((tabPaneItem, index) => {
            if (!tabPaneItem.items) {
                tabPaneItem.items = [];
            }
            //只有一个子元素时，如果标签页是填充模式或者设置了高度，那么子元素为填充模式
            if (
                tabPaneItem.items.length === 1 &&
                (this.options.fill === true || (this.options.height && this.options.height !== 'auto'))
            ) {
                tabPaneItem.items[0].fill = true;
            }
            tabPaneItem.items.forEach(item => {
                item.renderTo = panelBodyList[index];
                item.$window = this.$window;
                item.$parent = this;
                item.allowWidth = this.options.allowWidth || this.options.renderTo.clientWidth;
                Gikam.create(item.type, item);
            });
        });
    }

    resizeChildren() {
        this.$children.forEach(child => {
            child.onResize && child.onResize();
        });
    }

    show() {
        this.model.$refs.vm.show = true;
    }

    hide() {
        this.model.$refs.vm.show = false;
    }

    removePanel(id) {
        this.model.$refs.vm.removePanel(id);
    }

    revertPanel(id) {
        this.model.$refs.vm.revertPanel(id);
    }

    showPanel(id) {
        this.model.$refs.vm.showPanel(id);
    }

    /**
     * @description 替换tab内的panels
     * @param {Array} panels  新的数组
     * @memberof Tab
     * @public
     */
    refreshPanels(panels) {
        if (panels instanceof Array === false) {
            return Gikam.error('parameter type must be array');
        }
        this.options.panels = panels;
        this.initPanelsId();
        this.model.$nextTick().then(() => {
            const { showIndex, switchPanel, switchTabIndex, initTabBtn } = this.model.$refs.vm;
            //处理激活的panels
            if (panels.length - 1 < showIndex) {
                switchPanel(0);
                switchTabIndex(0);
            }
            initTabBtn();
            const panelBodyList = this.model.$refs.vm.$refs.panelBody;
            panelBodyList.forEach(panelDom => {
                panelDom.innerHTML = '';
            });
            this.createItems();
        });
    }

    /**
     * @description 父组件尺寸变更时触发
     * @private
     * @param
     * @memberof Tab
     */
    onResize() {
        this.$width = this.$parent.$width;
        this.$height = this.$parent.$height;
        //触发tab.vue组件onResize事件
        this.model.$refs.vm.onResize();
        this.$children.forEach(comp => {
            comp.onResize && comp.onResize();
        });
    }

    /**
     * @description 根据每页的id 修改右上角角标数量
     * @public
     * @param {String} panelId 需要修改的页码id
     * @param {Number} badge   需要添加的数量
     * @returns Tab
     * @memberof Tab
     */
    setPanelBadge(panelId, badge) {
        if (typeof panelId !== 'string' || typeof badge !== 'number') {
            return;
        }
        this.model.$refs.vm.setPanelBadge(panelId, badge);
        return this;
    }

    /**
     * @description 向panel中追加组件参数
     * @public
     * @param {String} id panelId
     * @param {Array} items   需要添加的组件参数
     * @returns Tab
     * @memberof Tab
     */
    appendItems(id, items) {
        let _index = -1;
        Gikam.each(this.options.panels, (index, panel) => {
            if (panel.id === id) {
                _index = index;
            }
        });
        if (_index === -1) {
            return Gikam.error('该tab上未找到对应panel');
        }
        const panelBodyList = this.getPanelDomList();
        if (panelBodyList === false) {
            return;
        }

        this.options.panels[_index].items.concat(items);

        items.forEach(item => {
            item.renderTo = panelBodyList[_index];
            item.$window = this.$window;
            item.$parent = this;
            item.allowWidth = this.options.allowWidth || this.options.renderTo.clientWidth;
            Gikam.create(item.type, item);
        });
    }

    /**
     * @description 清空panel中的内容
     * @public
     */
    cleanItems(id) {
        let _index = -1;
        Gikam.each(this.options.panels, (index, panel) => {
            if (panel.id === id) {
                _index = index;
            }
        });
        if (_index === -1) {
            return Gikam.error('该tab上未找到对应panel');
        }
        this.options.panels[_index].items = [];
        const panelList = this.getPanelDomList();
        if (Gikam.isNotEmpty(panelList)) {
            panelList[_index].innerHTML = '';
        }
    }

    /**
     * @description 获取panel的dom集合
     * @private
     */
    getPanelDomList() {
        if (Gikam.isEmpty(this.model.$refs.vm)) {
            return false;
        }
        if (Gikam.isEmpty(this.model.$refs.vm.$refs.panelBody)) {
            return false;
        }
        return this.model.$refs.vm.$refs.panelBody;
    }

    /**
     * @description 设置panels 的id
     * @private
     */
    initPanelsId() {
        this.options.panels.forEach(panel => {
            !panel.id && (panel.id = panel.title);
        });
    }

    init() {
        this.initPanelsId();
        this.createModel();
        this.bindInstance(this.model.$el);
        this.createItems();
    }
}
