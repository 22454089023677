import Vue from 'vue';

function setLocaleCookie(locale) {
    if (!locale) {
        return;
    }
    if (locale.includes('-')) {
        const parts = locale.split('-');
        locale = parts[0] + '-' + parts[1].toUpperCase();
    }
    // 过期时间一个月
    const expires = new Date();
    expires.setTime(expires.getTime() + 30 * 24 * 60 * 60 * 1000);
    document.cookie = `locale=${locale};expires=${expires.toUTCString()}`;
}

export class I18N {
    static map;

    static async init(locale = 'zh_CN') {
        I18N.mixinVue();
        setLocaleCookie(locale);
        return new Promise(resolve => {
            const zh = ['zh-cn', 'zh_cn', 'zh_CN'];
            if (zh.indexOf(locale) > -1) {
                locale = 'zh-CN';
            }
            import(`./locale/${locale}`).then(map => {
                I18N.map = map.message;
                resolve();
            });
        });
    }

    static prop(key) {
        if (!I18N.map) {
            return key;
        }
        const message = I18N.map[key];
        if (message === undefined) {
            return key;
        }
        return message;
    }

    static mixinVue() {
        Vue.mixin({
            methods: {
                $i18n: I18N.prop
            }
        });
    }

    /**
     * @description 设置国际化语言
     * @static
     * @param {*} locale
     * @memberof I18N
     */
    static setLocale(locale) {
        if (!locale) {
            return;
        }
        setLocaleCookie(locale);
        localStorage.setItem('locale', locale);
        location.reload();
    }
}
