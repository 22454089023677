<template>
    <div class="edit-toolbar">
        <div class="btn plus" :title="$i18n('tree.plus')" @click.stop="click('insert', $event)"></div>
        <div class="btn edit" :title="$i18n('tree.edit')" @click.stop="click('edit', $event)"></div>
        <div class="btn remove" :title="$i18n('tree.remove')" @click.stop="click('remove', $event)"></div>
    </div>
</template>

<script>
export default {
    props: {
        options: Object
    },

    methods: {
        click(type, e) {
            this.$emit('click', type, e, this.options);
        }
    }
};
</script>

<style scoped>
.edit-toolbar {
    display: flex;
    position: relative;
    width: 70px;
}

.edit-toolbar > .btn {
    width: 16px;
    height: 16px;
    cursor: pointer;
    padding-right: 15px;
}

.edit-toolbar .plus {
    background: url('../img/plus.svg') no-repeat center;
}

.edit-toolbar .edit {
    background: url('../img/node-edit.svg') no-repeat center;
}

.edit-toolbar .remove {
    background: url('../img/remove.svg') no-repeat center;
}
</style>
