<template>
    <div class="mask">
        <img src="./img/loading.gif" />
        <span class="message">
            <slot>{{ $i18n('mask.dataLoading') }}</slot>
        </span>
    </div>
</template>

<script>
export default {};
</script>

<style scoped>
.mask {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
    z-index: 15;
}

.message {
    padding-left: 10px;
}</style
>>
