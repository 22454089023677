<template>
    <tr class="total-row">
        <td :colspan="options.sum.endIndex + 1" style="padding-left:8px;">
            <div>{{ $i18n('grid.totalRow') }}</div>
        </td>
        <template v-for="(field, index) in totalColumns">
            <td :key="index" v-html="getTotalText(field)" style="padding-left:8px;"></td>
        </template>
    </tr>
</template>

<script>
export default {
    props: {
        options: Object,
        state: Object
    },

    data() {
        return {
            totalColumns: this.getTotalColumns()
        };
    },

    methods: {
        getTotalColumns() {
            return this.options.columns.slice(this.options.sum.endIndex + 1);
        },

        getTotalText(field) {
            if (this.state.totalMap && this.state.totalMap.hasOwnProperty(field.field)) {
                return this.state.totalMap[field.field];
            }
            return '';
        }
    }
};
</script>
