import Base from '../../base';
import Gikam from '../../../core/gikam-core';
import Vue from 'vue';
import fm from '../vue/iframe';

let defaultOptions = {
    id: void 0,
    height: '100%',
    src: void 0, //请求地址
    hidden: false, //是否隐藏
    renderTo: void 0,
    minHeight: '100px'
};

export default class Iframe extends Base {
    constructor(props) {
        super('Iframe');
        this.listeners = {
            rendered: Gikam.emptyFunction
        };
        this.initialize(props, defaultOptions).init();
    }

    refresh(param) {
        Gikam.extend(true, this.options, param);
    }

    show() {
        this.options.hidden = false;
        return this;
    }

    hide() {
        this.options.hidden = true;
        return this;
    }

    create() {
        let _this = this;
        const mountedDom = document.createElement('dom');
        this.options.renderTo.appendChild(mountedDom);
        this.model = new Vue({
            el: mountedDom,
            components: { fm },
            render() {
                return <fm options={this.options}></fm>;
            },
            data() {
                return {
                    options: _this.options
                };
            }
        });
        this.iframe = this.model.$el.querySelector('iframe');
        this.bindInstance(this.model.$el);
        this.trigger('rendered');
    }

    init() {
        this.create();
    }
}
