<template>
    <div class="checkbox" @click="clickHandle" @mouseup.stop :class="{ checked: checked, halfcheck: halfChecked }">
        <div v-if="checked && halfChecked" class="half-check"></div>
        <tickImg v-else-if="checked" v-bind="{ color: '#fff', width: '14px', height: '14px' }" />
    </div>
</template>

<script>
import Gikam from 'gikam';
export default {
    props: {
        propId: [String, Number],
        propChecked: {
            type: Boolean,
            default: false
        },
        propHalfChecked: {
            type: Boolean,
            default: false
        },
        propValue: [String, Number],
        externalControl: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            checked: this.propChecked,
            value: this.propValue,
            halfChecked: this.propHalfChecked
        };
    },

    watch: {
        propChecked() {
            this.checked = this.propChecked;
        },

        propHalfChecked() {
            this.halfChecked = this.propHalfChecked;
        }
    },

    methods: {
        clickHandle(e) {
            Gikam.isFalse(this.externalControl) && (this.checked = !this.checked);
            this.$emit('change', this.checked, this.value, e, this.propId);
        }
    }
};
</script>

<style scoped>
.checkbox {
    width: 14px;
    height: 14px;
    background-color: #fff;
    border: 1px solid #333;
    border-radius: 2px;
    cursor: pointer;
}

.checkbox:hover {
    border: 1px solid rgb(0, 122, 255);
    box-shadow: 0 0 3px rgba(0, 122, 255, 0.5);
}

.checkbox.checked {
    background-color: rgb(0, 122, 255);
    border: none;
}

.checkbox.checked.halfcheck {
    background-color: #fff;
    border: none;
}

.checkbox .half-check {
    background: url('./img/half-check.png') no-repeat center;
    background-size: 100% 100%;
    width: 15px;
    height: 15px;
}
</style>
