<template>
    <div class="echarts" :class="inputDirection" :id="id">
        <div class="title" :style="formatterTitleStyle" v-if="titleShow">{{ title }}</div>
        <div class="drop-down-icon" @click.stop="showPicker($event)" v-if="dropDownShow">
            <moreImg color="#007AFF" width="14px" height="14px" />
        </div>
        <div class="search" ref="search" v-if="searchShow" :style="{ height: searchHeight, top: searchTop }">
            <span class="trun" v-if="inputDirection === 'vertical'" @click.stop="changeTrunState">{{
                trunClose ? $i18n('echarts.expand') : $i18n('echarts.retract')
            }}</span>
            <div ref="elForm" class="el-form"></div>
        </div>
        <div class="main" ref="main" :style="echartsStyle"></div>
    </div>
</template>

<script>
import Gikam from '@/gikam/js/core/gikam-core';
import picker from './echartsDropdownPicker';
import Vue from 'vue';

export default {
    props: { echartsOptions: Object, options: Object, id: [String, Number] },

    created() {
        this.initTitle();
        this.initDropDown();
    },

    mounted() {
        import('echarts').then(echarts => {
            this.echarts = echarts.init(this.$refs.main);
            this.echarts.setOption(this.echartsOptions);
            this.$emit('ready', this.echarts);
            // 窗口改变事件
            window.onresize = () => {
                this.onResize();
            };
        });
        this.$nextTick(() => {
            this.searchShow && this.initForm();
        });
    },

    computed: {
        formatterTitleStyle() {
            const style = this.titleStyle;
            style && style.fontSize && (style.fontSize = style.fontSize + 'px');
            return style;
        },

        dropDownShow() {
            return Gikam.isNotEmpty(this.flatDropDownMenu) ? true : false;
        },

        searchShow() {
            return this.options.search.type === 'form' && Gikam.isNotEmpty(this.options.search.fields);
        },

        titleShow() {
            return Gikam.isEmpty(this.title) ? false : true;
        },

        searchTop() {
            return this.titleShow ? '30px' : 0;
        },

        echartsStyle() {
            return {
                height: this.titleShow ? 'calc(100% - 30px)' : '100%',
                'padding-top': this.searchShow ? '30px' : '0'
            };
        },

        searchHeight() {
            return this.trunClose ? '30px' : this.verticalMaxHeight + 'px';
        },

        verticalMaxHeight() {
            return this.options.search.fields.length * 30;
        }
    },

    data() {
        return {
            title: '',
            titleStyle: {},
            flatDropDownMenu: [],
            inputDirection: 'vertical', //竖向vertical  横向transverse
            trunClose: true
        };
    },

    methods: {
        initTitle() {
            if (Gikam.isNotEmpty(this.options.search.fields) && Gikam.isNotEmpty(this.echartsOptions.title)) {
                if (Gikam.isNotEmpty(this.echartsOptions.title.text)) {
                    this.title = this.echartsOptions.title.text;
                    delete this.echartsOptions.title.text;
                }
                if (Gikam.isNotEmpty(this.echartsOptions.title.textStyle)) {
                    this.titleStyle = this.echartsOptions.title.textStyle;
                    delete this.echartsOptions.title.textStyle;
                }
            }
        },

        initDropDown() {
            const optionsMenu = Gikam.isNotEmpty(this.options.dropDownMenu)
                ? this.options.dropDownMenu
                : this.echartsOptions.dropDownMenu;

            if (Gikam.isEmpty(optionsMenu)) {
                this.flatDropDownMenu = [];
                return;
            }
            const dropDownMenu = [];
            optionsMenu.forEach(item => {
                if (item.type === 'btnGroup') {
                    item.items.forEach(list => {
                        list.type === 'button' && dropDownMenu.push(list);
                    });
                } else if (item.type === 'button') {
                    dropDownMenu.push(item);
                }
            });
            this.flatDropDownMenu = dropDownMenu;
        },

        onResize() {
            this.echarts && this.echarts.resize();
            this.searchShow && this.initFormClass();
        },

        initForm() {
            const form = this.options.search;
            form.titleWidthAuto && delete form.titleWidthAuto;
            Gikam.create(form.type, { renderTo: this.$refs.elForm, ...form });
            this.initFormClass();
        },

        initFormClass() {
            const formElWidth = this.$refs.search.clientWidth - 32;
            const fieldLength = this.options.search.fields.length;

            if (formElWidth / fieldLength < 260) {
                this.inputDirection = 'vertical';
            } else {
                this.inputDirection = 'transverse';
            }
        },

        changeTrunState() {
            this.trunClose = !this.trunClose;
        },

        showPicker(event) {
            const _this = this;
            const maxHeight = this.$el.getBoundingClientRect().height - 40;
            const calcHeight = this.flatDropDownMenu.length * 30;
            new Vue({
                el: Gikam.createDom('div', document.body),
                render() {
                    return (
                        <picker
                            target={event.target}
                            propDropDownMenu={_this.flatDropDownMenu}
                            propHeight={calcHeight < maxHeight ? calcHeight : maxHeight}
                        />
                    );
                }
            });
        },

        setOption(...arg) {
            this.initTitle();
            this.echarts.setOption(...arg);
        }
    }
};
</script>

<style scoped>
.echarts {
    width: 100%;
    height: 100%;
    position: relative;
}

.title {
    height: 30px;
    line-height: 30px;
    margin-right: 32px;
    padding-left: 32px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.drop-down-icon {
    cursor: pointer;
    position: absolute;
    right: 12px;
    top: 6px;
    z-index: 10;
}

.search {
    height: 30px;
    width: calc(100% - 32px);
    position: absolute;
    z-index: 1;
    transition: all 0.2s linear;
    overflow: hidden;
}

.main {
    width: 100%;
    box-sizing: border-box;
}
.echarts >>> .form {
    padding-top: 0;
}

.echarts >>> .form .form-group {
    width: 250px !important;
    margin-bottom: 0;
    height: 30px;
    padding: 3px 0;
}

.echarts >>> .form .form-group .title {
    width: auto;
}

.echarts >>> .field .field-container > div:not(.checkbox),
.echarts >>> .field .field-container > input {
    height: 24px;
    min-height: 24px;
}

.transverse >>> .form section {
    justify-content: center;
}

.transverse >>> .form .form-group .title {
    padding-left: 16px;
}

.vertical >>> .form {
    margin: 0 auto;
}

.vertical >>> .form .form-group .title {
    width: 75px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.vertical .search {
    width: 100%;
    background: #fff;
}

.vertical .search .el-form {
    width: 250px;
    margin: 0 auto;
    box-sizing: content-box;
}

.vertical .search .trun {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 30px;
    line-height: 30px;
    font-size: 12px;
    font-family: Microsoft YaHei;
    color: #007aff;
    margin-right: calc(50% - 149px);
    cursor: pointer;
}
</style>
