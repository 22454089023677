import Grid from './grid/js/grid';
import Gikam from '../core/gikam-core.js';

export default class SimpleGrid extends Grid {
    constructor(options) {
        Gikam.error('SimpleGrid will be disabled, please replace it with grid');
        super(options);
    }
}


