import progress from './progress.vue';
import Vue from 'vue';
import Base from '@/gikam/js/components/base.js';
import jQuery from 'jquery';

let defaultOptions = {
    modalOption: {
        width: 408,
        height: 204,
        closeHeader: true
    }
};

export default class Progress extends Base {
    constructor(props) {
        super();
        this.initialize(props, defaultOptions).init();
    }

    createModel() {
        const options = this.options;
        this.options.modalOption.modal = this;
        return new Vue({
            el: jQuery('<gikam-progress :id="options.id" ref="vm" :options="options"></gikam-progress>').appendTo(
                'body'
            )[0],
            provide: {
                progress: this,
                oldGrid: this.options.oldGrid
            },
            data() {
                return {
                    options: options
                };
            },
            components: { gikamProgress: progress }
        });
    }

    changeProgress(type) {
        this.progress.$refs.vm.changePage(type);
    }

    changePercentageNum(num) {
        this.progress.$refs.vm.changePercentage(num);
    }

    stopProgress() {
        this.progress.$refs.vm.changeStop();
    }

    closeProgress() {
        this.progress.$refs.vm.$refs.modal.close();
    }

    init() {
        this.progress = this.createModel();
        this.bindInstance(this.progress.$el);
    }
}
