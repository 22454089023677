<template>
    <div class="radio" @click="clickHandle" @mouseup.stop :class="{ checked: checked }">
        <div class="circle"></div>
    </div>
</template>

<script>
import Gikam from 'gikam';
export default {
    props: {
        propChecked: {
            type: Boolean,
            default: false
        },

        propValue: [String, Number],
        propId: [String, Number],

        externalControl: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            checked: this.propChecked,
            value: this.propValue
        };
    },

    watch: {
        propChecked() {
            this.checked = this.propChecked;
        }
    },

    methods: {
        clickHandle(e) {
            Gikam.isFalse(this.externalControl) && (this.checked = !this.checked);
            this.$emit('change', this.checked, this.value, e, this.propId);
        }
    }
};
</script>

<style scoped>
.radio {
    width: 14px;
    height: 14px;
    background-color: #fff;
    border: 1px solid #333;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.radio:hover {
    border: 1px solid rgb(0, 122, 255);
    box-shadow: 0 0 3px rgba(0, 122, 255, 0.5);
}

.radio.checked {
    border: 1px solid rgb(0, 122, 255);
}

.circle {
    width: 6px;
    height: 6px;
    border-radius: 50%;
}

.radio.checked .circle {
    background-color: rgb(0, 122, 255);
}
</style>
