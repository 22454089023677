<template>
    <svg
        version="1.1"
        id="图层_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        :width="width"
        :height="height"
        viewBox="0 0 38 38"
        style="enable-background:new 0 0 38 38;"
        xml:space="preserve"
    >
        <g>
            <path
                :fill="color"
                class="st0"
                d="M25.1,12.9L25.1,12.9c0.5,0.5,0.5,1.3,0,1.8L14.7,25.1c-0.5,0.5-1.3,0.5-1.8,0l0,0c-0.5-0.5-0.5-1.3,0-1.8
                l10.4-10.4C23.8,12.4,24.6,12.4,25.1,12.9z"
            />
            <g>
                <path
                    :fill="color"
                    class="st0"
                    d="M28.4,22.5c-0.4,0-0.8-0.1-1.1-0.4c-0.6-0.6-0.6-1.5,0-2.1l5.3-5.3c1.4-1.4,2.3-3.3,2.4-5.2
                    c0.1-1.8-0.5-3.5-1.7-4.7c-2.6-2.6-7-2.3-9.9,0.7l-5.3,5.3c-0.6,0.6-1.5,0.6-2.1,0c-0.6-0.6-0.6-1.5,0-2.1l5.3-5.3
                    c4.1-4.1,10.4-4.4,14.2-0.7c1.8,1.8,2.8,4.3,2.6,7c-0.1,2.6-1.3,5.2-3.3,7.1L29.5,22C29.2,22.3,28.8,22.5,28.4,22.5z"
                />
                <path
                    :fill="color"
                    class="st0"
                    d="M9.1,38c-2.4,0-4.7-0.9-6.5-2.6c-1.8-1.8-2.7-4.3-2.6-7c0.1-2.6,1.3-5.2,3.3-7.1L8.5,16
                    c0.6-0.6,1.5-0.6,2.1,0c0.6,0.6,0.6,1.5,0,2.1l-5.3,5.3C4,24.8,3.1,26.6,3,28.5c-0.1,1.8,0.5,3.5,1.7,4.7c2.6,2.6,7,2.3,9.9-0.7
                    l5.3-5.3c0.6-0.6,1.5-0.6,2.1,0s0.6,1.5,0,2.1l-5.3,5.3C14.6,36.9,11.8,38,9.1,38z"
                />
            </g>
        </g>
    </svg>
</template>

<script>
import baseSvgImg from './baseSvgImg';
export default { extends: baseSvgImg };
</script>
