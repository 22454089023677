<template>
    <svg
        version="1.1"
        id="图层_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        :width="width"
        :height="height"
        viewBox="0 0 40 40"
        style="enable-background:new 0 0 40 40;"
        xml:space="preserve"
    >
        <path
            :fill="color"
            d="M39,29.8L39,29.8c0-0.8-0.7-1.5-1.5-1.5h-35C1.7,28.3,1,29,1,29.8l0,0c0,0.8,0.7,1.5,1.5,1.5h35
	C38.3,31.3,39,30.6,39,29.8z M38,21.9c-0.6,0.6-1.5,0.6-2.1,0l-1.1-1.1L20,6L5.2,20.9L4.1,22c-0.6,0.6-1.5,0.6-2.1,0s-0.6-1.5,0-2.1
	l1-1.1L17.8,3.9l1.1-1.1L20,1.8L22.1,4L37,18.8l0,0l1,1.1C38.6,20.4,38.6,21.3,38,21.9z M39,36.8L39,36.8c0-0.8-0.7-1.5-1.5-1.5h-35
	C1.7,35.3,1,36,1,36.8l0,0c0,0.8,0.7,1.5,1.5,1.5h35C38.3,38.3,39,37.5,39,36.8z"
        />
    </svg>
</template>

<script>
export default {
    props: {
        color: {
            type: String,
            default: '#fff'
        },
        width: {
            type: String,
            default: '18px'
        },
        height: {
            type: String,
            default: '18px'
        }
    }
};
</script>
