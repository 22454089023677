<template>
    <svg
        version="1.1"
        id="图层_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 38 38"
        style="enable-background:new 0 0 38 38;"
        xml:space="preserve"
        :width="width"
        :height="height"
    >
        <path
            id="XMLID_949_"
            class="st0"
            :fill="color"
            d="M17.5,35.7l0.1-2.4l0,0c0-0.5,0.2-1,0.6-1.3l12.7-12.7c0.9-0.9,2.4-0.7,3.4,0.4l1.6,1.6
	c1.1,1.1,1.3,2.6,0.4,3.4L23.5,37.3c-0.3,0.3-0.8,0.5-1.3,0.6L19.8,38c-0.6,0-1.2-0.2-1.6-0.7C17.7,36.9,17.5,36.3,17.5,35.7
	C17.5,35.7,17.5,35.7,17.5,35.7z M33.5,23.1L32.3,22L20.5,33.8l0,1.2l1.2,0L33.5,23.1z"
        />
        <path
            id="XMLID_639_"
            class="st0"
            :fill="color"
            d="M22.2,10.4L22.2,10.4c0,0.8-0.7,1.5-1.5,1.5h-12c-0.8,0-1.5-0.7-1.5-1.5v0
	c0-0.8,0.7-1.5,1.5-1.5h12C21.6,8.9,22.2,9.6,22.2,10.4z"
        />
        <path
            id="XMLID_638_"
            class="st0"
            :fill="color"
            d="M17.2,26.6L17.2,26.6c0,0.8-0.7,1.5-1.5,1.5h-7c-0.8,0-1.5-0.7-1.5-1.5v0
	c0-0.8,0.7-1.5,1.5-1.5h7C16.6,25.1,17.2,25.8,17.2,26.6z"
        />
        <path
            id="XMLID_637_"
            class="st0"
            :fill="color"
            d="M19.2,18.2L19.2,18.2c0,0.8-0.7,1.5-1.5,1.5h-9c-0.8,0-1.5-0.7-1.5-1.5v0
	c0-0.8,0.7-1.5,1.5-1.5h9C18.6,16.7,19.2,17.4,19.2,18.2z"
        />
        <path
            id="XMLID_624_"
            class="st0"
            :fill="color"
            d="M26.7,0h-22C2.8,0,1.2,1.7,1.2,3.8v30.5c0,2.1,1.6,3.8,3.5,3.8h8.1c0.8,0,1.5-0.7,1.5-1.6
	c0-0.9-0.7-1.6-1.5-1.6H4.7c-0.3,0-0.5-0.3-0.5-0.6V3.8c0-0.3,0.2-0.6,0.5-0.6h22c0.3,0,0.5,0.3,0.5,0.6v12.1c0,0.9,0.7,1.6,1.5,1.6
	s1.5-0.7,1.5-1.6V3.8C30.2,1.7,28.7,0,26.7,0z"
        />
    </svg>
</template>

<script>
import baseSvgImg from './baseSvgImg';
export default {
    extends: baseSvgImg,
    props: {
        color: {
            type: String,
            default: '#007aff'
        },
        width: {
            type: String,
            default: '15px'
        },
        height: {
            type: String,
            default: '15px'
        }
    }
};
</script>

<style scoped>
svg {
    display: block;
}
</style>
