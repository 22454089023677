import Gikam from 'gikam';
import { RsaEncryptUtils } from './rsaEncryptUtils';

export class LoginUtils {
    static async checkAccount(username, password) {
        const publicKey = await RsaEncryptUtils.getPublicKey();
        return new Promise((resolve, reject) => {
            const url = `${Gikam.IFM_CONTEXT}/core/module/sys/logins/action/check-account`;
            Gikam.post(
                url,
                Gikam.getJsonWrapper({
                    username,
                    password: RsaEncryptUtils.encode(publicKey, password)
                }),
                {
                    'SECURITY-RSA-KEY': publicKey
                }
            )
                .done(function(data) {
                    if (data === true) {
                        resolve(publicKey);
                    }
                })
                .fail(function(r) {
                    reject(Gikam.getI18NErrorMessage(JSON.parse(r.responseText).message));
                });
        });
    }

    static async getDeptList(username, password, publicKey) {
        const url = Gikam.IFM_CONTEXT + '/secure/core/module/mdm/user-permissions/login-org/queries/post-selectable';
        const json = Gikam.getJsonWrapper({
            username,
            password: RsaEncryptUtils.encode(publicKey, password)
        });
        return new Promise((resolve, reject) => {
            Gikam.post(url, json, {
                'SECURITY-RSA-KEY': publicKey
            })
                .done(deptList => resolve(deptList))
                .fail(r => reject(r));
        });
    }

    static async login(username, password, orgId, roleId, loginType) {
        const publicKey = await RsaEncryptUtils.getPublicKey();
        const data = { id: username, password: RsaEncryptUtils.encode(publicKey, password) };
        if (orgId) {
            data.orgId = orgId;
        }

        if (roleId) {
            data.roleId = roleId;
        }

        if (loginType === 1) {
            data.loginType = 'AD';
            LoginUtils.setLoginType('AD');
        } else {
            LoginUtils.setLoginType(null);
        }

        LoginUtils.setLoginUserId(username);

        return new Promise((resolve, reject) => {
            Gikam.ajax({
                url: Gikam.IFM_CONTEXT + '/core/module/sys/login',
                type: 'post',
                data,
                beforeSend: xhr => {
                    xhr.setRequestHeader('SECURITY-RSA-KEY', publicKey);
                }
            })
                .done(() => {
                    location.href = Gikam.IFM_CONTEXT + '/module/index/workspaces';
                    resolve();
                })
                .fail(r => reject(Gikam.getI18NErrorMessage(JSON.parse(r.responseText).message)));
        });
    }

    static async getRoleList(username, password, publicKey) {
        const url = Gikam.IFM_CONTEXT + '/secure/core/module/mdm/user-permissions/login-role/queries/post-selectable';
        return new Promise((resolve, reject) => {
            Gikam.post(
                url,
                Gikam.getJsonWrapper({
                    username,
                    password: RsaEncryptUtils.encode(publicKey, password)
                }),
                {
                    'SECURITY-RSA-KEY': publicKey
                }
            )
                .done(list => resolve(list || []))
                .fail(r => reject(r));
        });
    }

    static getDefaultDeptOrRoleItem(list, name) {
        let defaultValue = null;
        let items = list.map(function(item) {
            if (item.defaultFlag === '1') {
                defaultValue = item.targetId;
            }
            return {
                value: item.targetId,
                text: item.ext$[name],
                defaultFlag: item.defaultFlag
            };
        });
        if (items.length === 1) {
            defaultValue = items[0].value;
        }
        return { items, defaultValue };
    }

    static getDefaultDeptItem(list) {
        return LoginUtils.getDefaultDeptOrRoleItem(list, 'orgname');
    }

    static getDefaultRoleItem(list) {
        return LoginUtils.getDefaultDeptOrRoleItem(list, 'rolename');
    }

    static setLoginType(loginType) {
        if (!loginType) {
            localStorage.removeItem('loginType');
        } else {
            localStorage.setItem('loginType', loginType);
        }
    }

    static setLoginUserId(userId) {
        localStorage.setItem('loginUserId', userId);
    }

    static getLoginUserId() {
        return localStorage.getItem('loginUserId');
    }

    static getLoginType() {
        return localStorage.getItem('loginType');
    }
}
