<template>
    <div class="drag" slot="modal-body" :id="options.id">
        <div class="drag-container">
            <div class="drag-header" v-if="options.showScheme">
                <div class="scheme-name">{{ $i18n('indexPanelConfig.schemeName') }}</div>
                <select-field
                    ref="scheme"
                    class="scheme"
                    :options="{}"
                    :propValue="schemeValue"
                    @change="changeProject"
                ></select-field>
            </div>
            <panel :options="headerOptions">
                <checkboxField
                    slot="panel-body"
                    :options="headerOptions.options"
                    :propValue="headerOptions.values"
                    @change="panelChange"
                ></checkboxField>
            </panel>
            <panel :options="bodyOptions" style="flex: 1;height: 100%" :propStyle="{ position: 'absolute' }">
                <scroll slot="panel-body">
                    <div class="panner-body-container" ref="container">
                        <grid-layout
                            :layout.sync="bodyOptions.panelOptions"
                            :col-num="defaultColumns"
                            :row-height="defaultHeight"
                            :margin="[8, 8]"
                        >
                            <grid-item
                                v-for="item in bodyOptions.panelOptions"
                                :x="item.x"
                                :y="item.y"
                                :w="item.w"
                                :h="item.h"
                                :i="item.i"
                                :key="item.id"
                            >
                                <panel :options="item" :defaultHeight="defaultHeight" @selectChange="selectChange">
                                    <button
                                        @click.stop="close(item.id)"
                                        :title="$i18n('indexPanelConfig.close')"
                                        slot="panel-close"
                                        class="panel-close"
                                    >
                                        <fork />
                                    </button>
                                    <div slot="panel-body" class="panel-icon">
                                        <img src="../img/panel-icon.svg" />
                                    </div>
                                </panel>
                            </grid-item>
                        </grid-layout>
                    </div>
                </scroll>
            </panel>
            <div class="drag-footer">
                <btnGroup :options="btnOptions"></btnGroup>
            </div>
        </div>
    </div>
</template>

<script>
import Gikam from '../../../../core/gikam-core';
import Mask from '../../../template/mask/mask';
import panel from './panel.vue';
import Vue from 'vue';
import btnGroup from '../../../template/buttonGroup.vue';
import selectField from '../../../template/fields/select/select';
import checkboxField from '../../../template/fields/checkbox.vue';
import VueGridLayout from 'vue-grid-layout';
const GridLayout = VueGridLayout.GridLayout;
const GridItem = VueGridLayout.GridItem;
export default {
    props: {
        options: Object
    },

    components: {
        panel,
        checkboxField,
        btnGroup,
        GridLayout,
        GridItem,
        selectField
    },

    data() {
        const _this = this;
        return {
            btnOptions: {
                items: [
                    {
                        text: this.$i18n('indexPanelConfig.save'),
                        class: 'blue',
                        onClick: _this.saveConfig
                    },
                    {
                        text: this.$i18n('indexPanelConfig.cancel'),
                        hidden: !_this.options.indexSetting,
                        color: 'primary',
                        onClick: function() {
                            Gikam.getLastModal().close();
                        }
                    }
                ]
            },

            headerOptions: {
                title: this.$i18n('indexPanelConfig.functionSelection'),
                options: {
                    field: 'checkbox'
                },
                values: ''
            },

            bodyOptions: {
                title: this.$i18n('indexPanelConfig.panelSettings'),
                height: 2,
                panelOptions: []
            },
            defaultHeight: 150,
            defaultColumns: 12,
            saveParams: [],
            // 方案合集
            schemeFields: [],
            // 选择方案id
            schemeValue: void 0,
            // 选择数据参数
            selectedParams: void 0
        };
    },

    mounted() {
        this.options.indexSetting && this.getPanelConfig();
    },

    methods: {
        changeProject(field, schemeId, rowIndex, oldValue) {
            this.schemeValue = Number(schemeId);
            if (!oldValue) return;
            Gikam.getJson(
                Gikam.IFM_CONTEXT + `/secure/core/module/item/workspace-user-schemes/schemes/${schemeId}/panel-configs`
            ).done(data => {
                this.configDataHandle(data);
            });
        },

        //打开弹框请求配置数据
        getPanelConfig(param) {
            let url = '';
            this.selectedParams = {};
            if (param) {
                url = param.url;
                this.selectedParams = param;
            } else {
                url = Gikam.IFM_CONTEXT + this.options.url;
            }
            Gikam.getJson(url).done(data => {
                if (Gikam.isNotEmpty(data.schemeList)) {
                    this.schemeFields = data.schemeList.map(item => {
                        item.text = item.schemeName;
                        item.value = item.id;
                        return item;
                    });
                    this.$refs.scheme.items = this.schemeFields;
                    this.schemeValue = data.schemeId || data.schemeList[0].value;
                }
                this.configDataHandle(data);
            });
        },

        configDataHandle(data) {
            const values = [];
            const panelOptions = [];

            data.panelList.forEach(item => {
                if (item.config) {
                    const config = JSON.parse(item.config);
                    if (config.position) {
                        config.x = config.position[0] * 3;
                        config.y = config.position[1];
                        config.w = config.width * 3;
                        config.h = config.height;
                        config.i = config.id;
                        config.innerpanel = true;
                        delete config.position;
                    }
                    panelOptions.push(config);
                }
            });
            data.systemPanelList.forEach(item => {
                let checked = 0;
                data.panelList.some(i => i.panelId === item.id) && (checked = 1);
                values.push({
                    text: item.title,
                    value: item.id,
                    checked
                });
            });
            this.headerOptions.values = JSON.stringify(values);
            this.bodyOptions.panelOptions = panelOptions;
        },

        panelChange(checked, value) {
            let panelOptions = this.bodyOptions.panelOptions;
            const oldSelected = panelOptions.map(item => item.id);
            const selected = JSON.parse(value).filter(item => item.checked);
            this.headerOptions.values = value;
            if (selected.length === 0) {
                this.bodyOptions.panelOptions = [];
                return;
            }
            if (oldSelected.length === 0 && selected.length !== 1) {
                this.addAllPanels();
                return;
            }
            if (selected.length > oldSelected.length) {
                selected.forEach(item => {
                    if (oldSelected.findIndex(value => value === item.value) === -1) {
                        this.bodyOptions.panelOptions.push({
                            id: item.value,
                            i: item.value,
                            title: item.text,
                            innerpanel: true,
                            w: 2,
                            h: 1,
                            x: this.getlastPanelOption(),
                            y: 99
                        });
                    }
                });
            } else {
                const delId = oldSelected.filter(value => selected.findIndex(item => value === item.value) === -1)[0];
                this.bodyOptions.panelOptions = panelOptions.filter(item => item.id !== delId);
            }
        },

        getlastPanelOption() {
            let panelOptions = Gikam.deepExtend(this.bodyOptions.panelOptions);
            if (panelOptions.length === 0) {
                return 0;
            }
            if (panelOptions.length === 1) {
                const x = panelOptions[0].x + panelOptions[0].w;
                return x > this.defaultColumns - 2 ? 0 : x;
            }
            panelOptions
                .sort((current, next) => {
                    return current.x - next.x;
                })
                .sort((current, next) => {
                    return current.y + current.h - next.y - next.h;
                });
            const lastPanel = panelOptions[panelOptions.length - 1];
            return lastPanel.x + lastPanel.w > this.defaultColumns - 2 ? 0 : lastPanel.x + lastPanel.w;
        },

        addAllPanels() {
            this.bodyOptions.panelOptions = [];
            let x = 0,
                y = 0;
            JSON.parse(this.headerOptions.values).forEach(item => {
                const panelConfig = {
                    id: item.value,
                    i: item.value,
                    title: item.text,
                    innerpanel: true,
                    w: 2,
                    h: 1,
                    x,
                    y
                };
                this.bodyOptions.panelOptions.push(panelConfig);
                x += 2;
                if (panelConfig.x + panelConfig.w >= this.defaultColumns) {
                    y++;
                    x = 0;
                }
            });
        },

        selectChange(options, size, val) {
            options.size = val;
            options.h = parseInt(size.charAt(0));
            options.w = parseInt(size.charAt(size.length - 1));
        },

        close(id) {
            const values = JSON.parse(this.headerOptions.values);
            values.forEach(item => {
                item.value === id && (item.checked = 0);
            });
            this.headerOptions.values = JSON.stringify(values);
            const index = this.bodyOptions.panelOptions.findIndex(item => item.id === id);
            this.bodyOptions.panelOptions.splice(index, 1);
        },

        // 保存配置
        saveConfig() {
            const save = () => {
                this.saveParams = [];
                this.bodyOptions.panelOptions.forEach(item => {
                    this.saveParams.push({
                        panelId: item.id,
                        config: JSON.stringify(item)
                    });
                });
                const schemeId = this.schemeValue || this.selectedParams.schemeId;
                let url = '';
                let params = null;
                if (this.options.indexSetting) {
                    url = '/secure/core/module/item/workspace-user-schemes';
                    params = Gikam.getJsonWrapper(
                        null,
                        ['', this.saveParams],
                        ['coreWorkspaceUserSchemeServiceImpl', [{ schemeId }]]
                    );
                } else {
                    url = `/secure/core/module/item/workspace-schemes/${schemeId}/panel-configs`;
                    params = Gikam.getJsonWrapper(null, ['', this.saveParams]);
                }
                Gikam.put(Gikam.IFM_CONTEXT + url, params)
                    .done(() => {
                        if (this.options.indexSetting) {
                            window.location.reload();
                        } else {
                            Gikam.toast('保存成功');
                        }
                    })
                    .always(() => {
                        this.closeMask();
                    });
            };
            if (this.options.indexSetting) {
                const text = this.schemeFields.find(item => item.id === this.schemeValue).text;
                Gikam.confirm(
                    '提示：',
                    `${text}方案已修改，是否保存修改后的方案`,
                    () => {
                        this.openMask();
                        save();
                    },
                    () => {
                        Gikam.getLastModal().close();
                    }
                );
            } else {
                if (!this.selectedParams || !this.selectedParams.schemeId) {
                    Gikam.alert('没有方案不能保存');
                    return;
                }
                this.openMask();
                save();
            }
        },

        clearPanelData() {
            this.bodyOptions.panelOptions = [];
            this.headerOptions.values = '';
            this.selectedParams = {};
        },

        //打开遮罩
        openMask() {
            if (this.maskModel) {
                return;
            }
            this.maskModel = new Vue({
                el: Gikam.createDom('div', document.body),
                components: { Mask },
                render() {
                    return <Mask />;
                }
            });
        },

        //关闭遮罩
        closeMask() {
            this.maskModel.$nextTick(() => {
                this.maskModel.$destroy();
                Gikam.removeDom(this.maskModel.$el);
                this.maskModel = null;
            });
        }
    }
};
</script>

<style scoped>
.drag {
    padding: 0 16px 16px;
    height: 100%;
}

.drag .drag-container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.drag .drag-container > .drag-header {
    height: 40px;
    padding: 5px;
    display: flex;
    margin-bottom: 10px;
    border-bottom: 1px solid #d9d9d9;
}

.drag .drag-container > .drag-header > .scheme-name {
    font-size: 14px;
    line-height: 30px;
}

.drag .drag-container > .drag-header > .scheme {
    width: 150px;
    height: 30px;
}

.drag .drag-container > .drag-footer {
    height: 25px;
    display: flex;
    justify-content: flex-end;
}

.drag .checkbox-container {
    border: none;
}

.drag .panner-body-container {
    width: 100%;
}

.drag .panner-body-container >>> .vue-grid-item.vue-grid-placeholder {
    background: #007aff;
}

.drag .panner-body-container button.panel-close {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.5);
    padding: 5px 16px 3px;
}

.drag .panner-body-container button.panel-close > svg {
    width: 12px;
}

.drag-header > .drag-title > .title-container > .title {
    float: left;
    margin-left: 8px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    padding: 2px 5px;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.panel-icon {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.panel-icon img {
    width: 54px;
    height: 60px;
}
</style>
