<template>
    <div class="caption-container">
        <div class="caption" :style="'color:' + options.color">{{ options.text }}</div>
    </div>
</template>
<script>
import Gikam from '../../core/gikam-core';
export default {
    props: {
        options: Object,
        index: Number
    },

    data() {
        return {
            color: this.options.color,
            text: Gikam.propI18N(this.options.text)
        };
    }
};
</script>

<style lang="scss">
.caption {
    font-size: 12px;
    font-family: 'Microsoft YaHei', serif;
    color: rgba(0, 0, 0, 0.65);
}
</style>
