<template>
    <svg
        version="1.1"
        id="图层_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 42 42"
        style="enable-background:new 0 0 42 42;"
        xml:space="preserve"
        :width="width"
        :height="height"
    >
        <g>
            <path
                id="XMLID_2695_"
                class="st0"
                :fill="color"
                d="M30,20.9L30,20.9c0,0.8-0.7,1.5-1.5,1.5H10.1c-0.8,0-1.5-0.7-1.5-1.5v0
            c0-0.8,0.7-1.5,1.5-1.5h18.4C29.3,19.4,30,20.1,30,20.9z"
            />
            <path
                id="XMLID_2694_"
                class="st0"
                :fill="color"
                d="M29.9,30.9L29.9,30.9c0,0.8-0.7,1.5-1.5,1.5H10.1c-0.8,0-1.5-0.7-1.5-1.5v0
            c0-0.8,0.7-1.5,1.5-1.5h18.3C29.3,29.4,29.9,30.1,29.9,30.9z"
            />
            <path
                id="XMLID_2697_"
                class="st0"
                :fill="color"
                d="M38.4,11.8c0,0.5-0.1,1-0.3,1.5c-0.5,1.2-1.5,1.9-2.6,1.9l-7.8,0c-2.4,0-4-1.6-4-3.9l0-7.8
            c0-1,0.8-2,2-2.5c1.4-0.6,2.9-0.4,3.9,0.6l7.8,7.8C38,9.9,38.4,10.8,38.4,11.8z M35.3,12.2c0.1-0.1,0.1-0.3,0.1-0.4
            c0-0.1,0-0.3-0.1-0.3l-7.8-7.8c0,0-0.1,0-0.2,0c-0.2,0-0.5,0.1-0.6,0.2l0,7.5c0,0.7,0.2,0.9,1,0.9L35.3,12.2z"
            />
            <path
                class="st0"
                :fill="color"
                d="M32.9,41.6h-26c-3,0-5.5-2.5-5.5-5.5v-30c0-3,2.5-5.5,5.5-5.5h20.7c0.8,0,1.5,0.7,1.5,1.5s-0.7,1.5-1.5,1.5
            H6.9c-1.4,0-2.5,1.1-2.5,2.5v30c0,1.4,1.1,2.5,2.5,2.5h26c1.4,0,2.5-1.1,2.5-2.5V11.8c0-0.8,0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5v24.3
            C38.4,39.1,35.9,41.6,32.9,41.6z"
            />
        </g>
    </svg>
</template>

<script>
import baseSvgImg from './baseSvgImg';
export default { extends: baseSvgImg };
</script>
