import Gikam from 'gikam';
import { getPageCss, printPage, getPageHtml, getHtmlCompType } from './utils';

/**
 * 顶部添加内容按钮
 * @memberof Les
 */
export function addContent() {
    if (this.store.state.bodyIsEdit) {
        Gikam.alert('请先保存编辑的内容再添加组件');
        return;
    }
    const _getTemplates = this.getTemplates.bind(this);
    this.addContentModal = new Gikam.Vue({
        el: Gikam.createDom('div', document.body),
        store: this.store,
        provide() {
            return {
                changeTemplateData: _getTemplates
            };
        },
        components: {
            lesModel: () => import('../vue/lesModel.vue')
        },
        render() {
            return <lesModel />;
        }
    });
}

/**
 * 导出pdf按钮
 * @memberof Les
 */

export async function importEvent() {
    if (this.store.state.bodyIsEdit) {
        Gikam.alert('请先保存编辑的内容再点击导出pdf按钮');
        return;
    }
    window.workspace.window.showMask();
    const center = this.model.$refs.vm.$refs.body;
    const $dom = getPageHtml(center);
    const CompType = getHtmlCompType($dom);
    const head = await getPageCss(CompType);

    const content = `<!DOCTYPE HTML><html><head><meta charset="utf-8"/>${head.innerHTML}</head><body>${$dom.innerHTML}</body></html>`;

    Gikam.postText(
        Gikam.IFM_CONTEXT + '/core/module/item/files/action/download-pdf',
        Gikam.getJsonWrapper({
            content,
            fileName: this.store.state.lesInfo.templateName || this.store.state.lesInfo.instanceName
        })
    )
        .done(function(url) {
            Gikam.download(Gikam.IFM_CONTEXT + url);
        })
        .always(() => {
            window.workspace.window.closeMask();
        });
}

/**
 * 返回内容按钮
 * @memberof Les
 */
export function backEvent() {
    if (this.store.state.activeComp.isNew === true) {
        this.store.commit('deleteComp', this.store.state.activeComp);
    }
    this.store.commit('changeEditState', false);
    this.store.commit('changeActiveComp', { comp: {} });
    this.options.back();
}

/**
 * 打印按钮
 * @memberof Les
 */
export async function printEvent() {
    if (this.store.state.bodyIsEdit) {
        Gikam.alert('请先保存编辑的内容再点击打印按钮');
        return;
    }
    window.workspace.window.showMask();
    const center = this.model.$refs.vm.$refs.body;
    const centerDom = getPageHtml(center);
    const CompType = getHtmlCompType(centerDom);
    const head = await getPageCss(CompType);
    window.workspace.window.closeMask();
    printPage(head, centerDom);
}
/**
 * 审计跟踪按钮
 * @memberof Les
 */
export function auditTrailEvent() {
    const tableName = this.options.instance ? 'T_CORE_LES_INSTANCE' : 'T_CORE_LES_TEMPLATE';
    const trail = Gikam.audit.trail;

    Gikam.getJson(
        Gikam.printf(Gikam.IFM_CONTEXT + '/secure/core/{trail}/{tableName}', {
            tableName: tableName,
            trail: trail.indexOf('audit-trail') === -1 ? 'audit/audit-configs' : 'module/sys/at-configs'
        })
    ).done(r => {
        if (
            (trail.indexOf('audit-trail') === -1 && r.auditable === '1') ||
            (trail.indexOf('audit-trail') !== -1 && r.auditTrail === '1')
        ) {
            const id = this.options.id;
            const store = this.store;
            this.auditModal = new Gikam.Vue({
                el: Gikam.createDom('div', document.body),
                components: {
                    lesModel: () => import('../vue/base/audit/audit.vue')
                },
                store: store,
                render() {
                    return <lesModel tableName={tableName} id={id} />;
                }
            });
        } else {
            Gikam.alert(Gikam.propI18N('GIKAM.AT.NO_ACTIVATED_CONFIG'));
        }
    });
}
