<template>
    <div class="filter-date-picker" :style="{ top, left, width }" @click.stop>
        <div class="text">{{ $i18n('grid.filterFrom') }}</div>
        <dateField :options="{ field: 'form', type }" @change="formChange" :propValue="fromValue"></dateField>
        <div class="text">{{ $i18n('grid.filterTo') }}</div>
        <dateField :options="{ field: 'to', type }" @change="toChange" :propValue="toValue"></dateField>
    </div>
</template>

<script>
export default {
    props: {
        isDateTime: {
            type: Boolean,
            default: false
        },
        propLeft: Number,
        propTop: Number,
        propWidth: Number,
        propFromValue: String,
        propToValue: String
    },
    methods: {
        formChange(file, value) {
            this.$emit('fromChange', value);
        },
        toChange(file, value) {
            this.$emit('toChange', value);
        }
    },
    data() {
        return {
            left: this.propLeft + 'px',
            top: this.propTop + 'px',
            width: this.propWidth + 'px',
            fromValue: this.propFromValue,
            toValue: this.propToValue
        };
    },
    computed: {
        type() {
            return this.isDateTime ? 'dateTime' : 'date';
        }
    }
};
</script>

<style scoped>
@keyframes slide-in {
    from {
        transform: translate3d(0, 20px, 0);
        opacity: 0.3;
    }
    to {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}
.filter-date-picker {
    position: absolute;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    background-color: #fff;
    z-index: 10;
    border-radius: 4px;
    animation: slide-in 0.3s;
    padding: 8px;
}

.text {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.85);
}

.filter-date-picker >>> .date-input {
    height: 24px;
}
</style>
