import Gikam from '../core/gikam-core.js';
import window from './template/window';
import Base from './base.js';
import Vue from 'vue';
import jQuery from 'jquery';
import { I18N } from '@/gikam/i18n/I18N';

let defaultOptions = {
    renderTo: void 0,
    //页面大标题
    title: void 0,
    //定义子标题
    subTitle: null,
    //纪录缓存的window标识
    objectId: void 0,
    //是否显示标题，默认显示
    showTitle: true,
    //标题后的按钮位置，可选配置为'left','center','right'(默认)
    toolbarAlign: 'right',
    //标题及按钮工具栏显示在顶部
    toolbarTop: true,
    //是否进入全屏状态
    isEnterFullScreen: false
};

export default class Window extends Base {
    static windowManager = {};

    static htmlParseDom = null;

    static rememberWindow(window) {
        if (!window.options.objectId) {
            return;
        }
        this.listeners = {
            ready: Gikam.emptyFunction
        };
        if (window.hasIframe()) {
            window.hide();
            Window.windowManager[window.options.objectId] = window;
        } else {
            let fragment = document.createDocumentFragment();
            fragment.appendChild(window.model.$el);
            Window.windowManager[window.options.objectId] = fragment;
        }
    }

    constructor(param) {
        super();
        this.history = [];
        this.historyViewModel = [];
        this.historyCompStore = [];
        this.fragment = document.createDocumentFragment();
        this.activeUrl = void 0;
        // 窗口是否只读
        this.readonly = false;
        // 获取菜单url参数
        this.$param = {};
        // 子组件数量
        this.$compStoreNum = {};
        // 所有组件
        this.$compStore = {};
        // 根组件
        this.$window = this;
        // 页面id
        this.$pageId = null;
        // 存储页面的url
        this.pageUrl = '';
        // js容器
        this.jsFileManager = new Map();
        // 是否js编辑模式
        this.isEdit = false;
        // html内容
        this.htmlText = '';
        // 初始化
        this.initialize(param, defaultOptions).init();
        // 保存按钮
        // this.$saveButton = null;
        // 记录发生改变的组件
        this.$changeCompContainer = [];
        // 当前是否是点击的保存按钮
        this.$isSaveButtonClick = false;
        // 在只读页面需要显示的按钮
        this.$alwaysButton = [];
    }

    changeSubTitle(text) {
        text && (this.options.subTitle = text);
    }

    createModel() {
        let _this = this;
        return new Vue({
            el: Gikam.createDom('div', this.options.renderTo),
            components: { window },
            data() {
                return {
                    options: _this.options
                };
            },
            render() {
                return <window ref="vm" options={this.options}></window>;
            }
        });
    }

    //显示数据加载中遮罩
    showMask() {
        this.model.$children[0].dataLoading = true;
    }

    //关闭数据加载中遮罩
    closeMask() {
        this.model.$children[0].dataLoading = false;
    }

    showPageMask() {
        this.model.$refs.vm.pageLoading = true;
    }

    closePageMask() {
        this.model.$refs.vm.pageLoading = false;
    }

    clean() {
        let toolbar = this.model.$el.querySelector('.toolbar');
        let body = this.model.$el.querySelector('.body');
        if (toolbar) {
            toolbar.innerHTML = '';
        }
        if (body) {
            body.innerHTML = '';
        }
    }

    rememberWindow() {
        this.model.$el.$pageId = this.$pageId;
        this.fragment.appendChild(this.model.$el);
        this.historyViewModel.push(this.model);
        this.model.$alwaysButton = this.$alwaysButton;
        if (this.$compStore.Grid) {
            this.$compStore.Grid.forEach(comp => {
                comp.$scrollTop = comp.store.state.scrollTop;
                comp.$scrollLeft = comp.store.state.scrollLeft;
            });
        }
        this.historyCompStore.push(this.$compStore);
        this.init();
    }

    load(url) {
        let _this = this;
        const header = {};
        this.pageUrl = url;
        this.showPageMask();
        Gikam.extend(this.$param, Gikam.getRequestParam(url));
        if (Gikam.isTrue(this.$param.readonly)) {
            this.readonly = true;
        } else {
            this.readonly = false;
        }
        if (this.options.objectId) {
            header['sunway-menu'] = this.options.objectId;
        }
        if (this.isEdit) {
            header['sunway-page-config'] = '1';
        }
        Gikam.getText(url, header)
            .done(r => {
                if (typeof r === 'string' && r.indexOf('<!DOCTYPE') === 0) {
                    return;
                } else {
                    this.rememberWindow();
                    this.$cleanStoreComp();
                    this.history.push(url);
                }
                this.$pageId = this.getPageId(url);
                this.htmlText = r;
                try {
                    this.loadHtmlScript(r);
                } finally {
                    _this.closePageMask();
                }
                this.getJsFileText(r);
            })
            .fail(function(result) {
                if (result.status === 404 || result.status === 500) {
                    _this.loadHtmlScript(result.responseText);
                }
            })
            .always(() => {
                _this.closePageMask();
            });
    }

    /**
     * @description 将url作为页面id
     * @private
     * @param {String} url请求地址
     * @memberof Window
     */
    getPageId(url) {
        return url ? url.replace(/\?[\s\S]+/g, '') : null;
    }

    enterFullScreen() {
        let el = this.model.$refs.vm.$el;
        if (el) {
            this.options.isEnterFullScreen = true;
        }
        return;
    }

    goBack(options = {}) {
        const def = jQuery.Deferred();
        this.validateWindowComp(I18N.prop('window.save.before.back')).then(boolean => {
            if (boolean) {
                def.resolve();
            } else {
                this.showPageMask();
                this.history.pop();
                this.destroy();
                this.$cleanStoreComp();
                const lastViewModel = this.historyViewModel.pop();
                this.$compStore = this.historyCompStore.pop();
                this.$param.readonly = false;
                this.options.renderTo.appendChild(lastViewModel.$el);
                this.model = lastViewModel;
                this.$dom = this.model.$refs.vm.$refs.body;
                const storage = this.model.storage;
                if (storage && storage.grid) {
                    storage.grid.store.state.scrollLeft = storage.grid.$scrollLeft;
                    storage.grid.store.state.scrollTop = storage.grid.$scrollTop;
                    delete storage.grid.$scrollLeft;
                    delete storage.grid.$scrollTop;
                    storage.grid.refreshRowByIndex(storage.index);
                    this.model.storage = null;
                } else {
                    if (options.refresh !== false) {
                        this.refreshData();
                    }
                }
                this.closePageMask();
                this.$pageId = this.model.$el.$pageId;
                def.resolve();
            }
        });
        return def;
    }

    removeTitle() {
        this.options.showTitle = false;
        return this;
    }

    setToolbarAlign(align) {
        this.model.$refs.vm.toolbarAlign = align;
        return this;
    }

    save(param) {
        let dataList = [null];
        let _this = this;
        let def = jQuery.Deferred();
        let mainForm = Gikam.getInstance(jQuery(_this.$dom).find('.form:first'));
        if (!param.url) {
            Gikam.error('url not exists');
        }
        if (!param.page.setCompUrl) {
            Gikam.error('do not find updateCompUrl method');
        }
        let $comps = jQuery(this.$dom).find('.form,.grid:not(.grid-fixed),.simple-grid');
        let validateResult = true;
        $comps.each(function() {
            let comp = Gikam.getInstance(jQuery(this));
            let compData = [];
            let d = comp.getData();
            if (comp.validate() === false) {
                validateResult = false;
                return false;
            }
            if (Gikam.isEmpty(comp.options.service)) {
                return true;
            }
            compData.push(comp.options.service);
            compData.push(Array.isArray(d) ? d : [d]);
            if (typeof param.format === 'function') {
                compData = param.format(comp.options.id, compData);
            }
            dataList.push(compData);
        });
        if (validateResult === false) {
            this.closeMask();
            return def;
        }
        this.showMask();
        Gikam[Gikam.isEmpty(param.page.param.id) ? 'postText' : 'put'](
            param.url,
            Gikam.getJsonWrapper.apply(Gikam, dataList),
            param.header
        )
            .done(function(id) {
                _this.saveSuccess();
                if (Gikam.isNotEmpty(param.page.param.id)) {
                    def.resolve();
                    return;
                }
                mainForm.toFieldsReadonly(['id']);
                param.page.param.id = arguments[0];
                param.page.setCompUrl.call(param.page);
                let async = [];
                $comps.each(function() {
                    let comp = Gikam.getInstance(this);
                    comp.getOptions().service && async.push(comp.refresh());
                });
                jQuery.when.apply(jQuery, async).done(function() {
                    def.resolve(id);
                });
            })
            .always(function() {
                _this.$isSaveButtonClick = false;
                _this.closeMask();
            });
        return def;
    }

    destroy() {
        jQuery(this.model.$el).remove();
        this.model.$destroy();
        if (Window.windowManager[this.options.objectId]) {
            delete Window.windowManager[this.options.objectId];
        }
    }

    setDefaultOptions(options) {
        Gikam.extend(true, defaultOptions, options.defaultOptions);
    }

    showHeader() {
        this.model.$refs.vm.headerShow = true;
    }

    /**
     * @description 注册window下所有组件
     * @private
     * @param {String} type 组件类型
     * @param {*} comp 组件对象
     * @memberof Window
     */
    $addStoreComp(type, comp) {
        if (!this.$compStore[type]) {
            this.$compStore[type] = [];
        }
        this.$compStore[type].push(comp);
    }

    /**
     * @description 清空window下所有组件
     * @private
     * @memberof Window
     */
    $cleanStoreComp() {
        this.$compStore = {};
    }

    /**
     * @description 刷新window下所有组件数据
     * @public
     * @memberof Window
     */
    refreshData() {
        if (Gikam.isFalse(this.$param.refresh)) {
            return;
        }
        for (let compType in this.$compStore) {
            this.$compStore[compType].forEach(comp => {
                comp.refresh && comp.$canRefreshPageSwitch && comp.refresh();
            });
        }
    }

    init() {
        this.model = this.createModel();
        this.$dom = this.model.$refs.vm.$refs.body;
        this.$toolbar = this.model.$refs.vm.$refs.toolbar;
        this.bindInstance(this.model.$el);
        this.bindInstance(this.$dom);
        this.listeners = {
            beforeClose: Gikam.emptyFunction
        };
    }

    /**
     * @description 创建子组件方法
     * @param {Object} options {title:'',subTitle:'',header:[],footer:[],body:[]}
     * @memberof Window
     */
    createChildren(options) {
        const { title, subTitle, body } = options;
        if (title) {
            this.options.title = title;
        }
        if (subTitle) {
            this.options.subTitle = subTitle;
        }
        body.forEach(item => {
            item.renderTo = this.$dom;
            item.$window = this;
            Gikam.create(item.type, item);
        });
    }

    /**
     * @description 是否有iframe
     * @private
     * @memberof Window
     */
    hasIframe() {
        return Gikam.isNotEmpty(this.$compStore.Iframe);
    }

    /**
     * @description 隐藏
     * @public
     * @memberof Window
     */
    hide() {
        this.model.$refs.vm.hide();
    }

    /**
     * @description 显示
     * @public
     * @memberof Window
     */
    show() {
        this.model.$refs.vm.show();
    }

    /**
     * @description 父组件尺寸变更时触发
     * @private
     * @param
     * @memberof Tab
     */
    onResize() {
        this.$width = this.$dom.clientWidth;
        this.$height = this.$dom.clientHeight;
        this.$children.forEach(comp => {
            comp.onResize && comp.onResize();
        });
    }

    loadHtmlScript(text) {
        if (!Window.htmlParseDom) {
            Window.htmlParseDom = document.createElement('div');
            document.body.appendChild(Window.htmlParseDom);
        }
        Window.htmlParseDom.innerHTML = '';
        const scriptDom = document.createElement('script');
        scriptDom.text = text.replace(/<script>|<\/script>/g, '');
        Window.htmlParseDom.appendChild(scriptDom);
    }

    /**
     * @description
     * @private
     * @memberof Window
     */
    getJsFileText(htmlText) {
        const lines = htmlText.split(/\n/);
        const temp = { path: null, content: [] };
        let beginFlag = false;
        lines.forEach(line => {
            if (line.indexOf('/*start/') === 0) {
                temp.path = line.replace(/(^\/\*start)|(\*\/)/g, '');
                beginFlag = true;
                return;
            }
            if (line.indexOf('/*end/') === 0) {
                beginFlag = false;
                this.jsFileManager.set(temp.path, temp.content.join('\n'));
                return;
            }
            if (beginFlag) {
                temp.content.push(line);
            }
        });
    }

    onBeforeClose(callback) {
        if (Gikam.isFunction(callback)) {
            this.listeners.beforeClose = callback;
        }
    }

    /**
     * @description 表格或表单状态改变之后对状态进行记录
     * @param {*} flag
     * @memberof Window
     * @private
     */
    changeSaveButtonPrompt(comp, flag) {
        if (!this.model.$saveButton) {
            return;
        }
        if (flag === true) {
            if (!this.$changeCompContainer.some(item => item === comp)) {
                this.$changeCompContainer.push(comp);
            }
        } else {
            const index = this.$changeCompContainer.indexOf(comp);
            this.$changeCompContainer.splice(index, 1);
        }
        this.model.$saveButton.prompt = Gikam.isEmpty(this.$changeCompContainer) ? false : true;
    }

    /**
     * @description 还原到没有任何更改的状态
     * @memberof Window
     * @private
     */
    cleanSavePromptState() {
        if (!this.model.$saveButton) {
            return;
        }
        this.$changeCompContainer = [];
        this.model.$saveButton.prompt = false;
        const compType = ['Form', 'Grid'];
        compType.forEach(compType => {
            if (this.$compStore[compType]) {
                this.$compStore[compType].forEach(comp => {
                    comp.$changeFields = {};
                    comp.initData = Gikam.deepExtend(comp.options.data);
                });
            }
        });
    }

    /**
     * @description 调用save方法保存成功之后
     * @memberof Window
     * @private
     */
    saveSuccess() {
        this.cleanSavePromptState();
    }

    /**
     * @description 校验是否有发生改变的组件
     * @memberof Window
     * @private
     */
    validateWindowComp(message) {
        return new Promise(resolve => {
            if (Gikam.isNotEmpty(this.$changeCompContainer)) {
                Gikam.confirm(
                    '提示',
                    message,
                    () => resolve(true),
                    () => resolve(false)
                );
            } else {
                resolve(false);
            }
        });
    }

    /**
     * @description 调用save方法保存成功之后
     * @memberof Window
     * @public
     */
    setAlwaysButton(buttons) {
        this.$alwaysButton = buttons;
    }
}
