import alert from '../vue/alert';
import Base from '../../base.js';
import Vue from 'vue';
import Gikam from '../../../core/gikam-core';

let defaultOptions = {
    message: void 0,
    height: 250
};

let singleAlert = null;

export default class Alert extends Base {
    constructor(props) {
        super(props);
        if (singleAlert) {
            return;
        }
        this.listeners = {
            confirm: Gikam.emptyFunction
        };
        this.initialize(props, defaultOptions).init();
    }

    createModel() {
        const _this = this;
        return new Vue({
            el: Gikam.createDom('div', document.body),
            components: { alert },
            methods: {
                close() {
                    singleAlert = null;
                    _this.trigger('confirm');
                    _this.$destroy();
                }
            },
            render() {
                return <alert options={_this.options} onClick={this.close}></alert>;
            }
        });
    }

    init() {
        this.model = this.createModel();
        singleAlert = this;
    }
}
