<template>
    <svg
        version="1.1"
        id="图层_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 48 48"
        style="enable-background:new 0 0 48 48;"
        xml:space="preserve"
        :width="width"
        :height="height"
    >
        <path
            class="st0"
            :fill="color"
            d="M38.5,43h-29C7,43,5,41,5,38.5v-29C5,7,7,5,9.5,5H24c0.8,0,1.5,0.7,1.5,1.5S24.8,8,24,8H9.5C8.7,8,8,8.7,8,9.5
	v29C8,39.3,8.7,40,9.5,40h29c0.9,0,1.5-0.6,1.5-1.5V23.7c0-0.8,0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5v14.8C43,41,41,43,38.5,43z"
        />
        <path
            class="st0"
            :fill="color"
            d="M18.8,31.4c-0.5,0-1.1-0.2-1.5-0.6c-0.5-0.5-0.8-1.3-0.6-2l0.7-2.9c0.1-0.7,0.5-1.1,0.6-1.2l19-18.9
	c0.9-0.9,2.5-0.9,3.6,0l0.1,0.1l1.6,1.6c0.5,0.5,0.8,1.2,0.8,1.8c0,0.7-0.2,1.4-0.7,1.9l-19,18.9c-0.2,0.2-0.6,0.6-1.2,0.6l-2.9,0.7
	C19.1,31.4,18.9,31.4,18.8,31.4z M18.6,28.4C18.6,28.4,18.5,28.4,18.6,28.4L18.6,28.4z M20.2,26.6l-0.4,1.5l1.5-0.4L39.9,9.3
	l-1.2-1.2L20.2,26.6z M39.1,7.9L39.1,7.9C39.1,7.9,39.1,7.9,39.1,7.9z"
        />
    </svg>
</template>

<script>
import baseSvgImg from './baseSvgImg';
export default { extends: baseSvgImg };
</script>

<style scoped>
svg {
    display: block;
}
</style>
