<template>
    <div class="button-group" :class="{ noBorder: noBorder }">
        <btn v-for="(item, index) in options.items" :key="index" :options="item"></btn>
    </div>
</template>
<script>
import btn from '../../button.vue';
import Gikam from 'gikam';

export default {
    name: 'buttonGroupField',

    props: {
        options: Object,
        propReadonly: {
            type: Boolean,
            default: void 0
        }
    },

    computed: {
        noBorder() {
            return Gikam.isTrue(this.options.noBorder) || false;
        }
    },

    components: { btn }
};
</script>

<style scoped lang="scss">
.button-group {
    width: 100%;
    height: 100%;
    padding: 0 4px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    color: #666;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    background: transparent;
    overflow: hidden;
    &.noBorder {
        border: none;
    }
    ::v-deep {
        .btn-container {
            min-height: auto !important;
            user-select: none;
            .button {
                border: none;
                color: #007aff;
                .icon {
                    display: none;
                }
            }

            .button:hover {
                background-color: transparent;
            }

            .button:active {
                color: #2316db;
            }
        }
    }
}
</style>
