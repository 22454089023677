<template>
    <svg
        viewBox="0 0 20 20"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        :width="width"
        :height="height"
    >
        <g id="对话框" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="信息提示" transform="translate(-88.000000, -844.000000)">
                <g id="Group-5" transform="translate(88.000000, 844.000000)">
                    <rect id="Rectangle-12" :fill="color" x="0" y="0" width="20" height="20" rx="10" />
                    <g id="帮助" transform="translate(6.000000, 3.000000)" fill="#FFFFFF" fill-rule="nonzero">
                        <path
                            d="M4.00027027,13.2011622 C3.40635135,13.2011622 2.923,12.7181892 2.923,12.1242973 C2.923,11.5304054 3.40624324,11.0471622 4.0002973,11.0471622 C4.59408108,11.0471622 5.077,11.5303784 5.077,12.1242973 C5.077,12.7182162 4.59405405,13.2011622 4.00027027,13.2011622 Z M4,9.64343243 C3.61001233,9.64343243 3.29386486,9.32728496 3.29386486,8.9372973 C3.29386486,7.49927027 4.44875676,6.5802973 5.21302703,5.97213514 C5.38378378,5.83610811 5.54513514,5.70767568 5.63702703,5.61581081 C6.07264999,5.18256553 6.31681407,4.59303114 6.31508108,3.97864865 C6.31508108,2.70197297 5.27640541,1.66343243 3.99986486,1.66343243 C2.72332432,1.66343243 1.68464865,2.70197297 1.68464865,3.97864865 C1.68464865,4.36864378 1.36849513,4.6847973 0.9785,4.6847973 C0.58850487,4.6847973 0.272351351,4.36864378 0.272351351,3.97864865 C0.272351351,1.92332432 1.94437838,0.251297297 3.99986486,0.251297297 C6.05518919,0.251297297 7.72735135,1.92332432 7.72735135,3.97864865 C7.72735135,4.97427027 7.33962162,5.91027027 6.6357027,6.61418919 C6.48721622,6.76267568 6.30413514,6.90824324 6.09227027,7.07694595 C5.47456757,7.56845946 4.70586486,8.18021622 4.70586486,8.93716216 C4.70600832,9.12445038 4.63169964,9.30411489 4.49930486,9.43658571 C4.36691008,9.56905653 4.18728827,9.64346835 4,9.64343243 Z"
                            id="Shape"
                        />
                    </g>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
import baseSvgImg from './baseSvgImg';
export default {
    extends: baseSvgImg,
    props: {
        color: {
            type: String,
            default: '#007AFF'
        }
    }
};
</script>
