import Gikam from './gikam-core';

let config = {};

export default {
    addConfigParam: function(param) {
        param.forEach(function(item) {
            config[item.category] = item;
        });
    },

    getConfig: function(category) {
        if (!config[category]) {
            Gikam.error('do not find choose config by [' + category + ']');
        }
        return config[category];
    }
};
