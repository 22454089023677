<template>
    <svg
        version="1.1"
        id="图层_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 38 38"
        style="enable-background:new 0 0 38 38;"
        xml:space="preserve"
        :width="width"
        :height="height"
    >
        <g>
            <rect
                x="26.2"
                y="1.3"
                transform="matrix(0.7071 0.7071 -0.7071 0.7071 15.3824 -16.6038)"
                class="st0"
                width="3"
                height="18"
                :fill="color"
            />
            <path
                class="st0"
                :fill="color"
                d="M35.9,4.3l-2.1-2.1l1.8-1.8c0.4-0.4,1-0.4,1.4,0L37.7,1c0.4,0.4,0.4,1,0,1.4L35.9,4.3z"
            />
            <polygon class="st0" :fill="color" points="18.8,19.2 19.6,16.3 21.7,18.4 	" />
        </g>
        <path
            class="st0"
            :fill="color"
            d="M27,38H12c-0.8,0-1.5-0.7-1.5-1.5l0,0c0-0.8,0.7-1.5,1.5-1.5h15c0.8,0,1.5,0.7,1.5,1.5l0,0
	C28.5,37.3,27.8,38,27,38z"
        />
        <rect x="17.5" y="28.5" class="st0" width="3" height="8" :fill="color" />
        <path
            class="st0"
            :fill="color"
            d="M34.5,30h-31C1.6,30,0,28.4,0,26.5v-20C0,4.6,1.6,3,3.5,3h23C27.3,3,28,3.7,28,4.5S27.3,6,26.5,6h-23
	C3.2,6,3,6.2,3,6.5v20C3,26.8,3.2,27,3.5,27h31c0.3,0,0.5-0.2,0.5-0.5v-15c0-0.8,0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5v15
	C38,28.4,36.4,30,34.5,30z"
        />
    </svg>
</template>

<script>
import baseSvgImg from './baseSvgImg';
export default { extends: baseSvgImg };
</script>

<style scoped>
svg {
    display: block;
}
</style>
