import toast from '../vue/toast';
import Base from '../../base.js';
import Vue from 'vue';
import Gikam from '../../../core/gikam-core';

let defaultOptions = {
    message: void 0,
    duration: 3,
    position: 'middle'
};

export default class Toast extends Base {
    constructor(props) {
        super(props);
        this.initialize(props, defaultOptions).init();
    }

    createModel() {
        const _this = this;
        return new Vue({
            el: Gikam.createDom('div', document.body),
            components: { toast },
            render() {
                return <toast options={_this.options}></toast>;
            }
        });
    }

    init() {
        this.model = this.createModel();
    }
}
