<template>
    <svg
        version="1.1"
        id="图层_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 42 42"
        style="enable-background:new 0 0 42 42;"
        xml:space="preserve"
        :width="width"
        :height="height"
    >
        <path
            class="st0"
            :fill="color"
            d="M21,34.8c-11,0-21-9.4-21-13c0-3.6,10-13,21-13s21,9.4,21,13C42,25.5,32,34.8,21,34.8z M21,11.8
            c-9.8,0-17.7,8.3-18,10c0.3,1.6,8.2,10,18,10c9.7,0,17.6-8.2,18-10C38.6,20,30.7,11.8,21,11.8z"
        />
        <path
            class="st0"
            :fill="color"
            d="M21,27.8c-3.3,0-6-2.7-6-6s2.7-6,6-6s6,2.7,6,6S24.3,27.8,21,27.8z M21,17.8c-2.2,0-4,1.8-4,4c0,2.2,1.8,4,4,4
            c2.2,0,4-1.8,4-4C25,19.6,23.2,17.8,21,17.8z"
        />
    </svg>
</template>

<script>
import baseSvgImg from './baseSvgImg';
export default { extends: baseSvgImg };
</script>

<style scoped>
svg {
    display: block;
}
</style>
