<script>
import chooseInput from './chooseInput.vue';
import Gikam from 'gikam';
export default {
    extends: chooseInput,
    name: 'customChoose',
    methods: {
        choose() {
            if (this.options.onChoose) {
                this.modal = this.options.onChoose.apply(this.form || this.grid);
                return;
            }
            const data = this.options.data;
            let columns = [];
            if (this.options.mode === 'user') {
                columns = [
                    {
                        index: true
                    },
                    {
                        field: 'id',
                        title: 'T_CORE_USER.ID',
                        genericQuery: true
                    },
                    {
                        field: 'userNo',
                        title: 'T_CORE_USER.USERNO',
                        genericQuery: true
                    },
                    {
                        field: 'userName',
                        title: 'T_CORE_USER.USERNAME',
                        genericQuery: true
                    },
                    {
                        field: 'orgName',
                        title: 'T_CORE_USER.ORGNAME',
                        genericQuery: true
                    }
                ];
            } else if (this.options.mode === 'role') {
                columns = [
                    {
                        title: 'T_CORE_ROLE.ROLENAME',
                        field: 'roleName',
                        type: 'link',
                        genericQuery: true
                    },
                    {
                        title: 'T_CORE_ROLE.ROLEDESC',
                        field: 'roleDesc'
                    }
                ];
            } else {
                columns = this.options.columns;
            }
            this.options.single
                ? columns.unshift({
                      radio: true
                  })
                : columns.unshift({
                      checkbox: true
                  });
            const _this = this;
            this.modal = Gikam.create('modal', {
                title: _this.options.title,
                width: _this.options.width,
                height: _this.options.height,
                onAfterClose: function(rows) {
                    if (_this.setValueAfterChoose && Gikam.isNotEmpty(rows)) {
                        _this.value = Gikam.getFieldValue(rows[0], _this.field);
                    }
                    _this.$emit('afterChoose', rows, _this.rowIndex);
                    _this.setRelateFieldsValue(rows);
                    _this.focus();
                }
            });
            Gikam.create('layout', {
                renderTo: this.modal.window.$dom,
                center: {
                    items: [
                        {
                            type: 'grid',
                            id: 'core-role-choose-custom-grid',
                            toolbar: [
                                {
                                    type: 'button',
                                    text: 'GIKAM.BUTTON.CONFIRM',
                                    icon: 'select',
                                    onClick: function() {
                                        Gikam.getLastModal().close(
                                            Gikam.getComp('core-role-choose-custom-grid').getSelections()
                                        );
                                    }
                                },
                                {
                                    type: 'button',
                                    text: 'GIKAM.BUTTON.CANCEL',
                                    icon: 'cancel',
                                    onClick: function() {
                                        Gikam.getLastModal().close();
                                    }
                                }
                            ],
                            data,
                            columns,
                            pages: false
                        }
                    ]
                }
            });
        }
    }
};
</script>
