<template>
    <svg
        t="1574154266411"
        class="icon"
        viewBox="0 0 1024 1024"
        version="1.1"
        p-id="3759"
        :width="width"
        :height="height"
    >
        <path
            :style="{ fill: color }"
            d="M1024 512v-5.389474c0-21.557895-18.863158-40.421053-40.421053-40.421052h-889.263158c-21.557895 0-40.421053 18.863158-40.421052 40.421052v5.389474c0 21.557895 18.863158 40.421053 40.421052 40.421053h891.957895c18.863158-2.694737 37.726316-18.863158 37.726316-40.421053z"
            fill="#007AFF"
            p-id="3760"
        />
        <path
            :style="{ fill: color }"
            d="M188.631579 719.494737c-10.778947 0-21.557895-2.694737-29.642105-10.778948l-132.042106-134.736842c-37.726316-37.726316-37.726316-97.010526 0-132.042105l134.736843-132.042105c16.168421-16.168421 40.421053-16.168421 56.589473 0 16.168421 16.168421 16.168421 40.421053 0 56.589474l-132.042105 132.042105c-5.389474 2.694737-5.389474 8.084211-5.389474 10.778947s0 5.389474 2.694737 10.778948l132.042105 134.736842c16.168421 16.168421 16.168421 40.421053 0 56.589473-5.389474 5.389474-16.168421 8.084211-26.947368 8.084211zM1024 94.315789c0 21.557895-18.863158 40.421053-40.421053 40.421053h-943.157894C18.863158 134.736842 0 115.873684 0 94.315789S18.863158 53.894737 40.421053 53.894737h943.157894c21.557895 0 40.421053 18.863158 40.421053 40.421052zM1024 929.684211c0 21.557895-18.863158 40.421053-40.421053 40.421052h-943.157894C18.863158 970.105263 0 951.242105 0 929.684211S18.863158 889.263158 40.421053 889.263158h943.157894c21.557895 0 40.421053 18.863158 40.421053 40.421053z"
            fill="#007AFF"
            p-id="3761"
        />
    </svg>
</template>

<script>
import baseSvgImg from './baseSvgImg';
export default { extends: baseSvgImg };
</script>

<style scoped>
svg {
    display: block;
}
</style>
