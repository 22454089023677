<template>
    <div
        class="field"
        :class="[field.contentAlign]"
        @keydown.capture="keyDownEvent($event)"
        @click.stop="cellClickEvent"
        :style="cellStyle"
    >
        <div
            v-if="editor"
            class="editor"
            :key="field.field"
            :is="tagName"
            :options="field"
            :validateArg="validateArg"
            :rowIndex="rowIndex"
            :cellIndex="colIndex"
            :propValue="fieldValue"
            ref="input"
            :propReadonly="editorReadonly"
            :propInvisible="false"
            :inputStyle="inputStyle"
            @change="editorChangeEvent"
            @focus="editorFocusEvent"
            @saveCoordinate="editorNoInvisibleClickEvent"
            @afterChoose="editorAfterChooseEvent"
            @rendered="editorRenderedEvent"
        ></div>
        <template v-else>
            <a
                class="noEditor"
                v-if="field.type === 'link'"
                href="javascript:"
                :class="linkClass"
                v-html="fieldValue"
                :key="field.field"
                :title="getFormatterText"
            ></a>
            <div
                class="noEditor"
                v-else-if="field.type === 'select'"
                :title="getSelectTextTitle"
                v-html="getSelectText"
                :key="field.field"
            ></div>
            <richTextField
                v-else-if="field.type === 'richText'"
                :propReadonly="true"
                :options="{ field: field }"
                :propValue="fieldValue"
                :rowIndex="rowIndex"
                :key="field.field"
            ></richTextField>
            <div
                class="noEditor"
                v-else-if="field.type === 'checkbox'"
                :title="getCheckboxTextTitle"
                v-html="getCheckboxText"
                :key="field.field"
            ></div>
            <div class="cell-icon" v-else-if="field.iconFormatter" :class="iconFormatterClass" :key="field.field"></div>
            <cellTagField
                v-else-if="field.tagFormatter"
                :options="tagFormatterOptions"
                :key="field.field"
            ></cellTagField>
            <textAddonField
                v-else-if="addon"
                :key="field.field"
                :options="field"
                :validateArg="validateArg"
                :rowIndex="rowIndex"
                :cellIndex="colIndex"
                :propValue="fieldValue"
                :propReadonly="editorReadonly"
                :style="cellStyle"
                :propInvisible="true"
            ></textAddonField>
            <div v-else class="noEditor" :key="field.field" :title="getFormatterText" v-html="getFormatterText"></div>
        </template>
    </div>
</template>

<script>
import Gikam from 'gikam';
import { mapGetters, mapMutations, mapState } from 'vuex';

// eslint-disable-next-line complexity
const fieldFunciton = function() {
    this.renderEditor = true;
    this.field = Gikam.deepExtend(this.propsFeild);
    if (this.propsFeild.onBeforeEditorRender) {
        //计算field
        const editorRenderRes = this.propsFeild.onBeforeEditorRender.call(
            null,
            this.propsRow,
            this.rowIndex,
            this.field.field
        );

        if (editorRenderRes && editorRenderRes.constructor === Object) {
            for (const key in editorRenderRes) {
                this.field[key] = editorRenderRes[key];
            }
        }

        if (editorRenderRes === false) {
            this.renderEditor = false;
        }

        if (this.$store.getters.hasAudit === true && editorRenderRes.type === 'select') {
            !this.propsFeild.auditCategory && (this.propsFeild.auditCategory = editorRenderRes.category);
        }
    }

    this.editorReadonly = this.field.readonly;
    if (this.field.onEditorInitReadonly) {
        const initReadonly = this.field.onEditorInitReadonly(this.propsRow);
        this.editorReadonly = initReadonly;
    }

    this.cellStyle = {};
    if (this.field.cellStyle) {
        this.cellStyle = this.field.cellStyle(this.propsRow);
    }
    if (this.field.textStyleFormatter) {
        this.inputStyle = this.field.textStyleFormatter(Gikam.deepExtend(this.propsRow));
    }
};

const dataFuncion = function() {
    this.fieldValue = this.propsFeild.value;
    const dataValue = Gikam.getFieldValue(this.propsRow, this.field.field);
    if (dataValue) {
        this.fieldValue = dataValue;
    }
};

export default {
    props: {
        propsFeild: {
            type: Object,
            required: true
        },
        propsRow: {
            type: Object,
            required: true
        },
        rowIndex: Number,
        colIndex: Number
    },

    created() {
        fieldFunciton.call(this);
        dataFuncion.call(this);
    },

    data() {
        return {
            field: this.propsFeild,
            renderEditor: true,
            fieldValue: this.propsFeild.value,
            editorReadonly: this.propsFeild.readonly,
            validateArg: Gikam.deepExtend(this.propsRow),
            cellStyle: {},
            inputStyle: {}
        };
    },
 
    inject: ['triggerEvent', 'autoSave', 'grid', 'bindGridHook'],

    computed: {
        ...mapGetters(['fieldChangeFlag', 'leafColumns', 'hasAudit']),
        ...mapGetters('moveCursor/', ['currentCell']),
        ...mapState(['canAutoSave', 'data', 'isMoving']),
        ...mapState('moveCursor/', ['keyDownField', 'moveDirectionCode', 'startCell']),
        maxCol() {
            return this.leafColumns.length - 1;
        },
        minCol() {
            return this.leafColumns.filter(col => !col.field).length - 1;
        },
        maxRow() {
            return this.data.length - 1;
        },
        cell() {
            return this.rowIndex + '_' + this.colIndex;
        },
        editor() {
            return Gikam.isTrue(this.field.editor) && Gikam.isTrue(this.renderEditor);
        },
        //编辑态的标签名
        tagName() {
            if (!this.editor) {
                return '';
            } else {
                return (this.field.type || 'text') + 'Field';
            }
        },
        //select未去除标签的 text
        getSelectText() {
            if (this.field.type !== 'select') {
                return '';
            }
            let value;
            if (this.field.multiple && this.fieldValue.indexOf(',') > -1) {
                value = this.fieldValue.split(',');
            } else {
                value = [this.fieldValue];
            }

            let selectText = '';
            Gikam.each(this.field.items, (index, item) => {
                if (value.indexOf(item.value) > -1 || this.propsFeild.selectedIndex === index) {
                    const text = this.field.formatter
                        ? this.field.formatter(this.rowIndex, item.value, this.propsRow, item.text)
                        : item.text;
                    selectText += ',' + text;
                }
            });

            return selectText.slice(1);
        },
        //select去除标签的 text
        getSelectTextTitle() {
            if (this.field.type !== 'select') {
                return '';
            }
            return Gikam.getTextIgnoreHtml(this.getSelectText);
        },
        //Checkbox未去除标签的 text
        getCheckboxText() {
            if (this.field.type !== 'checkbox') {
                return '';
            } else {
                const value = this.fieldValue.split(',');
                let selectText = '';
                Gikam.each(this.field.items, (index, item) => {
                    if (value.indexOf(item.value) > -1) {
                        selectText += ',' + item.text;
                    }
                });
                return selectText.slice(1);
            }
        },
        //Checkbox去除标签的 text
        getCheckboxTextTitle() {
            if (this.field.type !== 'checkbox') {
                return '';
            }
            return Gikam.getTextIgnoreHtml(this.getCheckboxText);
        },
        //字段iconFormatter方法结果
        iconFormatterClass() {
            return this.field.iconFormatter(this.propsRow) || '';
        },
        //字段tagFormatter方法结果
        tagFormatterOptions() {
            return this.field.tagFormatter ? this.field.tagFormatter(this.propsRow) : { type: '' };
        },
        //tagFormatter结果class
        linkClass() {
            return this.tagFormatterOptions.type;
        },
        //字段formatter方法结果
        getFormatterText() {
            if (this.field.formatter) {
                return this.field.formatter(this.rowIndex, this.fieldValue, this.propsRow);
            } else {
                return this.fieldValue;
            }
        },

        addon() {
            return this.field.addon;
        }
    },

    watch: {
        currentCell(val) {
            if (val !== this.cell) {
                return;
            }
            if (this._isFocus) {
                this._isFocus = false;
                return;
            }
            if (val === this.startCell) {
                return;
            }
            if (
                (this.field.type && this.keyDownField.indexOf(this.field.type) < 0) ||
                this.editorReadonly === true ||
                !this.editor
            ) {
                return this.nextRowCell(val);
            }
            if (this.$refs.input) {
                this.$refs.input.activeCell();
                this.$refs.input.$nextTick(() => {
                    this.changeIsMoving(false);
                });
            } else {
                this.changeIsMoving(false);
            }
        },

        propsFeild() {
            fieldFunciton.call(this);
        },

        propsRow() {
            dataFuncion.call(this);
        }
    },

    methods: {
        ...mapMutations('gridCell', ['changeActiveCellVue']),
        ...mapMutations('gridBody/', ['changeActiveRowIndex']),
        ...mapMutations('moveCursor/', [
            'changeCurrentCellRow',
            'changeCurrentCellCol',
            'changeMoveDirectionCode',
            'changeIsMoving',
            'changeStartCell'
        ]),

        //组件内事件
        keyDownEvent(e, code) {
            if (this.isMoving) {
                return;
            }
            this.changeStartCell(this.rowIndex + '_' + this.colIndex);
            if ([38, 40].indexOf(e.code) > -1) {
                e.preventDefault();
            }
            this.changeMoveDirectionCode(e.keyCode);
            let rowIndex = this.rowIndex;
            switch (code || e.keyCode) {
                case 38:
                    if (this.rowIndex === 0) {
                        rowIndex = this.maxRow;
                    } else {
                        rowIndex = this.rowIndex - 1;
                    }
                    break;
                case 40:
                    if (this.rowIndex === this.maxRow) {
                        rowIndex = 0;
                    } else {
                        rowIndex = this.rowIndex - 1 + 2;
                    }
                    break;
                default:
                    return;
            }

            if (rowIndex !== this.rowIndex) {
                this.changeIsMoving(true);
                this.changeCurrentCellRow(rowIndex);
            }
        },

        nextRowCell(cellIndex) {
            let currentRow = parseInt(cellIndex.split('_')[0]);
            if (this.moveDirectionCode === 40) {
                currentRow = currentRow + 1;
            } else if (this.moveDirectionCode === 38) {
                currentRow = currentRow - 1;
            }

            if (currentRow >= this.maxRow) {
                currentRow = 0;
            } else if (this.rowIndex <= 0) {
                currentRow = this.maxRow;
            }
            this.changeCurrentCellRow(currentRow);
        },

        cellClickEvent() {
            this._isFocus = true;
            //记忆单元格
            this.changeCurrentCellRow(this.rowIndex);
            this.changeCurrentCellCol(this.colIndex);

            //激活行
            this.changeActiveRowIndex({ index: this.rowIndex, type: 'cell' });

            //记忆上次操作的单元格
            if (this.editor) {
                this.changeActiveCellVue(this.$refs.input);
            } else {
                this.changeActiveCellVue({
                    field: this.propsFeild.field,
                    rowIndex: this.rowIndex
                });
            }

            //执行外部单元格事件
            this.triggerEvent('cellClick', this.field.field, this.propsRow, this.rowIndex);

            //向上冒出点击事件
            this.$emit('activeRowEvent');
        },

        returnEditorChildren() {
            return this.editor ? this.$refs.input : null;
        },

        //传入事件
        editorChangeEvent(...args) {
            Gikam.setFieldValue(this.propsRow, args[0], args[1]);
            if (this.field.onChange && this.fieldChangeFlag) {
                this.bindGridHook(this.field.onChange)(...args);
            }
            if (this.canAutoSave) {
                const data = { id: this.propsRow.id, row: this.propsRow };
                data[args[0]] = args[1];
                this.autoSave(data);
            }
        },

        editorFocusEvent(...args) {
            if (this.field.onFocus) {
                this.bindGridHook(this.field.onFocus)(...args);
            }
        },

        editorNoInvisibleClickEvent() {},

        editorAfterChooseEvent(...args) {
            if (this.field.onAfterChoose) {
                this.bindGridHook(this.field.onAfterChoose)(...args);
            }
        },

        editorRenderedEvent() {},

        getCellComp() {
            if (this.$refs.input) {
                const cell = Object.create(null);
                cell.$vue = this.$refs.input;
                cell.value = this.$refs.input.value;
                cell.getValue = function() {
                    return this.$vue.value;
                };
                cell.setValue = function(value) {
                    this.$vue.value = value;
                };
                return cell;
            } else {
                return null;
            }
        }
    }
};
</script>

<style scoped lang="scss">
.field {
    padding: 2px 5px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.field.marge * {
    display: flex;
    align-items: center;
}

.field.alignLeft {
    text-align: left;
}

.field.alignCenter {
    text-align: center;
}

.field.alignRight {
    text-align: right;
}

.field > .noEditor {
    position: absolute;
    top: 2px;
    bottom: 2px;
    left: 5px;
    right: 5px;
    font-size: 12px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.65);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: inherit;
    &.hasAddon {
        display: flex;
        align-items: center;
        .addon {
            width: 30px;
        }
        .text {
            flex: 1;
        }
    }
}
.field > .editor {
    min-height: 24px;
}

.none {
    display: none;
}

.field a {
    text-decoration: none;
    color: #007aff !important;
}
</style>
