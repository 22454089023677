<template>
    <svg
        version="1.1"
        id="图层_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        :width="width"
        :height="height"
        viewBox="0 0 42 42"
        style="enable-background:new 0 0 42 42;"
        xml:space="preserve"
    >
        <path
            id="XMLID_2696_"
            class="st0"
            :fill="color"
            d="M35,40.3H6c-2.5,0-4.5-2-4.5-4.5v-29c0-2.5,2-4.5,4.5-4.5h29c2.5,0,4.5,2,4.5,4.5v29
  C39.5,38.3,37.5,40.3,35,40.3z M6,5.3C5.2,5.3,4.5,6,4.5,6.8v29c0,0.8,0.7,1.5,1.5,1.5h29c0.8,0,1.5-0.7,1.5-1.5v-29
  c0-0.8-0.7-1.5-1.5-1.5H6z"
        />
    </svg>
</template>
<script>
import baseSvgImg from './baseSvgImg';
export default {
    extends: baseSvgImg,
    props: {
        color: {
            type: String,
            default: '#007aff'
        }
    }
};
</script>
<style scoped>
svg {
    display: block;
}
</style>
