<template>
    <div class="operation">
        <div class="table-button" :style="{ right: areaButton }">
            <template v-for="(item, index) in toolbarList">
                <btn
                    v-if="item.type === 'button'"
                    v-show="item.show !== false"
                    :options="item"
                    :index="index"
                    :key="item.type + index"
                    memory
                    @memoryWidth="memoryWidth"
                    @hiddenChangeEvent="hiddenChangehandler"
                ></btn>
                <field-editor
                    v-else
                    :is="item.type + 'Field'"
                    :options="item"
                    :alone="true"
                    :key="item.type + index"
                ></field-editor>
            </template>
        </div>
        <div
            class="table-more-button"
            :class="{ openMore: openMore }"
            v-if="hasMore"
            ref="more"
            @mouseenter="openMoreList"
        >
            <span class="icon icon-btn-more"></span> <span>{{ $i18n('grid.toolbar.more') }}</span>
        </div>
    </div>
</template>

<script>
import Gikam from 'gikam';

import { mapGetters, mapState } from 'vuex';
export default {
    props: {},

    data() {
        return {
            widthObj: {},
            widthArr: [],
            show: false,
            moreList: [],
            elWidth: -1,
            openMore: false
        };
    },

    computed: {
        ...mapGetters(['toolbarList', 'tableId']),
        ...mapState(['showTable']),
        ...mapState('tool', ['buttonIsInit']),
        areaButton() {
            return this.hasMore ? '70px' : '0';
        },

        hasMore() {
            return this.moreList.length > 0 ? true : false;
        }
    },

    components: {
        btn: () => import('@/gikam/js/components/template/button.vue')
    },

    watch: {
        elWidth(val, oldVal) {
            if (val < 10) {
                return;
            }
            if (val > oldVal && this.moreList.length <= 0 && oldVal !== -1) {
                return;
            }

            this.$store.commit('tool/changeStore', {
                key: 'buttonIsInit',
                value: true
            });
            this.initLists(val);
        },

        showTable(val) {
            if (val && this.buttonIsInit === false) {
                this.elWidth = this.$el.offsetWidth;
            }
        }
    },

    methods: {
        memoryWidth(index, width) {
            this.widthObj[index] = width + 4 + 5;
        },

        hiddenChangehandler(val, buttonVue) {
            const index = buttonVue.index;
            const width = buttonVue.$el.offsetWidth + 4 + 5;
            this.widthObj[index] = width;
            this.widthArr.splice(index, 1, width);
            this.initLists(this.elWidth);
        },

        onResize() {
            Gikam.finalDelay(
                'calcResultButton' + this.tableId,
                () => {
                    this.elWidth = this.$el.offsetWidth;
                },
                200
            );
        },

        initLists(elWidth) {
            let _width = 0;
            const maxWidth = elWidth - 70;
            this.widthArr.forEach((width, index) => {
                _width += width;
                if (_width > maxWidth) {
                    this.toolbarList[index].show = false;
                } else {
                    this.toolbarList[index].show = true;
                }
            });
            this.moreList = this.toolbarList.filter(item => item.show === false);
        },

        openMoreList() {
            this.openMore = true;
            const _this = this;
            const target = this.$refs.more;
            this.morePanel = new Gikam.Vue({
                el: Gikam.createDom('div', document.body),
                store: this.$store,
                components: {
                    operationMore: () => import('./operationMore')
                },
                render() {
                    return (
                        <operation-more
                            ref={'vm'}
                            targetDom={target}
                            list={_this.moreList}
                            oncleanHandler={_this.cleanHandler.bind(this)}
                        ></operation-more>
                    );
                }
            });
        },

        cleanHandler() {
            this.openMore = false;
        },

        cleanMoreList() {
            this.morePanel && this.morePanel.$refs.vm.clean();
        },

        getArrayWidthLists() {
            const len = this.toolbarList.length;
            for (let i = 0; i < len; i++) {
                const w = this.widthObj[i + ''] || 0;
                this.widthArr.push(w);
            }
        }
    },

    mounted() {
        setTimeout(() => {
            this.getArrayWidthLists();
            this.elWidth = this.$el.offsetWidth;
        });
    }
};
</script>

<style scoped lang="scss">
.operation {
    position: relative;
}

.table-button {
    overflow: hidden;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;

    .select {
        display: inline-block;
        vertical-align: top;
        width: auto;

        ::v-deep .select-container {
            height: 24px;
        }
    }
}

.btn-container {
    margin-right: 5px;
    margin-bottom: 10px;
}

.table-more-button {
    position: absolute;
    top: 0;
    right: 10px;
    height: 24px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 12px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    padding: 5px 16px 5px 5px;
    cursor: pointer;
}

.table-more-button::after {
    content: '';
    border-bottom: 6px solid #999;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    position: absolute;
    right: 4px;
    top: 8px;
    transition: transform 0.5s;
    transform: rotate(180deg);
    transform-origin: center;
}

.table-more-button:hover::after {
    transform: rotate(0);
}

.table-more-button.openMore::after {
    transform: rotate(0);
}

.table-more-button .icon-btn-more {
    width: 20px;
}
</style>
