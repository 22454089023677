<template>
    <svg
        :width="width"
        :height="height"
        viewBox="0 0 14 14"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <title>喇叭</title>
        <g id="下拉菜单" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g transform="translate(-88.000000, -843.000000)" id="喇叭">
                <g transform="translate(88.000000, 843.000000)">
                    <rect id="矩形" x="0" y="0" width="16" height="16"></rect>
                    <g transform="translate(0.000000, 1.000000)" :fill="color" fill-rule="nonzero">
                        <path
                            d="M7.38181818,11.9 C7.22272727,11.9 7.06363636,11.8681818 6.90454545,11.8045455 L3.05454545,9.95909091 C2.70454545,9.8 2.29090909,9.70454545 1.90909091,9.70454545 C0.986363636,9.70454545 0.222727273,8.94090909 0.222727273,8.01818182 L0.222727273,4.07272727 C0.222727273,3.15 0.986363636,2.38636364 1.90909091,2.38636364 C2.29090909,2.38636364 2.70454545,2.29090909 3.05454545,2.13181818 L6.90454545,0.318181818 C7.25454545,0.159090909 7.63636364,0.190909091 7.98636364,0.381818182 C8.30454545,0.572727273 8.49545455,0.954545455 8.49545455,1.33636364 L8.49545455,10.7863636 C8.49545455,11.1681818 8.30454545,11.5181818 7.98636364,11.7409091 C7.79545455,11.8363636 7.60454545,11.9 7.38181818,11.9 Z M7.38181818,1.14545455 C7.35,1.14545455 7.35,1.14545455 7.31818182,1.14545455 L3.46818182,2.95909091 C2.99090909,3.18181818 2.45,3.30909091 1.90909091,3.30909091 C1.52727273,3.30909091 1.17727273,3.62727273 1.17727273,4.04090909 L1.17727273,8.01818182 C1.17727273,8.4 1.49545455,8.75 1.90909091,8.75 C2.45,8.75 2.99090909,8.87727273 3.46818182,9.1 L7.31818182,10.9136364 C7.38181818,10.9454545 7.44545455,10.9136364 7.47727273,10.9136364 C7.50909091,10.8818182 7.54090909,10.85 7.54090909,10.7863636 L7.54090909,1.33636364 C7.54090909,1.27272727 7.50909091,1.20909091 7.47727273,1.20909091 C7.44545455,1.17727273 7.41363636,1.14545455 7.38181818,1.14545455 Z"
                            id="形状"
                        ></path>
                        <path
                            d="M9.48181818,9.48181818 C9.29090909,9.48181818 9.13181818,9.38636364 9.03636364,9.19545455 C8.94090909,8.94090909 9.03636364,8.68636364 9.29090909,8.55909091 C10.2772727,8.11363636 10.9454545,7.12727273 10.9454545,6.04545455 C10.9454545,4.96363636 10.3090909,3.97727273 9.29090909,3.53181818 C9.03636364,3.43636364 8.94090909,3.15 9.03636364,2.89545455 C9.13181818,2.64090909 9.41818182,2.54545455 9.67272727,2.64090909 C11.0409091,3.21363636 11.9,4.55 11.9,6.01363636 C11.9,7.47727273 11.0409091,8.81363636 9.67272727,9.38636364 C9.60909091,9.45 9.54545455,9.48181818 9.48181818,9.48181818 Z"
                            id="路径"
                        ></path>
                        <path
                            d="M10.8181818,10.8818182 C10.6590909,10.8818182 10.5,10.7863636 10.4045455,10.6590909 C10.2772727,10.4363636 10.3409091,10.15 10.5636364,9.99090909 C11.9636364,9.16363636 12.7909091,7.66818182 12.7909091,6.04545455 C12.7909091,4.42272727 11.9636364,2.92727273 10.5636364,2.1 C10.3409091,1.97272727 10.2772727,1.68636364 10.4045455,1.43181818 C10.5318182,1.20909091 10.8181818,1.14545455 11.0727273,1.27272727 C12.7272727,2.25909091 13.7772727,4.10454545 13.7772727,6.04545455 C13.7772727,7.98636364 12.7272727,9.83181818 11.0727273,10.8181818 C10.9772727,10.8818182 10.9136364,10.8818182 10.8181818,10.8818182 Z"
                            id="路径"
                        ></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
import baseSvgImg from './baseSvgImg';
export default {
    extends: baseSvgImg,
    props: {
        color: {
            type: String,
            default: '#007AFF'
        }
    }
};
</script>
