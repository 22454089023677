<template>
    <svg
        version="1.1"
        id="图层_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        :width="width"
        :height="height"
        viewBox="0 0 40 40"
        style="enable-background:new 0 0 40 40;"
        xml:space="preserve"
    >
        <path
            :fill="color"
            d="M37.5,11.2h-35c-0.8,0-1.5,0.7-1.5,1.5v0c0,0.8,0.7,1.5,1.5,1.5h35c0.8,0,1.5-0.7,1.5-1.5v0
	C39,11.9,38.3,11.2,37.5,11.2z M38,19.9L37,21l0,0L22.1,35.8L20,38l-1.1-1.1l-1.1-1.1L3,21L2,19.9c-0.6-0.6-0.6-1.5,0-2.1
	s1.5-0.6,2.1,0l1.1,1.1L20,33.7l14.8-14.8l1.1-1.1c0.6-0.6,1.5-0.6,2.1,0S38.6,19.4,38,19.9z M37.5,3.7h-35C1.7,3.7,1,4.4,1,5.2v0
	C1,6,1.7,6.7,2.5,6.7h35C38.3,6.7,39,6,39,5.2v0C39,4.4,38.3,3.7,37.5,3.7z"
        />
    </svg>
</template>

<script>
export default {
    props: {
        color: {
            type: String,
            default: '#fff'
        },
        width: {
            type: String,
            default: '18px'
        },
        height: {
            type: String,
            default: '18px'
        }
    }
};
</script>
