import lesPage from './lesPage';
import Base from '@/gikam/js/components/base.js';
import Gikam from 'gikam';

let appendComps = [];
let sectionButtons = {};
export default class Les extends Base {
    constructor(options) {
        super();
        options.appendComps = appendComps;
        options.sectionButtons = sectionButtons;
        this.initialize(options, {});

        if (options.instance) {
            const ids = this.options.id.split(',');
            if (ids.length === 1) {
                return new lesPage(options);
            }
            this.creatInstance();
        } else {
            new lesPage(options);
        }
    }

    async creatInstance() {
        const toolbarDom = this.$parent.$toolbar;
        if (!toolbarDom) {
            return;
        }
        toolbarDom.style.height = '30px';

        const layout = Gikam.create('layout', {
            renderTo: this.options.renderTo,
            west: {
                width: 150,
                items: [{ type: 'I18N' }]
            }
        });
        await layout.model.$nextTick();

        Gikam.create('grid', {
            renderTo: layout.options.west.$dom,
            type: 'grid',
            height: '100%',
            page: false,
            toolbar: [],
            genericQuery: false,
            columnsFill: true,
            filter: false,
            filterOpen: false,
            generalButtonGroup: false,
            url: Gikam.IFM_CONTEXT + '/secure/core/les/les-instances/queries',
            requestData: {
                id_IN: this.options.id
            },
            columns: [
                {
                    field: 'instanceName',
                    title: '实例名称',
                    sort: false
                }
            ],
            onRendered() {
                Gikam.jQuery('.grid.columns-fill')[0].style.padding = '3px';
            },
            onRowActive: (index, row) => {
                toolbarDom.innerHTML = '';
                layout.options.center.$dom.innerHTML = '';

                const _row = Gikam.deepExtend(this.options, row);
                _row.renderTo = layout.options.center.$dom;
                _row.$parent_toolbar = toolbarDom;
                this.les = new lesPage(_row);
            },
            onLoadSuccess(rows) {
                rows && rows.length > 0 && this.activeRowByIndex(0);
            }
        });
    }

    static appendComps(comps) {
        appendComps = appendComps.concat(comps);
    }

    static cleanComps() {
        appendComps = [];
    }

    static appendSectionButtons(buttons) {
        buttons.forEach(obj => {
            const unitCategory = obj.unitCategory;
            if (sectionButtons[unitCategory]) {
                sectionButtons[unitCategory].push(obj);
            } else {
                sectionButtons[unitCategory] = [obj];
            }
        });
    }
}
