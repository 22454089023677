import Base from './base.js';
import Gikam from "../core/gikam-core";
import Vue from 'vue';
import iconPanel from './template/iconPanel/iconPanel.vue';

// 菜单图标配置组件
let defaultOptions = {
    title: '图标配置',
    data: [],
    onClick: void 0,
    width: void 0,
    height: void 0,
    onRendered: void 0, // 组件渲染完成
    onConfirm: void 0, // 点击确定
    onCancel: void 0, // 点击取消
};

export default class IconPanel extends Base {
    constructor(options) {
        super(options);
        this.initialize(options, defaultOptions).init();
    }

    initModal() {
        const result = Gikam.createSimpleModal({
            title: this.options.title,
            width: 898,
            height: 400,
            onConfirm: () => this.confirm(),
            onCancel: () => this.cancel()
        });
        this.modal = result.modal;
        this.layout = result.layout;
    }

    confirm() {
        let panelObj = this.iconsPanel.$refs.iconPanel;
        this.options.onConfirm && this.options.onConfirm(panelObj.activeNode);
    }

    cancel() {
        let panelObj = this.iconsPanel.$refs.iconPanel;
        this.options.onCancel && this.options.onCancel(panelObj.activeNode);
        this.modal.close();
    }

    initPanel () {
        const _this = this;
        this.iconsPanel = new Vue({
            el: Gikam.createDom('div', this.layout.options.center.$dom),
            render () {
                return <icon-panel ref="iconPanel" options={this.options}></icon-panel>
            },
            components: { iconPanel },
            data () {
                return {
                    options: _this.options
                };
            }
        });
        this.bindInstance(this.iconsPanel.$el);
    }

    /**
     * 动态填充数据
     * @public
     * @param { Array } list -图标数组
     * @memberof IconPanel
     */
    setData (data) {
        this.options.data = data;
    }

    /**
     * 选中事件
     * @public
     * @param { String } nodeId 图标id
     * @memberof IconPanel
     */
    activeIcon(id) {
        this.iconsPanel.$refs.iconPanel.activeIconHandle(id);
    }
    

    init () {
        this.initModal();
        this.initPanel();
    }
}