import Base from '../../base.js';
import Vue from 'vue';

const defaultOptions = {

};


export default class ErrorPage extends Base {

    constructor(props) {
        super(props);

        this.initialize(props, defaultOptions).init();
    }

    createModel() {
        const _this = this;
        return new Vue({
            el: this.options.renderTo,
            components: { errorPage: () => import('../vue') },
            render() {
                return <errorPage options={_this.options} />
            }
        });
    }

    init() {
        this.model = this.createModel();
        this.bindInstance(this.model.$el);
    }
}
