<template>
    <div class="window" v-show="!hidden" :class="options.isEnterFullScreen ? 'full-screen-window' : ''">
        <header v-if="toolbarTop" v-show="headerShow">
            <div class="widow-title" v-if="options.showTitle && options.title">
                <div class="title">{{ title }}</div>
                <div class="sub-title" v-if="subTitle" :title="subTitle">/{{ subTitle }}</div>
            </div>
            <div class="toolbar" ref="toolbar" :class="toolbarAlign"></div>
        </header>
        <section>
            <div class="body" ref="body"></div>
        </section>
        <footer v-if="!toolbarTop">
            <div class="toolbar right" ref="toolbar"></div>
        </footer>

        <!--页面加载中-->
        <div v-if="pageLoading" class="window-loading page">
            <div class="window-loading-container"><img :src="loadingImage" />{{ $i18n('window.pageLoading') }}</div>
        </div>

        <!--数据请求中-->
        <div v-if="dataLoading" class="window-loading data">
            <div class="window-loading-container"><img :src="loadingImage" />{{ $i18n('window.dataLoading') }}</div>
        </div>
        <div
            class="exit-full-screen"
            v-show="options.isEnterFullScreen"
            @dblclick="exitFullScreen()"
            :title="$i18n('window.exitFullScreen')"
        >
            <exitFullScreenImg :width="'18px'" :height="'18px'"></exitFullScreenImg>
        </div>
    </div>
</template>

<script>
import loadingImage from '../../../img/loading.gif';

export default {
    props: {
        options: Object
    },

    data() {
        return {
            title: void 0,
            subTitle: void 0,
            pageLoading: false,
            dataLoading: false,
            loadingImage: loadingImage,
            toolbarTop: true,
            headerShow: true,
            toolbarAlign: this.options.toolbarAlign,
            hidden: false
        };
    },

    watch: {
        'options.subTitle'(val) {
            val && (this.subTitle = val);
        }
    },

    mounted() {
        let box = document.getElementsByClassName('exit-full-screen')[0];
        let disX;
        let disY;
        box.onmousedown = function(ev) {
            var oEvent = ev || event;
            disX = oEvent.clientX - box.offsetLeft;
            disY = oEvent.clientY - box.offsetTop;
            document.onmousemove = function(ev) {
                var oEvent = ev || event;
                var l = oEvent.clientX - disX;
                var t = oEvent.clientY - disY;
                if (l < 0) {
                    l = 0;
                } else if (l > document.documentElement.clientWidth - box.offsetWidth) {
                    l = document.documentElement.clientWidth - box.offsetWidth;
                }
                if (t < 0) {
                    t = 0;
                } else if (t > document.documentElement.clientHeight - box.offsetHeight) {
                    t = document.documentElement.clientHeight - box.offsetHeight;
                }
                box.style.left = l + 'px';
                box.style.top = t + 'px';
            };
            document.onmouseup = function() {
                document.onmousemove = null;
                document.onmouseup = null;
            };
            return false;
        };
    },

    methods: {
        hide() {
            this.hidden = true;
        },
        show() {
            this.hidden = false;
        },
        exitFullScreen() {
            this.options.isEnterFullScreen = false;
        }
    },

    created() {
        Object.assign(this, this.options);
    }
};
</script>

<style scoped>
.window {
    height: 100%;
    width: 100%;
    flex-direction: column;
    display: flex;
    position: relative;
}

.window.full-screen-window {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #ffffff;
    z-index: 9;
}

.window .exit-full-screen {
    width: 32px;
    height: 32px;
    position: absolute;
    top: 4px;
    right: 40px;
    background: #27986d;
    border-radius: 50%;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

header {
    border-bottom: 1px solid #eee;
    display: flex;
    align-items: center;
    background-color: #ffffff;
}

/* header - title */
header > .widow-title {
    padding: 0 15px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 4px 0;
}

header > .widow-title > .title {
    font-size: 16px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.85);
    display: inline;
}

header > .widow-title > .sub-title {
    font-size: 14px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.6);
    display: inline;
    margin-left: 8px;
}

/* header - toolbar */
header > .toolbar,
footer > .toolbar {
    flex: 1;
    display: flex;
    padding-right: 8px;
}

header > .toolbar >>> .btn-toolbar {
    margin: 2px 0 3px;
}

header > .toolbar.toolbar-left > .btn-toolbar > .items:first-child {
    margin-left: 0;
}

.toolbar.left {
    justify-content: flex-start;
}

.toolbar.center {
    justify-content: center;
}

.toolbar.right {
    justify-content: flex-end;
}

/* 控制type:button的margin ；type:btnGroup 内的button不控制 */
header > .toolbar >>> .items > .btn-container {
    margin: 0 !important;
}

/* 控制type:button的宽度；由子节点撑开 */
header > .toolbar >>> .items > .btn-group {
    display: flex;
    align-content: center;
}

header > .toolbar >>> .button {
    height: 24px !important;
}

.window > section {
    flex: 1;
    overflow: hidden;
}

.window > section > .body {
    height: 100%;
    width: 100%;
}

footer {
    height: 60px;
    padding: 0 16px;
    display: flex;
    align-items: center;
}

.window .window-loading {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
    z-index: 10;
}

.window .window-loading.page {
    background-color: #fff;
}

.window .window-loading .window-loading-container {
    padding: 20px;
    border-radius: 4px;
    display: flex;
    align-items: center;
}

.window .window-loading .window-loading-container img {
    margin-right: 16px;
}
</style>
