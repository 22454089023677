<template>
    <svg
        version="1.1"
        id="图层_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 38 38"
        style="enable-background:new 0 0 38 38;"
        xml:space="preserve"
        :width="width"
        :height="height"
    >
        <g>
            <path
                class="st0"
                :fill="color"
                d="M18.1,36.1c-0.4,0-0.8-0.1-1.2-0.3c-0.8-0.4-1.2-1.1-1.3-2l-1.5-21.3c-0.1-1.3,0.5-2.5,1.7-3.1
		c1.1-0.6,2.5-0.6,3.5,0.1l17.7,12c0.7,0.5,1.1,1.3,1.1,2.1c0,0.9-0.5,1.7-1.4,2.2L36.3,26l-7.5,0.7c-0.8,0.1-1.6-0.5-1.6-1.4
		s0.5-1.6,1.4-1.6l5.5-0.5L17.6,12c-0.1-0.1-0.2-0.1-0.4,0c-0.1,0.1-0.2,0.2-0.2,0.3l1.4,19.9l3.4-4.7c0.5-0.7,1.4-0.8,2.1-0.3
		c0.7,0.5,0.8,1.4,0.3,2.1l-4.6,6.3l-0.3,0.2C19.1,36,18.6,36.1,18.1,36.1z"
            />
            <path
                :fill="color"
                d="M12.4,31h-9C1.5,31,0,29.5,0,27.6V3.4C0,1.5,1.5,0,3.4,0h30.2C35.5,0,37,1.5,37,3.4v12.9
		c0,0.8-0.7,1.5-1.5,1.5S34,17.1,34,16.2V3.4C34,3.2,33.8,3,33.6,3H3.4C3.2,3,3,3.2,3,3.4v24.3C3,27.8,3.2,28,3.4,28h9
		c0.8,0,1.5,0.7,1.5,1.5C13.9,30.3,13.3,31,12.4,31z"
            />
            <path
                :fill="color"
                d="M29.2,38c-1.3,0-2.6-0.7-3.4-2L22,29.4c-0.4-0.7-0.2-1.6,0.5-2c0.7-0.4,1.6-0.2,2,0.5l3.8,6.6
		c0.3,0.5,0.7,0.6,0.9,0.5l1.7-1c0.2-0.1,0.3-0.6,0-1l-3.8-6.6c-0.4-0.7-0.2-1.6,0.5-2c0.7-0.4,1.6-0.2,2,0.5l3.8,6.6
		c1.1,1.9,0.6,4.1-1.1,5.1l-1.7,1C30.3,37.9,29.8,38,29.2,38z"
            />
        </g>
    </svg>
</template>

<script>
import baseSvgImg from './baseSvgImg';
export default { extends: baseSvgImg };
</script>

<style scoped>
svg {
    display: block;
}
</style>
