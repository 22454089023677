<template>
    <svg
        t="1573115017201"
        class="icon"
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="5195"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        :width="width"
        :height="height"
    >
        <path
            :fill="color"
            d="M548.465493 551.765333h233.984a38.997333 38.997333 0 0 0 39.168-38.912 38.997333 38.997333 0 0 0-39.168-38.912H548.465493"
        ></path>
        <path
            :fill="color"
            d="M554.950827 473.941333H236.230827a38.997333 38.997333 0 0 0-39.168 38.912c0 21.504 17.578667 38.912 39.168 38.912h318.72"
        ></path>
        <path
            :fill="color"
            d="M814.449493 816.128a433.493333 433.493333 0 0 1-610.304 0 426.837333 426.837333 0 0 1 0-606.464 433.493333 433.493333 0 0 1 610.304 0 427.434667 427.434667 0 0 1 126.293334 303.189333 424.618667 424.618667 0 0 1-126.293334 303.274667m55.381334-661.504a511.914667 511.914667 0 0 0-720.725334 0.341333 504.064 504.064 0 0 0 0.341334 716.117334 511.914667 511.914667 0 0 0 720.384 0 504.917333 504.917333 0 0 0 0-716.458667"
        ></path>
    </svg>
</template>

<script>
import baseSvgImg from './baseSvgImg';
export default {
    extends: baseSvgImg,
    props: {
        color: {
            default: '#007AFF',
            type: String
        }
    }
};
</script>

<style scoped>
svg {
    display: block;
}
</style>
