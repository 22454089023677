<template>
    <svg
        viewBox="0 0 12 12"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        :width="width"
        :height="height"
    >
        <g id="文本框" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g
                id="文本输入框（横向）"
                transform="translate(-145.000000, -1767.000000)"
                :fill="color"
                fill-rule="nonzero"
            >
                <g
                    id="提示"
                    transform="translate(151.000000, 1773.000000) scale(1, -1) translate(-151.000000, -1773.000000) translate(145.000000, 1767.000000)"
                >
                    <path
                        d="M5.99753392,11.9802956 C2.68310728,11.9802956 0,9.30049261 0,5.99014778 C0,2.67980295 2.68310727,0 5.99753392,0 C9.31196057,0 11.9950678,2.67980295 11.9950678,5.99014778 C11.9950678,9.30049261 9.31196055,11.9802956 5.99753392,11.9802956 Z M5.99753392,0.945812808 C3.21972874,0.945812808 0.946979038,3.21576355 0.946979038,5.99014778 C0.946979038,8.76453201 3.21972873,11.0344827 5.99753392,11.0344827 C8.77533911,11.0344827 11.0480888,8.76453201 11.0480888,5.99014778 C11.0480888,3.21576355 8.77533909,0.945812808 5.99753392,0.945812808 L5.99753392,0.945812808 Z"
                        id="Shape"
                    ></path>
                    <path
                        d="M5.55758323,2.40591133 L6.44143033,2.40591133 C6.59926017,2.40591133 6.72552404,2.5320197 6.72552404,2.68965517 L6.50456227,7.4817734 C6.50456227,7.57635468 6.44143033,7.63940886 6.34673242,7.63940886 L5.6838471,7.63940886 C5.58914919,7.63940886 5.52601725,7.57635467 5.52601727,7.4817734 L5.30505548,2.68965517 C5.27348952,2.5320197 5.39975339,2.40591133 5.55758323,2.40591133 L5.55758323,2.40591133 Z"
                        id="Shape"
                    ></path>
                    <path
                        d="M5.27348951,9.24729064 C5.27345249,9.50637684 5.41182249,9.74579819 5.63646823,9.87535198 C5.86111396,10.0049058 6.1378996,10.0049058 6.36254533,9.87535198 C6.58719106,9.74579819 6.72556107,9.50637684 6.72552405,9.24729064 C6.72556107,8.98820443 6.58719106,8.74878308 6.36254533,8.6192293 C6.1378996,8.48967551 5.86111396,8.48967551 5.63646823,8.6192293 C5.41182249,8.74878308 5.27345249,8.98820443 5.27348951,9.24729064 L5.27348951,9.24729064 Z"
                        id="Shape"
                    ></path>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    props: {
        color: {
            type: String,
            default: '#333333'
        },
        width: {
            type: [Number, String],
            default: '12px'
        },
        height: {
            type: [Number, String],
            default: '12px'
        }
    }
};
</script>
