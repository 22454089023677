<template>
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 42 42"
        style="enable-background:new 0 0 42 42;"
        xml:space="preserve"
        :width="width"
        :height="height"
    >
        <path
            :fill="color"
            d="M19,3c4,0,7.3,3.3,7.3,7.3S23,17.5,19,17.5s-7.3-3.3-7.3-7.3S15,3,19,3 M19,0C13.3,0,8.8,4.6,8.8,10.3
            S13.3,20.5,19,20.5s10.3-4.6,10.3-10.3S24.7,0,19,0L19,0z"
        />
        <path
            :fill="color"
            d="M36.5,38c-0.8,0-1.5-0.7-1.5-1.5c0-8.8-7.2-16-16-16s-16,7.2-16,16C3,37.3,2.3,38,1.5,38S0,37.3,0,36.5
            c0-10.5,8.5-19,19-19s19,8.5,19,19C38,37.3,37.3,38,36.5,38z"
        />
    </svg>
</template>

<script>
import baseSvgImg from './baseSvgImg';
export default {
    extends: baseSvgImg,
    props: {
        color: {
            default: '#007AFF',
            type: String
        }
    }
};
</script>

<style scoped>
svg {
    display: block;
}
</style>
