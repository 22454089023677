<template>
    <svg
        version="1.1"
        id="图层_1"
        x="0px"
        y="0px"
        :width="width"
        :height="height"
        viewBox="0 0 38 38"
        style="enable-background:new 0 0 38 38;"
        xml:space="preserve"
    >
        <path
            class="st0"
            :style="{ fill: color }"
            d="M19,21c-1,0-2-0.2-2.8-0.6L1.7,12.9C0.6,12.4,0,11.5,0,10.5s0.6-1.9,1.7-2.4l14.5-7.4c1.6-0.8,4-0.8,5.7,0
            l14.5,7.4c1.1,0.6,1.7,1.4,1.7,2.4s-0.6,1.9-1.7,2.4l-14.5,7.4C21,20.8,20,21,19,21z M3.5,10.5l14,7.2c0.7,0.4,2.2,0.4,2.9,0l14-7.2
            l-14-7.2c-0.7-0.4-2.2-0.4-2.9,0L3.5,10.5z"
        />
        <path
            class="st0"
            :style="{ fill: color }"
            d="M19,29.3c-1.1,0-2.1-0.2-3-0.6L0.8,20.8c-0.7-0.4-1-1.3-0.6-2c0.4-0.7,1.3-1,2-0.6L17.4,26
            c0.8,0.4,2.3,0.4,3.2,0l15.3-7.9c0.7-0.4,1.6-0.1,2,0.6c0.4,0.7,0.1,1.6-0.6,2L22,28.6C21.1,29.1,20.1,29.3,19,29.3z"
        />
        <path class="st1" d="M21.3,18" />
        <path class="st1" d="M16.7,18" />
        <path
            class="st0"
            :style="{ fill: color }"
            d="M19,38c-1.1,0-2.1-0.2-3-0.6L0.8,29.5c-0.7-0.4-1-1.3-0.6-2c0.4-0.7,1.3-1,2-0.6l15.3,7.9
            c0.8,0.4,2.3,0.4,3.2,0l15.3-7.9c0.7-0.4,1.6-0.1,2,0.6c0.4,0.7,0.1,1.6-0.6,2L22,37.3C21.1,37.8,20.1,38,19,38z"
        />
    </svg>
</template>

<script>
import baseSvgImg from './baseSvgImg';
export default { extends: baseSvgImg };
</script>

<style scoped>
svg {
    display: block;
}
.st1 {
    fill: none;
    stroke: #007aff;
    stroke-width: 3;
    stroke-linecap: round;
    stroke-miterlimit: 10;
}
</style>
