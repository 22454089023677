import { Node } from 'topology-core/models/node';

export function addNodeAnimation(node, animationType) {
    const animateFrames = [];
    const state = Node.cloneState(node);
    switch (animationType) {
        case 'upDown':
            state.rect.y -= 10;
            state.rect.ey -= 10;
            animateFrames.push({
                duration: 100,
                linear: true,
                state
            });
            animateFrames.push({
                duration: 100,
                linear: true,
                state: Node.cloneState(node)
            });
            animateFrames.push({
                duration: 200,
                linear: true,
                state: Node.cloneState(node)
            });
            break;
        case 'leftRight':
            state.rect.x -= 10;
            state.rect.ex -= 10;
            animateFrames.push({
                duration: 100,
                linear: true,
                state: Node.cloneState(state)
            });
            state.rect.x += 20;
            state.rect.ex += 20;
            animateFrames.push({
                duration: 80,
                linear: true,
                state: Node.cloneState(state)
            });
            state.rect.x -= 20;
            state.rect.ex -= 20;
            animateFrames.push({
                duration: 50,
                linear: true,
                state: Node.cloneState(state)
            });
            state.rect.x += 20;
            state.rect.ex += 20;
            animateFrames.push({
                duration: 30,
                linear: true,
                state: Node.cloneState(state)
            });
            animateFrames.push({
                duration: 300,
                linear: true,
                state: Node.cloneState(node)
            });
            break;
        case 'heart':
            state.rect.x -= 5;
            state.rect.ex += 5;
            state.rect.y -= 5;
            state.rect.ey += 5;
            state.rect.width += 5;
            state.rect.height += 10;
            animateFrames.push({
                duration: 100,
                linear: true,
                state
            });
            animateFrames.push({
                duration: 400,
                linear: true,
                state: Node.cloneState(node)
            });
            break;
        case 'success':
            state.strokeStyle = '#237804';
            animateFrames.push({
                duration: 100,
                linear: true,
                state
            });
            animateFrames.push({
                duration: 100,
                linear: true,
                state: Node.cloneState(node)
            });
            state.strokeStyle = '#237804';
            animateFrames.push({
                duration: 100,
                linear: true,
                state
            });
            animateFrames.push({
                duration: 100,
                linear: true,
                state: Node.cloneState(node)
            });
            state.strokeStyle = '#237804';
            state.fillStyle = '#389e0d22';
            animateFrames.push({
                duration: 3000,
                linear: true,
                state
            });
            break;
        case 'warning':
            state.strokeStyle = '#fa8c16';
            state.dash = 2;
            animateFrames.push({
                duration: 300,
                linear: true,
                state
            });
            state.strokeStyle = '#fa8c16';
            state.dash = 0;
            animateFrames.push({
                duration: 500,
                linear: true,
                state: Node.cloneState(state)
            });
            state.strokeStyle = '#fa8c16';
            state.dash = 2;
            animateFrames.push({
                duration: 300,
                linear: true,
                state: Node.cloneState(state)
            });
            break;
        case 'error': {
            const cloneState = Node.cloneState(state);
            cloneState.strokeStyle = '#cf1322';
            cloneState.fillStyle = '#cf132222';
            animateFrames.push({
                duration: 100,
                linear: true,
                state: cloneState
            });
            animateFrames.push({
                duration: 100,
                linear: true,
                state
            });
            break;
        }

        case 'show':
            state.strokeStyle = '#fa541c';
            state.rotate = -10;
            animateFrames.push({
                duration: 100,
                linear: true,
                state: Node.cloneState(state)
            });
            state.rotate = 10;
            animateFrames.push({
                duration: 100,
                linear: true,
                state: Node.cloneState(state)
            });
            state.rotate = 0;
            animateFrames.push({
                duration: 100,
                linear: true,
                state: Node.cloneState(state)
            });
            break;
    }
    node.animateType = animationType;
    node.animateFrames = animateFrames;
    node.animateDuration = 0;
    for (const item of node.animateFrames) {
        node.animateDuration += item.duration;
    }
}
