import Base from '../../../base';
import Vue from 'vue';
import Gikam from '../../../../core/gikam-core';
import indexPanelConfig from '@/gikam/js/components/huge/indexPanelConfig/vue/indexPanelConfig.vue';

let defaultOptions = {
    id: void 0,
    showScheme: false,
    indexSetting: false,
    renderTo: void 0,
    onAfterClose: Gikam.emptyFunction
};

export default class IndexPanelConfig extends Base {
    constructor(options) {
        super(options);
        this.initialize(options, defaultOptions).init(options);
    }

    createModel() {
        const _this = this;
        this.model = new Vue({
            el: Gikam.createDom('div', this.options.renderTo),
            components: {
                indexPanelConfig
            },
            mounted() {
                _this.bindInstance(this.$el);
            },
            render() {
                return <indexPanelConfig ref="indexPanel" options={_this.options} />;
            }
        });
    }

    /**
     * @description 清空配置数据
     * @public
     * @memberof indexPanelConfig
     */
    clear() {
        this.model.$refs.indexPanel.clearPanelData();
    }

    /**
     * @description 刷新配置数据
     * @public
     * @memberof indexPanelConfig
     */
    refresh(param) {
        this.model.$refs.indexPanel.getPanelConfig(param);
    }

    init() {
        this.createModel();
    }
}
