import gantt from '../vue/gantt.vue';
import Base from '../../base.js';
import Vue from 'vue';
import Gikam from '../../../core/gikam-core.js';

const defaultOptions = {
    zoomConfig: { levels: [{}] },
    showForm: false,
    draggable: false
};

export default class Gantt extends Base {
    constructor(options) {
        super(options);
        this.listeners = {
            ready: Gikam.emptyFunction
        };
        this.initialize(options, defaultOptions).init();
    }
    createViewModel() {
        const _this = this;
        this.model = new Vue({
            el: Gikam.createDom('div', this.options.renderTo),
            render() {
                return (
                    <gantt
                        ref="vm"
                        options={_this.options}
                        onReady={gantt => {
                            _this.trigger('ready', gantt);
                        }}
                    ></gantt>
                );
            },
            components: { gantt },
            data() {
                return {
                    options: _this
                };
            }
        });
        this.model.$nextTick(() => {
            this.bindInstance(this.model.$refs.vm.$el);
        });
    }

    init() {
        this.createViewModel();
    }
}
