import Gikam from 'gikam';

//1.初始化宽度
export function columnsAddWidth(columns) {
    columns.forEach(col => {
        if (Gikam.isTrue(col.index)) {
            col.width = 50;
            col.title = '序号';
            col.contentAlign = 'alignCenter';
        }

        if (Gikam.isTrue(col.checkbox) || Gikam.isTrue(col.radio)) {
            col.width = 50;
            col.contentAlign = 'alignCenter';
        }
    });

    const getChildrenColumns = function getChildrenColumns(cols) {
        return cols.reduce((total, col) => {
            if (col.subColumns) {
                col.width = getChildrenColumns(col.subColumns);
            } else {
                col.width === undefined && (col.width = 150);
            }
            return parseInt(col.width) + total;
        }, 0);
    };
    getChildrenColumns(columns);
}

//给父节点放上field
export function addParentFields(columns) {
    const getFieldName = function getFieldName(cols) {
        return cols.reduce((total, col) => {
            if (!col.field && col.subColumns) {
                const field = getFieldName(col.subColumns);
                col.field = 'p' + field;
            }
            return total + col.field;
        }, '');
    };
    getFieldName(columns);
}

// 返回field-宽度 对象(为了拖拽) 并在叶子节点上挂在自己的所有父节点(为了拖拽)
export function getFieldWidthMap(columns) {
    const fieldWidth = {};

    const getFieldName = function getFieldName(cols, parentFields) {
        cols.forEach(col => {
            const _parentFields = Gikam.deepExtend(parentFields);
            if (col.subColumns) {
                _parentFields.push(col.field);
                getFieldName(col.subColumns, _parentFields);
            } else {
                col.parentFields = _parentFields;
            }
            fieldWidth[col.field] = col.width;
        });
    };
    getFieldName(columns, []);

    return fieldWidth;
}

export function getTotalWidth(columns) {
    return columns.reduce((total, col) => {
        return total - 1 + (col.width - 1) + 2;
    }, 0);
}

//计算表头高度
export const columnsAddHeight = function(columns) {
    const heightArr = [];
    const setHeight = function(index, val) {
        if (Gikam.isEmpty(heightArr[index]) || val > heightArr[index]) {
            heightArr[index] = val;
        }
    };
    const initChildrenHeight = function initChildrenHeight(cols, level) {
        let _height = 35;
        cols.forEach(col => {
            if (col.subColumns) {
                initChildrenHeight(col.subColumns, level + 1);
                _height = 35;
            } else {
                const height = col.heightTitle || 35;
                height > _height && (_height = height);
            }
        });
        setHeight(level, _height);
        return _height;
    };

    initChildrenHeight(columns, 0);

    const arr = [];
    heightArr.reverse().forEach((num, i, Arr) => {
        let H = num;
        Arr.forEach((jN, j) => {
            if (j > i) {
                H += jN;
            }
        });
        arr.push(H);
    });
    return arr;
};

//获取所有的叶子节点
export const getLeafCol = function getLeafCol(columns) {
    return columns.reduce((total, col) => {
        if (col.subColumns) {
            total = total.concat(getLeafCol(col.subColumns));
        } else {
            total.push(col);
        }
        return total;
    }, []);
};

//计算表头共有多少层
export const getMaxHeaderLevel = function getMaxHeaderLevel(columns) {
    let _level = 1;

    const fun = (cols, lvl) => {
        if (lvl > _level) {
            _level = lvl;
        }
        cols.forEach(col => {
            if (col.subColumns) {
                fun(col.subColumns, lvl + 1);
            }
        });
    };

    fun(columns, _level);
    return _level;
};

//遍历数类型的数据  treeList, childrenKey, callback
export const baseErgodicTreeData = function baseErgodicTreeData(treeList, childrenKey, callback) {
    if (!Array.isArray(treeList)) {
        Gikam.error('treeList expected array type');
        return;
    }
    const eachData = dataArray => {
        return dataArray.forEach(data => {
            if (Gikam.isNotEmpty(data[childrenKey])) {
                eachData(data[childrenKey]);
            }
            callback.call(data, data[childrenKey]);
        });
    };

    return eachData(treeList);
};

// 单位
export function getUnit(val) {
    if (Gikam.isNumber(val)) {
        return val + 'px';
    } else if (val && val.indexOf('%') > 0) {
        return val;
    } else {
        return void 0;
    }
}

//计算合并的列返回定位对象
export function getGroupFields(groupFields, data) {
    if (data.length < 2) {
        return {};
    }
    const map = {};
    if (typeof groupFields === 'string') {
        map[groupFields] = baseGetGroupFields(groupFields, data);
    } else {
        groupFields.forEach(field => {
            map[field] = baseGetGroupFields(field, data);
        });
    }
    return map;
}

function baseGetGroupFields(field, data) {
    //time vue diff的key成员
    const map = { time: new Date().getTime() };
    let baseValue = null;
    let pointer = {};
    let len = 0;

    data.forEach((row, index) => {
        if (row[field] === baseValue) {
            len += 1;
        } else {
            //给上一个赋长度
            pointer.len = len;
            //从新设置变量
            baseValue = row[field];
            pointer = map[index] = {
                len: 1,
                value: baseValue
            };
            len = 1;
        }
    });
    pointer.len = len;
    baseValue = null;
    pointer = null;
    len = null;

    return map;
}
