<template>
    <div class="notice-detail">
        <scroll maxWidth="100%">
            <div class="title">{{ options.title }}</div>
            <div class="info">
                <div v-if="options.infoCategory">
                    信息类型：
                    <span>{{ checkInfoCategory(options.infoCategory) }}</span>
                </div>
                <div v-if="options.noticeCategory">
                    公告类型：
                    <span>{{ checkNoticeType(options.noticeCategory) }}</span>
                </div>
                <div v-if="options.importance">
                    重要程度：
                    <span>{{ checkImportance(options.importance) }}</span>
                </div>
                <div v-if="options.createdTime">
                    发布日期：
                    <span>{{ options.createdTime }}</span>
                </div>
                <div v-if="options.publishOrgName">
                    发布单位：
                    <span>{{ options.publishOrgName }}</span>
                </div>
            </div>
            <div class="line"></div>
            <div class="content" v-html="checkContent(options.content)"></div>
            <div class="file">
                <div v-if="fileData.length != 0" class="file-title">附件：</div>
                <div class="file-data" v-for="(item, index) in fileData" :key="item.id + index">
                    {{ index + 1 }}.
                    <span @click="downFile(item)">{{ item.name }}</span>
                </div>
            </div>
        </scroll>
    </div>
</template>

<script>
import Gikam from '../../../core/gikam-core';

export default {
    props: {
        options: Object,
        infoCategoryData: Array,
        bizId: String
    },

    data() {
        return {
            importanceData: [],
            noticeType: [],
            option: Object,
            fileData: []
        };
    },

    mounted() {
        this.initImportance();
        this.initNoticeType();
        this.initFile();
    },

    methods: {
        initImportance() {
            let selectItems = [
                {
                    type: 'select',
                    category: 'sys',
                    param: {
                        codeCategoryId: 'importance'
                    }
                }
            ];
            Gikam.select.cacheItems(selectItems).done(() => {
                this.importanceData = selectItems[0].items;
            });
        },

        initNoticeType() {
            let selectItems = [
                {
                    type: 'select',
                    category: 'sys',
                    param: {
                        codeCategoryId: 'noticecategory'
                    }
                }
            ];
            Gikam.select.cacheItems(selectItems).done(() => {
                this.noticeType = selectItems[0].items;
            });
        },

        initFile() {
            let _this = this;
            let url = `${Gikam.IFM_CONTEXT}/core/module/item/files/queries`;
            Gikam.post(
                url,
                Gikam.getJsonWrapper({
                    f: { bizId: _this.options.id, targetId: `T_CORE_NOTICE$${_this.options.id}` },
                    n: -1
                })
            )
                .done(data => {
                    this.fileData = data.rows;
                })
                .fail(() => {
                    this.fileData = [];
                });
        },

        checkInfoCategory(data) {
            for (let i = 0; i < this.infoCategoryData.length; i++) {
                if (data == this.infoCategoryData[i].value) {
                    return this.infoCategoryData[i].text;
                }
            }
            return '';
        },

        checkImportance(data) {
            for (let i = 0; i < this.importanceData.length; i++) {
                if (data == this.importanceData[i].value) {
                    return this.importanceData[i].text;
                }
            }
            return '';
        },

        checkNoticeType(data) {
            for (let i = 0; i < this.noticeType.length; i++) {
                if (data == this.noticeType[i].value) {
                    return this.noticeType[i].text;
                }
            }
            return '';
        },

        downFile(item) {
            Gikam.download(Gikam.IFM_CONTEXT + item.downloadUrl, item.name);
        },

        checkContent(content) {
            if (!content) return '';
            return content.replace(/type="checkbox"/g, 'type="checkbox" onclick="this.checked=!this.checked"');
        }
    }
};
</script>

<style>
.notice-detail {
    height: 100%;
    overflow-y: auto;
}

.notice-detail .title {
    width: 100%;
    height: 54px;
    font-size: 16px;
    line-height: 54px;
    text-align: center;
}

.notice-detail .info {
    width: 100%;
    height: 28px;
    line-height: 28px;
    display: flex;
    justify-content: center;
}

.notice-detail .info div {
    margin-right: 18px;
    font-size: 12px;
    font-weight: bold;
}

.notice-detail .info div span {
    font-weight: normal;
}

.notice-detail .line {
    width: 868px;
    margin: 0 auto;
    height: 1px;
    background: #e6e6e6;
}

.notice-detail .content {
    width: 848px;
    min-height: 260px;
    margin: 16px auto;
}

.notice-detail >>> .content div {
    max-width: 848px !important;
}

.notice-detail .file {
    width: 848px;
    height: auto;
    margin: 16px auto;
    display: flex;
    flex-direction: column;
}

.notice-detail .file .title {
    line-height: 20px;
    font-size: 12px;
}

.notice-detail .file .file-data {
    font-size: 12px;
    text-align: left;
    height: 20px;
}

.notice-detail .file .file-data span {
    color: rgb(0, 122, 255);
    cursor: pointer;
}
</style>
