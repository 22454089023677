<template>
    <div
        class="combo-box"
        :class="{ 'validate-error': !validateResult, readonly: readonly, invisible: invisible }"
        @mousedown.stop
        @dblclick="dblclickHandle"
        @click="clickHandle"
    >
        <div v-if="readonly || invisible" class="readonly-text">{{ value }}</div>
        <div v-else class="container">
            <div class="text">
                <input
                    :name="field"
                    :value="value"
                    :placeholder="placeholder"
                    ref="input"
                    autocomplete="off"
                    @focus="focusHandle"
                    @blur="blurHandle"
                    @input="inputHandle"
                    @change="changeHandle"
                />
            </div>
            <div class="icon" @click.stop="showPicker(true)">
                <arrow-down v-bind="{ color: '#666666', width: '8px', height: '8px' }" />
            </div>
        </div>
    </div>
</template>
<script>
import GikamCore from '../../../../core/gikam-core';
import picker from './picker';
import jQuery from 'jquery';
import Vue from 'vue';
import pinyinMatch from 'pinyin-match';

const Gikam = top === window ? GikamCore : top.Gikam;

export default {
    name: 'comboBoxField',
    props: {
        options: Object,
        rowIndex: Number,
        cellIndex: Number,
        propValue: String,
        //编辑器中的边框等样式是否显示出来
        propInvisible: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            picker: void 0,
            validateResult: true,
            readonly: this.propReaconly || this.options.readonly,
            items: this.options.items || [],
            placeholder: this.options.placeholder,
            value: this.propValue,
            field: this.options.field,
            maxHeight: 150,
            invisible: this.propInvisible,
            multiple: Gikam.isNotEmpty(this.options.multiple) ? this.options.multiple : false
        };
    },

    mounted() {
        this.$emit('rendered', this.field, this.value, this.rowIndex);
    },

    watch: {
        value(val, oldValue) {
            this.$emit('change', this.field, this.value, this.rowIndex, oldValue);
        },

        propValue(val) {
            this.value = val;
        }
    },

    methods: {
        changeHandle(e) {
            this.value = e.target.value;
        },

        focusHandle() {
            this.$el.classList.add('active');
            this.showPicker();
        },

        blurHandle() {
            this.$el.classList.remove('active');
        },

        inputHandle(e) {
            this.items = this.options.items.filter(item => {
                return pinyinMatch.match(item.text, e.target.value);
            });
        },

        cleanPicker() {
            jQuery(Gikam.comPicker.$el).remove();
            Gikam.comPicker.$destroy();
            Gikam.comPicker = null;
            this.$el.classList.remove('active');
            Gikam.comboboxPicker = null;
        },

        showPicker(boolean) {
            if (Gikam.comPicker) {
                this.cleanPicker();
                if (boolean) {
                    return;
                }
            }
            if (!Gikam.isTrue(this.propInvisible)) {
                Gikam.simulatedEvent(document, 'mousedown');
            }
            const mountedDom = document.createElement('div');
            const $el = jQuery(this.$el);
            const $elTop = $el.offset().top;
            const pickerHeight = this.items.length * 32 > this.maxHeight ? this.maxHeight : this.items.length * 32;
            const top =
                $elTop + $el.height() + this.maxHeight > jQuery(window).height()
                    ? $elTop - pickerHeight - 4
                    : $elTop + $el.height();

            const left = $el.offset().left;
            const _this = this;
            document.body.appendChild(mountedDom);
            this.$el.classList.add('active');
            Gikam.comPicker = new Vue({
                el: mountedDom,
                provide: {
                    combobox: this
                },
                render() {
                    return (
                        <picker
                            propTop={top}
                            propLeft={left}
                            propItems={_this.items}
                            propMultiple={_this.multiple}
                            propWidth={$el.width()}
                        />
                    );
                }
            });

            const $picker = jQuery(Gikam.comPicker.$el);
            $picker.height() > this.maxHeight && $picker.height(this.maxHeight);
        },

        dblclickHandle() {
            this.$emit('dblclick', this);
        },

        setItems(items) {
            this.items = items;
        },

        getValue() {
            const filterItem = this.items.filter(item => item.value + '' === this.value + '');
            if (Gikam.isEmpty(filterItem)) {
                return null;
            }
            return filterItem[0].value;
        },

        dumpActiveCell() {
            if (!this.invisible) {
                this.$refs.input && this.$refs.input.blur();
                Gikam.comPicker && this.cleanPicker();
            }
        },

        activeCell() {
            if (!this.invisible) {
                this.focus();
            } else {
                this.$emit('click', this);
            }
        },

        focus() {
            this.$refs.input && this.$refs.input.focus();
            this.$emit('saveCoordinate');
        },

        //点击时保存当前单元格的坐标
        clickHandle() {
            if (!this.invisible) {
                this.$emit('saveCoordinate');
            } else {
                this.$emit('click', this);
            }
        }
    }
};
</script>

<style scoped>
.combo-box {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: stretch;
    padding: 0;
}

.combo-box.readonly {
    font-family: 'Microsoft YaHei', serif;
    font-size: 12px;
    display: flex;
    align-items: center;
}

.combo-box .container,
.combo-box .readonly-text {
    border: 1px solid #d9d9d9;
    display: flex;
    width: 100%;
    border-radius: 4px;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.65);
    font-family: 'Microsoft YaHei', serif;
    font-size: 12px;
    background-color: #fff;
    overflow: hidden;
}

.combo-box .readonly-text {
    background-color: #f4f4f4;
    color: rgba(0, 0, 0, 0.45);
    display: flex;
    align-items: center;
    height: 100%;
    padding-left: 8px;
}

.combo-box.validate-error .container {
    border-color: #ff6e6e;
}

.combo-box .container .placeholder {
    width: 100%;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.15);
    display: flex;
    align-items: center;
    padding-left: 14px;
}

.combo-box .container .icon {
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s;
}

.combo-box.active .container {
    border: 1px solid rgba(0, 122, 255, 0.5);
}

.combo-box.active .container .icon {
    transform: rotate(180deg);
    color: #4784ff;
}

.combo-box .container .text {
    width: 100%;
    flex: 1;
    color: rgba(0, 0, 0, 0.65);
    font-family: 'Microsoft YaHei', serif;
    font-size: 12px;
    display: flex;
    align-items: center;
}

input {
    width: 100%;
    height: 100%;
    border: none;
    color: rgba(0, 0, 0, 0.65);
    font-family: 'Microsoft YaHei', serif;
    font-size: 12px;
    padding-left: 8px;
}

.invisible .readonly-text {
    background-color: transparent;
    border: none;
}
</style>
