<template>
    <svg
        version="1.1"
        id="图层_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 42 42"
        style="enable-background:new 0 0 42 42;"
        xml:space="preserve"
        :width="width"
        :height="height"
    >
        <path
            class="st0"
            :fill="color"
            d="M21,40L21,40c-0.9,0-1.5-0.7-1.5-1.5V24.5c0-0.8,0.7-1.5,1.5-1.5H21c0.8,0,1.5,0.7,1.5,1.5v14.1
            C22.5,39.4,21.8,40,21,40z"
        />
        <path
            class="st0"
            :fill="color"
            d="M27.3,28.9c-0.4,0-0.8-0.1-1.1-0.4l-4.9-4.9c-0.1-0.1-0.3-0.1-0.4-0.1s-0.2,0-0.4,0.1l-5,4.9
            c-0.6,0.6-1.5,0.6-2.1,0s-0.6-1.5,0-2.1l4.9-4.9c1.3-1.3,3.6-1.3,4.9,0l4.9,4.9c0.6,0.6,0.6,1.5,0,2.1
            C28.1,28.7,27.7,28.9,27.3,28.9z"
        />
        <g>
            <path
                class="st0"
                :fill="color"
                d="M9.6,33.4c-0.1,0-0.1,0-0.2,0C4.1,32.8,0,28.3,0,22.9c0-5.4,4.1-9.9,9.4-10.4c0.8-0.1,1.6,0.5,1.6,1.3
                c0.1,0.8-0.5,1.6-1.3,1.6C5.9,15.9,3,19.1,3,22.9c0,3.9,2.9,7.1,6.7,7.5c0.8,0.1,1.4,0.8,1.3,1.6C11,32.8,10.3,33.4,9.6,33.4z"
            />
            <path
                class="st0"
                :fill="color"
                d="M32.4,33.6c-0.8,0-1.4-0.6-1.5-1.3c-0.1-0.8,0.5-1.6,1.3-1.6c3.8-0.4,6.7-3.6,6.7-7.5c0-3.9-2.9-7.1-6.7-7.5
                c-0.8-0.1-1.4-0.8-1.3-1.6c0.1-0.8,0.8-1.4,1.6-1.3c5.4,0.5,9.4,5,9.4,10.4c0,5.4-4.1,9.9-9.4,10.4C32.5,33.6,32.5,33.6,32.4,33.6z
                "
            />
        </g>
        <path
            class="st0"
            d="M32,15.5c-0.8,0-1.5-0.6-1.5-1.4C30.3,9,26.1,5,21,5s-9.3,4-9.5,9c0,0.8-0.7,1.5-1.6,1.4
            c-0.8,0-1.5-0.7-1.4-1.6C8.8,7.2,14.3,2,21,2s12.2,5.2,12.5,11.9C33.5,14.7,32.9,15.4,32,15.5C32,15.5,32,15.5,32,15.5z"
        />
    </svg>
</template>

<script>
import baseSvgImg from './baseSvgImg';
export default { extends: baseSvgImg };
</script>
