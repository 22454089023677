<template>
    <div
        class="text-input"
        :class="{ 'validate-error': !validateResult, readonly: readonly, invisible: invisible }"
        @dblclick="dblclickHandle"
        @click="clickHandle"
        @mousedown.stop
    >
        <div
            v-if="readonly || invisible"
            class="readonly-text"
            :style="styleFormatter('readonly')"
            data-user="memoryGlobalField"
            :title="inputTitle ? value : null"
        >
            {{ value }}
        </div>
        <input
            v-else
            :name="field"
            v-model.lazy.trim="value"
            :placeholder="placeholder"
            :style="styleFormatter()"
            @focus="focusHandle($event)"
            @blur="blurHandle"
            @input="inputHandle"
            @keydown="keydownHandle"
            @keyup="keyupHandle"
            ref="input"
            autocomplete="off"
            :title="inputTitle ? value : null"
        />
        <div class="validate-error-icon" v-if="validateResult === false" @click.stop>
            <info-image @mouseenter.stop="errorIconMouseenter" @mouseleave.stop="errorIconMouseleave"></info-image>
        </div>
    </div>
</template>
<script>
import Gikam from '../../../core/gikam-core';
import BaseField from '../baseField/baseField.vue'; //校验相关的逻辑
import infoImage from '../img/info.vue';

const defaultOptions = {
    //聚焦时是否选择所有文本
    selectAllOnFocus: false,
    // 鼠标悬浮是否提示文本内容
    inputTitle: false
};

export default {
    name: 'textInput',
    props: {
        options: Object,
        propValue: [String, Number],
        rowIndex: Number,
        cellIndex: Number,
        propReadonly: {
            type: Boolean,
            default: void 0
        },
        //编辑器中的边框等样式是否显示出来
        propInvisible: {
            type: Boolean,
            default: false
        }
    },
    extends: BaseField,
    components: { infoImage },

    data() {
        return {
            value: this.propValue,
            readonly: Gikam.isNotEmpty(this.propReadonly) ? this.propReadonly : this.options.readonly,
            field: this.options.field,
            invisible: this.propInvisible,
            placeholder: Gikam.propI18N(this.options.placeholder),
            selectAllOnFocus: this.options.selectAllOnFocus || defaultOptions.selectAllOnFocus,

            validateResult: true,
            //保存前一次校验状态，用于当输入框没输入值时，blur时，还原校验状态
            oldValidateResult: null,
            //用于判断用户输没输过值
            isInput: false,
            //保存前一次输入框的值，用于判断用户输入之后的值是否与之前的值一致
            oldValue: this.propValue,
            //判断是否为初始化之后的赋值
            setDataState: false,
            isValidate: false,
            lastBlurValue: '',
            // 鼠标悬浮是否提示文本内容
            inputTitle: this.options.inputTitle || defaultOptions.inputTitle
        };
    },

    watch: {
        value(val) {
            if (this.setDataState) {
                this.setDataState = false;
                return;
            }
            this.validateResult = this.validate();
            this.isValidate = true;
            this.oldValue = val;
            if (Gikam.isTrue(this.validateResult)) {
                this.$emit('change', this.options.field, val, this.rowIndex);
            }
        },

        propValue(val) {
            if (this.value !== val) {
                this.setDataState = true;
            }
            this.validateResult = true;
            this.oldValue = val;
            this.value = val;
        },

        propInvisible(val) {
            this.invisible = val;
        }
    },

    methods: {
        focusHandle(event) {
            //存储当前激活的单元格
            if (this.grid) {
                Gikam.globalActiveField = { [this.grid.options.id]: this };
            }
            this.oldValidateResult = this.validateResult;
            this.isInput = false;
            this.validateResult = true;
            this.$emit('focus', this.options.field, this.value, this.rowIndex);
            this.selectAllOnFocus && event.currentTarget.select();

            if (!Gikam.isTrue(this.propInvisible)) {
                Gikam.simulatedEvent(document, 'mousedown');
            }
        },

        blurHandle() {
            if (Gikam.isFalse(this.isInput)) {
                this.validateResult = this.oldValidateResult;
            } else if (this.oldValue === this.value && this.isInput && !this.isValidate) {
                this.validateResult = this.validate();
            }
            this.isValidate = false;
            if (this.value === this.lastBlurValue) {
                this.$emit('blur', this.options.field, this.value, this.rowIndex);
            }
            this.lastBlurValue = this.value;
        },

        inputHandle(e) {
            this.isInput = true;
            this.$emit('input', e);
            if (this.options.onInput) {
                this.options.onInput(e.target.value, this.rowIndex);
            }
        },

        keydownHandle(e) {
            if (this.options.onKeyDown) {
                this.options.onKeyDown(e.keyCode, e.target.value);
            }
        },

        keyupHandle(e) {
            this.$emit('keyup', e);
        },

        blur() {
            this.$refs.input.blur();
        },

        dblclickHandle() {
            this.$emit('dblclick', this);
        },

        override(props) {
            Gikam.extend(defaultOptions, props);
        },

        styleFormatter(param) {
            if (this.options.textStyleFormatter) {
                const style = this.options.textStyleFormatter(Gikam.deepExtend(this.validateArg));
                return param ? Gikam.extend({}, style, { paddingLeft: '8px' }) : style;
            }
        },

        //键盘控制光标移动前 单元格做的事情
        dumpActiveCell() {
            if (!this.invisible) {
                this.$refs.input && this.$refs.input.blur();
            }
        },

        //键盘控制光标移动后 单元格做的事情
        activeCell() {
            if (!this.invisible) {
                this.focus();
            } else {
                this.$emit('click', this);
            }
        },

        //用于 cellEditor 的click事件处理当前单元格
        focus() {
            this.$refs.input && this.$refs.input.focus();
            this.$emit('saveCoordinate');
        },

        //点击时保存当前单元格的坐标
        clickHandle() {
            if (!this.invisible) {
                this.$emit('saveCoordinate');
            } else {
                this.$emit('click', this);
            }
        }
    }
};
</script>

<style>
.text-input {
    width: 100%;
    height: 100%;
    padding: 0;
    display: flex;
    align-items: stretch;
}

.text-input.readonly {
    color: #666;
    font-family: 'Microsoft YaHei', serif;
    font-size: 12px;
    align-items: center;
}

.text-input input,
.text-input .readonly-text {
    width: 100%;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    padding-left: 8px;
    color: rgba(0, 0, 0, 0.65);
    font-family: 'Microsoft YaHei', serif;
    font-size: 12px;
    height: 100%;
}

.text-input .readonly-text {
    background-color: #f4f4f4;
    display: flex;
    align-items: center;
}

.text-input.validate-error input {
    border-color: #ff6e6e;
    padding-right: 25px;
}

.text-input input:focus {
    border: 1px solid rgba(0, 122, 255, 0.5);
}

.text-input .validate-error-icon {
    margin-left: -25px;
    display: flex;
    align-items: center;
}

.text-input .validate-error-icon svg {
    height: 20px;
    cursor: pointer;
}

.text-input.invisible .readonly-text {
    background-color: transparent;
    border: none;
}
</style>
