import Base from '../../../base';
import Gikam from "../../../../core/gikam-core";
import Vue from 'vue';

let defaultOptions = {
    renderTo: void 0
};

export default class OrganizeTree extends Base {

    constructor(options) {
        super(options);
        this.initialize(options, defaultOptions).init();
    }

    createViewModel() {
        const _this = this;
        this.organizeTree = new Vue({
            el: Gikam.createDom('div', this.options.renderTo),
            render() {
                return <organize-tree options={this.options}></organize-tree>
            },
            components: {
                organizeTree: () => import('../vue/organizeTree.vue')
            },
            data() {
                return {
                    options: _this.options
                };
            }
        });

        this.bindInstance(this.organizeTree.$el);
    }

    init() {
        this.createViewModel();
    }
}