import Gikam from '../core/gikam-core.js';
import modal from './template/modal.vue';
import Mask from './template/mask/mask';
import Vue from 'vue';
import Base from './base';

let defaultOptions = {
    id: void 0,
    title: void 0,
    width: '80%',
    height: '80%',
    src: void 0,
    showCloseBtn: true,
    showMaxBtn: true,
    toolbarTop: null,
    // 设置modal-body样式
    bodyStyle: {},
    // 用于自定义数据
    data: void 0,
    // 非模块对话框
    isModal: true
};

export default class Modal extends Base {
    constructor(props) {
        super(props);
        this.activeGrid = null;
        this.listeners = {
            afterClose: Gikam.emptyFunction,
            beforeClose: Gikam.emptyFunction,
            resize: Gikam.emptyFunction
        };
        this.initialize(props, defaultOptions).init(props);
    }

    createModel() {
        if (Gikam.activeGrid) {
            this.activeGrid = Gikam.activeGrid;
            Gikam.activeGrid = null;
        }
        const _this = this;
        this.options.modal = this;
        return new Vue({
            el: Gikam.createDom('div', document.body),
            components: { modal },
            render() {
                return (
                    <modal
                        options={this.options}
                        ref="vm"
                        onResizeRestore={this.resizeRestoreHandle}
                        onResizeMax={this.resizeMaxHandle}
                        onResize={_this.onResize.bind(_this)}
                    ></modal>
                );
            },
            data() {
                return {
                    options: _this.options
                };
            },
            methods: {
                resizeRestoreHandle() {
                    _this.onResize();
                },
                resizeMaxHandle() {
                    _this.onResize();
                }
            }
        });
    }

    createWindow() {
        if (this.options.src) {
            return;
        }
        let toolbarTop = false;
        if (this.options.toolbarTop) {
            toolbarTop = true;
        } else if (Gikam.isFalse(this.options.toolbarTop)) {
            toolbarTop = false;
        } else {
            toolbarTop = this.options.url ? true : false;
        }
        return Gikam.create('window', {
            $parent: this,
            renderTo: this.model.$el.querySelector('.modal-body'),
            toolbarTop: toolbarTop,
            headerShow: false
        });
    }

    //开启遮罩
    openMask() {
        this.maskModel = new Vue({
            el: Gikam.createDom('div', this.model.$el.querySelector('.modal-body')),
            components: { Mask },
            render() {
                return <Mask />;
            }
        });
    }

    //关闭遮罩
    closeMask() {
        this.maskModel.$nextTick(() => {
            this.maskModel.$destroy();
            Gikam.removeDom(this.maskModel.$el);
            this.maskModel = null;
        });
    }

    //获取子组件Iframe元素对象
    getIframeDom() {
        return this.model.$children[0].getIframeDom();
    }

    load() {
        this.options.url && this.window.load(this.options.url);
    }

    close(data) {
        this.model.$children[0].close(data);
        Gikam.activeGrid = this.activeGrid;
    }

    /**
     * @description 初始化移动弹框尺寸,如果是手机端，宽度强制显示100%
     * @private
     * @memberof Modal
     */
    initMobileSize() {
        if (Gikam.isMobilePhone()) {
            this.options.width = '100%';
            this.options.height = '100%';
        }
    }

    /**
     * @description 给modal的header中间添加html
     * @Public
     * @memberof Modal
     */
    setHeaderCenter(htmlString) {
        this.model.$refs.vm.setHeaderCenterDom(htmlString);
    }

    /**
     * @description modal窗口大小改变
     * @private
     * @param
     * @memberof Tab
     */
    onResize() {
        this.window && this.window.onResize();
    }

    /**
     * @description 获取modal的自定义数据
     * @Public
     * @memberof Modal
     */
    getData() {
        return this.options.data;
    }

    /**
     * @description 设置modal的自定义数据
     * @Public
     * @memberof Modal
     */
    setData(data) {
        this.options.data = data;
    }

    /**
     * @description 设置modal的自定义数据
     * @public
     * @memberof Modal
     */
    onBeforeClose(callback) {
        const beforeClose = this.listeners.beforeClose;
        this.listeners.beforeClose = data => {
            let result = true;
            if (beforeClose && beforeClose.call(this, data) === false) {
                result = false;
            }
            if (Gikam.isFunction(callback) && callback.call(this, data) === false) {
                result = false;
            }
            return result;
        };
    }

    init() {
        this.initMobileSize();
        this.model = this.createModel();
        this.window = this.createWindow();
        this.window && (this.window.$parent = this);
        this.bindInstance(this.model.$el);
        this.load();
    }
}
