<template>
    <div
        :id="options.id"
        class="img-panel-cont"
        :style="{ width: options.width + 'px', height: options.height + 'px' }"
    >
        <div class="btn-group" ref="btnGroup" v-if="!options.hiddenBtn[0] || !options.hiddenBtn[1]"></div>
        <scroll>
            <div class="img-panel">
                <hr class="hr" v-if="imgsList.length > 0" :style="{ width: panelWidth ? panelWidth + 'px' : 'auto' }" />
                <div
                    v-for="(node, index) in imgsList"
                    class="item"
                    :class="{ active: activeIndex === index }"
                    :key="node.id"
                    @click="activeimg(index)"
                    @dblclick="openBigImg(node)"
                >
                    <checkbox
                        class="checkbox"
                        propReadonly="true"
                        :propChecked="activeImgIdList.includes(node.id)"
                    ></checkbox>
                    <div class="cover-checkbox"></div>
                    <div class="img-container">
                        <img
                            v-show="!showImgIdList.includes(node.id)"
                            class="img"
                            src="../img/defaultImg.svg"
                            :key="node.id + 'default'"
                        />
                        <img
                            v-show="showImgIdList.includes(node.id)"
                            class="img"
                            :src="downloadUrlHandle(node.downloadUrl)"
                            :key="node.id"
                            @load="imgLoad(node)"
                        />
                    </div>
                    <p class="text">{{ node.name }}</p>
                </div>
            </div>
        </scroll>
        <img-page
            v-if="page"
            :showBody="true"
            :propPage="page"
            @updatePageNum="updatePageNumHandle"
            @updatePageSize="updatePageSizeHandle"
        ></img-page>
    </div>
</template>
<script>
import Gikam from '../../../core/gikam-core';
import Vue from 'vue';
import blowUpImg from './blowUpImg.vue';
import imgPage from './page.vue';

export default {
    props: {
        options: Object
    },

    inject: {
        imgGrid: {
            default: Object
        }
    },

    components: {
        imgPage
    },

    data() {
        return {
            imgsList: this.options.data, // 图片数据
            activeNode: null, // 选中的图片
            activeIndex: null, // 选中的图片序列
            activeImgIdList: [], // 激活的图片id的list
            showImgIdList: [], // 加载完图片的id
            panelWidth: void 0, // 一行可放图片的宽度
            page: this.options.page
        };
    },

    watch: {
        'options.data'(list) {
            this.imgsList = list;
            if (list.length === 0) {
                this.modal && this.modal.close();
                return;
            }
            this.activeNode = list[this.activeIndex];
            this.checkImgModal && this.checkImgModal.reassignHandle();
            this.modalHeader();
        },

        'options.page'(page) {
            this.page = page;
            this.activeIndex = null;
            this.modal && this.modal.close();
        }
    },

    methods: {
        // 图片路径
        downloadUrlHandle(downloadUrl) {
            return Gikam.IFM_CONTEXT + downloadUrl;
        },

        // 图片 加载事件
        imgLoad(node) {
            this.showImgIdList.push(node.id);
        },

        // 单击-点击选中激活图片
        activeimg(index) {
            this.activeIndex = index;
            this.activeNode = this.imgsList[index];
            if (this.activeImgIdList.includes(this.imgsList[index].id)) {
                this.activeImgIdList = this.activeImgIdList.filter(id => {
                    return id !== this.imgsList[index].id;
                });
            } else {
                this.activeImgIdList.push(this.imgsList[index].id);
                this.activeImgIdList = [...new Set(this.activeImgIdList)];
            }
            if (this.clickType) {
                //取消上次延时未执行的方法
                this.clickType = clearTimeout(this.clickType);
            }

            this.clickType = setTimeout(() => {
                this.options.onClick && this.options.onClick(this.activeNode);
            }, 300); //延时300毫秒执行
        },

        // 双击
        openBigImg(img) {
            if (this.clickType) {
                //取消上次延时未执行的方法
                this.clickType = clearTimeout(this.clickType);
            }
            this.checkBigImg();
            this.options.onDblclick && this.options.onDblclick(img);
        },

        // 为modal头部添加计数
        modalHeader() {
            let html = `${this.activeIndex + 1}/${this.imgsList.length}`;
            this.modal && this.modal.setHeaderCenter(html);
        },

        // 查看大图
        checkBigImg() {
            this.modal = Gikam.create('modal', {
                title: this.activeNode.name,
                toolbarTop: true,
                width: 700,
                height: 400
            });
            this.modalHeader();

            let _this = this;
            this.checkImgModal = new Vue({
                el: this.modal.window.$dom,
                components: {
                    blowUpImg
                },
                data() {
                    return {
                        activeNode: _this.activeNode,
                        activeIndex: _this.activeIndex
                    };
                },
                methods: {
                    switchImgHandle(index) {
                        if (index > -1 && index < _this.imgsList.length) {
                            _this.activeIndex = index;
                            _this.activeNode = _this.imgsList[index];
                            this.reassignHandle();
                            _this.modalHeader();
                        }
                    },
                    reassignHandle() {
                        this.activeIndex = _this.activeIndex;
                        this.activeNode = _this.activeNode;
                    },
                    modifyClick(node) {
                        _this.options.onModifyClick && _this.options.onModifyClick(node);
                    }
                },
                render() {
                    return (
                        <blow-up-img
                            ref="blowUpImg"
                            propImgNode={this.activeNode}
                            propImgIndex={this.activeIndex}
                            onSwitchImg={this.switchImgHandle}
                            onModifyClick={this.modifyClick}
                        />
                    );
                }
            });
        },

        // 返回选中图片的数组
        getSelections() {
            return this.imgsList.filter(item => {
                return this.activeImgIdList.includes(item.id);
            });
        },

        // 返回选中图片的数组
        getData() {
            return this.imgsList;
        },

        // 获取面板的宽度
        getPanelWidth() {
            let width = Gikam.jQuery(this.$el).width();
            this.panelWidth = void 0;
            Gikam.finalDelay(
                'leftLoadSuccess',
                () => {
                    this.panelWidth = parseInt(width / 100) * 100 + 1;
                },
                100
            );
        },

        // 切换页码
        updatePageNumHandle(num) {
            this.imgGrid.options.pageNum = num;
            this.imgGrid.getUrlData();
        },

        // 切换一页显示的数量
        updatePageSizeHandle(size) {
            this.imgGrid.options.pageSize = size;
            this.imgGrid.getUrlData();
        }
    },

    mounted() {
        this.options.onRendered && this.options.onRendered(this);
        setTimeout(() => {
            this.getPanelWidth(); // 等待侧边栏收缩
        }, 500);
        // 窗口改变事件
        Gikam.jQuery(window).resize(() => {
            this.getPanelWidth();
        });
    }
};
</script>
<style scope>
.img-panel-cont {
    height: 100%;
    padding: 0 8px;
    padding-bottom: 36px;
    margin: 0 auto;
}

.img-panel-cont .btn-group {
    height: 35px;
}

.img-panel-cont .img-panel {
    display: inline-block;
    border-left: 1px solid #d9d9d9;
    position: relative;
}

.img-panel-cont .img-panel hr {
    height: 1px;
    margin: 0;
    border: none;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #d9d9d9;
}

.img-panel-cont .img-panel > .item {
    float: left;
    width: 100px;
    height: 75px;
    border-right: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
    cursor: pointer;
    position: relative;
    box-sizing: border-box;
}

.img-panel-cont .img-panel > .item .checkbox {
    position: absolute;
    top: 4px;
    left: 4px;
    z-index: 1;
}

.img-panel-cont .img-panel > .item .cover-checkbox {
    width: 16px;
    height: 16px;
    position: absolute;
    top: 3px;
    left: 3px;
    z-index: 2;
}

.img-panel-cont .img-panel > .item .img-container {
    width: 100%;
    height: 51px;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.img-panel-cont .img-panel > .item .img {
    max-width: 100%;
    max-height: 51px;
}

.img-panel-cont .img-panel > .item .text {
    width: 100%;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.65);
    text-align: center;
    padding: 0 8px;
    margin: 0;
    position: absolute;
    left: 0;
    bottom: 2px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

/* 选中样式 */
.img-panel-cont .img-panel > .item.active {
    border: 2px solid #007aff;
    position: relative;
    left: -1px;
    z-index: 2;
}
</style>
