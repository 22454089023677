<template>
    <div class="grid-generic-query-panel" :style="{ left: left, top: top }">
        <div class="toolbar">
            <div class="add-row" @click="addFilterRow" :title="$i18n('grid.addFilterRow')"></div>
        </div>
        <scroll>
            <section>
                <ul>
                    <li v-for="(condition, index) in conditions" :key="condition.id">
                        <select-field
                            :options="{ items: fields }"
                            :rowIndex="index"
                            :propValue="condition.field"
                            @change="fieldChangeHandle"
                        />
                        <select-field
                            :options="{ items: getMatchRules(condition.field) }"
                            :propValue="condition.matchValue"
                            :rowIndex="index"
                            @change="matchRuleChangeHandle"
                            ref="matchRule"
                        />

                        <select-field
                            v-if="condition.inputType === 'select'"
                            :options="{ items: getSelectItems(condition.field) }"
                            :propValue="condition.value"
                            @change="inputValueChangeHandle"
                            :rowIndex="index"
                        />
                        <dateField
                            v-else-if="condition.inputType === 'date'"
                            :options="{}"
                            :propValue="condition.value"
                            :rowIndex="index"
                            @change="inputValueChangeHandle"
                        />
                        <dateTimeField
                            v-else-if="condition.inputType === 'dateTime'"
                            :options="{}"
                            :propValue="condition.value"
                            :rowIndex="index"
                            @change="inputValueChangeHandle"
                        />
                        <input v-else v-model="condition.value" />
                        <div class="cancel-btn" @click="removeFilterRow(index)"></div>
                    </li>
                </ul>
            </section>
        </scroll>
        <footer>
            <btn :options="{ text: $i18n('grid.generalQueryPanelReset'), onClick: reset }" />
            <btn :options="{ text: $i18n('grid.generalQueryPanelSearch'), onClick: refresh }" />
        </footer>
    </div>
</template>

<script>
import btn from '../../template/button';
import Gikam from '../../../core/gikam-core';
import Vue from 'vue';

export default {
    components: { btn },

    props: {
        options: Object
    },

    inject: ['grid'],

    created() {
        const _this = this;
        this.conditions.forEach(item => {
            if (Gikam.isEmpty(item.id)) {
                item.id = Date.now() + item.field;
            }
            item.inputType = this.getInputType(item.field);
            if (Gikam.isEmpty(item.matchValue)) {
                item.matchValue = _this.getDefaultMatchValue(item.field);
            }
        });
    },

    mounted() {
        Gikam.setInstance(this.$el, this);
    },

    watch: {
        conditions(val) {
            this.$emit('change', val);
        }
    },

    methods: {
        addFilterRow() {
            this.conditions.push({ id: Date.now() });
        },

        removeFilterRow(index) {
            const condition = this.conditions[index];
            const requestData = this.grid.options.requestData || {};
            for (let key in requestData) {
                if (key.indexOf(condition.field) >= 0) {
                    delete requestData[key];
                }
            }
            setTimeout(() => this.conditions.splice(index, 1));
        },

        destroy() {
            this.$emit('destroy');
            Gikam.removeDom(this.$el);
            this.$destroy();
        },

        fieldChangeHandle(field, value, rowIndex, oldValue) {
            const condition = this.conditions[rowIndex];
            this.conditions[rowIndex].field = value;
            this.$refs.matchRule[rowIndex].items = this.getMatchvalueByFieldType(this.getFieldType(value));
            Vue.set(condition, 'inputType', this.getInputType(value));
            Vue.set(condition, 'matchValue', this.getDefaultMatchValue(value));
            Vue.set(condition, 'value', null);
            if (Gikam.isNotEmpty(oldValue)) {
                for (let key in this.grid.options.requestData) {
                    if (key.indexOf(oldValue) >= 0) {
                        delete this.grid.options.requestData[key];
                    }
                }
            }
        },

        getDefaultMatchValue(field) {
            if (!field) {
                return;
            }
            const type = this.getFieldType(field);
            if (type === 'string') {
                return 'SC';
            } else if (type === 'number') {
                return 'NEQ';
            } else if (type === 'date') {
                return 'DEQ';
            } else if (type === 'dateTime') {
                return 'TEQ';
            } else if (type === 'select') {
                return 'EQ';
            }
        },

        getInputType(field) {
            return this.isSelect(field) ? 'select' : this.getFieldType(field);
        },

        matchRuleChangeHandle(field, value, rowIndex, oldValue) {
            this.conditions[rowIndex].matchValue = value;
            if (Gikam.isNotEmpty(oldValue)) {
                for (let key in this.grid.options.requestData) {
                    if (key.indexOf(oldValue) >= 0) {
                        delete this.grid.options.requestData[key];
                    }
                }
            }
        },

        reset() {
            this.conditions.forEach(item => {
                item.value = null;
            });
        },

        refresh() {
            const requestData = this.grid.options.requestData;

            this.conditions.forEach(item => {
                for (let key in requestData) {
                    if (key.indexOf(item.field) >= 0) {
                        delete requestData[key];
                    }
                }
                requestData[item.field + '_' + item.matchValue] = item.value;
            });

            this.grid.refresh({
                requestData: requestData
            });
        },

        getFieldType(field) {
            const mapper = this.grid.options.fieldTypeMapper;
            if (!field) {
                return 'string';
            }
            if (this.isSelect(field)) {
                return 'select';
            }
            if (mapper) {
                return mapper[field.toLowerCase().replace('ext$.', '')];
            } else {
                return 'string';
            }
        },

        getMatchRules(field) {
            if (!field) {
                return [];
            }
            return this.getMatchvalueByFieldType(this.getFieldType(field));
        },

        isSelect(field) {
            const options = this.options.fields.filter(item => item.field === field)[0];
            return options.type === 'select' ? true : false;
        },

        getSelectItems(field) {
            return this.options.fields.filter(item => item.field === field)[0].items;
        },

        inputValueChangeHandle(field, value, index) {
            this.conditions[index].value = value;
        },

        getMatchvalueByFieldType(type = 'string') {
            const rules = {
                string: [
                    {
                        value: 'SB',
                        text: '开始'
                    },
                    {
                        value: 'SC',
                        text: '包含'
                    },
                    {
                        value: 'SE',
                        text: '结尾'
                    },
                    {
                        value: 'SEQ',
                        text: '等于'
                    }
                ],

                date: [
                    {
                        value: 'DG',
                        text: '大于'
                    },
                    {
                        value: 'DGOE',
                        text: '大于等于'
                    },
                    {
                        value: 'DL',
                        text: '小于'
                    },
                    {
                        value: 'DLOE',
                        text: '小于等于'
                    },
                    {
                        value: 'DEQ',
                        text: '等于'
                    }
                ],

                dateTime: [
                    {
                        value: 'TG',
                        text: '大于'
                    },
                    {
                        value: 'TGOE',
                        text: '大于等于'
                    },
                    {
                        value: 'TL',
                        text: '小于'
                    },
                    {
                        value: 'TLOE',
                        text: '小于等于'
                    },
                    {
                        value: 'TEQ',
                        text: '等于'
                    }
                ],

                number: [
                    {
                        value: 'NG',
                        text: '大于'
                    },
                    {
                        value: 'NGOE',
                        text: '大于等于'
                    },
                    {
                        value: 'NL',
                        text: '小于'
                    },
                    {
                        value: 'NLOE',
                        text: '小于等于'
                    },
                    {
                        value: 'NEQ',
                        text: '等于'
                    }
                ],

                select: [
                    {
                        value: 'EQ',
                        text: '等于'
                    }
                ]
            };

            return rules[type];
        }
    },

    data() {
        return {
            top: this.options.top,
            left: this.options.left,
            fields: this.options.fields.map(item => {
                return {
                    value: item.field,
                    text: Gikam.propI18N(item.title)
                };
            }),
            conditions: this.options.genericQueryFields,
            matchMode: [
                {
                    value: 'all',
                    text: 'all'
                },
                {
                    value: 'any',
                    text: 'any'
                }
            ]
        };
    }
};
</script>

<style scoped>
.grid-generic-query-panel {
    position: absolute;
    width: 500px;
    border-radius: 4px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    min-height: 150px;
    background-color: #fff;
    padding: 16px;
    z-index: 10;
}

.grid-generic-query-panel section {
    max-height: 200px;
    padding-right: 8px;
}

.grid-generic-query-panel ul {
    margin: 0;
    padding: 0;
}

.grid-generic-query-panel ul > li {
    display: flex;
    align-items: center;
    line-height: 28px;
    height: 28px;
    margin-bottom: 16px;
}

.grid-generic-query-panel ul > li input {
    line-height: 28px;
    height: 28px;
    width: 144px;
    padding-left: 8px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 12px;
}

.grid-generic-query-panel ul > li input:focus {
    border: 1px solid rgba(0, 122, 255, 0.5);
}

.grid-generic-query-panel ul > li .select {
    padding-right: 8px;
    width: 110px;
}

.grid-generic-query-panel ul > li .select:first-child {
    width: 180px;
}

.cancel-btn {
    width: 16px;
    height: 16px;
    background: url('../../../../img/grid-generic-query-cancel.png') no-repeat center;
    margin-left: 8px;
    cursor: pointer;
}

.toolbar {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.toolbar .select {
    height: 28px;
    width: 125px;
    padding-right: 8px;
}

.toolbar .add-row {
    width: 16px;
    height: 16px;
    background: url('../../../../img/grid-generic-query-add-row.png') no-repeat center;
    cursor: pointer;
}

footer {
    height: 28px;
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

footer >>> .button {
    height: 28px;
    width: 64px;
    display: flex;
    justify-content: center;
    margin-left: 16px;
}

footer >>> .button:last-child {
    background-color: #007aff;
    color: #fff;
}

.grid-generic-query-panel ul > li > *:nth-child(3) {
    width: 145px;
    padding-right: 0;
}
</style>
