<template>
    <svg
        version="1.1"
        id="图层_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 38 38"
        style="enable-background:new 0 0 38 38;"
        xml:space="preserve"
        :width="width"
        :height="height"
    >
        <path
            :fill="color"
            d="M30.3,35.9c-0.1,0-0.1,0-0.2,0H7.7c-1.5,0-2.4-0.6-2.8-1.2C3.9,33.6,4,32,4,31.6V18.1h2.4v13.6l0,0.1
        c0,0.2,0,1,0.3,1.4c0.1,0.1,0.3,0.3,1,0.3h22.5l0,0c0.1,0,0.8,0,1.3-0.4c0.3-0.3,0.5-0.8,0.5-1.5V18.1h2.4v13.5
        c0,1.7-0.7,2.7-1.3,3.2C32.1,35.8,30.8,35.9,30.3,35.9L30.3,35.9z M5.2,19.2H1.5c-0.7,0-1.2-0.5-1.2-1.2c0-0.3,0.1-0.7,0.4-0.9
        L18.4,1.4C18.9,1,19.5,1,20,1.4L37.9,17c0.5,0.4,0.6,1.2,0.1,1.7c-0.2,0.3-0.5,0.4-0.9,0.4l-3.9,0.1c-0.7,0-1.2-0.5-1.2-1.2
        c0-0.7,0.5-1.2,1.2-1.2l0.9,0L19.2,3.9L4.7,16.8h0.5c0.7,0,1.2,0.5,1.2,1.2C6.4,18.7,5.9,19.2,5.2,19.2L5.2,19.2z M15.2,34.7
        c-0.6,0-1-0.5-1-1c0,0,0,0,0,0v-8.5c0-0.2,0-0.9,0.7-1.6c0.9-0.9,2.3-1.3,4.3-1.3c2,0,3.4,0.4,4.3,1.3c0.7,0.7,0.8,1.5,0.8,1.7v8.3
        c0,0.6-0.5,1-1,1s-1-0.5-1-1l0,0v-8.3c0,0,0-0.2-0.3-0.4c-0.3-0.2-1-0.6-2.7-0.6c-2,0-2.7,0.5-2.9,0.7c-0.1,0.1-0.1,0.2-0.2,0.2
        c0,0,0,0,0,0v8.4C16.2,34.3,15.7,34.7,15.2,34.7C15.2,34.7,15.2,34.7,15.2,34.7L15.2,34.7z M15.5,26.7l-0.3,7.7l-8.9-0.5l-0.6-9.5
        l0.2-6.2l-2.7-0.9L19.2,2.7L35,16.5l-1.6,1.5l-0.7,13.9l-0.6,2.1l-8.4,0.3l-0.1-5.6L23,25l-1-0.9l-2.1-0.3l-2.5,0.2l-1.6,0.2
        L15.5,26.7z"
        />
    </svg>
</template>

<script>
import baseSvgImg from './baseSvgImg';
export default {
    extends: baseSvgImg,
    props: {
        color: {
            default: '#ccc',
            type: String
        }
    }
};
</script>

<style scoped>
svg {
    display: block;
}
</style>
