<template>
    <svg
        version="1.1"
        id="图层_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        :width="width"
        :height="height"
        viewBox="0 0 38 38"
        style="enable-background:new 0 0 38 38;"
        xml:space="preserve"
    >
        <g>
            <path
                :fill="color"
                id="XMLID_2672_"
                class="st0"
                d="M27.2,39H3c-1.9,0-3.5-1.6-3.5-3.5v-31C-0.5,2.6,1.1,1,3,1h24.2c0.8,0,1.5,0.7,1.5,1.5
                S28,4,27.2,4H3C2.7,4,2.5,4.2,2.5,4.5v31C2.5,35.8,2.7,36,3,36h24.2c0.8,0,1.5,0.7,1.5,1.5S28,39,27.2,39z"
            />
            <path
                :fill="color"
                id="XMLID_2671_"
                class="st0"
                d="M37.5,19.9L37.5,19.9c0,0.9-0.7,1.5-1.5,1.5H14.9c-0.8,0-1.5-0.7-1.5-1.5v-0.1
                c0-0.8,0.7-1.5,1.5-1.5H36C36.8,18.5,37.5,19.1,37.5,19.9z"
            />
            <path
                :fill="color"
                id="XMLID_2670_"
                class="st0"
                d="M19.9,12.1c0.4,0,0.8,0.1,1.1,0.4c0.6,0.6,0.6,1.5,0,2.1L16,19.6c-0.2,0.2-0.2,0.5,0,0.7
                l4.9,4.9c0.6,0.6,0.6,1.5,0,2.1c-0.6,0.6-1.5,0.6-2.1,0l-4.9-4.9c-1.4-1.4-1.4-3.6,0-4.9l5-4.9C19.2,12.3,19.5,12.1,19.9,12.1z"
            />
        </g>
    </svg>
</template>

<script>
import baseSvgImg from './baseSvgImg';
export default { extends: baseSvgImg };
</script>
