<template>
    <div class="item">
        <div
            class="checkbox"
            v-if="hasCheckbox"
            :class="{ checked: isChecked, readonly: readonly }"
            @click="clickHandle"
        >
            <tickImg v-show="isChecked" v-bind="{ color: '#fff', width: '14px', height: '14px' }" />
        </div>
        <span>{{ text }}</span>
    </div>
</template>

<script>
export default {
    props: {
        boxType: {
            type: String,
            default: 'box'
        },

        propsChecked: {
            type: Boolean,
            default: false
        },

        item: {
            type: Object,
            default: () => ({
                value: '',
                text: ''
            })
        },

        propsValues: {
            type: Array,
            default: () => []
        },

        propsReadonly: {
            type: Boolean,
            default: false
        },

        propsReadonlyLists: {
            type: Array,
            default: () => []
        },

        hasCheckbox: {
            type: Boolean,
            default: true
        }
    },

    computed: {
        text() {
            return this.item.text;
        },
        readonly() {
            return this.propsReadonly || this.propsReadonlyLists.includes(this.item.value);
        },
        isChecked() {
            return this.propsChecked || this.propsValues.includes(this.item.value);
        }
    },

    methods: {
        clickHandle() {
            if (this.readonly) {
                return;
            }
            if (this.boxType === 'all') {
                this.$emit('checkedEvent', !this.isChecked);
            }

            if (this.boxType === 'box') {
                this.$emit('checkedEvent', !this.isChecked, this.item.value);
            }

            if (this.boxType === 'other') {
                this.$emit('checkedEvent', !this.isChecked);
            }
        }
    }
};
</script>

<style scoped lang="scss">
.item {
    display: flex;
    align-items: center;
    .checkbox {
        width: 14px;
        height: 14px;
        border: 1px solid #d9d9d9;
        border-radius: 2px;
        margin-right: 5px;
        &:hover {
            border: 1px solid #007aff;
            box-shadow: 0 0 3px #007aff;
            cursor: pointer;
        }
    }

    .checkbox.checked {
        background-color: #007aff;
        border: none;
        &:hover {
            border: none;
        }
    }

    .checkbox.readonly {
        background-color: #ccc;
        &:hover {
            box-shadow: none;
            border: 1px solid #d9d9d9;
            cursor: not-allowed;
        }
    }
}
</style>
