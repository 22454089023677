<template>
    <div :id="options.id" class="btn-container" :style="{ float: options.align, margin: options.margin }">
        <div
            v-show="hidden === false"
            class="button"
            :title="options.title"
            :class="options.class"
            @click="clickHandle"
            :style="{ padding: options.padding }"
        >
            <span class="icon" v-if="options.icon" :class="['icon-' + options.icon, options.color]">
                <slot></slot>
            </span>
            <span class="button-text">{{ text }}</span>
            <div class="corner-marker" v-if="markerNum > 0">{{ Number(markerNum) }}</div>
            <div v-if="prompt" class="prompt-dot"></div>
        </div>
    </div>
</template>

<script>
import Gikam from '../../core/gikam-core';

export default {
    props: {
        options: Object,
        scope: Object,
        index: Number,
        memory: Boolean,
        window: Object
    },

    created() {
        if (this.window && this.options.icon === 'save') {
            this.window.model.$saveButton = this;
        }
    },

    mounted() {
        Gikam.setInstance(this.$el, this);
        this.options.onRendered && this.options.onRendered.call(this);
        if (this.options.id) {
            Gikam.compInstanceContainer[this.options.id] = this;
        }
        if (this.memory) {
            this.$emit('memoryWidth', this.index, this.$el.offsetWidth);
        }
    },

    data() {
        return {
            hidden: Gikam.isEmpty(this.options.hidden) ? false : this.options.hidden,
            text: Gikam.propI18N(this.options.text),
            markerNum: this.options.markerNum || 0,
            prompt: false
        };
    },

    watch: {
        hidden(val) {
            this.$nextTick(() => {
                this.$emit('hiddenChangeEvent', val, this);
            });
        }
    },

    methods: {
        show() {
            this.hidden = false;
        },

        hide() {
            this.hidden = true;
        },

        setMarkerNum(number) {
            if (Gikam.isNumber(number)) {
                this.markerNum = number;
                return true;
            } else {
                return false;
            }
        },

        clickHandle(event) {
            Gikam.buttonText = this.text;
            if (this.options.onClick) {
                //如果是保存按钮，点击的时候记录，防止不点击保存按钮，只调用保存方法而出现提示的问题
                if (this.window && this.options.icon === 'save') {
                    this.window.$isSaveButtonClick = true;
                }
                this.options.onClick(event);
            }
            this.$emit('click', event);
            setTimeout(() => {
                Gikam.buttonText = null;
            }, 300);
        }
    }
};
</script>

<style lang="scss">
.btn-container {
    display: inline-block;
    vertical-align: middle;
}

.btn-container .corner-marker {
    font-size: 12px;
    color: #fff;
    letter-spacing: 0;
    padding: 0 4px;
    margin-left: 5px;
    height: 14px;
    line-height: 14px;
    background: #ff003c;
    border-radius: 7px;
}

.button {
    display: flex;
    border: 1px solid #d9d9d9;
    color: rgba(0, 0, 0, 0.65);
    font-family: 'Microsoft YaHei', serif;
    font-size: 12px;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 4px;
    align-items: center;
    height: 24px;
    position: relative;

    .prompt-dot {
        position: absolute;
        top: 4px;
        right: 4px;
        height: 4px;
        width: 4px;
        border-radius: 50%;
        background-color: #ff003c;
    }
}

.button.blue {
    background-color: #007aff;
    color: #fff;
}

.button.blue:hover {
    color: #fff;
    background-color: #007aff;
}

.button:hover {
    background-color: #f4f4f4;
}

.button:active {
    border-color: #007aff;
    color: #007aff;
    background-color: #fff;
}

.button .icon {
    margin-right: 2px;
    font-size: 16px;
}

.button .button-text {
    white-space: nowrap;
}

.button .icon.danger::before {
    color: #ff362e;
}

.button .icon.success::before {
    color: #29cf68;
}

.button .icon.primary::before {
    color: #007aff;
}

.button .icon.info::before {
    color: #1597a5;
}

.button .icon.warning::before {
    color: #f9762b;
}

.button .icon.wait::before {
    color: #f08239;
}
</style>
