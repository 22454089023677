<template>
    <div
        class="choose-input"
        :class="{ 'validate-error': !validateResult, readonly: readonly, invisible: invisible }"
        @dblclick="dblclickHandle"
        @mousedown.stop
        @click="clickHandle"
        @mouseenter.stop="showPoptipPanel($event)"
        @mouseleave.stop="removeErrorPanel"
    >
        <div v-if="readonly || invisible" class="readonly-text">
            <div class="content-wrapper" v-html="formatterValue"></div>
        </div>
        <template v-else>
            <input v-once type="text" ref="input" readonly key="1" :value="formatterValue" class="disabledInput" />
            <input
                v-if="editable"
                :name="options.field"
                :value="formatterValue"
                @change="changeValue"
                autocomplete="off"
            />
            <div v-else class="input" :name="options.field">
                <div class="content-wrapper" v-if="formatterValue" v-html="formatterValue"></div>
                <div class="content-wrapper placeholder" v-else v-html="placeholder"></div>
            </div>
            <div class="choose-input-icon" @click="choose">
                <chooseImg v-bind="{ color: '#666666', width: '14px', height: '14px' }" />
            </div>
            <div class="choose-input-clean" v-if="isShowClean" @click="cleanValue">
                <cleanImg v-bind="{ color: '#666666', width: '14px', height: '14px' }" />
            </div>
        </template>
    </div>
</template>
<script>
import Gikam from '../../../core/gikam-core';
import Vue from 'vue';

const defaultOptions = {
    placeholder: ''
};

export default {
    name: 'chooseField',
    props: {
        options: Object,
        propValue: String,
        propSetValueAfterChoose: Boolean,
        rowIndex: Number,
        cellIndex: Number,
        propInvisible: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            validateResult: true,
            readonly: Gikam.isNotEmpty(this.propReadonly) ? this.propReadonly : this.options.readonly,
            field: this.options.field,
            value: this.propValue || this.options.value,
            setValueAfterChoose: this.propSetValueAfterChoose || false,
            invisible: this.propInvisible,
            placeholder: this.options.placeholder || defaultOptions.placeholder
        };
    },

    inject: {
        form: {
            default: null
        },
        grid: {
            default: null
        }
    },

    computed: {
        isShowClean() {
            return this.value && this.options.cleanable !== false;
        },

        editable() {
            return this.options.editable;
        },

        formatterValue() {
            if (this.options.formatter) {
                return this.options.formatter.call(this, this.field, this.value);
            } else {
                return this.value;
            }
        },

        targetFields() {
            if (!this.options.targetFields) {
                return [];
            }
            return this.options.targetFields.reduce((result, item) => {
                let targetField = item;
                let valueField = item;
                if (Gikam.isPlainObject(item)) {
                    targetField = Object.keys(item)[0];
                    valueField = item[targetField];
                }
                result.push({ targetField, valueField });
                return result;
            }, []);
        }
    },

    watch: {
        value() {
            this.validateResult = true;
            this.$emit('change', this.options.field, this.value, this.rowIndex);
        },

        propValue(val) {
            this.value = val;
        }
    },

    methods: {
        cleanValue() {
            if (Gikam.isNotEmpty(this.targetFields)) {
                this.cleanRelateFieldsValue();
            } else {
                if (!this.options.onClean) {
                    this.value = '';
                    return;
                } else {
                    this.options.onClean.call(this.form || this.grid, this.options.field, this.value, this.rowIndex);
                }
            }
        },

        changeValue(e) {
            this.value = e.target.value;
        },

        choose() {
            if (this.options.onChoose) {
                this.modal = this.options.onChoose.apply(this.form || this.grid);
                return;
            }
            let category = this.options.category;
            if (this.options.getCategory) {
                category = this.options.getCategory();
            }
            let config = Gikam.choose.getConfig(category);
            let url = Gikam.IFM_CONTEXT + config.url;
            let customData = null;
            if (this.options.onBeforeChoose) {
                customData = this.options.onBeforeChoose.apply(this.form || this.grid, [this.rowIndex]);
            }
            if (customData === false) {
                return;
            }
            const requestData = Gikam.deepExtend(customData);
            const _this = this;
            this.options.single && (requestData.single = 1);
            this.modal = Gikam.create('modal', {
                title: config.title,
                url: url + Gikam.param(requestData),
                width: config.width,
                height: config.height,
                onAfterClose: function(rows) {
                    if (_this.setValueAfterChoose && Gikam.isNotEmpty(rows)) {
                        _this.value = Gikam.getFieldValue(rows[0], _this.field);
                    }
                    _this.$emit('afterChoose', rows, _this.rowIndex);
                    _this.setRelateFieldsValue(rows);
                    _this.focus();
                }
            });
        },

        dblclickHandle() {
            this.$emit('dblclick', this);
        },

        // 气泡
        showPoptipPanel(event) {
            let _this = this;
            if (Gikam.isEmpty(this.formatterValue)) {
                return false;
            }
            this.removeErrorPanel();
            let formatterValue = this.formatterValue;
            if (!Gikam.poptipPanel) {
                this.timeHandle = setTimeout(() => {
                    const top = event.clientY + 20;
                    const left = event.clientX;
                    Gikam.poptipPanel = new Vue({
                        el: Gikam.createDom('div', document.body),
                        data: {
                            formatterValue: formatterValue,
                            tipStyle: {
                                top: top + 'px'
                            }
                        },
                        methods: {
                            close() {
                                _this.removeErrorPanel();
                            },
                            options() {
                                // 解决气泡居边产生滚动条
                                let poptip = Gikam.jQuery(this.$el);
                                let win = Gikam.jQuery(window);
                                let width = poptip.width();
                                let height = poptip.height();
                                let window_width = win.width();
                                let window_height = win.height();
                                if (left + width + 24 > window_width) {
                                    this.tipStyle = { top: top, right: '16px' };
                                } else {
                                    this.tipStyle = { top: top, left: left + 'px' };
                                }
                                if (top + height + 24 > window_height) {
                                    this.tipStyle.top = top - 40 + 'px';
                                }
                            }
                        },
                        mounted() {
                            this.options();
                        },
                        template: `<div class='poptip-message-panel' :style='tipStyle' v-html=formatterValue></div>`
                    });
                }, 1000);
            }
        },

        // 关闭气泡
        removeErrorPanel() {
            if (this.timeHandle) {
                clearTimeout(this.timeHandle);
                this.timeHandle = null;
            }
            if (Gikam.poptipPanel) {
                Gikam.removeDom(Gikam.poptipPanel.$el);
                Gikam.poptipPanel.$destroy();
                Gikam.poptipPanel = null;
            }
        },

        //给其他字段赋值
        setRelateFieldsValue(rows) {
            if (Gikam.isEmpty(rows) || Gikam.isEmpty(this.targetFields)) {
                return;
            }
            const data = Gikam.isArray(rows) ? rows[0] : rows;
            const valueObject = this.targetFields.reduce(
                (mapper, item) => {
                    let fieldValue = Gikam.getFieldValue(data, item.valueField);
                    if (fieldValue === undefined) {
                        fieldValue = '';
                    }
                    if (item.targetField.includes('ext$')) {
                        mapper.ext$[item.targetField.replace('ext$.', '')] = fieldValue;
                    } else {
                        mapper[item.targetField] = fieldValue;
                    }
                    return mapper;
                },
                { ext$: {} }
            );
            Gikam.isNotEmpty(this.rowIndex) && (valueObject.index = this.rowIndex);
            (this.form || this.grid).setData(valueObject);
        },

        //清空相关字段值
        cleanRelateFieldsValue() {
            this.setRelateFieldsValue([{}]);
        },

        dumpActiveCell() {
            if (!this.invisible) {
                this.$refs.input && this.$refs.input.blur();
            }
            this.modal && this.modal.close();
        },

        activeCell() {
            if (!this.invisible) {
                this.focus();
            } else {
                this.$emit('click', this);
            }
        },

        focus() {
            this.$refs.input && this.$refs.input.focus();
            this.$emit('saveCoordinate');
        },

        //点击时保存当前单元格的坐标
        clickHandle() {
            if (!this.invisible) {
                this.$emit('saveCoordinate');
            } else {
                this.$emit('click', this);
            }
        },

        override(props) {
            Gikam.extend(defaultOptions, props);
        }
    }
};
</script>

<style scoped>
.choose-input {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: stretch;
    position: relative;
}

.choose-input input,
.choose-input .input,
.choose-input .readonly-text {
    width: 100%;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    padding-right: 38px;
    padding-left: 8px;
    color: rgba(0, 0, 0, 0.65);
    font-family: 'Microsoft YaHei', serif;
    font-size: 12px;
    height: 100%;
    background-color: #fff;
    display: flex;
    align-items: center;
}

.choose-input .readonly-text {
    position: absolute;
    padding-right: 8px;
}

.choose-input .input >>> p {
    margin: 0;
}

.choose-input .content-wrapper {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.choose-input .content-wrapper.placeholder {
    color: rgba(0, 0, 0, 0.15);
}

.choose-input .input >>> a,
.choose-input .readonly-text >>> a {
    color: #007aff;
    text-decoration: none;
}

.choose-input .readonly-text {
    background-color: #f4f4f4;
    display: flex;
    align-items: center;
}

.choose-input input:focus {
    border: 1px solid rgba(0, 122, 255, 0.5);
}

.choose-input.validate-error input,
.choose-input.validate-error .input {
    border-color: #ff6e6e;
}

.choose-input .choose-input-icon {
    width: 30px;
    cursor: pointer;
    margin-left: -30px;
    background-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.choose-input .choose-input-clean {
    visibility: hidden;
    position: absolute;
    right: 28px;
    width: 20px;
    height: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.choose-input:hover .choose-input-clean {
    visibility: visible;
}

.invisible .readonly-text {
    background-color: transparent;
    border: none;
}

.choose-input .readonly-text >>> p {
    margin: 0;
    line-height: 30px;
}
</style>
<style>
@keyframes fadeIn {
    from {
        opacity: 0.3;
    }
    to {
        opacity: 1;
    }
}

.poptip-message-panel {
    position: absolute;
    background-color: #fff;
    height: 20px;
    border: calc(0.5px) solid rgba(0, 0, 0, 0.65);
    font-size: 12px;
    line-height: 19px;
    color: #333;
    padding: 0 6px;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.55);
    animation: fadeIn 0.3s;
    z-index: 11;
}

.poptip-message-panel a {
    text-decoration: none;
    color: #333;
}

.poptip-message-panel p {
    margin: 0;
    line-height: 16px;
}

.choose-input input.disabledInput {
    width: 0;
    opacity: 0;
    float: left;
    z-index: -1;
    border-width: 0;
    padding: 0;
    margin: 0;
    outline: none;
}
.choose-input input.disabledInput:focus {
    border: none;
}

/* .poptip-message-panel sub {
    font-size: 8px;
    position: relative;
    top: -2px;
}

.poptip-message-panel sup {
    font-size: 8px;
    position: relative;
    top: 3px;
} */
</style>
