<template>
    <div class="date-picker" @click.stop @mousedown.stop @dblclick.stop>
        <div class="ym-select">
            <div class="year" @dblclick.stop>
                <button
                    :title="$i18n('date.lastYear')"
                    v-on:click="setLastYear()"
                    class="last-year-btn"
                ></button>
                <div class="ym-container">
                    <input
                        class="year-input"
                        v-model="year"
                        v-on:input="refresh"
                        autocomplete="off"
                    />
                    {{
                    $i18n('date.year')
                    }}
                </div>
                <button
                    :title="$i18n('date.nextYear')"
                    v-on:click="setNextYear()"
                    class="next-year-btn"
                ></button>
            </div>

            <div class="month" @dblclick.stop>
                <button
                    :title="$i18n('date.lastMonth')"
                    v-on:click="setLastMonth()"
                    class="last-month-btn"
                ></button>
                <div class="ym-container">
                    <input
                        class="month-input"
                        v-model="month"
                        v-on:input="refresh"
                        autocomplete="off"
                    />
                    {{
                    $i18n('date.month')
                    }}
                </div>
                <button
                    v-on:click="setNextMonth()"
                    :title="$i18n('date.nextMonth')"
                    class="next-month-btn"
                ></button>
            </div>
        </div>

        <div class="date-select-container">
            <table>
                <thead>
                    <tr>
                        <td v-for="item in week" :key="item">{{ item }}</td>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(weekList, trIndex) in createDays" :key="weekList.key">
                        <td
                            v-for="(day, tdIndex) in weekList"
                            :key="tdIndex"
                            class="date-picker-days"
                            :class="day.class"
                            @click="chooseDate($event, trIndex, tdIndex)"
                            @dblclick.stop="handleTimeDblClick($event, trIndex, tdIndex)"
                        >
                            <div class="text">{{ day.dayOfMonth }}</div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="time-container" v-if="options.type === 'dateTime'">
            <div class="time" @dblclick.stop>
                <div class="hour">
                    <input
                        class="hour-input"
                        v-model="activeHour"
                        autocomplete="off"
                        @input="inputHour"
                        @change="changeHour"
                    />
                    <div class="switch">
                        <div class="up" @click="hourPlus"></div>
                        <div class="down" @click="hourMinus"></div>
                    </div>
                </div>
                <div class="split">:</div>
                <div class="minute">
                    <input
                        class="minute-input"
                        ref="minute-input"
                        v-model="activeMinute"
                        autocomplete="off"
                        @input="inputMinute"
                        @change="changeMinute"
                    />
                    <div class="switch">
                        <div class="up" @click="minutePlus"></div>
                        <div class="down" @click="minuteMinus"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="select-today">
            <a
                href="javascript:"
                class="select-today-btn"
                v-on:click="selectToday()"
            >{{ $i18n('date.today') }}</a>
            <div class="btn-container">
                <div class="clean-btn" @click="clean">{{ $i18n('date.clean') }}</div>
                <div class="confirm-btn" @click="confirm">{{ $i18n('date.confirm') }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import Gikam from '../../../../core/gikam-core';
import jQuery from 'jquery';

export default {
    props: {
        options: Object
    },

    data() {
        return {
            week: ['日', '一', '二', '三', '四', '五', '六'],
            Gikam: Gikam,
            year: void 0,
            month: void 0,
            activeDate: void 0,
            activeHour: Gikam.DateUtils.formatter(
                this.options.chooseDate ? this.options.chooseDate : this.options.date,
                'hh'
            ),
            activeMinute: Gikam.DateUtils.formatter(
                this.options.chooseDate ? this.options.chooseDate : this.options.date,
                'mm'
            ),
            initDate: Gikam.DateUtils.formatter(this.options.date, 'yyyy-MM-dd')
        };
    },

    created() {
        this.refreshAll();
        //取消jQuery绑定时间双击事件的方式
        //this.bindDateDblclickEvent();
    },
    computed: {
        // eslint-disable-next-line complexity
        createDays() {
            let dateTimeArray = [];

            let year = parseInt(this.year),
                month = parseInt(this.month),
                firstDate = new Date(year, month - 1, 1),
                firstDay = firstDate.getDay(),
                lastDate = new Date(year, month, 0),
                lastDay = lastDate.getDay();

            firstDate.setDate(firstDate.getDate() - firstDay);
            lastDate.setDate(lastDate.getDate() + (7 - lastDay));

            let totalDays = (lastDate.getTime() - firstDate.getTime()) / (24 * 60 * 60 * 1000);

            let week = 0;
            for (let i = 0; i < totalDays; i++) {
                if (i % 7 === 0) {
                    dateTimeArray.push([]);
                    dateTimeArray[week].key = `${Gikam.DateUtils.formatter(firstDate, 'yyyy-MM')}-w${week}`;
                }

                //计算是否为当前月
                let beginDisabled = !!(this.options.beginDate && firstDate < this.options.beginDate),
                    endDisabled = !!(this.options.endDate && firstDate > this.options.endDate),
                    disabled = beginDisabled === true || endDisabled === true ? 'disabled' : '';

                let firstDateStr = Gikam.DateUtils.formatter(firstDate, 'yyyy-MM-dd');

                //计算展示时间集合
                dateTimeArray[week].push({
                    time: firstDateStr,
                    class: [
                        //是否当前月
                        disabled,
                        //其他月日期
                        ~~firstDate.getMonth() === ~~month - 1 ? '' : 'other-month-days',
                        //是否为指定时间区间外的时间
                        this.otherRangeDays(firstDateStr) ? 'other-range-days ' : '',
                        //是否今天
                        firstDateStr === Gikam.DateUtils.formatter(new Date(), 'yyyy-MM-dd') ? 'today ' : '',
                        //是否为最后选中时间
                        firstDateStr === Gikam.DateUtils.formatter(this.options.chooseDate, 'yyyy-MM-dd')
                            ? 'lastSelectedDay '
                            : ''
                    ],
                    dayOfMonth: firstDate.getDate()
                });
                if ((i + 1) % 7 === 0) week++;
                firstDate.setDate(firstDate.getDate() + 1);
            }

            return dateTimeArray;
        }
    },

    beforeDestroy() {
        this.options.close && this.options.close();
    },

    methods: {
        handleTimeDblClick(e, tr, td) {
            const activeDate = this.createDays[tr][td];
            if (this.otherRangeDays(activeDate.time)) {
                return;
            }
            this.confirm();
        },

        // 时间双击 - 已废弃
        bindDateDblclickEvent() {
            let _this = this;
            jQuery('.date-picker-days').dblclick(function() {
                let date = jQuery(this).attr('value');
                if (_this.otherRangeDays(date)) {
                    return;
                }
                _this.confirm();
            });
        },

        refresh() {
            this.options.date.setFullYear(~~this.year);
            this.options.date.setMonth(~~this.month - 1);
        },

        refreshAll() {
            this.year = Gikam.DateUtils.formatter(this.options.date, 'yyyy');
            this.month = Gikam.DateUtils.formatter(this.options.date, 'MM');
        },

        setNextMonth() {
            this.options.date.setMonth(this.options.date.getMonth() + 1);
            this.refreshAll();
        },

        setLastMonth() {
            this.options.date.setMonth(this.options.date.getMonth() - 1);
            this.refreshAll();
        },

        setLastYear() {
            this.options.date.setFullYear(this.options.date.getFullYear() - 1);
            this.refreshAll();
        },

        setNextYear() {
            this.options.date.setFullYear(this.options.date.getFullYear() + 1);
            this.refreshAll();
        },

        selectToday() {
            const date = new Date();
            this.activeDate = Gikam.DateUtils.formatter(date, 'yyyy-MM-dd');
            this.activeHour = Gikam.DateUtils.formatter(date, 'hh');
            this.activeMinute = Gikam.DateUtils.formatter(date, 'mm');
            this.confirm();
        },

        chooseDate(e, tr, td) {
            const activeDate = this.createDays[tr][td];
            if (this.otherRangeDays(activeDate.time)) {
                return;
            }
            const activeCell = this.$el.querySelector('td.active');
            if (activeCell) {
                activeCell.classList.remove('active');
            }
            const activeDom = e.target.tagName === 'TD' ? e.target : e.target.parentNode;
            activeDom.classList.add('active');
            this.activeDate = activeDate.time;
        },

        confirm() {
            let value = this.activeDate;
            if (value) {
                if (this.options.type === 'dateTime') {
                    value += ' ' + this.activeHour + ':' + this.activeMinute;
                }
                this.options.dateInput.value = value;
            } else {
                value = Gikam.DateUtils.formatter(this.options.chooseDate || new Date(), 'yyyy-MM-dd');
                if (this.options.type === 'dateTime') {
                    value += ' ' + this.activeHour + ':' + this.activeMinute;
                }
                this.options.dateInput.value = value;
            }
            this.options.closeEvent();
            this.destroy();
        },

        clean() {
            this.options.dateInput.value = '';
            this.options.closeEvent();
            this.destroy();
        },

        destroy() {
            jQuery(this.$el).remove();
            this.$destroy();
            this.options.dateInput.datePicker = void 0;
        },

        hourPlus() {
            this.activeHour = parseInt(this.activeHour) + 1;
        },

        hourMinus() {
            this.activeHour = parseInt(this.activeHour) - 1;
        },

        minutePlus() {
            this.activeMinute = parseInt(this.activeMinute) + 1;
        },

        minuteMinus() {
            this.activeMinute = parseInt(this.activeMinute) - 1;
        },

        // 时间选择范围
        // eslint-disable-next-line complexity
        otherRangeDays(date) {
            if (!this.options.dateInput.dateRange) {
                return false;
            }

            if (this.options.dateInput.dateRange[0] && this.options.dateInput.dateRange[1]) {
                if (
                    new Date(date).getTime() < new Date(this.options.dateInput.dateRange[0]).getTime() ||
                    new Date(date).getTime() > new Date(this.options.dateInput.dateRange[1]).getTime()
                ) {
                    return true;
                }
                return false;
            } else if (this.options.dateInput.dateRange[0] && !this.options.dateInput.dateRange[1]) {
                if (new Date(date).getTime() < new Date(this.options.dateInput.dateRange[0]).getTime()) {
                    return true;
                }
                return false;
            } else if (!this.options.dateInput.dateRange[0] && this.options.dateInput.dateRange[1]) {
                if (new Date(date).getTime() > new Date(this.options.dateInput.dateRange[1]).getTime()) {
                    return true;
                }
                return false;
            }
        },

        inputHour(e) {
            const val = e.target.value;
            if (isNaN(val) || val.indexOf('.') > -1) {
                this.activeHour = Gikam.DateUtils.formatter(this.options.chooseDate || new Date(), 'hh');
                return;
            }
            if (val >= 24) {
                this.activeHour = '23';
            }
            if (val < 0) {
                this.activeHour = '00';
            }
            if (!isNaN(val) && !(val >= 24) && !(val < 0) && val.length === 2) {
                this.$refs['minute-input'].select();
            }
        },

        inputMinute(e) {
            const val = e.target.value;
            if (isNaN(val) || val.indexOf('.') > -1) {
                this.activeMinute = Gikam.DateUtils.formatter(this.options.chooseDate || new Date(), 'mm');
                return;
            }
            if (val >= 60) {
                this.activeMinute = '59';
            }
            if (val < 0) {
                this.activeMinute = '00';
            }
        },

        changeHour(e) {
            const val = e.target.value;
            if (val === '') {
                this.activeHour = Gikam.DateUtils.formatter(this.options.chooseDate || new Date(), 'hh');
                return;
            }
            const formatterVal = parseInt(val);
            if (formatterVal < 10) {
                this.activeHour = '0' + formatterVal;
            } else {
                this.activeHour = formatterVal;
            }
        },

        //处理数字00开头
        changeMinute(e) {
            const val = e.target.value;
            if (val === '') {
                this.activeMinute = Gikam.DateUtils.formatter(this.options.chooseDate || new Date(), 'mm');
                return;
            }
            const formatterVal = parseInt(val);
            if (formatterVal < 10) {
                this.activeMinute = '0' + formatterVal;
            } else {
                this.activeMinute = formatterVal;
            }
        }
    }
};
</script>

<style scoped>
@keyframes date-slide-bottom {
    from {
        transform: translate3d(0, 20px, 0);
        opacity: 0.3;
    }
    to {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

@keyframes date-slide-top {
    from {
        transform: translate3d(0, -20px, 0);
        opacity: 0.3;
    }
    to {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

button::-moz-focus-inner {
    border: none;
}

.date-picker {
    background-color: #fff;
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    font-family: 'Microsoft YaHei', serif;
    width: 248px;
    position: absolute;
    z-index: 10;
    animation: date-slide-bottom 0.3s;
}

.date-picker.top {
    animation: date-slide-top 0.3s;
}

.date-picker:before {
    content: '';
    position: absolute;
    top: -7px;
    left: 30px;
    width: 10px;
    height: 10px;
    background-color: #fff;
    transform: rotate(45deg);
    border: 1px solid #e5e5e5;
    border-bottom: none;
    border-right: none;
}

.date-picker.top:before {
    bottom: -7px;
    top: auto;
    transform: rotate(-135deg);
}

.date-picker > .ym-select {
    height: 36px;
    border-bottom: 1px solid #f0f0f0;
    display: flex;
    align-items: stretch;
    overflow: hidden;
    justify-content: space-between;
    padding: 0 16px;
}

.date-picker > .ym-select > .year,
.date-picker > .ym-select > .month {
    display: flex;
}

.date-picker > .ym-select button {
    background-color: #fff;
    border: none;
    outline: none;
    cursor: pointer;
    background-repeat: no-repeat;
    height: 100%;
    padding: 0;
}

.last-year-btn,
.last-month-btn {
    background-image: url('../../../../../img/date/left.png');
    width: 24px;
    background-position: 0 center;
}

.next-year-btn,
.next-month-btn {
    background-image: url('../../../../../img/date/right.png');
    width: 24px;
    background-position: 20px center;
}

.ym-container {
    display: flex;
    align-items: center;
    font-size: 14px;
}

.date-picker > .ym-select .ym-container > input {
    height: 30px;
    border: none;
    font-size: 14px;
    color: #000;
    text-align: center;
    font-family: 'Microsoft YaHei', serif;
}

.year-input {
    width: 37px;
}

.month-input {
    width: 20px;
}

.date-picker > .ym-select > .ym-container > input:focus {
    border: 1px solid #e5e5e5;
    border-radius: 4px;
}

.date-picker > .date-select-container {
    font-size: 12px;
    padding: 0 5px 5px;
}

.date-picker > .date-select-container > table {
    width: 100%;
}

.date-picker > .date-select-container > table thead td {
    text-align: center;
    color: #000;
    padding: 10px 0 16px 0;
}

.date-picker > .date-select-container > table >>> tbody > tr > td {
    cursor: pointer;
    font-size: 12px;
    vertical-align: middle;
    text-align: center;
}

.date-picker > .date-select-container > table >>> tbody > tr > td > .text {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    transition: all ease-out 0.3s;
    border-radius: 50%;
}

.date-picker > .date-select-container > table >>> tbody > tr > td.other-month-days {
    color: rgba(0, 0, 0, 0.5);
}

.date-picker > .date-select-container > table >>> tbody > tr > td.other-range-days {
    color: rgba(0, 0, 0, 0.5);
}

.date-picker > .date-select-container > table >>> tbody > tr > td.today > .text {
    background-color: #d6d6d6;
    border-radius: 50%;
    color: #007aff;
}

.date-picker > .date-select-container > table >>> tbody > tr > td.lastSelectedDay > .text {
    border-radius: 50%;
    border: 2px solid #007aff;
}

.date-picker > .date-select-container > table >>> tbody > tr > td.active > .text {
    background-color: #007aff;
    border-radius: 50%;
    color: #fff;
}

.date-picker > .date-select-container > table >>> tbody > tr > td:hover > .text {
    background-color: #007aff;
    color: #fff;
}

.date-picker > .date-select-container > table >>> tbody > tr > td.other-range-days:hover > .text {
    background-color: transparent;
    color: rgba(0, 0, 0, 0.5);
    cursor: default;
}

.date-picker > .select-today {
    border-top: 1px solid rgba(217, 217, 217, 0.5);
    height: 36px;
    display: flex;
    align-items: center;
    padding: 0 16px;
    justify-content: space-between;
}

.date-picker > .select-today > a {
    color: #007aff;
    font-size: 12px;
    text-decoration: none;
}

.time-container {
    height: 38px;
    padding: 0 36px;
    display: flex;
    align-items: center;
    border-top: 1px solid rgba(217, 217, 217, 0.5);
}

.time {
    height: 30px;
    display: flex;
    border-radius: 4px;
    border: 1px solid #d6d6d6;
    align-items: center;
    overflow: hidden;
}

.time .minute {
    display: flex;
    height: 100%;
    flex-grow: 1;
}

.time .hour {
    display: flex;
    height: 100%;
    width: 78px;
}

.time .switch {
    width: 25px;
    display: flex;
    flex-direction: column;
}

.time .split {
    height: 100%;
    width: 13px;
    text-align: center;
}

.time .switch .up {
    flex-direction: column;
    background: url(../../../../../img/date/up.png) no-repeat center;
    flex: 1;
    background-color: #d6d6d6;
    margin-top: -0.5px;
    cursor: pointer;
}

.time .switch .down {
    flex-direction: column;
    background: url(../../../../../img/date/down.png) no-repeat center;
    flex: 1;
    background-color: #d6d6d6;
    border-top: 1px solid #fff;
    margin-bottom: -1px;
    cursor: pointer;
}

.hour-input,
.minute-input {
    width: 50px;
    border: none;
    text-align: center;
    font-size: 14px;
    color: #000;
}

.confirm-btn,
.clean-btn {
    height: 24px;
    width: 52px;
    background-color: #007aff;
    color: #fff;
    border: 1px solid #d9d9d9;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    cursor: pointer;
}

.clean-btn {
    background-color: #fff;
    border: 1px solid #d9d9d9;
    margin-right: 10px;
    color: rgba(0, 0, 0, 0.65);
}

.btn-container {
    display: flex;
    align-items: center;
}
</style>
