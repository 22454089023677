<template>
    <div
        class="prop-panel"
        :style="styleObject"
        @mousedown.stop
        @click.stop
        @mousewheel.stop
        @wheel.stop
        @DOMMouseScroll.stop
    >
        <slot></slot>
    </div>
</template>

<script>
import Gikam from '../../../core/gikam-core';

const cleanPropPanel = () => {
    if (!window.top.Gikam || !window.top.Gikam.propPanel) {
        return;
    }
    window.top.Gikam.propPanel.$destroy();
    Gikam.removeDom(window.top.Gikam.propPanel.$el);
    window.top.Gikam.propPanel = null;
};
const wheel = document.onmousewheel !== undefined ? 'mousewheel' : 'DOMMouseScroll';
const eventNames = ['mousedown', 'scroll', wheel, 'resize'];
eventNames.forEach(item => {
    document.addEventListener(item, () => {
        window.top.Gikam.propPanel && window.top.Gikam.propPanel.clean();
        // cleanPropPanel();
    });
});
window.addEventListener('resize', () => {
    window.top.Gikam.propPanel && window.top.Gikam.propPanel.clean();
    // cleanPropPanel();
});

export default {
    props: {
        target: Node,
        top: {
            type: Number,
            default: null
        },
        left: {
            type: Number,
            default: null
        },
        width: {
            type: Number,
            default: null
        },
        height: {
            type: Number,
            default: null
        },
        propStyle: Object,
        listeners: Object
    },

    data() {
        return {
            react: this.target ? this.target.getBoundingClientRect() : null
        };
    },

    computed: {
        styleObject() {
            return {
                top: (this.top || this.react.top) + this.react.height + 'px',
                left: (this.left || this.react.left) + 'px',
                height: this.height ? this.height + 'px' : 'auto',
                width: (this.width || this.react.width) + 'px',
                ...this.propStyle
            };
        }
    },

    created() {
        this.clean();
        this.$emit('startEvent');
    },

    beforeDestroy() {
        this.$emit('closeEvent');
    },

    mounted() {
        this.$nextTick(() => {
            // 当出现计算的时候，不触发页面的resize事件
            window.top.document.body.style.overflow = 'hidden';
            const react = this.$el.getBoundingClientRect();
            if (react.left + react.width > window.top.document.body.clientWidth) {
                this.$el.style.left = react.left - (react.width - this.target.clientWidth) + 'px';
            }
            if (react.top + react.height > window.top.document.body.clientHeight) {
                this.$el.style.top = react.top - (react.height + this.target.clientHeight) - 20 + 'px';
            }
            if (window !== window.top) {
                const iframeRect = window.top.document.body
                    .querySelector(`[name=${window.name}]`)
                    .getBoundingClientRect();
                this.$el.style.top = parseInt(this.$el.style.top) + iframeRect.top + 'px';
                this.$el.style.left = parseInt(this.$el.style.left) + iframeRect.left + 'px';
            }
            window.top.document.body.style.overflow = '';
        });
        window.top.Gikam.propPanel = this;
    },

    methods: {
        clean() {
            this.listeners && this.listeners.beforeClean && this.listeners.beforeClean();
            cleanPropPanel();
        }
    }
};
</script>

<style scoped>
@keyframes date-slide-bottom {
    from {
        transform: translate3d(0, 20px, 0);
        opacity: 0.3;
    }
    to {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

.prop-panel {
    position: absolute;
    border-radius: 4px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    background-color: #fff;
    padding: 8px 0;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.65);
    animation: date-slide-bottom 0.3s;
    z-index: 11;
}

@media screen and (max-width: 576px) {
    .prop-panel {
        max-width: 100%;
        right: 0 !important;
        margin: 0 auto;
        /* overflow-x: scroll; */
    }
}
</style>
