<template>
    <div class="general-group" @click.stop>
        <generalGroup color="#1597a5" width="18px" height="18px" />
    </div>
</template>

<script>
export default {
    props: {},
    computed: {},

    methods: {}
};
</script>

<style scoped>
.general-group {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #aaa;
    margin: 2px 0;
    border-radius: 5px;
    cursor: pointer;
}
</style>
