import Gikam from '../../core/gikam-core.js';
import Base from '../base.js';

let defaultOptions = {
    //eln页面
    url: Gikam.IFM_CONTEXT + '/core/module/item/files/page/spread-eln'
};

export default class Eln extends Base {
    constructor(options) {
        super();
        this.initialize(options, defaultOptions).init();
    }

    setDefaultOptions(options) {
        Gikam.extend(true, defaultOptions, options.defaultOptions);
    }

    init() {
        const options = Gikam.deepExtend(this.options);
        delete options.url;
        window.open(this.options.url + Gikam.param(options));
    }
}
