import Vue from 'vue';
import Gikam from '../../core/gikam-core.js';
import Base from '../base.js';
import Mask from "../template/mask/mask";

let defaultOptions = {
    GC: null,
    designer: null,
    renderTo: null
};

export default class Eln extends Base {
    constructor(options) {
        super();
        this.initialize(options, defaultOptions);
    }

    /**
     * @description 上传生成PDF
     * @public
     * @memberof Eln
     */
    elnUploadPdf(tableName, bizId){
        if (tableName && bizId) {
            // 开启遮罩
            this.openMask();
            this.addFontsToFontManager();
            this.setPrintInfo();
            this.options.designer.wrapper.spread.savePDF((blob) => {
                var formData = new FormData();
                formData.append("file", new File([blob], this.options.renderTo.title.replace("ELN:", "") + ".pdf", {type:"application/pdf;charset=utf-8"}));
                formData.append("name", this.options.renderTo.title.replace("ELN:", "") + ".pdf"); // 文件名
                formData.append("targetId", tableName + "$" + bizId);
                formData.append("bizCategory", "SIGNATURE");
                
                Gikam.ajax({
                    url: Gikam.IFM_CONTEXT + "/core/module/item/files",
                    dataType:'json',
                    type:'POST',
                    async: false,
                    data: formData,
                    processData : false,
                    contentType : false
                }).done(() => {
                    Gikam.alert("PDF上传成功");
                }).fail(() => {
                    this.options.designer.MessageBox.show("PDF上传失败，重试或联系管理员", "提示", 3);
                })
            });
            // 关闭遮罩
            this.closeMask();
        }
    }

    /**
     * @description 设置导出pdf样式
     * @private
     * @memberof Eln
     */
    setPrintInfo() {
        let spread = this.options.designer.wrapper.spread;
        let sheetCount = spread.getSheetCount();
        let sheet;
        for (let i = 0; i < sheetCount; i++) {
            sheet = spread.getSheet(i);
            let rowCount = sheet.getRowCount();
            let rowStart = -1;
            let rowEnd = -1;
            for (let row = 0; row < rowCount; row++) {
                let tag = sheet.getTag(row, -1);
                if ("打印标题" == tag || (tag && "打印标题" == tag["打印标题"])) {
                    if (rowStart == -1) {
                        rowStart = row;
                    }
                    rowEnd = row;
                }
            }
            let printInfo = sheet.printInfo();
            printInfo.showGridLine(false);
            printInfo.orientation(this.options.GC.Spread.Sheets.Print.PrintPageOrientation.portrait);
            printInfo.showBorder(false);
            printInfo.showColumnHeader(this.options.GC.Spread.Sheets.Print.PrintVisibilityType.hide);
            printInfo.showRowHeader(this.options.GC.Spread.Sheets.Print.PrintVisibilityType.hide);
            printInfo.centering(this.options.GC.Spread.Sheets.Print.PrintCentering.horizontal);
            printInfo.paperSize(new this.options.GC.Spread.Sheets.Print.PaperSize(this.options.GC.Spread.Sheets.Print.PaperKind.a4));
            //针对现场导入的模板取消其原打印行数设置
            printInfo.rowEnd(-1);
            printInfo.columnEnd(-1);
            //页眉页脚
            printInfo.margin({ top: 80, bottom: 110, left: 20, right: 20, header: 20, footer: 40 });
            //针对现场导入的模板取消其页眉页脚
            printInfo.headerLeft("");
            printInfo.headerCenter("");
            printInfo.headerRight("");
            printInfo.footerCenter("");
            printInfo.footerLeft("");
            printInfo.footerRight("");
            if (rowStart > -1) {
                printInfo.repeatRowStart(rowStart);
            }
            if (rowEnd > -1) {
                printInfo.repeatRowEnd(rowEnd);
            }
        }
    }

    /**
     * @description 设置导出pdf字体（当前字体没有设置对应字体时执行）
     * @private
     * @memberof Eln
     */
    addFontsToFontManager() { 
        
    }

    /**
     * @description 打开遮罩
     * @private
     * @memberof Eln
     */
    openMask() {
        if (this.maskModel) {
            return;
        }
        this.maskModel = new Vue({
            el: Gikam.createDom("div", this.options.renderTo.body),
            components: { Mask },
            render() {
                return <Mask />;
            }
        });
    }

    /**
     * @description 关闭遮罩
     * @private
     * @memberof Eln
     */
    closeMask() {
        this.maskModel.$nextTick(() => {
            this.maskModel.$destroy();
            Gikam.removeDom(this.maskModel.$el);
            this.maskModel = null;
        });
    }
}