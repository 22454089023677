<template>
    <svg
        version="1.1"
        id="图层_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        :width="width"
        :height="height"
        viewBox="150 150 700 700"
        style="enable-background:new 0 0 1000 1000;"
        xml:space="preserve"
    >
        <path
            :fill="color"
            d="M483.8,198.8c-178.9,0-324,145.1-324,324s145.1,324,324,324s324-145.1,324-324S662.7,198.8,483.8,198.8z
    M503.4,722.3c-12.1,7-27.1,7-39.3,0c-12.1-7-19.6-20-19.6-34c0-14,7.5-27,19.6-34c12.1-7,27.1-7,39.3,0c12.1,7,19.6,20,19.6,34
    C523.1,702.3,515.6,715.3,503.4,722.3z M511.1,592.7c0,5.1-3.4,8.5-8.5,8.5h-35.8c-5.1,0-8.5-3.4-8.5-8.5l-11.9-259.4
    c-1.7-8.5,5.1-15.4,13.7-15.4h47.8c8.5,0,15.4,6.8,15.4,15.4L511.1,592.7z"
        />
    </svg>
</template>
<script>
import baseSvgImg from './baseSvgImg';
export default {
    extends: baseSvgImg,
    props: {
        color: {
            type: String,
            default: '#ffba00'
        }
    }
};
</script>
<style scoped>
svg {
    display: block;
}
</style>
