<template>
    <svg
        viewBox="0 0 23 17"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <g :fill="color">
            <path
                d="M22.5680091,1.41336761 L7.67854899,16.1383033 C7.59628678,16.2205656 7.43176235,16.2205656 7.34950014,16.1383033 L0.439474436,8.981491 C0.357212225,8.89922879 0.357212225,8.73470437 0.439474436,8.65244216 L1.42662097,7.33624679 C1.50888318,7.25398458 1.6734076,7.25398458 1.75566981,7.25398458 L7.34950014,11.5316195 C7.43176235,11.6138817 7.59628678,11.6138817 7.67854899,11.5316195 L21.334076,0.261696658 C21.4163382,0.179434447 21.5808626,0.179434447 21.6631248,0.261696658 L22.5680091,1.16658098 C22.6502714,1.16658098 22.6502714,1.3311054 22.5680091,1.41336761 Z"
                id="XMLID_2288_"
            ></path>
        </g>
    </svg>
</template>

<script>
export default {
    props: {
        color: {
            type: String,
            default: '#00D94D'
        }
    }
};
</script>
