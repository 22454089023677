<template>
    <svg
        version="1.1"
        id="图层_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        :width="width"
        :height="height"
        viewBox="0 0 42 42"
        style="enable-background:new 0 0 42 42;"
        xml:space="preserve"
    >
        <path
            id="XMLID_909_"
            class="st0"
            :fill="color"
            d="M2.9,40c-0.6-0.6-0.4-1.7,0.3-2.5L35.8,5c0.8-0.8,1.9-0.9,2.5-0.3s0.4,1.7-0.3,2.5L5.4,39.7
  C4.6,40.5,3.5,40.6,2.9,40z"
        />
        <path
            id="XMLID_928_"
            class="st0"
            :fill="color"
            d="M35.8,39.7L3.2,7.1C2.5,6.4,2.3,5.3,2.9,4.7S4.6,4.2,5.4,5l32.6,32.6c0.8,0.8,0.9,1.9,0.3,2.5
  S36.6,40.5,35.8,39.7z"
        />
    </svg>
</template>
<script>
import baseSvgImg from './baseSvgImg';
export default {
    extends: baseSvgImg,
    props: {
        color: {
            type: String,
            default: '#007aff'
        }
    }
};
</script>
<style scoped>
svg {
    display: block;
}
</style>
