import { render, staticRenderFns } from "./noticeImg.vue?vue&type=template&id=60cb8f1e&scoped=true&"
import script from "./noticeImg.vue?vue&type=script&lang=js&"
export * from "./noticeImg.vue?vue&type=script&lang=js&"
import style0 from "./noticeImg.vue?vue&type=style&index=0&id=60cb8f1e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60cb8f1e",
  null
  
)

export default component.exports