<template>
    <svg
        version="1.1"
        id="图层_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="9 0 38 38"
        style="enable-background:new 9 0 38 38;"
        xml:space="preserve"
        :width="width"
        :height="height"
    >
        <path
            id="XMLID_2288_"
            :fill="color"
            d="M42.4,10.7L24.3,28.6c-0.1,0.1-0.3,0.1-0.4,0l-8.4-8.7c-0.1-0.1-0.1-0.3,0-0.4l1.2-1.6
	c0.1-0.1,0.3-0.1,0.4-0.1l6.8,5.2c0.1,0.1,0.3,0.1,0.4,0L40.9,9.3c0.1-0.1,0.3-0.1,0.4,0l1.1,1.1C42.5,10.4,42.5,10.6,42.4,10.7z"
        />
    </svg>
</template>

<script>
import baseSvgImg from './baseSvgImg';
export default {
    extends: baseSvgImg,
    props: {
        color: {
            default: '#007AFF',
            type: String
        }
    }
};
</script>

<style scoped>
svg {
    display: block;
}
</style>
