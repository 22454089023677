<template>
    <div class="picture-field" :class="[align, { border }]">
        <div class="content" :style="style" @click="imgClickHandle">
            <img v-show="value" :src="contextVal" />
        </div>
    </div>
</template>

<script>
const defaultUploadConfig = {
    title: '图片上传',
    width: 550,
    height: 500,
    uploadUrl: '',
    bizCategory: null,
    toolbarAlign: ''
};

import Gikam from 'gikam';
export default {
    props: {
        options: Object,
        propValue: String,
        rowIndex: Number,
        cellIndex: Number,
        validateArg: Object,
        propReadonly: Boolean
    },

    data() {
        return {
            value: this.propValue || this.options.value || '',
            readonly: this.options.readonly !== undefined ? this.options.readonly : this.propReadonly
        };
    },

    computed: {
        style() {
            return {
                width: this.options.boxWidth ? this.options.boxWidth + 'px' : '',
                height: this.options.boxHeight ? this.options.boxHeight + 'px' : ''
            };
        },

        border() {
            return this.options.border || false;
        },

        align() {
            return this.options.align || '';
        },

        id() {
            return this.validateArg.id;
        },

        contextVal() {
            return Gikam.IFM_CONTEXT + this.value;
        }
    },

    watch: {
        value(val, oldValue) {
            this.$emit('change', this.options.field, val, this.rowIndex, oldValue);
        },

        propValue(val) {
            this.value = val;
        }
    },

    methods: {
        imgClickHandle() {
            if (!this.id || this.readonly) {
                return;
            }
            const config = Gikam.deepExtend(defaultUploadConfig, this.options.uploadConfig || {});
            config.onAfterClose = this.successChoose;
            config.bizId = this.id;
            Gikam.create('SimpleImageUpload', config);
        },

        successChoose(id) {
            Gikam.getJson(Gikam.IFM_CONTEXT + '/core/module/item/files/' + id).done(img => {
                this.value = img.downloadUrl;
            });
        }
    }
};
</script>

<style scoped lang="scss">
.picture-field {
    display: flex;
    .content {
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        overflow: hidden;
        cursor: pointer;
        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
}
.picture-field.border {
    border: 1px solid #d9d9d9;
}
.picture-field.left {
    justify-content: flex-start;
}
.picture-field.center {
    justify-content: center;
}
.picture-field.right {
    justify-content: flex-end;
}
</style>
