import Uploader from "./uploader";

export default class SimpleUploader extends Uploader {

    constructor(props) {
        super(props);
    }

    init() {
        if (this.trigger('beforeUpload') === false) {
            return;
        }
        this.showUploadModal();
    }

}