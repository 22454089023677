<template>
    <div class="header-level-one" :class="{ root: level === 0 }" :style="{ height: height + 'px' }">
        <template v-for="(col, index) in columns">
            <base-next-level-header
                v-if="col.subColumns"
                :key="col.field ? col.field + index : index"
                :cellField="col"
                :columns="col.subColumns"
                :level="level + 1"
            ></base-next-level-header>
            <base-header-cell v-else :key="col.field ? col.field + index : index" :cellField="col"></base-header-cell>
        </template>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: 'tableHeader',
    props: {
        columns: Array,
        level: Number
    },

    computed: {
        ...mapState(['headerHeightArr']),
        height() {
            return this.headerHeightArr[this.level];
        }
    },

    components: {
        baseNextLevelHeader: () => import('./nextLevelHeader'),
        baseHeaderCell: () => import('./headerCell')
    }
};
</script>

<style scoped>
.header-level-one {
    height: 100%;
    height: 100%;
    display: flex;
}

.header-level-one.root {
    position: relative;
}

.header-level-one.root::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    border-right: 1px solid #d9d9d9;
}
</style>
