<template>
    <div class="btn-group" :style="{ padding: options.padding, margin: options.margin }">
        <btn v-for="(item, index) in options.items" :key="index" :options="item"></btn>
    </div>
</template>

<script>
import btn from './button.vue';

export default {
    props: {
        options: Object
    },

    components: { btn }
};
</script>

<style scoped>
.btn-group >>> .btn-container:not(:last-child) {
    margin-right: 8px;
}
</style>
