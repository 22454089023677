import Gikam from '../core/gikam-core.js';
import Vue from 'vue';
import Base from './base';

let defaultOptions = {
    id: void 0,
    images: void 0
};

export default class Viewer extends Base {
    constructor(props) {
        super(props);
        this.initialize(props, defaultOptions).init(props);
    }

    createModel() {
        const _this = this;
        return new Vue({
            el: Gikam.createDom('div', this.options.renderTo),
            components: { viewer: () => import('./template/viewer.vue') },
            render() {
                return <viewer images={_this.options.images}></viewer>;
            }
        });
    }

    init() {
        this.createModel();
    }
}
