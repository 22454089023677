import Base from '../../../base';
import Gikam from '../../../../core/gikam-core';
import Vue from 'vue';

// 首页面板
let defaultOptions = {
    renderTo: void 0,
    data: [],
    onClick: void 0,
    //待办请求地址
    url: void 0
};

export default class TodoPanel extends Base {
    constructor(options) {
        super(options);
        this.initialize(options, defaultOptions).init();
    }

    createViewModel() {
        const _this = this;
        this.countSize();
        // 窗口改变事件
        Gikam.jQuery(window).resize(() => {
            this.countSize();
        });

        this.model = new Vue({
            el: Gikam.createDom('div', this.options.renderTo),
            render() {
                return <todo-panel ref="todoPanel" onMounted={this.mountedHandle} options={this.options}></todo-panel>;
            },
            components: {
                todoPanel: () => import('@/gikam/js/components/huge/todoPanel/vue/todoPanel.vue')
            },
            data() {
                return {
                    options: _this.options
                };
            },
            methods: {
                mountedHandle() {
                    _this.bindInstance(this.$el);
                }
            }
        });
    }

    // 计算宽高
    countSize() {
        this.options.width = Gikam.jQuery(this.options.renderTo).width();
        this.options.height = Gikam.jQuery(this.options.renderTo).height();
    }

    /**
     * @description 动态添加面板上数据
     * @public
     * @param {Array} list 数组数据
     * @memberof TodoPanel
     */
    setData(data) {
        this.options.data = data;
    }

    /**
     * @description 初始化待办数据
     * @private
     * @memberof TodoPanel
     */
    initTodo() {
        const getTodoList = () => {
            Gikam.post(this.options.url || Gikam.IFM_CONTEXT + '/core/module/sys/todos').done(data => {
                this.options.data = data.rows;
            });
        };

        Gikam.getConstantValue('WORKSPACE-REFRESH-FREQUENCY').done(r => {
            getTodoList();
            top.Gikam.addInterVal(getTodoList.bind(this), Math.round(1000 * 60 * parseFloat(r.constantValue || 0.5)));
        });
    }

    init() {
        this.createViewModel();
        this.model.$nextTick(() => this.initTodo());
    }
}
