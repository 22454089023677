<template>
    <!-- 审计跟踪时间轴组件 -->
    <div class="audit-trail" :id="options.id">
        <scroll>
            <div class="audit-item" v-for="item in auditList" :key="item.id">
                <div class="name">
                    <p :title="item.createdByName">{{ item.createdByName }}</p>
                    <p>{{ item.createdTime }}</p>
                </div>
                <div class="icon"></div>
                <div class="text">{{ getI18N(item.newValue) }}</div>
            </div>
        </scroll>
    </div>
</template>
<script>
import Gikam from '../../core/gikam-core';

export default {
    name: 'eln-audit-trail',
    props: {
        options: Object
    },

    data() {
        return {
            auditList: this.options.list
        };
    },

    watch: {
        options: {
            handler(options) {
                this.auditList = options.list;
            },
            deep: true
        }
    },

    methods: {
        getI18N(text) {
            return Gikam.propI18N(text);
        }
    }
};
</script>
<style scoped>
.audit-trail {
    width: 100%;
    height: 100%;
    min-width: 210px;
    padding-top: 16px;
    box-sizing: border-box;
}

.audit-trail .audit-item {
    width: 100%;
    padding: 0 16px;
    margin-bottom: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
}

.audit-trail .audit-item:last-child {
    margin-bottom: 0;
}

/* 左侧的人员信息 */
.audit-trail .audit-item .name {
    text-align: right;
    /* 按照时间的宽度设计 */
}

.audit-trail .audit-item .name > p {
    width: 112px;
    font-size: 12px;
    line-height: 12px;
    font-family: 'PingFang-SC-Regular', Arial, Helvetica, sans-serif;
    color: rgba(0, 0, 0, 0.65);
    margin: 0;
    margin-bottom: 6px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.audit-trail .audit-item .name > p:last-child {
    font-family: 'ArialMT', Arial, Helvetica, sans-serif;
    margin-bottom: 0;
}

/* 中间的icon */
.audit-trail .audit-item .icon {
    width: 16px;
    height: 16px;
    margin: 0 8px;
    background: url(../../../img/choose/audit.svg) no-repeat center;
    background-size: 18px;
}

/* 右边的文本 */
.audit-trail .audit-item .text {
    display: flex;
    align-items: center;
    min-height: 30px;
    flex: 1;
    font-size: 12px;
    font-family: 'PingFang-SC-Regular', Arial, Helvetica, sans-serif;
    color: rgba(0, 0, 0, 0.65);
    padding: 5px 8px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    position: relative;
}

.audit-trail .audit-item .text::before {
    height: calc(100% / 2 + 13px);
    position: absolute;
    content: '';
    top: calc(100% / 2 + 8px);
    left: -19px;
    bottom: 0;
    border: none;
    border-left: 1px solid #d9d9d9;
}

.audit-trail .audit-item .text::after {
    height: calc(100% / 2);
    position: absolute;
    content: '';
    top: -10px;
    left: -19px;
    bottom: 0;
    border: none;
    border-left: 1px solid #d9d9d9;
}

.audit-trail .audit-item:first-child .text::after {
    display: none;
}</style
>>
