<template>
    <svg
        :width="width"
        :height="height"
        viewBox="0 0 57 114"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <g id="timeup-state" transform="translate(-383.000000, -688.000000)" :fill="color">
            <polygon
                id="choose"
                transform="translate(383.000000, 745.000000) scale(-1, 1) rotate(-45.000000) translate(-383.000000, -745.000000) "
                points="343 705 423 705 343 785"
            />
        </g>
    </svg>
</template>

<script>
import baseSvgImg from './baseSvgImg';
export default {
    extends: baseSvgImg,
    props: {
        color: {
            type: String,
            default: '#ddd'
        },
        width: {
            type: [Number, String],
            default: '100%'
        },
        height: {
            type: [Number, String],
            default: '100%'
        }
    }
};
</script>

<style scoped>
svg {
    display: block;
    transform: rotate(-90deg);
}
</style>
