<template>
    <div
        class="date-input"
        :class="{ 'validate-error': !validateResult, readonly: readonly, invisible: invisible }"
        @dblclick="dblclickHandle"
        @mousedown.stop
        @click="clickHandle"
    >
        <div v-if="readonly || invisible" class="readonly-text">{{ value }}</div>
        <template v-else>
            <input ref="input" :name="options.field" v-model="value" :readonly="editable" autocomplete="off" />
            <div class="date-input-icon" @click.stop="focus"></div>
        </template>
        <div class="validate-error-icon" v-if="validateResult === false" :class="{ input: !readonly }" @click.stop>
            <info-image @mouseenter.stop="errorIconMouseenter" @mouseleave.stop="errorIconMouseleave"></info-image>
        </div>
    </div>
</template>
<script>
import Gikam from '../../../../core/gikam-core';
import infoImage from '../../img/info.vue';

export default {
    name: 'dateField',
    props: {
        options: Object,
        propValue: String,
        rowIndex: Number,
        cellIndex: Number,
        //编辑器中的边框等样式是否显示出来
        propInvisible: {
            type: Boolean,
            default: false
        }
    },
    components: { infoImage },

    computed: {
        editable() {
            return this.options.editable === true ? false : 'readonly';
        }
    },

    data() {
        return {
            validateResult: true,
            readonly: this.options.readonly,
            field: this.options.field,
            value: Gikam.isNotEmpty(this.propValue) ? this.propValue : this.options.value ? this.options.value : '',
            invisible: this.propInvisible,
            dateRange: this.options.dateRange
        };
    },

    watch: {
        value(val, oldVal) {
            if (val == oldVal) {
                return;
            }
            this.validateResult = true;
            this.$emit('change', this.options.field, this.value, this.rowIndex);
        },

        propValue(val) {
            this.value = val;
        }
    },

    methods: {
        popDatePicker() {
            if (this.datePicker) {
                return;
            }

            if (!Gikam.isTrue(this.propInvisible)) {
                Gikam.simulatedEvent(document, 'mousedown');
            }

            if (this.readonly) {
                return;
            }

            let datePicker = document.body.querySelector('.date-picker');
            Gikam.getInstance(datePicker) && Gikam.getInstance(datePicker).destroy();
            if (this.options.onBeforeSelect) {
                this.options.onBeforeSelect.apply(this.form || this.grid);
            }
            this.datePicker = Gikam.create('DatePicker', {
                dateInput: this,
                date: Gikam.DateUtils.toDate(this.value),
                chooseDate: Gikam.DateUtils.toDate(this.value),
                type: this.options.type,
                closeEvent: () => {
                    this.focus();
                }
            });
        },

        setDateRange(dateRange) {
            this.dateRange = dateRange;
        },

        dblclickHandle() {
            this.$emit('dblclick', this);
        },

        dumpActiveCell() {
            this.datePicker && this.datePicker.destroy();
            this.$refs.input && this.$refs.input.blur();
        },

        activeCell() {
            if (this.invisible) {
                this.$emit('click', this);
            } else {
                this.focus();
            }
        },

        focus() {
            this.$refs.input && this.$refs.input.focus();
            this.popDatePicker();
            this.$emit('saveCoordinate');
        },

        //点击时保存当前单元格的坐标
        clickHandle() {
            if (!this.invisible) {
                this.$emit('saveCoordinate');
            } else {
                this.$emit('click', this);
            }
        }
    }
};
</script>

<style scoped>
.date-input {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: stretch;
    color: #666;
    font-size: 12px;
}

.date-input.readonly {
    display: flex;
    align-items: center;
}

.date-input input,
.date-input .readonly-text {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    padding-right: 30px;
    padding-left: 8px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 12px;
}

.date-input .readonly-text {
    background-color: #f4f4f4;
    display: flex;
    align-items: center;
}

.date-input.validate-error .readonly-text,
.date-input.validate-error input {
    border-color: #ff6e6e;
}

.date-input input:focus {
    border: 1px solid rgba(0, 122, 255, 0.5);
}

.date-input .date-input-icon {
    width: 30px;
    background: url(../../../../../img/date/date.svg) no-repeat center;
    cursor: pointer;
    margin-left: -30px;
    background-size: 18px;
}

.invisible .readonly-text {
    background-color: transparent;
    border: none;
}

.date-input .validate-error-icon {
    margin-left: -25px;
    display: flex;
    align-items: center;
}
.date-input.validate-error input {
    padding-right: 53px;
}
.date-input .validate-error-icon.input {
    margin-left: -48px;
}

.date-input .validate-error-icon svg {
    height: 20px;
    cursor: pointer;
}
</style>
