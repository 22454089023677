<template>
    <div
        id="drop-down-menu"
        class="picker-container"
        :class="{ 'mask-position': propMaskPosition }"
        :style="{ top, right, left, width, height: containerHeight }"
        @mouseleave.stop="close"
        @touchmove.stop
        @mouseenter="enterPicker = true"
    >
        <div class="combo-box-picker" :style="{ height: propHeight + 'px' }">
            <scroll :innerPanel="true">
                <ul ref="pickerContainer">
                    <template v-for="(item, index) in items">
                        <li class="btn-more" v-if="item.hidden === false" :key="index" @click.stop="clickHandle(item)">
                            <span class="icon" v-if="item.icon" :class="['icon-' + item.icon, item.color]">
                                <slot></slot>
                            </span>
                            {{ getI18N(item.text) }}
                        </li>
                    </template>
                </ul>
            </scroll>
        </div>
    </div>
</template>

<script>
import Gikam from '../../../core/gikam-core';

export default {
    props: {
        propItems: Array,
        propTop: Number,
        propRight: Number,
        propLeft: Number,
        propWidth: Number,
        propHeight: Number,
        propFragment: Array,
        propMaskPosition: Boolean,
        propEnterButton: Boolean
    },

    computed: {
        items() {
            const items = [];
            this.propItems.forEach(item => {
                if (item.type === 'btnGroup') {
                    item.items.forEach(list => {
                        list.type === 'button' && items.push(list);
                    });
                } else if (item.type === 'button') {
                    items.push(item);
                }
            });
            return items;
        },

        enterButton() {
            return this.propEnterButton;
        },

        containerHeight() {
            return this.propHeight + 30 + 'px';
        }
    },

    mounted() {
        this.addBtns();
        setTimeout(() => {
            if (!this.enterButton && !this.enterPicker && Gikam.dropDownMenuPicker) {
                Gikam.dropDownMenuPicker.close();
            }
        }, 200);
    },

    data() {
        return {
            top: this.propTop + 'px',
            right: this.propRight ? this.propRight + 'px' : null,
            left: this.propRight ? null : this.propLeft + 'px',
            width: this.propWidth + 'px',
            enterPicker: false
        };
    },

    methods: {
        getI18N(text) {
            return Gikam.propI18N(text);
        },

        clickHandle(item) {
            item.onClick && item.onClick();
            Gikam.dropDownMenuPicker.close();
        },

        addBtns() {
            const $el = this.$refs.pickerContainer;
            if (this.propFragment.length) {
                for (let i = this.propFragment.length; i > 0; i--) {
                    const $li = document.createElement('li');
                    $li.classList.add('btn-more');
                    $li.appendChild(this.propFragment[i - 1]);
                    $el.appendChild($li);
                }
            }
        },

        close() {
            this.enterPicker = false;
            Gikam.dropDownMenuPicker && Gikam.dropDownMenuPicker.close();
        }
    }
};
</script>

<style scoped>
@keyframes slide-bottom {
    from {
        transform: translate3d(0, 20px, 0);
        opacity: 0.3;
    }
    to {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

@keyframes slide-top {
    from {
        opacity: 0.3;
    }
    to {
        opacity: 1;
    }
}
.picker-container {
    position: absolute;
    z-index: 11;
    padding-top: 25px;
}

.picker-container.mask-position {
    padding-top: 0;
}

.combo-box-picker {
    position: absolute;
    right: 8px;
    margin-top: 1px;
    animation: slide-bottom 0.2s;
    min-width: 80px;
    overflow: hidden;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.08);
    box-sizing: content-box;
    background-color: #fff;
}

.combo-box-picker ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    background-color: white;
    min-height: 32px;
    border-radius: 4px;
}

.combo-box-picker ul li {
    height: 32px;
    font-family: 'Microsoft YaHei', serif;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.65);
    display: flex;
    align-items: center;
    padding: 0 8px;
    cursor: pointer;
    white-space: nowrap;
}

.combo-box-picker ul li.current-value {
    color: #000;
}

.combo-box-picker ul li:hover {
    background-color: rgba(0, 122, 255, 0.08);
}

.combo-box-picker ul li .icon {
    margin-right: 2px;
    font-size: 16px;
}
</style>
