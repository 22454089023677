<template>
    <div class="grid-footer" v-if="propPage">
        <div class="grid-page-toolbar">
            <div
                :title="$i18n('imgGrid.lastPage')"
                class="button last-page"
                :class="{ disabled: pageNum === 1 }"
                @click="toPage(pageNum - 1)"
            >
                <lastPageImg v-bind="{ color: '#999', width: '14px', height: '14px' }" />
            </div>
            <input class="page-num-input" :value="pageNum" @change="toPage($event.target.value)" />
            <span class="total-pages">&nbsp;/&nbsp;{{ totalPage }}&nbsp;</span>
            <div
                :title="$i18n('imgGrid.nextPage')"
                class="button next-page"
                :class="{ disabled: pageNum === totalPage }"
                @click="toPage(pageNum + 1)"
            >
                <nextPageImg v-bind="{ color: '#999', width: '14px', height: '14px' }" />
            </div>
            <selectField
                :options="{ items: formatterPageList, value: pageSize }"
                @change="pageSizeChangeHandle"
            ></selectField>
            <span class="total-record">{{
                $i18n('imgGrid.totalRecord') + ' ' + totalRecord + ' ' + $i18n('imgGrid.totalRecordItem')
            }}</span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        propPage: Object
    },

    data() {
        return {
            pageNum: this.propPage.pageNumber || 1,
            totalRecord: this.propPage.total || 1,
            pageList: [50, 100, 200, 'grid.all.data'],
            pageSize: 50
        };
    },

    watch: {
        'propPage.pageNumber'(num) {
            this.pageNum = num;
            if (num > this.totalPage) {
                this.pageNum = this.totalPage;
            }
        },

        'propPage.total'(total) {
            this.totalRecord = total;
        }
    },

    computed: {
        totalPage() {
            return Math.ceil(this.totalRecord / this.pageSize) || 1;
        },

        formatterPageList() {
            return this.pageList.map(item => {
                return {
                    value: item,
                    text: item === 'grid.all.data' ? this.$i18n(item) : item + this.$i18n('imgGrid.pageValue')
                };
            });
        }
    },

    methods: {
        toPage(pageNum) {
            this.pageNum = pageNum;
            if (pageNum > this.totalPage) {
                this.pageNum = this.totalPage;
            }
            this.$emit('updatePageNum', pageNum);
        },

        pageSizeChangeHandle(_field, value) {
            this.pageNum = 1;
            this.pageSize = value;
            this.$emit('updatePageSize', value);
        }
    }
};
</script>

<style scoped>
.grid-footer {
    height: 36px;
    display: flex;
    align-items: center;
    position: relative;
    padding-bottom: 2px;
}

.grid-footer > .grid-page-toolbar {
    width: 100%;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(0, 0, 0, 0.65);
    font-size: 12px;
}

.grid-footer > .grid-page-toolbar > .button {
    border: none;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
}

.grid-footer > .grid-page-toolbar > .page-num-input {
    width: 42px;
    height: 24px;
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    text-align: center;
    color: #666;
    margin-right: 12px;
    margin-left: 13px;
    font-size: 12px;
}

.grid-footer > .grid-page-toolbar > .page-num-input:focus {
    border: 1px solid rgba(0, 122, 255, 0.5);
}

.grid-footer > .grid-page-toolbar > .total-record {
    color: rgba(0, 0, 0, 0.45);
    margin-left: 16px;
}

.grid-footer > .grid-page-toolbar > .select {
    width: 100px;
    height: 24px;
    padding: 0;
    margin-left: 21px;
    margin-left: 12px;
}

.grid-footer > .grid-page-toolbar > .total-pages {
    margin-right: 10px;
}

.grid-footer > .grid-page-toolbar > .button.disabled {
    opacity: 0.3;
    pointer-events: none;
}

.grid-footer > .grid-page-toolbar > .button.last-page,
.grid-footer > .grid-page-toolbar > .button.next-page {
    width: 20px;
    height: 24px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.grid-footer > .grid-footer-toolbar {
    position: absolute;
    right: 28px;
    display: flex;
    align-items: center;
}

.grid-footer > .grid-footer-toolbar > .item {
    width: 20px;
    height: 20px;
    background-position: center;
    background-repeat: no-repeat;
    margin-left: 8px;
    cursor: pointer;
}
</style>
