import Base from './base.js';
import Gikam from '../core/gikam-core';
import Vue from 'vue';
import shuttleFrame from './template/shuttleFrame/shuttleFrame.vue';

// 穿梭框
let defaultOptions = {
    // 左右表头-当rightColumns不存在时
    columns: void 0,
    // 右表头
    rightColumns: void 0,
    // 左侧grid的列表数据
    data: [],
    // 右侧grid的列表数据
    rightData: [],
    // 向右添加的数据
    addList: [],
    // 向左删除的数据
    delList: [],
    // 默认打开快捷查询
    filterOpen: true,
    // 左grid url
    leftGridUrl: void 0,
    // 右grid url
    rightGridUrl: void 0,
    leftRequestData: void 0,
    rightRequestData: void 0,
    // 添加函数 =>
    onAddClick: void 0,
    // 删除函数 <=
    onDelClick: void 0,
    // 添加时的校验函数
    onBeforeAdd: () => true,
    // 删除时的校验函数
    onBeforeDel: () => true,
    // 加载数据完成
    onLeftLoadSuccess: void 0,
    onRightLoadSuccess: void 0,
    // 左侧grid去重字段，默认id
    delRepeatField: 'id',
    // 是否分页
    page: false,
    leftTitle: '',
    rightTitle: ''
};

export default class ShuttleFrame extends Base {
    constructor(options) {
        super(options);
        this.getListeners();
        this.initialize(options, defaultOptions).init();
    }

    getListeners() {
        this.listeners = {
            rendered: Gikam.emptyFunction
        };
    }

    createViewModel() {
        const _this = this;

        let shuttleModel = new Vue({
            el: Gikam.createDom('div', this.options.renderTo),
            provide: {
                shuttleFrame: this
            },
            render() {
                return <shuttle-frame ref="shuttleFrame" options={this.options}></shuttle-frame>;
            },
            components: {
                shuttleFrame
            },
            data() {
                return {
                    options: _this.options
                };
            }
        });
        this.createLeftGrid(shuttleModel);
        this.createRightGrid(shuttleModel);
        this.bindInstance(shuttleModel.$el);
        this.trigger('rendered');
        this.options.rightOriginalData = this.options.rightData;
        this.setLeftData(this.options.data);
    }

    // 创建左侧grid
    createLeftGrid(shuttleModel) {
        let _this = this;
        this.leftGrid = Gikam.create('grid', {
            renderTo: shuttleModel.$refs.shuttleFrame.$refs['left-grid'],
            id: 'left-grid',
            fill: true,
            url: _this.options.leftGridUrl,
            requestData: _this.options.leftRequestData,
            page: _this.options.page,
            filterOpen: _this.options.filterOpen,
            columns: _this.options.columns,
            columnsFill: true,
            onAllSelect() {
                _this.options.addList = _this.leftGrid.model.$children[0].getSelections();
            },
            onUnAllSelect() {
                _this.options.addList = _this.leftGrid.model.$children[0].getSelections();
            },
            onCheckboxClick(data) {
                _this.options.addList = _this.leftGrid.model.$children[0].getSelections();
                // 判断单双击，全选双击不处罚add or del事件
                if (_this.clickFlag && _this.clickFlag === data.id) {
                    if (typeof data === 'boolean') {
                        return;
                    }
                    clearTimeout(_this.clickFlag);
                    _this.clickFlag = null;
                    _this.options.addList = [data];
                    shuttleModel.$refs.shuttleFrame.add();
                    return;
                }
                _this.clickFlag = data.id;
                setTimeout(() => {
                    _this.clickFlag = null;
                }, 300);
            },
            onBeforeLoad() {
                if (_this.leftTotalRecord) {
                    this.backData.total = _this.leftTotalRecord;
                }
                if (_this.leftTotalPage) {
                    this.options.totalPage = _this.leftTotalPage;
                }
            },
            onLoadSuccess(data) {
                Gikam.finalDelay(
                    'leftLoadSuccess',
                    () => {
                        let allData = _this.leftGrid.getData();
                        _this.options.onLeftLoadSuccess && _this.options.onLeftLoadSuccess(allData);
                    },
                    100
                );
                if (!data) {
                    let gridFilter = _this.leftGrid.model.$refs.vm.$refs.header;
                    _this.leftOriginalDataHandle();
                    let allData = _this.options.leftOriginalData;
                    let filters = gridFilter.filterOriginalData;
                    let keys = Object.keys(filters);
                    if (keys.length > 0) {
                        let filtersData = allData.filter(item => {
                            return keys.every(key => {
                                if (key.indexOf('ext$') !== -1) {
                                    let type = key.split('.');
                                    item.ext$[type[1]] = item.ext$[type[1]] ? item.ext$[type[1]] : '';
                                    return item.ext$[type[1]].indexOf(filters[key]) !== -1;
                                } else {
                                    item[key] = item[key] ? item[key] + '' : '';
                                    return item[key].indexOf(filters[key]) !== -1;
                                }
                            });
                        });
                        _this.setLeftData(filtersData, true);
                    }
                    return;
                }

                if (!_this.options.chooseGrid) {
                    let n = _this.options.data.filter(
                        item =>
                            !data.some(obj => obj[_this.options.delRepeatField] === item[_this.options.delRepeatField])
                    );
                    if (_this.options.data.length !== data.length || n.length > 0) {
                        _this.setLeftData(data, true);
                    }
                }
                _this.options.chooseGrid = false;
            }
        });
    }

    // 创建右侧grid
    createRightGrid(shuttleModel) {
        let _this = this;
        this.rightGrid = Gikam.create('grid', {
            renderTo: shuttleModel.$refs.shuttleFrame.$refs['right-grid'],
            id: 'right-grid',
            fill: true,
            url: _this.options.rightGridUrl,
            requestData: _this.options.rightRequestData,
            page: _this.options.page,
            filterOpen: _this.options.filterOpen,
            columns: _this.options.rightColumns ? _this.options.rightColumns : _this.options.columns,
            data: _this.options.rightData,
            columnsFill: true,
            onAllSelect() {
                _this.options.delList = _this.rightGrid.model.$children[0].getSelections();
            },
            onUnAllSelect() {
                _this.options.delList = _this.rightGrid.model.$children[0].getSelections();
            },
            onCheckboxClick(data) {
                _this.options.delList = _this.rightGrid.model.$children[0].getSelections();
                // 判断单双击，全选双击不处罚add or del事件
                if (_this.clickFlag && _this.clickFlag === data.id) {
                    if (typeof data === 'boolean') {
                        return;
                    }
                    clearTimeout(_this.clickFlag);
                    _this.clickFlag = null;
                    _this.options.delList = [data];
                    shuttleModel.$refs.shuttleFrame.del();
                    return;
                }
                _this.clickFlag = data.id;
                setTimeout(() => {
                    _this.clickFlag = null;
                }, 300);
            },
            onBeforeLoad() {
                if (_this.rightTotalRecord) {
                    this.backData.total = _this.rightTotalRecord;
                }
                if (_this.rightTotalPage) {
                    this.options.totalPage = _this.rightTotalPage;
                }
            },
            onLoadSuccess(data) {
                Gikam.finalDelay(
                    'rightLoadSuccess',
                    () => {
                        let allData = _this.rightGrid.getData();
                        _this.options.onRightLoadSuccess && _this.options.onRightLoadSuccess(allData);
                    },
                    100
                );
                if (!data) {
                    let gridFilter = _this.rightGrid.model.$refs.vm.$refs.header;
                    _this.rightOriginalDataHandle();
                    let allData = _this.options.rightOriginalData;
                    let filters = gridFilter.filterOriginalData;
                    let keys = Object.keys(filters);
                    if (keys.length > 0) {
                        let filtersData = allData.filter(item => {
                            return keys.every(key => {
                                if (key.indexOf('ext$') !== -1) {
                                    let type = key.split('.');
                                    item.ext$[type[1]] = item.ext$[type[1]] ? item.ext$[type[1]] : '';
                                    return item.ext$[type[1]].indexOf(filters[key]) !== -1;
                                } else {
                                    item[key] = item[key] ? item[key] : '';
                                    return item[key].indexOf(filters[key]) !== -1;
                                }
                            });
                        });
                        _this.setRightData(filtersData, true);
                    }
                    return;
                }
            }
        });
    }

    // 左侧的快捷查询跟数据
    leftOriginalDataHandle() {
        this.options.leftOriginalData = this.options.leftOriginalData.filter(
            item =>
                !this.options.rightOriginalData.some(
                    obj => obj[this.options.delRepeatField] === item[this.options.delRepeatField]
                )
        );
        this.options.leftOriginalData = this.options.leftOriginalData.filter(
            item =>
                !this.options.data.some(obj => obj[this.options.delRepeatField] === item[this.options.delRepeatField])
        );
        this.options.leftOriginalData = [...this.options.leftOriginalData, ...this.options.data];
    }

    // 右侧的快捷查询跟数据
    rightOriginalDataHandle() {
        this.options.rightOriginalData = this.options.rightOriginalData.filter(
            item =>
                !this.options.data.some(obj => obj[this.options.delRepeatField] === item[this.options.delRepeatField])
        );
        this.options.rightOriginalData = this.options.rightOriginalData.filter(
            item =>
                !this.options.rightData.some(
                    obj => obj[this.options.delRepeatField] === item[this.options.delRepeatField]
                )
        );
        this.options.rightOriginalData = [...this.options.rightOriginalData, ...this.options.rightData];
    }

    // 设置左初始选项
    setLeftData(data, type) {
        if (!type) {
            this.options.leftOriginalData = data;
        }
        this.options.data = data;
        if (this.leftGrid) {
            this.initData();
        }
    }

    // 设置右初始选项
    setRightData(rightData, type) {
        if (!type) {
            this.options.rightOriginalData = rightData;
        }
        this.options.rightData = rightData;
        this.rightGrid.loadData(this.options.rightData);
        if (this.rightGrid) {
            this.initData();
        }
    }

    // 初始化grid
    initData() {
        this.options.leftGridUrl && (this.options.data = this.leftGrid.options.data);
        this.options.rightGridUrl && (this.options.rightData = this.rightGrid.options.data);
        this.options.data = this.options.data.filter(
            item =>
                !this.options.rightData.some(
                    obj => obj[this.options.delRepeatField] === item[this.options.delRepeatField]
                )
        );

        this.refreshData();
    }

    refreshData() {
        if (this.leftGrid.store.state.totalRecord && this.options.page) {
            let total = this.leftTotalRecord || this.leftGrid.store.state.totalRecord;
            this.leftTotalRecord = total;
            this.leftTotalPage =
                Math.ceil(this.leftGrid.store.state.totalRecord / this.leftGrid.store.state.pageSize) || 1;
            this.leftGrid.refreshByData({
                total: total,
                rows: this.options.data
            });
        } else {
            this.leftGrid.loadData(this.options.data);
        }

        if (this.rightGrid.store.state.totalRecord && this.options.page) {
            let total = this.rightTotalRecord || this.rightGrid.store.state.totalRecord;
            this.rightTotalRecord = total;
            this.rightTotalPage =
                Math.ceil(this.rightGrid.store.state.totalRecord / this.rightGrid.store.state.pageSize) || 1;
            this.rightGrid.refreshByData({
                total: total,
                rows: this.options.rightData
            });
        } else {
            this.rightGrid.loadData(this.options.rightData);
        }
    }

    // 最终返回结果-右grid
    getSelectList() {
        return this.options.rightOriginalData;
    }

    /**
     * 清空穿梭框的搜索
     * @public
     * @param { String } type: left/right
     * @memberof shuttleFrame
     */
    resetFilter(type) {
        if (type !== 'right') {
            this.leftGrid.model.$refs.vm.$refs.header.resetFilter();
        }
        if (type !== 'left') {
            setTimeout(() => {
                // 两个清空要等一个执行完毕，在进行下一个
                this.rightGrid.model.$refs.vm.$refs.header.resetFilter();
            }, 200);
        }
    }

    init() {
        this.createViewModel();
    }
}
