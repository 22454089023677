<template>
    <svg
        version="1.1"
        id="图层_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 48 48"
        style="enable-background:new 0 0 48 48;"
        xml:space="preserve"
    >
        <path :fill="color" d="M8,27c-1.6,0-3-1.4-3-3s1.4-3,3-3s3,1.4,3,3S9.6,27,8,27z M8,24C8,24,8,24,8,24L8,24z" />
        <path
            :fill="color"
            d="M24,27c-1.6,0-3-1.4-3-3s1.4-3,3-3s3,1.4,3,3S25.6,27,24,27z M24,24C24,24,24,24,24,24L24,24z"
        />
        <path
            :fill="color"
            d="M40,27c-1.6,0-3-1.4-3-3s1.4-3,3-3s3,1.4,3,3S41.6,27,40,27z M40,24C40,24,40,24,40,24L40,24z"
        />
    </svg>
</template>

<script>
export default {
    props: {
        color: {
            type: String,
            default: '#999'
        }
    }
};
</script>
