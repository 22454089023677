import Base from './base.js';
import Gikam from "../core/gikam-core";
import Vue from 'vue';

// 地铁导航
let defaultOptions = {
    data: void 0,
    onClick: void 0
};

export default class SubwayNav extends Base {
    constructor(options) {
        super(options);
        this.initialize(options, defaultOptions).init();
    }

    createViewModel () {
        const _this = this;

        let subwayNavigation = new Vue({
            el: Gikam.createDom('div', this.options.renderTo),
            render () {
                return <subway-nav ref="subwayNav" options={_this.options}></subway-nav>
            },
            components: {
                subwayNav: () => import('@/gikam/js/components/template/subwayNav/subwayNav.vue')
            }
        });

        this.bindInstance(subwayNavigation.$el);
    }

    // [公用方法]——设置数据
    setData (data) {
        this.options.data = data;
    }

    init () {
        this.createViewModel();
    }
}