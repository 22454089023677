<template>
    <svg
        t="1573530095166"
        class="icon"
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="3214"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        :width="width"
        :height="height"
    >
        <path
            :fill="color"
            d="M193.766 406.042l301.876 183.884a24.045 24.045 0 0 0 24.192 0l301.875-183.884a23.04 23.04 0 0 0 7.731-31.924 28.211 28.211 0 0 0-7.731-7.73l-301.875-183.81a23.962 23.962 0 0 0-24.192 0L193.766 366.336a23.04 23.04 0 0 0-7.833 31.949c1.869 3.225 4.582 5.888 7.833 7.757z"
            p-id="3215"
        ></path>
        <path
            :fill="color"
            d="M797.747 492.39L507.622 669.235 217.523 492.39a22.835 22.835 0 0 0-30.925 7.424 22.528 22.528 0 0 0 7.399 30.9l301.875 183.884a22.122 22.122 0 0 0 23.194 0L820.94 530.714a22.323 22.323 0 0 0 6.707-30.925 21.862 21.862 0 0 0-29.9-7.399z"
            p-id="3216"
        ></path>
        <path
            :fill="color"
            d="M797.747 616.448l-290.125 176.82-290.099-176.82a22.835 22.835 0 0 0-30.925 7.398 22.835 22.835 0 0 0 7.399 30.925l301.875 183.885a22.233 22.233 0 0 0 23.194 0L820.94 654.771a22.426 22.426 0 0 0-23.194-38.323z"
            p-id="3217"
        ></path>
    </svg>
</template>

<script>
import baseSvgImg from './baseSvgImg';
export default {
    extends: baseSvgImg,
    props: {
        color: {
            default: '#27986D',
            type: String
        }
    }
};
</script>

<style scoped>
svg {
    display: block;
}
</style>
