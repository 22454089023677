<template>
    <div class="ckeditor" :class="[options.type]">
        <div v-if="readonly" class="ckeditor-readOnly" :style="{ height: height + 'px' }">
            <scroll>
                <div v-html="value"></div>
            </scroll>
        </div>
        <div
            v-else
            @mouseenter="activeClean"
            @mouseleave="frozenClean"
            class="container"
            :class="{ 'validate-error': !validateResult && eventType === 'blur' }"
        >
            <iframe :src="src"></iframe>
            <div class="ckeditor-input-icon" @click="choose">
                <chooseImg v-bind="{ color: '#666666', width: '14px', height: '14px' }" />
            </div>
            <div class="ckeditor-input-clean" v-if="isShowClean" @click.stop="cleanValue">
                <cleanImg v-bind="{ color: '#666666', width: '14px', height: '14px' }" />
            </div>
            <div class="validate-error-icon" v-if="validateResult === false && eventType === 'blur'" @click.stop>
                <info-image
                    width="16px"
                    height="16px"
                    @mouseenter.stop="errorIconMouseenter"
                    @mouseleave="errorIconMouseleave"
                ></info-image>
            </div>
        </div>
    </div>
</template>
<script>
import Gikam from '../../../core/gikam-core';
import BaseField from '../baseField/baseField.vue';
import infoImage from '../img/info.vue';

export default {
    props: ['options', 'propValue', 'rowIndex'],
    extends: BaseField,
    components: { infoImage },

    data() {
        return {
            validateResult: true,
            eventType: 'change',
            readonly: this.options.readonly,
            field: this.options.field,
            value: this.propValue,
            height: this.options.height,
            active: false,
            showClean: false
        };
    },

    inject: ['form', 'grid'],

    watch: {
        propValue(val) {
            if (this.value === val) {
                return;
            }
            this.value = val;
            this.iframe && this.iframe.ckeditor.setData(val === undefined ? '' : val);
        }
    },

    computed: {
        isShowClean() {
            return this.value && this.showClean;
        },

        src() {
            // return Gikam.IFM_CONTEXT + '/ckeditor/index.html';
            return Gikam.IFM_CONTEXT + '/static/plugin/ckeditor/index.html';
        }
    },

    mounted() {
        this.initEditor();
    },

    methods: {
        saveValue(val, type) {
            this.value = val.lastIndexOf('\n') >= val.length - 2 ? val.substring(0, val.lastIndexOf('\n')) : val;
            Gikam.finalDelay(
                'ckeditorEvent',
                () => {
                    this.eventType = type;
                    this.validateResult = this.validate();
                    if (Gikam.isTrue(this.validateResult)) {
                        this.$emit('change', this.options.field, this.value, this.rowIndex);
                        this.iframe && this.iframe.changeBorderColor('#d1d1d1');
                    } else {
                        type === 'blur' && this.iframe && this.iframe.changeBorderColor('red');
                    }
                },
                200
            );
        },

        errorIconMouseenter(event) {
            this.showErrorPanel(event);
        },

        errorIconMouseleave(event) {
            this.removeErrorPanel(event);
        },

        initEditor() {
            let iframe = this.$el.querySelector('iframe');
            iframe.onload = function() {
                //加载完毕初始化编辑页面
                let options = {
                    type: 'ScriptChooseEditor',
                    options: this
                };
                this.iframe = iframe.contentWindow.init(options);
            }.bind(this);
        },

        cleanValue() {
            this.value = '';
            this.saveValue(this.value);
            this.iframe && this.iframe.ckeditor.setData(this.value);
            this.setRelateFieldsValue([{}]);
        },

        setRelateFieldsValue(rows) {
            if (Gikam.isEmpty(this.targetFields)) {
                return;
            }
            const data = Gikam.isArray(rows) ? rows[0] : rows;
            const valueObject = this.targetFields.reduce((mapper, item) => {
                mapper[item.targetField] = data[item.valueField];
                return mapper;
            }, {});
            Gikam.isNotEmpty(this.rowIndex) && (valueObject.index = this.rowIndex);
            (this.form || this.grid).setData(valueObject);
        },

        choose() {
            if (this.options.onChoose) {
                this.options.onChoose.apply(this.form || this.grid);
                return;
            }
            let category = this.options.category;
            if (this.options.getCategory) {
                category = this.options.getCategory();
            }
            let config = Gikam.choose.getConfig(category);
            let url = Gikam.IFM_CONTEXT + config.url;
            let customData = null;
            if (this.options.onBeforeChoose) {
                customData = this.options.onBeforeChoose.apply(this.form || this.grid, [this.rowIndex]);
            }
            if (customData === false) {
                return;
            }
            const requestData = Gikam.deepExtend(customData);
            const _this = this;
            this.options.single && (requestData.single = 1);
            Gikam.create('modal', {
                title: config.title,
                url: url + Gikam.param(requestData),
                width: config.width,
                height: config.height,
                onAfterClose: function(rows) {
                    if (_this.setValueAfterChoose && Gikam.isNotEmpty(rows)) {
                        _this.value = Gikam.getFieldValue(rows[0], _this.field);
                        _this.saveValue(_this.value);
                    }
                    _this.setRelateFieldsValue(rows);
                    _this.$emit('afterChoose', rows, _this.rowIndex);
                }
            });
        },

        activeClean() {
            this.showClean = true;
        },

        frozenClean() {
            this.showClean = false;
        }
    }
};
</script>

<style scoped>
.ckeditor {
    height: 100%;
    width: 100%;
    min-height: 32px;
}

.ckeditor .ckeditor-readOnly {
    height: 200px;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    padding-left: 8px;
    color: rgba(0, 0, 0, 0.65);
    background-color: #f4f4f4;
    font-family: Microsoft YaHei, serif;
    font-size: 12px;
}

.ckeditor > .container {
    border-radius: 4px;
    position: relative;
}

.ckeditor.script > .container {
    height: 30px;
}

.ckeditor .container .ckeditor-readOnly {
    height: 30px;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    padding-left: 8px;
    color: rgba(0, 0, 0, 0.65);
    font-family: Microsoft YaHei, serif;
    font-size: 12px;
}

.ckeditor > .container {
    height: 350px;
    border: 1px solid transparent;
    box-sizing: content-box;
}

.ckeditor.scriptChoose > .container {
    height: 30px;
}

.ckeditor > .container > iframe {
    border: none;
    width: 100%;
    height: 100%;
}

.ckeditor > .container > .ckeditor-input-icon {
    position: absolute;
    right: 5px;
    top: 6px;
    width: 14px;
    height: 14px;
    cursor: pointer;
}

.ckeditor > .container > .ckeditor-input-clean {
    position: absolute;
    right: 25px;
    top: 6px;
    width: 14px;
    height: 14px;
    cursor: pointer;
}

.ckeditor > .container.validate-error > .ckeditor-input-clean {
    right: 45px;
}

.ckeditor > .container > .validate-error-icon {
    position: absolute;
    right: 25px;
    top: 3px;
    display: flex;
    align-items: center;
}

.ckeditor > .container > .validate-error-icon svg {
    height: 20px;
    cursor: pointer;
}
</style>
