import Gikam from '../../core/gikam-core';

export class Position {
    constructor(position, layoutInstance) {
        this.layoutInstance = layoutInstance;
        this.position = position;
    }

    cleanItems() {
        const area = this.layoutInstance.options[this.position];
        area.$dom.innerHTML = '';
    }

    refreshItems(props) {
        if (!Array.isArray(props)) {
            Gikam.error('refreshItems accept array');
            return;
        }
        this.cleanItems();
        const layout = this.layoutInstance;
        const area = layout.options[this.position];
        props.forEach(subComp => {
            subComp.renderTo = area.$dom;
            subComp.$root = layout.options.renderTo;
            subComp.$window = layout.$window;
            subComp.$parent = area;
            subComp.allowWidth = parseInt(area.width) || layout.options.allowWidth;
            area.$children.push(Gikam.create(Gikam.toInitialUpperCase(subComp.type), subComp));
        });
    }
}
