<template>
    <div class="panel workspace-panel" :class="{ inner: options.innerpanel }" ref="panel">
        <div class="panel-container" :class="{ active: propActive }" :style="propStyle">
            <div class="panel-heading">
                <span class="panel-title">{{ options.title }}</span>
                <div class="toolbar" v-if="options.innerpanel">
                    <slot name="panel-close">
                        <fork />
                    </slot>
                </div>
            </div>
            <div class="panel-body">
                <slot name="panel-body"></slot>
                <iframe v-if="src" :src="src"></iframe>
            </div>
        </div>
    </div>
</template>

<script>
import Gikam from 'gikam';

export default {
    props: {
        options: Object,
        selectOptions: Array,
        defaultHeight: Number,
        propIndex: Number,
        propActive: Boolean,
        propStyle: Object
    },

    data() {
        return {
            selectOption: {
                type: 'select',
                items: this.selectOptions
            }
        };
    },

    computed: {
        height() {
            let height = parseInt(this.options.height);
            return height ? (height > 2 ? height + 'px' : height * this.defaultHeight + 'px') : null;
        },

        size() {
            return this.options.size + '';
        },

        src() {
            return this.options.src ? Gikam.IFM_CONTEXT + this.options.src : false;
        }
    },

    methods: {
        selectChange() {
            const val = this.$refs.pannerlCheck.value;
            this.$emit('selectChange', this.options, this.$refs.pannerlCheck.getSelectText(val), val);
        }
    }
};
</script>

<style scoped>
.panel {
    margin-bottom: 8px;
    transition: all 0.8s ease-in-out;
    position: relative;
}

.panel.inner {
    width: 100%;
    height: 100%;
    background-color: #fff;
}

.panel .panel-container {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.panel.inner .panel-container {
    box-shadow: 0 0 3px #ccc;
}

.panel.inner > .panel-container {
    border: 1px solid #eee;
}

.panel.inner > .panel-container.active {
    border-color: #007aff;
    box-shadow: 0 0 3px #007aff;
    border-radius: 0;
}

.panel > .panel-container > .panel-heading {
    height: 30px;
    line-height: 28px;
    background-color: #eee;
    border-bottom: 1px solid #d9d9d9;
}

.panel.inner > .panel-container > .panel-heading {
    background-color: #fff;
    border-bottom: none;
    display: flex;
}

.panel > .panel-container > .panel-heading > .panel-title {
    font-size: 14px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.85);
    padding-left: 16px;
}

.panel.inner > .panel-container > .panel-heading > .panel-title {
    font-size: 12px;
}

.panel > .panel-container > .panel-heading > .toolbar {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
}

.panel > .panel-container > .panel-heading > .toolbar > .select {
    width: 100px;
    height: 24px;
}

.panel > .panel-container > .panel-body {
    flex: 1;
    min-height: 30px;
    display: flex;
    flex-wrap: wrap;
}

.panel.inner > .panel-container > .panel-body {
    min-height: 30px;
    flex: 1;
    cursor: move;
}

@media screen and (max-width: 768px) {
    .workspace-panel {
        position: relative !important;
    }
}
</style>
