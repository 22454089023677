<template>
    <div class="file" :name="field" :class="{ 'validate-error': !validateResult }" :id="options.id">
        <div class="link-text">{{ text }}</div>
        <span class="icon upload-icon" @click="selectUploadFile" v-show="!text" />
        <span class="icon delete-icon" @click="deleteFile" v-show="text" />
        <input type="file" class="hide-input" ref="hideInput" />
    </div>
</template>
<script>
import Gikam from 'gikam';
import BaseField from '../../baseField/baseField.vue';

export default {
    name: 'fileField',
    props: {
        options: Object,

        propReadonly: {
            type: Boolean,
            default: void 0
        },

        propInvisible: {
            type: Boolean,
            default: false
        }
    },

    extends: BaseField,

    mounted() {
        Gikam.setInstance(this.$el, this);
    },

    data() {
        return {
            field: this.options.field,
            text: '',
            value: '',
            validateResult: true,
            fileItem: [],
            fileId: null
        };
    },

    methods: {
        selectUploadFile() {
            let fileInput = this.$refs.hideInput;
            fileInput.addEventListener('change', e => {
                this.fileItem = e.target.files;
                this.text = e.target.files[0].name;
                this.value = e.target.files[0];
            });
            fileInput.click();
        },

        deleteFile() {
            this.value = '';
            this.text = '';
            this.fileItem = [];
            if (this.$refs.hideInput.innerHTML) {
                this.$refs.hideInput.innerHTML = '';
            }
            this.$refs.hideInput.value = '';
            if (this.options.onDelete) {
                this.options.onDelete();
            } else {
                this.fileId &&
                    Gikam.del(
                        `${Gikam.IFM_CONTEXT}/core/module/item/files`,
                        Gikam.getJsonWrapper({}, ['', [{ id: this.fileId }]])
                    );
            }
        },

        setText(text) {
            this.text = text;
        },

        setFileId(id) {
            this.fileId = id;
        }
    }
};
</script>

<style scoped>
.file {
    display: flex;
    align-items: center;
    width: 100%;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    padding-left: 8px;
    color: rgba(0, 0, 0, 0.65);
    font-family: 'Microsoft YaHei', serif;
    font-size: 12px;
    height: 100%;
    cursor: pointer;
    position: relative;
}

.upload-button.validate-error {
    border-color: #ff6e6e;
}

.file:active {
    border: 1px solid rgba(0, 122, 255, 0.5);
}

.file .link-text {
    width: 100%;
    display: flex;
    align-items: center;
}

.file .icon {
    display: block;
    width: 24px;
    height: 17px;
    margin-right: 8px;
}

.file .icon.upload-icon {
    background: no-repeat url(./img/upload.png);
}

.file .icon.delete-icon {
    background: no-repeat url(./img/delete.png);
}

.hide-input {
    display: none;
}
</style>
