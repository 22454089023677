<template>
    <div class="sign" :class="{ 'validate-error': !validateResult }">
        <div class="container" :class="{ readonly: readonly }">
            <div class="content" :title="signSpliceUsersData()" @click="clickHandle">
                <div class="item" v-for="item in items" :key="item.id">
                    <span class="text">{{ item.signUserName }}</span>
                    <span class="sign-date">{{ item.signDate }}</span>
                </div>
            </div>
            <div class="tool">
                <div class="close" @click.stop="clear" :style="{ visibility: readonly ? 'hidden' : '' }">X</div>
                <div class="avator"><userImg :width="'14px'" /></div>
            </div>
        </div>
    </div>
</template>

<script>
import Gikam from '../../../core/gikam-core';

export default {
    props: {
        options: Object,
        propReadonly: Boolean,
        propValue: String,
        rowIndex: Number
    },

    data() {
        return {
            validateResult: true,
            readonly: this.options.readonly !== undefined ? this.options.readonly : this.propReadonly,
            field: this.options.field,
            items: this.propValue ? JSON.parse(this.propValue) : [],
            multiple: this.options.multiple === false ? false : true,
            showImage: true
        };
    },

    watch: {
        propValue(val) {
            this.items = JSON.parse(val);
        }
    },

    methods: {
        // 签名数据的字符串title
        signSpliceUsersData() {
            let string = this.items
                .map(item => {
                    return item.signUserName + item.signDate;
                })
                .join(',');
            return string;
        },

        //录入签名
        clickHandle() {
            if (this.readonly === true) {
                return;
            }
            if (this.multiple === false && this.items.length === 1) {
                return;
            }
            this.$emit('editing', true);
            let modal = Gikam.create('modal', {
                title: '录入签名',
                width: 350,
                height: 250,
                onAfterClose: () => {
                    this.$emit('editing', false);
                }
            });

            let _this = this;

            Gikam.create('layout', {
                renderTo: modal.window.$dom,
                center: {
                    items: [
                        {
                            type: 'btnToolbar',
                            items: [
                                {
                                    type: 'button',
                                    text: '确定',
                                    icon: 'confirm',
                                    class: 'blue',
                                    onClick() {
                                        modal.window.showMask();
                                        const data = Gikam.getComp('gikam-sign-modal-form').getData();
                                        Gikam.post(
                                            Gikam.IFM_CONTEXT + '/secure/core/module/mdm/users/action/sign',
                                            Gikam.getJsonWrapper({
                                                username: data.username,
                                                password: data.password
                                            })
                                        )
                                            .done(item => {
                                                _this.items.push(item);
                                                modal.window.closeMask();
                                                modal.close();
                                                _this.$emit('change', _this.field, JSON.stringify(_this.items));
                                            })
                                            .fail(() => {
                                                modal.window.closeMask();
                                            });
                                    }
                                },
                                {
                                    type: 'button',
                                    text: '取消',
                                    icon: 'cancel',
                                    onClick() {
                                        modal.close();
                                    }
                                }
                            ]
                        },
                        {
                            type: 'form',
                            columns: 1,
                            id: 'gikam-sign-modal-form',
                            titleWidth: 50,
                            fields: [
                                {
                                    field: 'username',
                                    title: '用户名'
                                },
                                {
                                    field: 'password',
                                    type: 'password',
                                    title: '密码'
                                }
                            ]
                        }
                    ]
                }
            });
        },

        //删除签名
        clear() {
            if (this.readonly === true) {
                return;
            }
            if (this.items.length === 0) {
                return;
            }
            let modal = Gikam.create('modal', {
                title: '删除签名',
                width: 350,
                height: 250
            });

            let _this = this;

            Gikam.create('layout', {
                renderTo: modal.window.$dom,
                center: {
                    items: [
                        {
                            type: 'btnToolbar',
                            items: [
                                {
                                    type: 'button',
                                    text: '确定',
                                    icon: 'confirm',
                                    class: 'blue',
                                    onClick() {
                                        const data = Gikam.getComp('gikam-sign-modal-form').getData();
                                        Gikam.post(
                                            Gikam.IFM_CONTEXT + '/secure/core/module/mdm/users/action/sign',
                                            Gikam.getJsonWrapper({
                                                username: data.username,
                                                password: data.password
                                            })
                                        ).done(() => {
                                            const userSignNum = _this.items.length;
                                            _this.items = _this.items.filter(item => item.signUserId !== data.username);
                                            if (userSignNum === _this.items.length) {
                                                Gikam.alert('您没有权限清除当前签名。');
                                            }
                                            const newValue = _this.items.length ? JSON.stringify(_this.items) : '';
                                            _this.$emit('change', _this.options.field, newValue, _this.rowIndex);
                                            modal.close();
                                        });
                                    }
                                },
                                {
                                    type: 'button',
                                    text: '取消',
                                    icon: 'cancel',
                                    onClick() {
                                        modal.close();
                                    }
                                }
                            ]
                        },
                        {
                            type: 'form',
                            columns: 1,
                            id: 'gikam-sign-modal-form',
                            titleWidth: 50,
                            fields: [
                                {
                                    field: 'username',
                                    title: '用户名'
                                },
                                {
                                    field: 'password',
                                    type: 'password',
                                    title: '密码'
                                }
                            ]
                        }
                    ]
                }
            });
        }
    }
};
</script>

<style scoped>
.sign {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.65);
    min-height: 30px;
    font-size: 12px;
}

.sign .container {
    border: 1px solid #e5e5e5;
    position: relative;
    border-radius: 4px;
    width: 100%;
    min-height: 30px;
    cursor: pointer;
    padding: 0;
}

.sign .container.readonly {
    cursor: default;
    border-color: transparent;
}

.sign img {
    height: 30px;
    margin-right: 5px;
}

.sign.validate-error .container {
    border-color: #ff6e6e;
}

.text {
    font-family: '楷体';
    font-size: 14px;
}

.sign .content {
    min-height: 30px;
    padding-right: 40px;
}

.sign .content .item {
    display: inline-block;
    overflow: hidden;
    line-height: 30px;
    height: 27px;
}

.sign .tool {
    width: 40px;
    height: 100%;
    position: absolute;
    right: 3px;
    top: 0;
    display: flex;
    align-items: center;
}

.sign .tool .close {
    width: 148px;
    border-radius: 50%;
    background-color: #eee;
    color: #aaa;
    height: 18px;
    text-align: center;
    line-height: 18px;
    font-size: 12px;
    visibility: hidden;
}

.sign:hover .close {
    visibility: visible;
}

.sign .tool .avator {
    padding: 0 4px;
}
</style>
