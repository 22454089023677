<template
    ><svg
        version="1.1"
        id="图层_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 42 42"
        style="enable-background:new 0 0 42 42;"
        xml:space="preserve"
        :width="width"
        :height="height"
    >
        <path
            id="XMLID_2665_"
            :fill="color"
            d="M20.5,33.4c-0.8,0-1.5-0.5-1.5-1V7.9c0-0.6,0.7-1,1.5-1s1.5,0.5,1.5,1v24.5
	C22,32.9,21.3,33.4,20.5,33.4z"
        />
        <path
            id="XMLID_2664_"
            :fill="color"
            d="M32.7,21.6H8.3c-0.6,0-1-0.7-1-1.5s0.5-1.5,1-1.5h24.5c0.6,0,1,0.7,1,1.5S33.3,21.6,32.7,21.6
	z"
        />
        <path
            id="XMLID_558_"
            :fill="color"
            d="M35,39.1H6c-2.5,0-4.5-2-4.5-4.5v-29c0-2.5,2-4.5,4.5-4.5h29c2.5,0,4.5,2,4.5,4.5v29
	C39.5,37.1,37.5,39.1,35,39.1z M6,4.1c-0.8,0-1.5,0.7-1.5,1.5v29c0,0.8,0.7,1.5,1.5,1.5h29c0.8,0,1.5-0.7,1.5-1.5v-29
	c0-0.8-0.7-1.5-1.5-1.5H6z"
        />
    </svg>
</template>

<script>
export default {
    props: {
        color: {
            type: String,
            default: '#333333'
        },
        width: {
            type: [Number, String],
            default: '12px'
        },
        height: {
            type: [Number, String],
            default: '12px'
        }
    }
};
</script>
