<template>
    <div
        :id="options.id"
        class="btn-container"
        @click.stop
        @mousedown.stop
        :style="{ float: options.align, margin: options.margin }"
    >
        <div
            v-show="hidden === false"
            class="button"
            :title="options.title"
            @mouseenter="pickerTimer"
            @touchend="pickerTimer"
            @click.stop
            @mouseleave="enterButton = false"
            :style="{ padding: options.padding }"
        >
            <span class="icon" v-if="options.icon" :class="['icon-' + options.icon, options.color]">
                <slot></slot>
            </span>
            <span class="button-text">{{ getI18N(options.text) }}</span>
            <span class="caret" :class="caret"></span>
        </div>
    </div>
</template>
<script>
import Gikam from '../../../core/gikam-core';
import picker from './picker';
import Vue from 'vue';

export default {
    props: {
        options: Object
    },

    data() {
        return {
            caret: 'down',
            picker: void 0,
            items: this.options.items || [],
            field: this.options.field,
            fragment: this.options.fragment || [],
            maxHeight: 150,
            timer: null,
            enterButton: false,
            hidden: Gikam.isEmpty(this.options.hidden) ? false : this.options.hidden
        };
    },

    mounted() {
        this.$emit('rendered', this.field);
        Gikam.setInstance(this.$el, this);
    },

    methods: {
        getI18N(text) {
            return Gikam.propI18N(text);
        },

        cleanPicker() {
            this.caret = 'down';
            Gikam.dropDownMenuPicker.$destroy();
            Gikam.removeDom(Gikam.dropDownMenuPicker.$el);
            Gikam.dropDownMenuPicker = null;
        },

        pickerTimer() {
            this.enterButton = true;
            clearTimeout(this.timer);
            this.timer = setTimeout(() => {
                if (this.enterButton && !Gikam.dropDownMenuPicker) {
                    this.showPicker();
                }
                this.timer = null;
            }, 100);
        },

        showPicker() {
            const _this = this;
            const $el = this.$el;
            const { top, width, left } = $el.getBoundingClientRect();
            const items = _this.items.map(item => {
                if (item.hidden === null || item.hidden === undefined) {
                    this.$set(item, 'hidden', false);
                }
                return item;
            });
            const itemsLength = this.items.filter(item => item.hidden !== true).length;
            const itemsNum = this.options.fragment ? this.options.fragment.length + itemsLength : itemsLength;
            const pickerHeight = itemsNum * 32;
            const maskPosition = top + pickerHeight > window.innerHeight;
            const _top = maskPosition ? top - pickerHeight - 4 : top;
            let right = null;
            left > 200 && (right = document.body.scrollWidth - left - width);
            _this.caret = 'up';
            Gikam.dropDownMenuPicker = new Vue({
                el: Gikam.createDom('div', document.body),
                methods: {
                    close: _this.cleanPicker
                },
                render() {
                    return (
                        <picker
                            propTop={_top}
                            propRight={right}
                            propLeft={left}
                            propWidth={width}
                            propItems={items}
                            propHeight={pickerHeight}
                            propFragment={_this.fragment}
                            propMaskPosition={maskPosition}
                            propEnterButton={_this.enterButton}
                        />
                    );
                }
            });
        },

        setItems(items) {
            if (Gikam.isArray(items)) {
                this.items = items;
                return true;
            }
            return false;
        },

        show(id) {
            if (id) {
                this.items.forEach(item => {
                    if (item.id === id) {
                        item.hidden = false;
                    }
                });
                if (this.items.some(item => item.hidden === false)) {
                    this.hidden = false;
                }
            } else {
                this.hidden = false;
            }
            this.$emit('update');
        },

        hide(id) {
            if (id) {
                this.items.forEach(item => {
                    if (item.id === id) {
                        item.hidden = true;
                    }
                });
                if (this.items.every(item => item.hidden === true)) {
                    this.hidden = true;
                }
            } else {
                this.hidden = true;
            }
            this.$emit('update');
        }
    }
};
</script>

<style>
.icon-btn-more {
    width: 18px;
    height: 16px;
    background: url(./img/more.svg) no-repeat;
}

.btn-more > .item {
    width: 100%;
}

.btn-more > .item > .btn-container {
    display: block;
    padding: 0;
}

.btn-more > .item > .btn-container > .corner-marker {
    display: none;
}

.btn-more > .item > .btn-container > .button {
    border: none;
    height: 32px;
}

.btn-more > .item > .btn-container > .button:hover {
    background-color: transparent;
}

.combo-box-picker ul li.btn-more:hover {
    background-color: rgba(0, 122, 255, 0.08);
}

.drop-down-mask {
    position: relative;
}

.button .caret {
    padding: 0;
    width: 0;
    height: 0;
    border-width: 5px;
    border-style: solid;
    border-color: #999 transparent transparent transparent;
    margin-top: 9px;
    margin-left: 5px;
    transition: all 0.3s;
}

.button .caret.up {
    transform-origin: 50% 50%;
    transform: rotate(180deg);
    margin-top: -6px;
}
</style>
