<template>
    <div
        class="image"
        :class="[{ 'validate-error': !validateResult }, align, { readonly: readonly }]"
        :style="{ height: height + 'px' }"
        @click="imgClickHandle"
    >
        <span v-show="!propValue" class="upload-icon">
            <uploadImg color="#333" />
        </span>
        <img v-show="value" :src="value" :name="field" :style="{ height: height - 2 + 'px' }" />
    </div>
</template>

<script>
import Gikam from '../../../core/gikam-core';
import Vue from 'vue';

export default {
    props: {
        options: Object,
        propValue: String,
        propReadonly: Boolean
    },

    data() {
        return {
            validateResult: true,
            value: this.propValue,
            field: this.options.field,
            height: this.options.height,
            align: this.options.align, // 排列方式 left/center/right
            title: this.options.title,
            // 控制是否放大，放大为true
            enlarged: Gikam.isNotEmpty(this.options.enlarged) ? this.options.enlarged : true,
            readonly: this.options.readonly !== undefined ? this.options.readonly : this.propReadonly
        };
    },

    watch: {
        propValue(val) {
            this.value = val ? Gikam.IFM_CONTEXT + val : null;
        }
    },

    methods: {
        imgClickHandle() {
            if (this.readonly) return;
            if (!this.propValue || !this.enlarged) {
                this.options.onClick && this.options.onClick(this);
            } else {
                this.checkImg();
            }
        },

        checkImg() {
            if (this.readonly) return;
            this.$emit('editing', true);
            let modal = Gikam.create('modal', {
                title: this.title,
                width: 600,
                height: 500,
                onAfterClose: () => {
                    this.$emit('editing', false);
                }
            });

            let _this = this;

            this.checkImgModal = new Vue({
                el: modal.window.$dom,
                methods: {
                    changeImg() {
                        _this.options.onClick && _this.options.onClick(_this);
                    }
                },
                render() {
                    return (
                        <div style="position:relative">
                            <div onClick={this.changeImg} title="替换" class="upload-change-img">
                                <img src={require('../../../../img/icon/modify.png')} />
                                <span>替换</span>
                            </div>
                            <div class="upload-large-img" style="margin:8px;">
                                <img
                                    src={Gikam.IFM_CONTEXT + _this.propValue}
                                    style="max-width:100%;max-height:100%;"
                                />
                            </div>
                        </div>
                    );
                }
            });
        }
    }
};
</script>

<style scoped>
.image {
    width: 100%;
    min-height: 50px;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
}

.image.readonly {
    background-color: #f4f4f4;
    cursor: default;
}

.image .upload-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 16px;
    height: 16px;
}

.text-input.validate-error {
    border-color: #ff6e6e;
}

.image.center {
    justify-content: center;
}

.image.left img {
    left: 8px;
}

.image.right img {
    right: 8px;
}
</style>

<style>
.upload-change-img {
    width: 60px;
    position: absolute;
    right: 8px;
    display: flex;
    border: 1px solid #d9d9d9;
    color: rgba(0, 0, 0, 0.65);
    font-family: 'Microsoft YaHei', serif;
    font-size: 12px;
    padding: 5px 6px;
    cursor: pointer;
    border-radius: 4px;
    align-items: center;
    height: 24px;
}

.upload-change-img:hover {
    background-color: #f4f4f4;
}

.upload-change-img img {
    margin-right: 5px;
}

.upload-large-img {
    border: 1px solid #d9d9d9;
    position: absolute;
    top: 32px;
    width: 584px;
    height: 350px;
}
.upload-large-img img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
</style>
