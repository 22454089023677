<template>
    <div class="gantt-iframe" :id="options.id">
        <gantt-mask v-if="getDataResult" />
        <iframe :src="getSrc()"></iframe>
    </div>
</template>
<script>
import mask from '@/gikam/js/components/template/mask/mask';
import Gikam from '../../../core/gikam-core';
export default {
    props: {
        options: Object
    },

    data() {
        return {
            getDataResult: true
        };
    },

    mounted() {
        this.initGantt();
    },

    components: { ganttMask: mask },

    methods: {
        initGantt() {
            let iframe = this.$el.querySelector('iframe');
            iframe.onload = () => {
                this.getDataResult = false;
                this.gantt = iframe.contentWindow && iframe.contentWindow.init(this);
                if (this.options.showForm) {
                    this.gantt.initForm(this.options.zoomConfig);
                }

                this.$emit('ready', this.gantt);
            };
        },

        getSrc() {
            return Gikam.IFM_CONTEXT + '/static/plugin/dhtmlxgantt/index.html';
        }
    }
};
</script>

<style scoped>
.gantt-iframe {
    height: 100%;
    width: 100%;
    overflow: hidden;
}
.gantt-iframe > iframe {
    height: 100%;
    width: 100%;
    border: medium none;
}
</style>
