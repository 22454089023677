import Gikam from 'gikam';
import tree from './vue/tree.vue';
import Vue from 'vue';
import Base from '../base';
import jQuery from 'jquery';
import { I18N } from '@/gikam/i18n/I18N.js';

let defaultOptions = {
    renderTo: void 0,
    //树的名称
    name: void 0,
    url: void 0,
    rootId: 'root',
    selectedNodeData: null,
    selectedNode: null,
    data: [],
    //新增删除时使用
    baseUrl: null,
    //节点名称对应的字段
    nodeTextField: null,
    //是否可以编辑
    edit: false,
    //树的加载是否是异步,如果是异步点击节点时，才加载子节点
    async: true,
    //树是否是有多选框
    checkbox: false,
    //点击节点名称是否勾选复选框
    checkOnActive: false,
    requestData: {},
    //是否可以拖放
    draggable: false,
    //点击加减号才能收缩树节点
    expandOnTextClick: true
};

export default class Tree extends Base {
    constructor(options) {
        super('Tree');
        this.listeners = {
            nodeSelected: Gikam.emptyFunction,
            beforeRequest: Gikam.emptyFunction,
            beforeDelete: Gikam.emptyFunction,
            // 触发拖拽完成之后的事件
            afterDrop: Gikam.emptyFunction,
            //加载成功之后的事件
            loadSuccess: Gikam.emptyFunction,
            //编辑结之后的事件
            afterEdit: Gikam.emptyFunction,
            //保存之前的事件
            beforeSave: Gikam.emptyFunction
        };
        this.requesting = false;
        this.loadSuccessCallback = [];
        this.initialize(options, defaultOptions).init();
    }

    init() {
        this.model = this.createModel();
        this.bindInstance(this.model.$el);
        this.renderTree();
        this.bindEvent();
    }

    createModel() {
        const _this = this;
        return new Vue({
            el: Gikam.createDom('div', this.options.renderTo),
            components: { tree },
            provide: {
                tree: this
            },
            data() {
                return {
                    options: _this.options,
                    treeStringId: ''
                };
            },
            render() {
                return (
                    <tree
                        options={this.options}
                        onHeadClick={() => {
                            _this.trigger('nodeSelected', null, { id: null });
                            const selectedNode = _this.options.selectedNode;
                            selectedNode && selectedNode.classList.remove('selected');
                            _this.options.selectedNode = null;
                            _this.options.selectedNodeData = null;
                        }}
                    ></tree>
                );
            }
        });
    }

    getData(parentId) {
        let def = jQuery.Deferred();
        if (!this.options.url) {
            if (!parentId) return def.resolve(this.options.data);
            return def.resolve([]);
        }
        let url = this.options.url;
        if (this.options.async === true) {
            url = Gikam.printf(url, {
                parentId: parentId || this.options.rootId
            });
        }
        let requestData;

        if (this.options.requestData) {
            requestData = Gikam.getJsonWrapper({ f: this.options.requestData });
        }
        this.requesting = true;
        Gikam.postText(url, requestData)
            .done(rows => {
                if (rows) {
                    def.resolve(JSON.parse(rows));
                } else {
                    def.resolve([]);
                }
            })
            .always(() => {
                this.requesting = false;
            });
        return def;
    }

    renderTree() {
        let _this = this;
        this.getData().done(list => {
            _this.options.data = list;
            _this.model.$nextTick(() => {
                _this.loadSuccessCallback.forEach(item => {
                    item.call(_this, list);
                });
                _this.loadSuccessCallback.length = 0;
                _this.options.selectedNodeData = null;
                _this.trigger('loadSuccess', list);
            });
        });
    }

    refresh(param) {
        this.editing = false;
        if (param) {
            Gikam.extend(true, this.options, param);
        }
        this.renderTree();
    }

    getSelectedNodeId() {
        return this.options.selectedNodeData ? this.options.selectedNodeData.id : null;
    }

    getSelectedNode() {
        return this.options.selectedNodeData
            ? {
                  nodeId: this.options.selectedNodeData.id,
                  nodeText: this.options.selectedNodeData.text,
                  childQty: this.options.selectedNodeData.childQty
              }
            : null;
    }

    getSelections() {
        return Gikam.filterTree(
            this.options.data,
            item => {
                return item.checked;
            },
            false
        );
    }

    deleteNode(node, array) {
        if (!array) {
            array = this.options.data;
        }
        let _this = this;
        let result = false;
        Gikam.each(array, function(i) {
            if (node === this) {
                array.splice(i, 1);
                result = true;
                return false;
            }
            if (Gikam.isNotEmpty(this.children)) {
                if (_this.deleteNode(node, this.children)) {
                    result = true;
                    return false;
                }
            }
        });
        return result;
    }

    bindEvent() {
        let _this = this;

        jQuery(this.model.$el).on('mouseenter', '.tree-header', e => {
            if (_this.options.edit === false) {
                return;
            }
            jQuery(e.currentTarget).addClass('hover');
        });

        jQuery(this.model.$el).on('mouseleave', '.tree-header', e => {
            if (_this.options.edit === false) {
                return;
            }
            jQuery(e.currentTarget).removeClass('hover');
        });

        jQuery(this.model.$el).on('click', '.tree-header>.insert-btn', () => {
            if (_this.options.edit === false) {
                return;
            }
            let newNode = {
                text: I18N.prop('tree.newNode'),
                id: null,
                parentId: '',
                editable: true
            };
            _this.options.data.push(newNode);

            _this.editing = true;
        });
    }

    onLoadSuccess(callback) {
        if (this.requesting) {
            this.loadSuccessCallback.push(callback);
        } else {
            callback.call(this, this.options.data);
        }
    }

    selectNodeById(id) {
        let liNode = document.querySelector('#' + this.options.id + '-' + id);
        liNode && liNode.querySelector('.node-text').click();
    }

    /**
     * @description 刷新指定节点，如果nodeId为空，则刷新整棵树
     * @public
     * @param {String,Number} nodeId 要刷新的节点id
     * @returns Tree
     * @memberof Tree
     */
    refreshNode(nodeId) {
        if (Gikam.isEmpty(nodeId)) {
            this.refresh();
        } else {
            this.getData(nodeId).done(childrenNodes => {
                const node = this.getNodeById(this.options.data, nodeId);
                Vue.set(node, 'childQty', childrenNodes.length);
                Vue.set(node, 'children', childrenNodes);
            });
        }
        return this;
    }

    /**
     * 通过id获取节点
     * @private
     * @param {object} nodes, {String,Number} id
     * @memberof Tree
     */
    getNodeById(nodes, id) {
        const _this = this;
        let node;
        Gikam.each(nodes, function() {
            if (this.id == id) {
                node = this;
                return false;
            }
            if (Gikam.isNotEmpty(this.children)) {
                node = _this.getNodeById(this.children, id);
                if (node) {
                    return false;
                }
            }
        });
        return node;
    }

    //设置节点的显示名称，用于本地更新，不请求数据
    setNodeText(nodeId, text) {
        if (Gikam.isEmpty(nodeId)) {
            return;
        }
        this.getNodeById(this.options.data, nodeId).text = text;
    }

    /**
     * 获取树节点数据
     * @public
     * @param
     * @memberof Tree
     */
    getTreeList() {
        return this.options.data;
    }

    /**
     * 展开树节点
     * @public
     * @param
     * @memberof Tree
     */
    setNodeExpand(nodeId) {
        const def = Gikam.getDeferred();
        Gikam.eachTree(this.options.data, node => {
            if (node.id === nodeId) {
                Vue.set(node, 'expanded', true);
                this.model.$nextTick(() => {
                    def.resolve();
                });
            }
        });
        return def;
    }

    /**
     * 通过id判断节点是否存在
     * @public
     * @param {string} nodeId需判断的节点id
     * @memberof Tree
     */
    nodeExistsById(nodeId) {
        if (Gikam.isEmpty(nodeId)) {
            return false;
        }
        const node = this.getNodeById(this.options.data, nodeId);
        return Gikam.isEmpty(node) ? true : false;
    }
}
