/* eslint-disable no-control-regex */
import Gikam from 'gikam';
export default class {
    static validateSyntax(content, title = '校验结果') {
        if (typeof content !== 'string') {
            Gikam.error('校验内容仅支持字符串')
            return false
        }
        const _content = removeNotes(content)
        return check(_content, title, new RegExp("[^\x00-\xff]", "g"))
    }
}

//根据正则检查内容
function check(content, title, regExp) {
    const messageArr = []
    content.split('\n').forEach((item, index) => {
        const checkRes = item.match(regExp);
        const i = index + 1;
        Gikam.isNotEmpty(checkRes) && messageArr.push(checkRes + '（第' + i + '行）')
    })
    return confirmResult(messageArr, title);
}

//弹出内容提示
function confirmResult(messageArr, title) {
    if (Gikam.isEmpty(messageArr)) {
        return true
    } else {
        Gikam.create('alert', {
            title: Gikam.propI18N(title),
            message: messageArr.join('<br/>')
        });
        return false
    }
}

//去除不校验的内容
function removeNotes(content) {
    //去掉单行注释
    let _content = content.replace(/\/\/.*(\r|\n|$)/g, '\n');
    //去掉多行注释
    _content = _content.replace(/(?:^|\n|\r)\s*\/\*[\s\S]*?\*\/\s*(?:\r|\n|$)/g, function (i) {
        let str;
        i.split('\n').forEach((item, i) => {
            (i !== 0) && (str += '\n')
        })
        return str
    })
    return _content
}