<template>
    <div
        class="grid"
        v-show="!hiddenGrid"
        :id="options.id"
        :style="{ height: height }"
        :class="{
            'line-feed': cellLineFeed,
            'user-select': dragDownIndex !== null,
            'columns-fill': options.columnsFill,
            'scroll-visible': this.options.scroll === 'visible'
        }"
        @mouseleave="scrollTypeLeave"
        @contextmenu="contextmenuHandle"
    >
        <div class="toolbar" v-show="showToobar" ref="toolbar">
            <div class="search">
                <div v-if="options.genericQuery" class="generic-query-btn">
                    <div class="drop" @click.stop="popGenericQueryPanel">
                        <div class="search-icon"></div>
                        <span class="text">{{ $i18n('grid.search') }}</span>
                        <div class="down-arrow"></div>
                    </div>
                    <div
                        class="refresh-icon"
                        :class="{ active: options.genericQueryFields.length > 0 }"
                        @click="genericQueryReset"
                    ></div>
                </div>
            </div>
            <div
                class="operation"
                :style="{ justifyContent: options.toolbarAlign === 'right' ? 'flex-end' : 'flex-start' }"
            >
                <template v-for="(item, index) in options.toolbar">
                    <template v-if="item.type === 'btnGroup'">
                        <template v-for="(option, index) in item.items">
                            <div
                                class="item"
                                type="button"
                                :key="item.type + index"
                                :index="index"
                                v-if="option.hidden !== true"
                            >
                                <btn :options="option"></btn>
                            </div>
                        </template>
                    </template>
                    <div
                        class="item"
                        v-else-if="item.hidden !== true"
                        :key="item.type + index"
                        :type="item.type"
                        :index="index"
                    >
                        <div
                            v-if="item.type === 'form'"
                            :ref="'toolbar_form_' + index"
                            class="form-container"
                            :index="index"
                        ></div>
                        <btn
                            v-else-if="item.type === 'button'"
                            :options="item"
                            :index="index"
                            @hiddenChangeEvent="handleBtnMore"
                        ></btn>
                        <dropDownMenu
                            v-else-if="item.type === 'dropDownMenu'"
                            :options="item"
                            :index="index"
                            @update="handleBtnMore"
                        ></dropDownMenu>
                        <sunway-caption
                            v-else-if="item.type === 'caption'"
                            :options="item"
                            :index="index"
                        ></sunway-caption>
                    </div>
                </template>
                <div class="item dropDownMenuMore" v-if="showMoreBtn">
                    <dropDownMenu
                        ref="dropDownMenuMore"
                        :options="moreBtnOptions"
                        @update="handleBtnMore"
                    ></dropDownMenu>
                </div>
            </div>
            <div class="general-group" v-if="!options.generalButtonGroup === false">
                <generalButtonGroup :options="options.generalButtonGroup" :filter="options.filter" />
            </div>
        </div>

        <div class="grid-header" :style="{ width: tableWidth }">
            <grid-header
                ref="header"
                :propFill="options.fill"
                :propFilter="options.filter"
                :propFilterOpen="options.filterOpen"
                :propColumnResize="options.columnResize"
                :propEditorInvisible="options.editorInvisible"
                :allChecked="!(!options.data || options.data.length === 0) && allChecked"
                :propTotalChecked="totalChecked"
                :propShowCheckedNum="options.showCheckedNum"
                @downFillValueEvent="downFillValueEvent"
                :class="{ noDataHeader: !options.data || options.data.length === 0 }"
                @allCheck="
                    allChecked => {
                        this.allChecked = allChecked;
                        this.isAllChecked(allChecked);
                    }
                "
                @filterClose="
                    () => {
                        this.grid.options.filterOpen = false;
                    }
                "
                @sortListGrid="sortGrid"
            ></grid-header>
        </div>

        <div
            :style="{ width: tableWidth }"
            class="grid-body"
            :class="{ noScroll: options.noScroll }"
            v-show="options.showBody"
            @keydown.tab.capture.stop.prevent="bodyKeyDown($event)"
            @keydown.up.capture.stop.prevent="bodyKeyDown($event, 'up')"
            @keydown.down.capture.stop.prevent="bodyKeyDown($event, 'down')"
            @keydown.enter.capture.prevent="bodyKeyDown($event, 'down')"
        >
            <div
                v-if="dragLinkHandle && scrollAutomaticSlide"
                class="scroll-type-top"
                :class="{ top: scrollType === 'top' }"
                @mouseenter="scrollTypeHandle('top')"
                @mouseleave="scrollTypeLeave"
                @mouseup="scrollTypeUp"
            ></div>
            <div
                v-if="dragLinkHandle && scrollAutomaticSlide"
                class="scroll-type-bottom"
                :class="{ bottom: scrollType === 'bottom' }"
                @mouseenter="scrollTypeHandle('bottom')"
                @mouseleave="scrollTypeLeave"
                @mouseup="scrollTypeUp"
            ></div>
            <grid-row-fixed-top
                :options="options"
                :fixedIndex="rowFixedTop"
                v-if="showRowFixedTop"
                :propCheckeds="checkeds"
                :rowActiveId="rowActiveId"
                @checkedsHandle="checkedsHandle"
            />
            <grid-row-fixed-bottom
                :options="options"
                :fixedIndex="rowFixedBottom"
                v-if="showRowFixedBottom && showVerticalScroll"
                :propCheckeds="checkeds"
                :rowActiveId="rowActiveId"
                @checkedsHandle="checkedsHandle"
            />
            <component
                :is="scroll"
                ref="vs"
                @handle-scroll="handleScroll"
                :barSize="'10px'"
                @transitionChange="transitionChange"
                @hasVerticalScroll="hasVerticalScroll"
                :isNotScroll="options.noScroll"
            >
                <table :style="{ width: width }" @mouseleave="dragEnterIndex = null">
                    <thead>
                        <template v-for="(item, index) in columns">
                            <th
                                :key="index"
                                :style="getCellWidth(item)"
                                v-if="getFieldVisible(item) && getFieldHideHeader(item)"
                                :colspan="item.colspan"
                            ></th>
                        </template>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(row, rowIndex) in options.data"
                            :key="row.id || rowIndex"
                            @click="rowActive($event, rowIndex, row)"
                            :data-index="rowIndex"
                            :row-group-index="row._groupIndex"
                            :parent-group-index="row._parentGroupIndex"
                            :class="{
                                expand: row._expand,
                                'row-group': row._groupRow,
                                hidden: getRowHidden(row),
                                hide: row.show === false,
                                'drag-active': dragDownIndex === rowIndex,
                                'drag-border-top':
                                    dragEnterIndex === rowIndex &&
                                    dragEnterType === 'top' &&
                                    dragDownIndex !== dragEnterIndex,
                                'drag-border-bottom':
                                    dragEnterIndex === rowIndex &&
                                    dragEnterType === 'bottom' &&
                                    dragDownIndex !== dragEnterIndex
                            }"
                            :ref="'row-' + row.id"
                            :style="getRowStyle(row)"
                        >
                            <td
                                v-if="row._groupRow && options.group.groupCheckbox"
                                class="body-cell body-cell-group-checkbox"
                                :colspan="1"
                            >
                                <div v-if="columns[0].checkbox" class="body-cell select-row-checkbox-cell">
                                    <checkbox
                                        :ref="'checkbox_' + rowIndex"
                                        :propChecked="
                                            allChecked || row._checked || groupCheckboxTypeHandle(rowIndex, row)
                                        "
                                        :propHalfChecked="groupCheckboxHalfCheck(rowIndex, row)"
                                        :propValue="rowIndex"
                                        @change="groupRowCheckedChange"
                                        :key="row.id + rowIndex"
                                    ></checkbox>
                                </div>
                            </td>
                            <td
                                v-if="row._groupRow"
                                class="body-cell body-cell-group"
                                :colspan="
                                    columns[0].checkbox && options.group.groupCheckbox
                                        ? columns.length - 1
                                        : columns.length
                                "
                            >
                                <div class="switch" @click.stop="switchRowGroup(row)">
                                    <roundAddImg
                                        v-if="!row._expand"
                                        v-bind="{ color: '#007aff', width: '16px', height: '16px' }"
                                    />
                                    <roundMinusImg
                                        v-if="row._expand"
                                        v-bind="{ color: '#007aff', width: '16px', height: '16px' }"
                                    />
                                </div>
                                <div v-html="getRowGroupFormatter(row)"></div>
                            </td>
                            <template v-else v-for="(field, cellIndex) in columns">
                                <td
                                    v-if="getFieldVisible(field)"
                                    :style="cellStyleFormatter(field, row)"
                                    :key="field.field || cellIndex"
                                    :class="{ columnsBoder: cellIndex === columnsSelectIndex }"
                                    @dblclick="td_dbClick(row, rowIndex, field)"
                                    @click="cellClick(row, rowIndex, field)"
                                >
                                    <template v-if="field.editor && renderEditor(field, row, rowIndex)">
                                        <div class="body-cell">
                                            <cellEditor
                                                :options="renderEditor(field, row, rowIndex)"
                                                :row="row"
                                                :rowIndex="rowIndex"
                                                :cellIndex="cellIndex"
                                                :ref="rowIndex + '_' + field.field"
                                            />
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div
                                            v-if="field.checkbox"
                                            @mouseup.stop="cellCheckboxClick(rowIndex, $event)"
                                            @click.stop="checkboxClickFn(row, rowIndex, $event)"
                                            class="body-cell select-row-checkbox-cell"
                                        >
                                            <checkbox
                                                :ref="'checkbox_' + rowIndex"
                                                :propChecked="
                                                    allChecked || row._checked || checkboxTypeHandle(rowIndex)
                                                "
                                                :propValue="rowIndex"
                                                :propId="row.id"
                                                @change="rowCheckedChange"
                                                @click.native="checkboxClickFn(row, rowIndex, $event)"
                                                :key="row.id + rowIndex"
                                            ></checkbox>
                                        </div>
                                        <div
                                            v-else-if="field.radio"
                                            @mouseup.stop="cellCheckboxClick(rowIndex, $event)"
                                            @click.stop
                                            class="body-cell select-row-radio-cell"
                                        >
                                            <radio
                                                :ref="'checkbox_' + rowIndex"
                                                :propChecked="allChecked || row._checked"
                                                :propValue="rowIndex"
                                                :propId="row.id"
                                                @change="rowRadioChange"
                                                @click.native="checkboxClickFn(row, rowIndex, $event)"
                                                v-if="options.type !== 'treeGrid'"
                                            ></radio>
                                        </div>
                                        <div v-else-if="field.index === true" class="body-cell row-index-cell">
                                            {{ getRowIndex(row.gridIndex || rowIndex + 1) }}
                                        </div>
                                        <div
                                            v-else-if="field.type === 'link'"
                                            class="body-cell"
                                            :style="{ textAlign: contentAlign(field) }"
                                        >
                                            <div
                                                class="cell-icon"
                                                v-if="field.iconFormatter"
                                                :class="field.iconFormatter(row)"
                                                :style="{ cursor: 'pointer' }"
                                            ></div>
                                            <a
                                                href="javascript:"
                                                class="cell-tag"
                                                :class="field.tagFormatter && field.tagFormatter().type"
                                                @mouseover="showPoptipPanel($event, rowIndex, field, row)"
                                                @mouseout.stop="removeErrorPanel"
                                                v-html="getFieldValue(row, field.field)"
                                            ></a>
                                        </div>
                                        <!--下拉框类型显示值-->
                                        <div v-else-if="field.type === 'select'" class="body-cell">
                                            <div
                                                @mouseover="showPoptipPanel($event, rowIndex, field, row)"
                                                @mouseout.stop="removeErrorPanel"
                                                class="readonly-text"
                                                v-html="getSelectText(field, row, rowIndex)"
                                            ></div>
                                        </div>
                                        <!--checkbox类型显示值-->
                                        <div v-else-if="field.type === 'checkbox'" class="body-cell">
                                            <div
                                                class="readonly-text"
                                                v-html="getCheckboxText(field, row, rowIndex)"
                                            ></div>
                                        </div>
                                        <div v-else-if="field.type === 'richText'" class="body-cell">
                                            <richTextField
                                                class="readonly-text"
                                                :propReadonly="true"
                                                :options="{ field: field }"
                                                :propValue="row[field.field]"
                                                :rowIndex="rowIndex"
                                            ></richTextField>
                                        </div>
                                        <!-- 电子签名 -->
                                        <div v-else-if="field.type === 'sign'" class="body-cell">
                                            <signField
                                                :propValue="getFieldValue(row, field.field)"
                                                :options="{ field: field }"
                                                :propReadonly="true"
                                            ></signField>
                                        </div>
                                        <!--simpleCheckbox-->
                                        <div v-else-if="field.type === 'simpleCheckbox'" class="body-cell">
                                            <simple-checkbox-field
                                                :propValue="getFieldValue(row, field.field)"
                                                :options="{ field: field }"
                                                :propReadonly="true"
                                                key="readonly-simpleCheckbox"
                                            />
                                        </div>
                                        <!--treeGrid字段类型-->
                                        <div v-else-if="field.type === 'tree'" class="body-cell">
                                            <treeGridNode
                                                :ref="'node_' + rowIndex"
                                                v-bind="{
                                                    row,
                                                    single: options.single,
                                                    fieldOptions: field,
                                                    rowIndex,
                                                    checkedNodes,
                                                    checked: getChecked(row, options.single, options.cascadeCheck),
                                                    halfCheck: getHalfCheck(row, options.cascadeCheck)
                                                }"
                                                @change="nodeClickHandle"
                                                v-if="options.type === 'treeGrid'"
                                            ></treeGridNode>
                                        </div>
                                        <!-- 拖动 -->
                                        <div v-else-if="field.type === 'drag'" class="body-cell">
                                            <div
                                                class="drag"
                                                :class="{ cur: dragDownIndex !== null }"
                                                @mousemove="dragMove($event)"
                                                @mouseup="dragUp(row, rowIndex)"
                                                @mouseenter="dragEnter(row, rowIndex)"
                                            >
                                                <dragImg
                                                    v-bind="{
                                                        width: '14px',
                                                        height: '14px',
                                                        color: dragDownIndex === rowIndex ? '#007AFF' : '#999'
                                                    }"
                                                    @mousedown.native="dragDown(row, rowIndex, $event)"
                                                />
                                            </div>
                                        </div>
                                        <div v-else class="body-cell" :field="field.field">
                                            <div
                                                class="cell-icon"
                                                v-if="field.iconFormatter"
                                                :class="field.iconFormatter(row)"
                                            ></div>
                                            <cellTagField
                                                v-else-if="field.tagFormatter"
                                                :options="field.tagFormatter(row)"
                                            ></cellTagField>
                                            <div
                                                v-else
                                                class="readonly-text"
                                                :style="{
                                                    lineHeight: checkReadonlyText(rowIndex, field, row)
                                                }"
                                                @mouseover="showPoptipPanel($event, rowIndex, field, row)"
                                                @mouseout.stop="removeErrorPanel"
                                            >
                                                <span
                                                    class="text"
                                                    :style="getReadonlyTextStyle(field)"
                                                    v-html="getReadonlyText(rowIndex, field, row)"
                                                ></span>
                                                <span class="addon" v-if="field.addon">{{ field.addon }}</span>
                                            </div>
                                        </div>
                                    </template>
                                </td>
                            </template>
                        </tr>
                        <totalRow
                            v-if="Gikam.isNotEmpty(this.options.sum.fields)"
                            :options="options"
                            :state="$store.state"
                        ></totalRow>
                        <tr
                            v-if="(!options.data || options.data.length === 0) && loading === false"
                            class="no-record-tr"
                        >
                            <td class="no-record-cell" :colspan="columns.length">
                                <div class="no-record body-cell">
                                    <div class="no-record-div">
                                        <img class="no-record-img" src="../../../../img/no-record.png" />
                                        <span class="no-record-text">{{ $i18n('grid.noRecordText') }}</span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </component>
        </div>
        <grid-footer :showBody="options.showBody"></grid-footer>
        <gridFixed
            :options="options"
            v-show="showFixedColumns"
            :rowFixedTop="rowFixedTop"
            :showFixedColumns="showFixedColumns"
            :showRowFixedTop="showRowFixedTop"
            :transition="transition"
            :propCheckeds="checkeds"
            :propFilterOpen="options.filterOpen"
            :rowActiveId="rowActiveId"
            :propTotalChecked="totalChecked"
            :propShowCheckedNum="options.showCheckedNum"
            :fixedIndex="rowFixedTop"
            @checkedsHandle="checkedsHandle"
        ></gridFixed>
        <div class="custom-content" v-if="options.customContent" v-html="options.customContent"></div>
    </div>
</template>

<script>
import gridFixed from './gridFixed.vue';
import btn from '../../template/button.vue';
import Gikam from '../../../core/gikam-core';
import genericQueryPanel from './gridGeneralQueryPanel.vue';
import gridHeader from './header';
import treeGridNode from './treeGridNode.vue';
import jQuery from 'jquery';
import Vue from 'vue';
import cellEditor from './cellEditor';
import generalButtonGroup from './generalButtonGroup.vue';
import gridFooter from './footer/gridFooter.vue';
import totalRow from './totalRow.vue';
import { mapState, mapMutations } from 'vuex';
import DefaultScroll from '../../template/scroll/vue/defaultScroll.vue';
import GridRowFixedTop from './gridRowFixedTop.vue';
import GridRowFixedBottom from './gridRowFixedBottom.vue';

export default {
    props: {
        options: Object
    },

    computed: {
        ...mapState('chooseColumns', ['columnsSelectIndex']),
        height() {
            if (this.options.noScroll === true) {
                return void 0;
            }
            if (this.options.fill) {
                return '100%';
            }
            const height = this.options.height;
            return Gikam.isNumber(height) ? height + 'px' : height;
        },

        width() {
            return this.$store.state.width + 'px';
        },

        tableWidth() {
            return this.options.noScroll === true ? this.$store.state.width + 2 + 'px' : void 0;
        },

        maxRow() {
            return this.options.data.length - 1;
        },

        maxCol() {
            return this.columns.length - 1;
        },

        minCol() {
            return this.columns.filter(item => {
                return !item.field;
            }).length;
        },

        cellLineFeed() {
            return this.options.textLineFeed;
        },

        dragLinkHandle() {
            return this.columns.some(item => {
                return item.type === 'drag';
            });
        },

        columns() {
            return this.$store.state.columns;
        },

        showFixedColumns() {
            return this.columns.reduce((str, item) => {
                if (item.visible !== '0' && item.fixed == true) {
                    str += item.field || item.index || item.checkbox;
                }
                return str;
            }, '');
        },

        rowActiveId() {
            if (Gikam.isEmpty(this.options.data) || Gikam.isEmpty(this.activeRow)) {
                return '';
            } else {
                return this.options.data[this.activeRow.getAttribute('data-index')].id;
            }
        },

        scrollTop() {
            return this.$store.state.scrollTop;
        },

        scrollLeft() {
            return this.$store.state.scrollLeft;
        },

        showToobar() {
            return (
                this.options.toolbarHidden &&
                (this.options.toolbar.length > 0 || this.options.genericQuery || this.options.filter)
            );
        }
    },

    inject: {
        grid: {
            default: null
        }
    },

    provide() {
        return {
            checkedNodes: this.checkedNodes
        };
    },

    beforeUpdate() {
        if (this.checkeds.length == 0) {
            this.totalChecked.num = this.checkeds.length;
            this.groupNum = 0;
            this.isCornerMarkerShow(this.totalChecked.num);
        } else {
            this.totalChecked.num = this.checkeds.length - this.groupNum;
            this.isCornerMarkerShow(this.totalChecked.num);
        }
    },

    methods: {
        ...mapMutations(['cleanCheckedTreeNodes']),
        //向下赋值事件句柄
        async downFillValueEvent({ index, field }) {
            let rowIndex;
            if (Gikam.isEmpty(this.options.group.fields)) {
                rowIndex = 0;
            } else {
                rowIndex = 1;
            }
            const value = this.$refs[rowIndex + '_' + index].value;
            const maxLength = this.options.data.length;

            while (rowIndex + 1 < maxLength) {
                const _i = rowIndex + 1;
                const currentRow = this.options.data[_i];
                if (currentRow._groupRow) {
                    rowIndex = _i;
                } else {
                    const row = {
                        [field]: value,
                        index: _i
                    };
                    rowIndex = await this.grid
                        .setData(row)
                        .then(() => {
                            return _i;
                        })
                        .catch(() => {
                            Gikam.error('保存接口异常，操作中断');
                            return maxLength;
                        });
                }
            }
        },

        changeHiddenGridState(arg) {
            this.hiddenGrid = arg;
        },

        transitionChange(val) {
            this.transition = val;
        },

        //td双击事件
        td_dbClick(row, rowIndex, field) {
            const fieldName = field.field;
            this.grid.trigger('cellDbClick', fieldName, row, rowIndex);
        },

        //
        checkedsHandle(data) {
            this.checkeds = data;
            this.allCheckboxType();
        },

        // checkbox通过index判断是否选中
        checkboxTypeHandle(index) {
            if (this.checkeds.includes(index)) return true;
            return false;
        },

        // 具有分组数据时，判断checkbox是否选中
        groupCheckboxTypeHandle(index, row) {
            let checked = false;
            this.options.data.forEach((item, index) => {
                if (item._parentGroupIndex == row._groupIndex && this.checkeds.includes(index)) {
                    checked = true;
                }
            });
            if (!this.checkeds.includes(index) && checked) {
                this.groupNum++;
                this.checkeds.push(index);
            } else if (this.checkeds.includes(index) && !checked) {
                this.groupNum--;
                this.checkeds.splice(this.checkeds.indexOf(index), 1);
            }
            return checked;
        },

        groupCheckboxHalfCheck(index, row) {
            let groupChildChecked = 0;
            let allGroupChildRow = 0;
            this.options.data.forEach((item, index) => {
                if (item._parentGroupIndex == row._groupIndex) {
                    allGroupChildRow++;
                    if (this.checkeds.includes(index)) {
                        groupChildChecked++;
                    }
                }
            });
            if (groupChildChecked == 0 || groupChildChecked == allGroupChildRow) {
                return false;
            } else {
                return true;
            }
        },

        // 全选状态自动改变
        allCheckboxType() {
            if (this.checkeds.length !== 0 && this.checkeds.length === this.options.data.length) {
                this.allChecked = true;
            } else {
                this.allChecked = false;
            }
        },

        getColumnTitle(field) {
            return Gikam.propI18N(field.title);
        },

        getRowGroupFormatter(row) {
            return this.options.group.formatter.call(this.grid, row);
        },

        getRowHidden(row) {
            let parentGroupRow;
            this.options.data.forEach(item => {
                if (item._groupIndex == row._parentGroupIndex) {
                    parentGroupRow = item;
                }
            });
            row._hidden = !parentGroupRow._expand;
            return row._hidden;
        },

        getCheckNum(row) {
            let checkNum = 0;
            row.children &&
                row.children.forEach(item => {
                    if (item.checked) {
                        checkNum++;
                    }
                });
            return checkNum;
        },

        getHalfCheckNum(row) {
            let halfCheckNum = 0;
            row.children &&
                row.children.forEach(item => {
                    if (this.getHalfCheck(item)) {
                        halfCheckNum++;
                    }
                });
            if (Gikam.isEmpty(row.children)) halfCheckNum = 1;
            return halfCheckNum;
        },

        getChecked(row, single, cascadeCheck) {
            if (single) {
                return row.checked;
            }
            if (!cascadeCheck) {
                return row.checked;
            }
            let checkNum = this.getCheckNum(row);
            if (row.children && checkNum == 0) {
                row.checked = false;
            } else if (row.children && checkNum != 0) {
                row.checked = true;
            }
            return row.checked;
        },

        getHalfCheck(row, cascadeCheck) {
            if (!cascadeCheck) {
                return false;
            }
            let num;
            if (row.children) {
                num = row.children.length;
            } else {
                num = 0;
            }
            let checkNum = this.getCheckNum(row);

            if (num == 0 || (checkNum == num && this.getHalfCheckNum(row) == 0)) {
                return false;
            } else {
                return true;
            }
        },

        switchRowGroup(row) {
            row._expand = !row._expand;
            this.options.data
                .filter(subRow => {
                    return subRow._parentGroupIndex === row._groupIndex;
                })
                .forEach(item => {
                    item._hidden = !row._expand;
                });
        },

        checkReadonlyText(rowIndex, field, row) {
            let val = this.getReadonlyText(rowIndex, field, row);
            if (val && typeof val == 'string' && val.indexOf('<sup>') != -1) {
                return '20px';
            } else {
                return '24px';
            }
        },

        contentAlign(field) {
            return field.contentAlign ? field.contentAlign : this.options.contentAlign;
        },

        getReadonlyText(rowIndex, field, row) {
            let text = Gikam.getFieldValue(row, field.field);
            if (field.formatter) {
                return field.formatter(rowIndex, Gikam.getFieldValue(row, field.field), row);
            } else if (field.type === 'select' && Gikam.isNotEmpty(field.items) && text) {
                return field.items.find(item => item.value === text).text;
            } else {
                return text;
            }
        },

        // 气泡
        showPoptipPanel(event, rowIndex, field, row) {
            if (field.titleTip === false) {
                return;
            }
            if (window.screen.width < 768) return;
            let formatterValue = this.getReadonlyText(rowIndex, field, row);
            if (Gikam.isEmpty(formatterValue)) {
                return false;
            }
            if (field.titleFormatter) {
                formatterValue = field.titleFormatter(row);
            }
            if (!Gikam.poptipPanel) {
                const top = event.clientY + 20;
                const left = event.clientX;
                let lineHeight;
                if (
                    formatterValue &&
                    typeof formatterValue == 'string' &&
                    formatterValue.indexOf('<sup>') != -1 &&
                    formatterValue.indexOf('</p>') == -1
                ) {
                    lineHeight = 10;
                } else {
                    lineHeight = 19;
                }
                Gikam.poptipPanel = new Vue({
                    el: Gikam.createDom('div', document.body),
                    data: {
                        formatterValue: formatterValue,
                        tipStyle: {
                            top: top + 'px',
                            backgroundColor: '#fff',
                            maxWidth: '400px',
                            height: 'auto'
                        }
                    },
                    methods: {
                        options() {
                            // 解决气泡居边产生滚动条
                            let poptip = Gikam.jQuery(this.$el);
                            let win = Gikam.jQuery(window);
                            let width = poptip.width();
                            let height = poptip.height();
                            let window_width = win.width();
                            let window_height = win.height();
                            if (left + width + 24 > window_width) {
                                this.tipStyle = { top: top, right: '16px' };
                            } else {
                                this.tipStyle = { top: top, left: left + 'px' };
                            }
                            if (top + height + 24 > window_height) {
                                this.tipStyle.top = top - 40 + 'px';
                            }
                            this.tipStyle['max-width'] = '400px';
                            this.tipStyle['height'] = 'auto';
                            this.tipStyle['lineHeight'] = lineHeight + 'px';
                        }
                    },
                    mounted() {
                        this.options();
                    },
                    template: `<div class='poptip-message-panel' ref= 'poptip' :style='tipStyle' v-html=formatterValue></div>`
                });
            }
        },

        // 关闭气泡
        removeErrorPanel() {
            if (Gikam.poptipPanel) {
                Gikam.removeDom(Gikam.poptipPanel.$el);
                Gikam.poptipPanel.$destroy();
                Gikam.poptipPanel = null;
            }
        },

        getReadonlyTitle(rowIndex, field, row) {
            let title = this.getReadonlyText(rowIndex, field, row);
            if (Gikam.isEmpty(title)) {
                return '';
            }
            if (field.titleFormatter) {
                return field.titleFormatter(row);
            }
            return (title + '').replace(/<\/?[a-zA-Z]+[^><]*>/g, '');
        },

        getFieldValue(data, field) {
            return Gikam.getFieldValue(data, field);
        },

        renderEditor(field, row, rowIndex) {
            if (field.onBeforeEditorRender) {
                const editorRenderFlag = field.onBeforeEditorRender.call(this.grid, row, rowIndex);
                if (editorRenderFlag === false) {
                    return false;
                }
                if (editorRenderFlag === true) {
                    return field;
                }
                return Gikam.deepExtend(field, editorRenderFlag);
            }
            return field;
        },

        rowCheckedChange(checked, rowIndex, event, id) {
            event && event.stopPropagation();

            if (this.options.activeOnCheck) {
                const row = this.$refs['row-' + id];
                row && row[0].click((this._clickRadio = true));
                this._clickRadio = false;
            }

            const row = this.options.data[rowIndex];
            if (checked) {
                this.checkeds.push(rowIndex);
                this.checkeds.sort((a, b) => {
                    return a - b;
                });
                this.grid.trigger('select', row);
                this.totalChecked.num = this.totalChecked.num + 1;
                this.isCornerMarkerShow(this.totalChecked.num);
            } else {
                this.checkeds.splice(this.checkeds.indexOf(rowIndex), 1);
                this.grid.trigger('unSelect', row);
                this.totalChecked.num = this.totalChecked.num - 1;
                this.isCornerMarkerShow(this.totalChecked.num);
            }
            this.allCheckboxType();
        },

        groupRowCheckedChange(checked, rowIndex, event) {
            event && event.stopPropagation();
            if (this.checkedAll) {
                this.$nextTick(() => {
                    this.checkedAll = null;
                });
                return;
            }

            const row = this.options.data[rowIndex];
            let groupRow = [];
            this.options.data.forEach((item, index) => {
                if (item._parentGroupIndex == row._groupIndex) {
                    groupRow.push(index);
                }
            });
            if (checked) {
                this.groupNum++;
                this.checkeds.push(rowIndex);
                this.checkeds.sort((a, b) => {
                    return a - b;
                });
                groupRow.forEach(item => {
                    if (this.checkeds.indexOf(item) == -1) {
                        this.$refs['checkbox_' + item][0].clickHandle();
                    } else {
                        return;
                    }
                });
            } else {
                this.groupNum--;
                this.checkeds.splice(this.checkeds.indexOf(rowIndex), 1);
                if (!this.rowActived) {
                    groupRow.forEach(item => {
                        if (this.checkeds.indexOf(item) !== -1) {
                            this.$refs['checkbox_' + item][0].clickHandle();
                        } else {
                            return;
                        }
                    });
                } else {
                    return;
                }
            }
        },

        isAllChecked(allChecked) {
            if (allChecked) {
                this.groupNum = 0;
                this.options.data.forEach(item => {
                    if (item.$rowStyle && item.$rowStyle == 'group') {
                        this.groupNum++;
                    }
                });
                this.totalChecked.num = this.options.data.length - this.groupNum;
                this.isCornerMarkerShow(this.totalChecked.num);
                // 当数据改变，页面更新后执行
                this.$nextTick(() => {
                    this.grid.trigger('allSelect', allChecked);
                });
            } else {
                this.groupNum = 0;
                this.checkeds = [];
                this.totalChecked.num = 0;
                this.isCornerMarkerShow(this.totalChecked.num);
                this.$nextTick(() => {
                    this.grid.trigger('unAllSelect', allChecked);
                });
            }
        },

        isCornerMarkerShow(num) {
            if (num > 1) {
                this.totalChecked.isShow = true;
            } else {
                this.totalChecked.isShow = false;
            }
        },

        rowRadioChange(checked, rowIndex, event, id) {
            let checkedRowIndex = this.checkeds[0];
            if (Gikam.isNotEmpty(checkedRowIndex)) {
                this.$refs['checkbox_' + checkedRowIndex][0].checked = false;
                this.checkeds.length = 0;
            }
            this.rowCheckedChange(checked, rowIndex, event, id);
            this.totalChecked.num = 0;
            this.isCornerMarkerShow(this.totalChecked.num);
        },

        nodeClickHandle(checked, single, row, rowIndex) {
            this.$set(row, 'checked', checked);
            this.grid.trigger('nodeSelected', row, checked);
            if (single) {
                if (checked) {
                    this.options.data
                        .filter(item => item.checked)
                        .forEach(item => {
                            this.$set(item, 'checked', false);
                            this.grid.trigger('nodeSelected', item, false);
                        });
                    this.$set(row, 'checked', true);
                }
                this.checkeds.length = 0;
            } else {
                if (!this.options.cascadeCheck) {
                    return;
                }
                const childrenRow = this.getAllChildren(row, []);
                if (Gikam.isNotEmpty(childrenRow)) {
                    childrenRow.forEach(_row => {
                        this.$set(_row, 'checked', checked);
                        this.grid.trigger('nodeSelected', _row, checked);
                    });
                }
            }

            if (checked) {
                this.checkeds.push(rowIndex);
                this.checkeds.sort((a, b) => {
                    return a - b;
                });
                this.grid.trigger('select', row);
            } else {
                this.checkeds.splice(this.checkeds.indexOf(rowIndex), 1);
                this.grid.trigger('unSelect', row);
            }
        },

        // 按下shift点击复选框，禁止鼠标选中文字。点选的时候容易选中文字.
        selectstart() {
            window.onkeydown = function(e) {
                if (e.shiftKey) {
                    window.onselectstart = function(event) {
                        event = window.event || event;
                        event.returnValue = false;
                    };
                }
            };
            window.onkeyup = function() {
                window.onselectstart = function(event) {
                    event = window.event || event;
                    event.returnValue = true;
                };
            };
        },

        // 先去一个index区间进行复选[选中]
        intervalCheckClick(shiftIndex) {
            for (
                let i = Math.min(this.lastActiveNodeIndex, shiftIndex);
                i < Math.max(this.lastActiveNodeIndex, shiftIndex);
                i++
            ) {
                let checkNode = this.grid.model.$refs.vm.$refs['checkbox_' + i][0];
                let checkType = checkNode.checked;
                !checkType && checkNode.$el.click();
            }
        },

        // 判断是否按下shift，并取相应的index
        getShiftCheckboxIndex(index, e) {
            if (this.lastActiveNodeIndex === undefined || this.lastActiveNodeIndex === index) {
                this.lastActiveNodeIndex = index;
            } else {
                if (e && e.shiftKey) {
                    this.$nextTick(() => {
                        this.intervalCheckClick(index);
                    });
                } else {
                    // 这里是不按shift时，最后一次点击index值
                    this.lastActiveNodeIndex = index;
                }
            }
        },

        checkboxClickFn(data, index, e) {
            // 加100ms，为了使其慢于click方法。比如：取getData()时
            setTimeout(() => {
                this.getShiftCheckboxIndex(index, e);
                this.grid.trigger('checkboxClick', data, index);
            }, 100);
        },

        getSelections() {
            return this.checkeds.map(rowIndex => Gikam.deepExtend(this.options.data[rowIndex]));
        },

        getSelectText(field, item, rowIndex) {
            let selectText = '';
            let value = Gikam.getFieldValue(item, field.field);
            if (field.multiple && value && value.indexOf(',') > -1) {
                value = value.split(',');
                field.items.forEach(select => {
                    if (select.value && value.some(v => v === select.value)) {
                        const text = field.formatter
                            ? field.formatter(rowIndex, select.value, item, select.text)
                            : select.text;
                        selectText += text + ',';
                    }
                });
                return selectText.substr(0, selectText.length - 1);
            }
            Gikam.each(field.items, function() {
                if (this.value == value) {
                    selectText = this.text;
                    return false;
                }
            });
            return field.formatter ? field.formatter(rowIndex, value, item, selectText) : selectText;
        },

        getSelectTextTitle(field, item, rowIndex) {
            return Gikam.getTextIgnoreHtml(this.getSelectText(field, item, rowIndex));
        },

        getCheckboxText(field, item) {
            const key = field.field;
            if (item[key]) {
                const value = JSON.parse(item[key]);
                const text = value.reduce((total, curr) => {
                    if (parseInt(curr.checked) === 1) {
                        total += curr.text + ',';
                    }
                    return total;
                }, '');
                return text.substr(0, text.length - 1);
            } else {
                return '';
            }
        },

        cellClick(row, rowIndex, field) {
            if (field.type === 'link') {
                const window = Gikam.getInstance(jQuery(this.$el).closest('.window'));
                if (window) {
                    window.model.storage = {
                        grid: this.grid,
                        index: rowIndex
                    };
                }
            }
            this.grid.trigger('cellClick', field.field, Gikam.deepExtend(row), rowIndex);
        },

        cellCheckboxClick(index, e) {
            this.getShiftCheckboxIndex(index, e);
            const checkbox = this.$refs['checkbox_' + index];
            checkbox && checkbox[0].clickHandle();
        },

        rowActive(event, index, rowData) {
            this.checkboxClickFn(rowData, index, event);
            if (this.options.type === 'treeGrid' && this.options.checkbox) {
                const checked = this.options.data[index].checked;
                this.$refs['node_' + index][0].changeHandle(!checked);
            } else {
                if (this.options.checkOneOnActive) {
                    this.rowActived = true;
                    Gikam.extend(true, [], this.checkeds).forEach(index => {
                        // checkeds这里面有rowGroup
                        // 判断不是rowGroup, 才进行此操作
                        if (Gikam.isEmpty(this.options.data[index]._groupIndex)) {
                            this.$refs['checkbox_' + index][0].clickHandle();
                        }
                    });
                    this.rowActived = false;
                }
                if (this.options.checkOnActive && this._clickRadio !== true) {
                    const checkbox = this.$refs['checkbox_' + index];
                    checkbox && checkbox[0].clickHandle();
                }
            }
            //激活的是当前行，此时不再进行激活
            if (this.activeRow && this.activeRow !== event.currentTarget) {
                this.activeRow.classList.remove('active');
            }
            if (this.activeRow !== event.currentTarget) {
                this.grid.trigger('rowActive', index, rowData);
            }
            this.activeRow = event.currentTarget;
            this.activeRowData = rowData;
            this.activeRow.classList.add('active');
        },

        getActiveRow() {
            if (this.activeRowData) {
                let row = Gikam.deepExtend(this.activeRowData);
                row.index = ~~this.activeRow.getAttribute('data-index');
                return row;
            } else {
                return null;
            }
        },

        clean() {
            this.checkeds = [];
            this.checkedNodes = [];
            this.activeRow && this.activeRow.classList.remove('active');
            this.activeRow = null;
            this.activeRowData = null;
            this.allChecked = false;
            this.cleanCheckedTreeNodes();
        },

        refreshCheck(index) {
            let activeRow = this.checkeds.indexOf(index);
            if (activeRow != -1) {
                this.checkeds.splice(activeRow, 1);
            }
            for (let i = 0; i < this.checkeds.length; i++) {
                if (this.checkeds[i] > index) {
                    this.checkeds[i]--;
                }
            }
        },

        //上方工具栏查询
        initToolbarForm() {
            if (Gikam.isEmpty(this.options.toolbar)) {
                return;
            }
            const _this = this;
            this.options.toolbar.forEach((item, index) => {
                if (item.type !== 'form') {
                    return;
                }
                item.fields.forEach(field => {
                    if (field.refreshGrid === false) {
                        return;
                    }
                    let change = field.onChange;
                    field.onChange = function(...args) {
                        const data = {};
                        const _val = this.getField(field.field).value;
                        data[field.field] = _val ? _val : '';
                        _this.$store.commit('refresh', {
                            requestData: data
                        });
                        change && change.apply(this, args);
                    };
                });
                item.renderTo = this.$refs['toolbar_form_' + index][0];
                item.autoSave = false;
                const _rendered = item.onRendered;
                item.rendered = function(...args) {
                    _this.handleBtnMore();
                    _rendered && _rendered.apply(this, args);
                    _this.grid.refresh({
                        requestData: toolbarFrom.getData()
                    });
                };
                let toolbarFrom = Gikam.create('form', item);
            });
        },

        setFieldsReadonly(index, fields, isReadonly) {
            for (let key in this.$refs) {
                if (
                    this.$refs[key] &&
                    this.$refs[key].options &&
                    fields.indexOf(this.$refs[key].options.field) > -1 &&
                    parseInt(key.split('_')[0]) === index
                ) {
                    this.$refs[key].readonly = isReadonly;
                }
            }
        },

        genericQueryReset() {
            const requestData = this.grid.options.requestData;
            this.options.genericQueryFields.forEach(item => {
                for (let key in requestData) {
                    if (key.indexOf(item.field) >= 0) {
                        delete requestData[key];
                    }
                }
            });
            this.options.genericQueryFields = this.defaultGenericQueryFields;
            this.grid.refresh();
        },

        popGenericQueryPanel() {
            if (this.activeGenericQuery) {
                return;
            }
            this.genericQueryCount = this.options.genericQueryFields.length;
            const $searchBtn = jQuery(this.$el.querySelector('.generic-query-btn'));
            const left = $searchBtn.offset().left + 'px';
            const top = $searchBtn.offset().top + $searchBtn.outerHeight() + 2 + 'px';
            const _this = this;
            this.activeGenericQuery = new Vue({
                el: jQuery(
                    '<generic-query-Panel :options="options" @change="changeHandle" @destroy="destroyHandle"/>'
                ).appendTo('body')[0],
                provide: {
                    grid: this.grid
                },
                components: { genericQueryPanel },
                methods: {
                    changeHandle(conditions) {
                        _this.genericQueryCount = conditions.length;
                    },
                    destroyHandle() {
                        _this.activeGenericQuery = void 0;
                    }
                },
                data() {
                    return {
                        options: {
                            left: left,
                            top: top,
                            fields: _this.columns.filter(item => item.field),
                            genericQueryFields: _this.options.genericQueryFields
                        }
                    };
                }
            });
        },

        cellStyleFormatter(fieldOptions, row) {
            if (fieldOptions.styleFormatter) {
                return fieldOptions.styleFormatter(row);
            }
        },

        getRowStyle(row) {
            if (this.options.rowStyleFormatter) {
                return this.options.rowStyleFormatter(row);
            }
        },

        getAllChildren(row, result) {
            const _this = this;
            if (row.children) {
                row.children.forEach(item => {
                    if (item.children) {
                        _this.getAllChildren(item, result);
                    }
                    result.push(item);
                });
            }
            return result;
        },

        initScroll() {
            this.$refs.vs.scrollTo({
                y: this.scrollTop,
                x: this.scrollLeft
            });
        },

        handleScroll(scrollTop, scrollLeft) {
            if (this.options.data.length) {
                this.$store.commit('changeScrollTop', scrollTop);
            }
            this.$store.commit('changeScrollLeft', scrollLeft);
        },

        bodyKeyDown(e, direction) {
            this.$el.click();
            if (this.canPositionState === undefined) {
                const canPositionsList = [
                    'textInput',
                    'number',
                    'textarea',
                    'password',
                    'richText',
                    'year',
                    'time',
                    'date',
                    'choose',
                    'select',
                    'insertableSelect',
                    'comboBox',
                    'cron',
                    'textAddon'
                ];
                this.canPositionState = this.columns.some(item => {
                    return (
                        item.readonly !== true && (canPositionsList.indexOf(item.type) >= 0 || Gikam.isEmpty(item.type))
                    );
                });
            }
            if (Gikam.isFalse(this.canPositionState)) {
                return;
            }
            if (this.moving) {
                e.stopPropagation();
                return;
            }
            this.moving = true;
            const [x = 0, y = this.minCol] = this.$store.state.activeCoordinate;

            if (direction) {
                this.inputCursorMovement(x, y, direction);
            } else if (e.keyCode === 9 && e.shiftKey) {
                this.inputCursorMovement(x, y, 'left');
            } else if (e.keyCode === 9) {
                this.inputCursorMovement(x, y, 'right');
            }
        },

        inputIndexChange(obj, index) {
            if (obj.direction === obj.reduce) {
                return index - 1;
            } else if (obj.direction === obj.add) {
                return index + 1;
            } else {
                return index;
            }
        },

        getNextInput(x, y, direction) {
            let row = x;
            let column = y;
            if (x > this.maxRow) {
                row = 0;
                column = this.inputIndexChange({ direction, reduce: 'up', add: 'down' }, y);
            } else if (x < 0) {
                row = this.maxRow;
                column = this.inputIndexChange({ direction, reduce: 'up', add: 'down' }, y);
            } else {
                row = this.inputIndexChange({ direction, reduce: 'up', add: 'down' }, x);
            }

            if (y > this.maxCol) {
                column = this.minCol;
                row = this.inputIndexChange({ direction, reduce: 'left', add: 'right' }, x);
            } else if (y < this.minCol) {
                column = this.maxCol;
                row = this.inputIndexChange({ direction, reduce: 'left', add: 'right' }, x);
            } else {
                row === x && (column = this.inputIndexChange({ direction, reduce: 'left', add: 'right' }, y));
            }
            return [row, column];
        },

        inputCursorMovement(x, y, direction, oldCell) {
            oldCell && oldCell.dumpActiveCell instanceof Function && oldCell.dumpActiveCell();

            const [next_X, next_Y] = this.getNextInput(...arguments);
            if (this.judgeCanDoing(next_X, next_Y) === true && this.judgeGroupState(next_X, next_Y) !== true) {
                const scrollRes = this.handleCursorPosition(next_X, next_Y);
                if (scrollRes) {
                    setTimeout(() => {
                        this.doneThing(next_X, next_Y, x, y);
                    }, 500);
                } else {
                    this.doneThing(next_X, next_Y, x, y);
                }
            } else {
                const currentCell = this.$refs[x + '_' + y];
                this.inputCursorMovement(next_X, next_Y, direction, currentCell);
            }
        },

        //判断是否可以执行移动逻辑
        judgeCanDoing(x, y) {
            const nextCell = this.$refs[x + '_' + y];
            if (
                nextCell &&
                nextCell.readonly !== true &&
                [
                    'textInput',
                    'numberField',
                    'textareaField',
                    'passwordField',
                    'richTextField',
                    'yearField',
                    'timeField',
                    'dateField',
                    'chooseField',
                    'selectField',
                    'insertableSelectField',
                    'comboBoxField',
                    'cronField',
                    'textAddonField'
                ].indexOf(nextCell.$options.name) >= 0
            ) {
                return true;
            } else {
                return false;
            }
        },

        //带有带分组
        judgeGroupState(x, y) {
            const nextCell = this.$refs[x + '_' + y];
            if (Gikam.isNotEmpty(this.grid.options.group.fields) && nextCell.validateArg.id) {
                const _data = Gikam.deepExtend(this.options.data);
                return _data.filter(row => row.id === nextCell.validateArg.id)[0]._hidden;
            } else {
                return false;
            }
        },

        async doneThing(next_X, next_Y, x, y) {
            const currentCell = this.$refs[x + '_' + y];
            currentCell && currentCell.dumpActiveCell instanceof Function && currentCell.dumpActiveCell();
            await this.$nextTick();
            const nextCell = this.$refs[next_X + '_' + next_Y];
            this.handleActiveCurrentRow(nextCell);
            await this.$nextTick();
            nextCell.activeCell instanceof Function && nextCell.activeCell();
            await nextCell.$nextTick();
            this.moving = false;
        },

        handleActiveCurrentRow(nextCell) {
            const id = this.options.data[nextCell.rowIndex].id;
            if (id === this.rowActiveId) {
                return false;
            }
            this.$refs['row-' + id][0].click();
            return true;
        },

        //处理光标向前和上下的位置变动
        handleCursorPosition(nextRowIndex, nextColumnIndex) {
            const fieldComp = this.$refs[nextRowIndex + '_' + nextColumnIndex];
            if (!fieldComp || !fieldComp.$el) return;
            const scroll = this.$refs.vs;
            if (Gikam.isEmpty(scroll)) {
                return false;
            }

            let state = false;

            const cellDom = fieldComp.$el.parentNode.parentNode;
            const cellDomWidth = cellDom.offsetWidth;
            const cellDomHeight = cellDom.offsetHeight;
            const cellDomLeft = cellDom.offsetLeft;
            const cellDomTop = cellDom.offsetTop;
            const wrapperWidth = scroll.$el.offsetWidth;
            const wrapperHeight = scroll.$el.offsetHeight;

            this.transition = false;
            const { scrollLeft, scrollTop } = scroll.getPosition();

            if (cellDomLeft < scrollLeft || cellDomLeft + cellDomWidth > scrollLeft + wrapperWidth) {
                scroll.scrollTo({ x: cellDomLeft + cellDomWidth - wrapperWidth });
                state = true;
            }

            if (cellDomTop < scrollTop || cellDomTop + cellDomHeight > scrollTop + wrapperHeight) {
                scroll.scrollTo({ y: cellDomTop + cellDomHeight - wrapperHeight });
                state = true;
            }
            setTimeout(() => {
                this.transition = true;
            }, 0);
            return state;
        },

        handleBtnMore() {
            this.showMoreBtn = false;
            // eslint-disable-next-line complexity
            this.$nextTick(() => {
                const $toolbar = this.$refs.toolbar;
                if (!$toolbar) return;
                const $btnContainer = $toolbar.querySelector('.operation');
                if (this.moreBtnOptions.fragment) {
                    for (let i = this.moreBtnOptions.fragment.length; i > 0; i--) {
                        $btnContainer.appendChild(this.moreBtnOptions.fragment[i - 1]);
                    }
                    this.updateOrder($btnContainer);
                    this.moreBtnOptions.fragment = null;
                }
                const children = $btnContainer.children;
                if (children.length === 0) {
                    return;
                }
                const btns = [];
                const others = [];
                for (let i = 0; i < children.length; i++) {
                    const type = children[i].getAttribute('type');
                    if (type === 'button') {
                        btns.push(children[i]);
                    } else {
                        others.push(children[i]);
                    }
                }

                if (btns.length === 0) return;
                const searchWidth = $toolbar.querySelector('.search')
                    ? $toolbar.querySelector('.search').offsetWidth
                    : 0;
                const generalGroupWidth = $toolbar.querySelector('.general-group')
                    ? $toolbar.querySelector('.general-group').offsetWidth
                    : 0;
                let btnContainerWidth =
                    (this.options.allowWidth || $toolbar.parentNode.offsetWidth) - 32 - searchWidth - generalGroupWidth;
                for (let i = 0; i < others.length; i++) {
                    btnContainerWidth -= Gikam.jQuery(others[i]).outerWidth(true);
                }
                let btnsWidth = 0;
                for (let i = 0; i < btns.length; i++) {
                    btnsWidth += Gikam.jQuery(btns[i]).outerWidth(true);
                }
                if (btnsWidth <= btnContainerWidth) return;

                const fragment = document.createDocumentFragment();
                btnsWidth -= Gikam.jQuery(btns[btns.length - 1]).outerWidth(true);
                fragment.appendChild(btns[btns.length - 1]);
                btns.pop();
                while (btnContainerWidth - 89 < btnsWidth && btns.length > 0) {
                    btnsWidth -= Gikam.jQuery(btns[btns.length - 1]).outerWidth(true);
                    fragment.appendChild(btns[btns.length - 1]);
                    btns.pop();
                }
                this.moreBtnOptions.fragment = [].slice.call(fragment.childNodes);
                this.showMoreBtn = true;
            });
        },

        // 更新toolbar顺序
        updateOrder(dom) {
            const fragment = document.createDocumentFragment();
            const toolbars = Array.from(dom.children).sort(
                (prev, cur) => prev.getAttribute('index') - cur.getAttribute('index')
            );
            toolbars.forEach(item => fragment.appendChild(item));
            dom.appendChild(fragment);
        },

        // 排序
        sortGrid(orderField, orderType) {
            if (!orderType || !orderField) {
                delete this.grid.options.order[this.grid.options.orderField];
                this.grid.refresh();
                return;
            }

            if (this.grid.options.orderField === orderField) {
                this.grid.options.order[orderField] = orderType;
            } else {
                this.grid.options.order = {};
                this.grid.options.order[orderField] = orderType;
                this.grid.options.orderField = orderField;
            }
            this.grid.refresh();
        },

        getCheckedNodeRow() {
            return this.checkedNodes.map(item => item.row);
        },

        /* 拖动的方法 */
        dragMove(e) {
            if (this.dragDownIndex === null) return;
            if (e.pageY - this.dragPageY > 0) {
                this.dragEnterType = 'bottom';
            }
            if (e.pageY - this.dragPageY < 0) {
                this.dragEnterType = 'top';
            }
            this.dragPageY = e.pageY;
        },

        dragDown(data, index, e) {
            this.scrollAutomaticSlide = true;
            this.dragDownIndex = index;
            this.dragPageY = e.pageY;
            this.dragDownData = data;
        },

        dragUp(data, index) {
            this.scrollAutomaticSlide = false;
            if (data && this.dragDownIndex !== null && this.dragDownIndex !== index) {
                // 排序逻辑
                this.options.data.splice(this.dragDownIndex, 1);
                for (let i = 0; i < this.options.data.length; i++) {
                    if (this.options.data[i].field === data.field) {
                        let index = { top: i, bottom: i + 1 }[this.dragEnterType];
                        this.options.data.splice(index, 0, this.dragDownData);
                        break;
                    }
                }
            }

            this.dragDownIndex = null;
            this.dragEnterIndex = null;
            this.dragPageY = 0;
            this.dragEnterType = null;
            this.dragDownData = null;
        },

        dragEnter(data, index) {
            this.dragEnterIndex = index;
        },

        dragLeave() {
            if (this.dragDownIndex === null) {
                return;
            }
            this.dragUp();
        },

        // 滑条上下滚动事件
        scrollTypeHandle(type) {
            this.scrollType = type;
            if (type === 'top') {
                this.scrollTime && clearInterval(this.scrollTime);
                this.scrollTime = null;
                this.scrollTime = setInterval(() => {
                    this.$refs['vs'] && this.$refs['vs'].scrollBy({ dy: -100 }, 300);
                }, 300);
            }
            if (type === 'bottom') {
                this.scrollTime && clearInterval(this.scrollTime);
                this.scrollTime = null;
                this.scrollTime = setInterval(() => {
                    this.$refs['vs'] && this.$refs['vs'].scrollBy({ dy: 100 }, 300);
                }, 300);
            }
        },

        scrollTypeLeave() {
            this.scrollType = null;
            clearInterval(this.scrollTime);
            this.scrollTime = null;
        },

        scrollTypeUp() {
            this.dragUp();
        },

        getFieldVisible(options) {
            const visible = options.visible;
            return visible === undefined || visible === '1' || visible === true;
        },

        // 获取表头隐藏属性hideHeader
        getFieldHideHeader(field) {
            if (field.hideHeader) {
                return false;
            }
            return true;
        },

        getCellWidth(item) {
            return item.width ? 'width:' + (item.width + 'px') : null;
        },

        contextmenuHandle(event) {
            if (Gikam.isEmpty(this.$store.state.contextmenu)) {
                return;
            }
            event.preventDefault();
            const { contextmenu } = this.$store.state;
            new Vue({
                el: Gikam.createDom('div', document.body),
                components: {
                    contextmenuVue: () => import('./contextmenu/contextmenuPanel')
                },
                render() {
                    return <contextmenuVue target={event.target} contextmenu={contextmenu}></contextmenuVue>;
                }
            });
        },

        getReadonlyTextStyle(field) {
            return {
                textAlign: this.contentAlign(field),
                paddingRight: field.addon ? '5px' : ''
            };
        },

        getRowIndex(index) {
            if (this.options.indexKeep) {
                return this.$store.state.pageSize * (this.$store.state.pageNum - 1) + index;
            }
            return index;
        },

        // 处理是否需要固定行
        handleRowFixed() {
            if (Gikam.isTrue(this.options.firstRowFixed)) {
                this.rowFixedTop = [0];
                this.showRowFixedTop = true;
            }
            if (this.options.sum.fixed) {
                this.rowFixedBottom = [-1];
                this.showRowFixedBottom = true;
            }
            if (Gikam.isNotEmpty(this.options.rowFixed)) {
                const showRowFixedBottom = this.showRowFixedBottom;
                const showRowFixedTop = this.showRowFixedTop;
                this.options.rowFixed.forEach(item => {
                    if (item < 0 && !showRowFixedBottom) {
                        this.rowFixedBottom.push(item);
                        this.showRowFixedBottom = true;
                    } else if (item >= 0 && !showRowFixedTop) {
                        this.rowFixedTop.push(item);
                        this.showRowFixedTop = true;
                    }
                });
                this.rowFixedTop.sort((prev, next) => prev - next);
                this.rowFixedBottom.sort((prev, next) => prev - next);
            }
        },

        // 监听是否有纵向滚动条
        hasVerticalScroll(val) {
            this.showVerticalScroll = val;
        }
    },

    watch: {
        allChecked() {
            if (this.allChecked === true) {
                const checkedIndex = [];
                for (let i = 0; i < this.options.data.length; i++) {
                    checkedIndex.push(i);
                }
                this.checkeds = checkedIndex;
            } else {
                // this.checkeds = [];  // checkbox是否选中通过allChecked || row._checked || checkboxTypeHandle(rowIndex) 判断
            }
        },

        'options.filterOpen'() {
            this.$nextTick(() => {
                this.$store.commit('changeHeaderHeight', this.$refs.header.$el.offsetHeight);
            });
        }
    },

    data() {
        return {
            Gikam: Gikam,
            hiddenGrid: this.options.hidden,
            allChecked: false,
            checkeds: [],
            checkedNodes: [],
            selectedRow: [],
            loading: false,
            activeRow: null,
            activeRowData: null,
            genericQueryCount: 0,
            activeGenericQuery: void 0,
            defaultGenericQueryFields: Gikam.extend(true, [], this.options.genericQueryFields),
            showMoreBtn: false,
            moreBtnOptions: {
                icon: 'btn-more',
                text: '更多',
                fragment: null
            },
            editorInvisible: this.options.editorInvisible,
            totalChecked: {
                isShow: false,
                num: 0
            },
            dragDownIndex: null, // drag时按下去的index
            dragEnterIndex: null, //  drag时移入的index
            dragEnterType: null, // drag时移入的状态。top/bottom
            dragPageY: 0, // drag按下时的位置
            dragDownData: null, // drag按下时的数据
            scrollType: null, // top/bottom
            groupNum: 0, // 全选时类型为group的数量
            rowActived: false,
            minCheckedCell: null, //记录最小选中行
            transition: true, // 控制固定列滚动是否有动画
            scrollAutomaticSlide: false,
            scroll: Gikam.isMobile() || this.options.scroll === 'visible' ? 'DefaultScroll' : 'scroll',
            showRowFixedTop: false,
            showRowFixedBottom: false,
            rowFixedTop: [],
            rowFixedBottom: [],
            showVerticalScroll: false // 是否存在纵向滚动条
        };
    },

    mounted() {
        // 按下shift点击复选框，禁止鼠标选中文字。点选的时候容易选中文字
        this.selectstart();
        this.initToolbarForm();
        Gikam.jQuery(window).mouseup(() => {
            this.dragLeave();
        });
        Gikam.jQuery(window).bind('mousewheel', () => {
            this.removeErrorPanel();
        });
    },

    updated() {
        // if (this.checkeds.length == 0) {
        //     if (this.options.data && this.options.data.length > 0) {
        //         Gikam.info(document.getElementsByTagName('tr'));
        //         document.getElementsByTagName('tr').forEach(item => {
        //             if (item.classList.contains('active')) {
        //                 item.classList.remove('active');
        //             }
        //         });
        //     }
        // }
        this.$nextTick(() => {
            this.initScroll();
            this.$refs.header && this.$store.commit('changeHeaderHeight', this.$refs.header.$el.offsetHeight);
        });
    },

    components: {
        gridFixed,
        btn,
        gridHeader,
        treeGridNode,
        cellEditor,
        generalButtonGroup,
        gridFooter,
        cellTagField: {
            props: {
                options: {
                    type: Object,
                    default: () => {
                        return {
                            type: '',
                            value: ''
                        };
                    }
                }
            },
            template:
                '<div class="cell-tag" :class="options.type"><div class="cell-tag-text">{{options.value}}</div></div>'
        },
        totalRow,
        DefaultScroll,
        GridRowFixedTop,
        GridRowFixedBottom
    }
};
</script>

<style>
.grid {
    height: 100%;
    background-color: white;
    padding: 8px;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1;
}

.grid > .toolbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.grid > .toolbar > .operation {
    height: 30px;
    align-items: center;
    flex: 1;
    display: flex;
    justify-content: flex-end;
}
.grid > .toolbar > .search {
    display: flex;
}

.grid > .toolbar > .general-group {
    padding-left: 8px;
}

.grid > .toolbar > .search > .filter-dropdown-btn > .text {
    white-space: nowrap;
}

.grid > .toolbar > .operation > .item {
    display: inline;
}

.grid > .toolbar > .operation > .item > .btn-container > .button {
    margin-right: 8px;
}

.search > .item > .form-container > .form > .panel {
    margin: 0;
}

.grid > .toolbar > .operation > .item > .form-container > .form .form-group .title {
    width: auto;
    padding-left: 16px;
}

.grid > .toolbar > .operation > .item > .form-container > .form .form-group:nth-child(1) .title {
    padding-left: 0;
}

.grid > .toolbar > .operation > .item > .form-container > .form .form-group {
    margin: 0;
    width: auto !important;
}

/*解决IE下toobar下formField跑版问题*/
.grid > .toolbar > .operation > .item > .form-container > .form .form-group .field {
    flex: none;
}

.grid > .toolbar > .operation > .item > .form-container > .form .form-group .field .choose-input {
    min-width: 100px;
}

.operation > .item > .form-container > .form > .panel .select,
.operation > .item > .form-container > .form > .panel .text-input {
    height: 24px;
    width: 150px;
    min-height: 24px;
}

.operation > .item > .form-container > .form > .panel .select div,
.operation > .item > .form-container > .form > .panel .text-input div,
.operation > .item > .form-container > .form > .panel .text-input input {
    min-height: 24px;
    /* 解决IE浏览器下拉箭头错位问题 */
    height: 24px;
    display: flex;
    align-items: center;
}

.operation > .item > .form-container > .form {
    padding: 0;
}

.grid > .grid-header {
    border-bottom: none;
    overflow: hidden;

    z-index: 1;
}

.grid > .grid-body {
    border: 1px solid #eee;
    flex: 1;
    overflow: auto;
    height: 0;
    border-top: none;
    position: relative;
}
.grid > .grid-body.noScroll {
    height: auto;
}

.grid > .grid-body table {
    border-collapse: collapse;
    border-spacing: 0;
    table-layout: fixed;
    position: relative;
}

.grid > .grid-body table > thead th {
    height: 0;
    padding: 0;
    border: none;
    border-right: 1px solid #eee;
}

.grid > .grid-body table > tbody > tr {
    transition: transform 0.2s ease-out;
}

.grid > .grid-body table > tbody > .hide {
    float: left;
    visibility: hidden;
    transform: translateY(-100%);
    height: 0;
}

.grid > .grid-body table > tbody > tr > td {
    padding: 0;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.65);
    text-align: left;
    border-right: 1px solid #eee;
}

.grid.columns-fill > .grid-body table > tbody > tr > td:last-child {
    border-right: none;
}

.grid > .grid-body .body-cell.select-row-checkbox-cell,
.grid > .grid-body .body-cell.select-row-radio-cell,
.grid > .grid-body .body-cell.row-index-cell {
    display: flex;
    justify-content: center;
}

.grid > .grid-body table > tbody > tr:nth-child(odd) {
    background-color: rgba(11, 184, 148, 0.05);
}

.grid > .grid-body table > tbody > tr > .no-record-cell {
    background-color: #fff;
}

.grid > .grid-body table > tbody > tr > td > .body-cell a {
    text-decoration: none;
    color: #007aff;
    height: auto;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.grid > .grid-body table > tbody > tr > td > .body-cell {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 8px;
    white-space: nowrap;
}

.grid > .grid-body table > tbody > tr > td > .body-cell p {
    margin: 0;
}

.grid > .grid-body table > tbody > tr > td > .body-cell > .cell-icon {
    width: 25px;
    height: 25px;
}

.grid > .grid-body table > tbody > tr > td > .body-cell > a.cell-tag,
.grid > .grid-body table > tbody > tr > td > .body-cell > .cell-tag > .cell-tag-text {
    height: 24px;
    line-height: 23px !important;
    padding: 0 8px;
    border-radius: 4px;
}

.grid > .grid-body table > tbody > tr > td > .body-cell > a.cell-tag.success,
.grid > .grid-body table > tbody > tr > td > .body-cell > .cell-tag.success > .cell-tag-text {
    color: #00d94d;
    background-color: #e5fbed;
    border: 1px solid #66e894;
    padding: 0 8px;
}

.grid > .grid-body table > tbody > tr > td > .body-cell > a.cell-tag.danger,
.grid > .grid-body table > tbody > tr > td > .body-cell > .cell-tag.danger > .cell-tag-text {
    color: #ff3b30;
    background-color: #ffe2e0;
    border: 1px solid #ff8983;
    padding: 0 8px;
}

.grid > .grid-body table > tbody > tr > td > .body-cell > a.cell-tag.info,
.grid > .grid-body table > tbody > tr > td > .body-cell > .cell-tag.info > .cell-tag-text {
    color: #007aff;
    background-color: #e5f1ff;
    border: 1px solid #66afff;
    padding: 0 8px;
}

.grid > .grid-body table > tbody > tr > td > .body-cell > a.cell-tag.warning,
.grid > .grid-body table > tbody > tr > td > .body-cell > .cell-tag.warning > .cell-tag-text {
    color: #ffba00;
    background-color: #fff8e5;
    border: 1px solid #ffd666;
    padding: 0 8px;
}

.grid > .grid-body table > tbody > tr > td > .body-cell > a.cell-tag.guard,
.grid > .grid-body table > tbody > tr > td > .body-cell > .cell-tag.guard > .cell-tag-text {
    color: #ff8936;
    background-color: #fff3eb;
    border: 1px solid #ffd886;
    padding: 0 8px;
}

.grid > .grid-body table > tbody > tr > td > .body-cell > a.cell-tag.fine,
.grid > .grid-body table > tbody > tr > td > .body-cell > .cell-tag.fine > .cell-tag-text {
    color: #00c4b4;
    background-color: #e5f9f7;
    border: 1px solid #66dcd2;
    padding: 0 8px;
}

.grid > .grid-body table > tbody > tr > td > .body-cell:not(.select-row-checkbox-cell):not(.select-row-radio-cell) > * {
    padding: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 24px;
    line-height: 24px;
}

.grid > .grid-body table > tbody > tr > td > .body-cell .simple-checkbox {
    display: flex;
    justify-content: center;
}

.grid > .grid-body table > tbody > tr > td > .body-cell > .readonly-text {
    width: 100%;
    display: block;
}

.grid > .grid-body table > tbody > tr > td > .body-cell > .readonly-text .text {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.grid > .grid-body table > tbody > tr > td > .body-cell > .readonly-text .addon {
    width: 48px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/* .grid > .grid-body table > tbody > tr > td > .body-cell > .readonly-text sub {
    font-size: 7px;
    position: relative;
    top: -2px;
}

.grid > .grid-body table > tbody > tr > td > .body-cell > .readonly-text sup {
    font-size: 7px;
    position: relative;
    top: 3px;
} */

.grid > .grid-body table > tbody > tr > td > .body-cell > .text-input > .readonly-text {
    /* 解决grid组件编辑录入的结果和不可编辑的内容不对齐的问题 */
    padding-left: 8px;
}

.grid > .grid-body table > tbody > tr > td > .body-cell .select .select-container .placeholder {
    font-size: 12px;
}

.grid > .grid-body table > tbody > tr > td > .body-cell.no-record {
    height: 78px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.45);
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
}

.grid > .grid-body table > tbody > tr > td > .body-cell.no-record > .no-record-div {
    box-sizing: content-box;
    padding: 0 0 5px 0;
    height: 70px;
}

.grid > .grid-body table > tbody > tr > td > .body-cell > .no-record-div > .no-record-img {
    display: block;
}

.grid > .grid-body table > tbody > tr > td > .body-cell > .no-record-div > .no-record-text {
    display: block;
    margin-top: -10px;
}
.grid > .grid-body table > tbody > tr:last-child {
    border-bottom: 1px solid #eee;
}

.grid > .toolbar > .item.dropDownMenuMore {
    margin-left: 8px;
}

.grid .item.dropDownMenuMore {
    order: 1;
}

.grid > .custom-content {
    color: rgba(0, 0, 0, 0.65);
    width: 100%;
    font-size: 14px;
}

.grid > .grid-body table > tbody > tr:hover {
    background-color: rgba(11, 184, 148, 0.08);
}

.grid > .grid-body table > tbody > tr.active {
    background-color: rgba(0, 122, 255, 0.16);
}

.grid > .toolbar > .search > .generic-query-btn {
    display: flex;
    align-items: center;
    height: 24px;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    padding: 0 0 0 9px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.65);
    width: 150px;
    justify-content: space-between;
    cursor: pointer;
    margin-right: 10px;
}

.grid > .toolbar > .search > .filter-dropdown-btn > .text {
    white-space: nowrap;
}

.grid > .toolbar > .search > .generic-query-btn.active {
    border: 1px solid rgba(0, 122, 255, 0.5);
}

.grid > .toolbar > .search > .generic-query-btn > .drop {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: space-between;
}

.grid > .toolbar > .search > .generic-query-btn > .drop > .search-icon {
    width: 12px;
    height: 12px;
    background: url(../../../../img/grid-generic-query.png) no-repeat center;
}

.grid > .toolbar > .search > .generic-query-btn > .refresh-icon {
    width: 34px;
    height: 100%;
    background: url(../../../../img/grid-generic-refresh.png) no-repeat center;
    border-left: 1px solid #d9d9d9;
}

.grid > .toolbar > .search > .generic-query-btn > .refresh-icon.active {
    background: url(../../../../img/grid-generic-refresh-blue.png) no-repeat center;
}

.grid > .toolbar > .search > .generic-query-btn > .drop > .down-arrow {
    width: 28px;
    height: 12px;
    background: url(../../../../img/grid-generic-query-down-arrow.png) no-repeat center;
}

.grid .row-group > .body-cell-group {
    line-height: 32px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.grid .row-group > .body-cell-group > .switch {
    float: left;
    height: 100%;
    width: 30px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.grid [parent-group-index].hidden {
    display: none;
}

.grid.grid-fixed {
    position: absolute;
    left: 0;
    bottom: 44px;
    padding: 0 !important;
    height: auto;
    overflow: hidden;
}

.grid-fixed .grid-body {
    overflow: hidden;
}

.__rail-is-horizontal {
    z-index: 2 !important;
}

.grid.line-feed
    > .grid-body
    table
    > tbody
    > tr
    > td
    > .body-cell:not(.select-row-checkbox-cell):not(.select-row-radio-cell)
    > * {
    white-space: normal;
    height: auto;
}

.grid.line-feed > .grid-body table > tbody > tr > td > .body-cell > .readonly-text {
    height: auto;
    white-space: normal;
}

.grid.line-feed > .grid-body table > tbody > tr > td > .body-cell {
    white-space: normal;
    line-height: 20px;
}

.grid.line-feed > .grid-body table > tbody > tr > td > .body-cell > .readonly-text {
    padding: 5px;
}

.grid.line-feed > .grid-body table > tbody > tr > td > .body-cell > a {
    padding: 5px;
}

.grid.line-feed > .grid-body table > tbody > tr > td > .body-cell .invisible .readonly-text {
    padding: 5px;
}

.grid.line-feed > .grid-body table > tbody > tr > td > .body-cell .invisible .readonly-text .content-wrapper {
    white-space: normal;
}

.grid.line-feed > .grid-body table > tbody > tr > td > .body-cell .validate-error .readonly-text {
    margin-right: 24px;
}

.grid > .grid-body .body-cell > .drag {
    width: 100%;
    height: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* 拖拽的样式 */
.grid > .grid-body .body-cell > .drag svg {
    cursor: pointer;
}

.grid > .grid-body .body-cell > .drag.cur {
    cursor: pointer;
}

.grid > .grid-body table > tbody > tr td {
    position: relative;
}

.grid > .grid-body table > tbody > tr.drag-active td::before {
    content: '';
    position: absolute;
    top: 1px;
    left: 0;
    right: 0;
    height: 1px;
    background-color: #007aff;
}

.grid > .grid-body table > tbody > tr.drag-active td::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    background-color: #007aff;
}

.grid > .grid-body table > tbody > tr.drag-active td:first-child {
    border-left: 1px solid #007aff;
}

.grid > .grid-body table > tbody > tr.drag-active td:last-child {
    border-right: 1px solid #007aff;
}

.user-select {
    user-select: none;
}

.grid > .grid-body table > tbody > tr.drag-border-top td::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 2px;
    background-color: rgba(0, 122, 255, 0.6);
}

.grid > .grid-body table > tbody > tr.drag-border-bottom td::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -1px;
    height: 2px;
    background-color: rgba(0, 122, 255, 0.6);
}

.grid > .grid-body > .scroll-type-top,
.grid > .grid-body > .scroll-type-bottom {
    width: 100%;
    height: 8px;
    z-index: 3;
}

.grid > .grid-body > .scroll-type-top {
    position: absolute;
    top: 0;
    left: 0;
}

.grid > .grid-body > .scroll-type-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
}

.grid > .grid-body > .scroll-type-top.top {
    background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(225, 225, 225, 0.1));
}

.grid > .grid-body > .scroll-type-bottom.bottom {
    background-image: linear-gradient(rgba(225, 225, 225, 0.1), rgba(0, 0, 0, 0.1));
}

.poptip-message-panel a {
    flex: auto !important;
}

/* 列边框 */
.grid > .grid-body table > tbody > tr:nth-last-child(1) > td.columnsBoder {
    border-bottom: 2px solid #007aff;
}
.grid > .grid-body table > tbody > tr > td.columnsBoder {
    position: relative;
}
.grid > .grid-body table > tbody > tr > td.columnsBoder::before {
    content: '';
    height: calc(100% + 2px);
    position: absolute;
    border-left: 2px solid #007aff;
    left: -1px;
    top: 0;
    overflow: hidden;
}
.grid > .grid-body table > tbody > tr > td.columnsBoder::after {
    content: '';
    height: calc(100% + 2px);
    position: absolute;
    border-left: 2px solid #007aff;
    right: -1px;
    top: 0;
    overflow: hidden;
}

@media screen and (max-width: 768px) {
    .grid {
        max-height: 500px;
    }
}

.grid > .toolbar > .operation > .item > .form-container > .form .form-group .field .checkbox-container {
    border: none;
}

.grid > .toolbar > .operation > .item > .caption-container {
    padding-left: 12px;
    padding-right: 12px;
}
</style>
