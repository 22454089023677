import reLoginAlert from '../vue/reLoginAlert';
import Base from '../../base.js';
import Vue from 'vue';
import Gikam from '../../../core/gikam-core';

let defaultOptions = {
    title: '提示',
    confirmText: '确认',
    countdown: true, // 是否倒计时
    time: 10, // 倒计时的大小/秒
    message: void 0,
    height: 250
};

export default class ReLoginAlert extends Base {

    constructor(props) {
        super(props);
        this.initialize(props, defaultOptions).init();
    }

    createModel() {
        const _this = this;
        return new Vue({
            el: Gikam.createDom('div', document.body),
            components: { reLoginAlert },
            methods: {
                close() {
                    _this.$destroy();
                }
            },
            render() {
                return <reLoginAlert options={_this.options} onClick={this.close}></reLoginAlert>
            }
        });
    }

    init() {
        this.model = this.createModel();
    }
}
