import Gikam from '../../../core/gikam-core';
import { I18N } from '@/gikam/i18n/I18N';

function isColumnVisible(fieldOption) {
    const visible = fieldOption.visible;
    return visible === undefined || visible === '1' || visible === true;
}

export default class GridConfig {
    constructor(store) {
        this.store = store;
        this.init();
    }

    initModal() {
        const _this = this;
        const result = Gikam.createSimpleModal({
            title: I18N.prop('grid.configuration'),
            width: 600,
            height: 400,
            toolbarFormatter(toolbar) {
                toolbar.unshift({
                    type: 'button',
                    text: I18N.prop('grid.configuration.restore'),
                    onClick() {
                        _this.revertDefault();
                    }
                });
                return toolbar;
            },
            onConfirm: () => this.confirm(),
            onCancel: () => this.cancel()
        });
        this.modal = result.modal;
        this.layout = result.layout;
    }

    revertDefault() {
        if (this.store.getters.defaultColumns.length === 0) {
            return;
        }
        this.modal.window.showMask();
        const defaultColumns = this.store.getters.defaultColumns.filter(item => {
            return item.field;
        });
        defaultColumns.forEach(item => {
            if (Gikam.isNotEmpty(item.fixed)) {
                item.fixed = item.fixed === true ? '1' : '0';
            }
        });
        this.updateColumns(defaultColumns)
            .done(() => {
                this.store.commit('refreshColumns', Gikam.deepExtend(this.store.getters.defaultColumns));
                this.modal.close();
            })
            .always(() => this.modal.window.closeMask());
    }

    confirm() {
        const newColumns = this.grid.getData();
        const finalColumns = this.store.state.columns
            .filter(item => {
                return item.checkbox || item.radio || item.index === true;
            })
            .concat(newColumns);
        this.modal.window.showMask();
        this.updateColumns(newColumns)
            .done(() => {
                this.store.commit('refreshColumns', finalColumns);
                this.modal.close();
            })
            .always(() => this.modal.window.closeMask());
    }

    updateColumns(newColumns) {
        const url = Gikam.IFM_CONTEXT + '/core/module/sys/page-grid-field-configs';
        const { gridId, $window } = this.store.state;
        return Gikam.postText(url, Gikam.getJsonWrapper({ gridId, pageId: $window.$pageId }, ['', newColumns]));
    }

    cancel() {
        this.modal.close();
    }

    getGridData() {
        return Gikam.deepExtend(this.store.state.columns)
            .filter(item => item.field)
            .map(item => {
                return Gikam.extend(item, {
                    fixed: isColumnVisible(item) && item.fixed == true ? '1' : '0',
                    visible: !isColumnVisible(item) ? '0' : '1',
                    title: Gikam.propI18N(item.title)
                });
            });
    }

    // 获取配置列表头数据
    getColumns() {
        let _this = this;
        return [
            {
                title: I18N.prop('grid.configuration.columnName'),
                field: 'title',
                width: 180
            },
            {
                title: I18N.prop('grid.configuration.sort'),
                type: 'drag',
                width: 134
            },
            {
                title: I18N.prop('grid.configuration.show'),
                field: 'visible',
                width: 134,
                type: 'simpleCheckbox',
                editor: true,
                onChange(field, val, row) {
                    if (val === '0') {
                        _this.grid.toFieldsReadonly(row, ['fixed']);
                        _this.grid.setData({ index: row, fixed: '0' });
                    } else {
                        _this.grid.toFieldsEdit(row, ['fixed']);
                    }
                }
            },
            {
                title: I18N.prop('grid.configuration.frozen'),
                field: 'fixed',
                type: 'simpleCheckbox',
                editor: true
            }
        ];
    }

    initGrid() {
        let _this = this;
        this.grid = Gikam.create('grid', {
            renderTo: this.layout.options.center.$dom,
            page: false,
            fill: true,
            filter: false,
            autoSave: false,
            editorInvisible: true,
            generalButtonGroup: false,
            columns: _this.getColumns(),
            columnsFill: true,
            data: this.getGridData()
        });
        this.getGridData().forEach((item, index) => {
            item.visible === '0' && this.grid.toFieldsReadonly(index, ['fixed']);
        });
    }

    init() {
        this.initModal();
        this.initGrid();
    }
}
