<template>
    <svg
        version="1.1"
        id="图层_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="8 -8 48 48"
        style="enable-background:new 8 -8 48 48;"
        xml:space="preserve"
        :width="width"
        :height="height"
    >
        <path
            id="XMLID_136_"
            :fill="color"
            d="M13-1.5L13-1.5C13-2.3,13.7-3,14.5-3h35C50.3-3,51-2.3,51-1.5l0,0C51-0.7,50.3,0,49.5,0h-35
	C13.7,0,13-0.7,13-1.5z"
        />
        <path
            id="XMLID_100_"
            :fill="color"
            d="M32,33.4L32,33.4c-0.8,0-1.5-0.7-1.5-1.5V10.7c0-0.8,0.7-1.5,1.5-1.5l0,0
	c0.8,0,1.5,0.7,1.5,1.5v21.1C33.5,32.7,32.8,33.4,32,33.4z"
        />
        <path
            :fill="color"
            d="M32,35c-1.7,0-3.2-0.6-4.4-1.8l-7.3-7.3c-0.6-0.6-0.6-1.5,0-2.1s1.5-0.6,2.1,0l7.3,7.3c1.2,1.2,3.3,1.2,4.5,0
	l7.4-7.4c0.6-0.6,1.5-0.6,2.1,0s0.6,1.5,0,2.1l-7.4,7.4C35.2,34.3,33.7,35,32,35z"
        />
    </svg>
</template>
<script>
import baseSvgImg from './baseSvgImg';
export default {
    extends: baseSvgImg,
    props: {
        color: {
            type: String,
            default: '#007aff'
        }
    }
};
</script>
