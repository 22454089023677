import { render, staticRenderFns } from "./chooseImg.vue?vue&type=template&id=805b860e&scoped=true&"
import script from "./chooseImg.vue?vue&type=script&lang=js&"
export * from "./chooseImg.vue?vue&type=script&lang=js&"
import style0 from "./chooseImg.vue?vue&type=style&index=0&id=805b860e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "805b860e",
  null
  
)

export default component.exports