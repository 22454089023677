<template>
    <svg
        t="1577686239775"
        class="icon"
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="19183"
        :width="width"
        :height="height"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <path
            d="M838.43072 696.79104s-25.68704-9.51808-29.68576-86.90688v-43.86304c0-0.24576 0.04096-0.53248 0.04096-0.78336l-0.04096 0.08704V428.61056c0-151.79776-105.94816-279.12704-244.80256-305.96608V106.8544c0-31.16544-24.52992-56.68352-54.5024-56.68352-30.01344 0-54.54336 25.51808-54.54336 56.68352v15.79008c-138.80832 26.83904-244.80256 154.16832-244.80256 305.96608v181.85216c-4.08064 76.84608-29.59872 86.3232-29.59872 86.3232-28.07296 0-50.9952 23.86944-50.9952 53.0176 0 29.184 22.92224 53.01248 50.9952 53.01248h657.89952c28.032 0 50.9952-23.82848 50.9952-53.01248-0.00512-29.14304-22.92736-53.01248-50.95936-53.01248zM509.44 978.944c58.9568 0 106.73664-49.67424 106.73664-110.98112H402.70336c0 61.30688 47.77984 110.98112 106.73664 110.98112z"
            :fill="color"
            p-id="19184"
        />
    </svg>
</template>

<script>
import baseSvgImg from './baseSvgImg';
export default {
    extends: baseSvgImg,
    props: {
        color: {
            type: String,
            default: '#007aff'
        },
        width: {
            type: String,
            default: '15px'
        },
        height: {
            type: String,
            default: '15px'
        }
    }
};
</script>

<style scoped>
svg {
    display: block;
}
</style>
