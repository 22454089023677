import Gikam from 'gikam';

const getBaseTypeValue = (key, value) => {
    if (/\./.test(key)) {
        key = `'${key}'`;
    }
    if (Gikam.isString(value)) {
        return key + ':' + "'" + value + "'";
    } else if (Gikam.isNumber(value) || Gikam.isBoolean(value) || Gikam.isFunction(value)) {
        return key + ':' + value;
    } else if (value === null) {
        return key + ':null';
    } else if (value === undefined) {
        return key + ':undefined';
    }
};

const parseObjectToString = props => {
    let serialize = '';
    const parse = object => {
        if (Array.isArray(object)) {
            serialize += '[';
            object.forEach((item, index) => {
                if (Gikam.isPlainObject(item)) {
                    parse(item);
                } else if (Gikam.isString(item)) {
                    serialize += "'" + item + "'";
                } else {
                    serialize += item;
                }
                if (index < object.length - 1) {
                    serialize += ',';
                }
            });
            serialize += ']';
        } else {
            serialize += '{';
            let keys = Object.getOwnPropertyNames(object);
            keys.forEach((name, index) => {
                if (name === '__ob__') {
                    return;
                }
                const value = object[name];
                if (Array.isArray(value) || Gikam.isPlainObject(value)) {
                    serialize += name + ':';
                    parse(object[name]);
                } else {
                    serialize += getBaseTypeValue(name, value);
                }
                if (index < keys.length - 1) {
                    serialize += ',';
                }
            });
            serialize += '}';
        }
        return serialize;
    };
    return parse(props);
};

export { parseObjectToString };
