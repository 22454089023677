<template>
    <svg
        version="1.1"
        id="图层_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="8 -8 48 48"
        style="enable-background:new 8 -8 48 48;"
        xml:space="preserve"
        :width="width"
        :height="height"
    >
        <path
            class="st0"
            :fill="color"
            d="M43,35H16.5c-1.9,0-3.5-1.6-3.5-3.5v-31C13-1.4,14.6-3,16.5-3h31C49.4-3,51-1.4,51,0.5v25.9L43,35z M16.5,0
            C16.2,0,16,0.2,16,0.5v31c0,0.3,0.2,0.5,0.5,0.5h25.1l6.4-6.8V0.5C48,0.2,47.8,0,47.5,0H16.5z"
        />
        <path id="XMLID_1791_" class="st1" :fill="color" d="M44.3,33.7" />
        <path
            class="st0"
            :fill="color"
            d="M41.8,11.3H22.2c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5h19.6c0.8,0,1.5,0.7,1.5,1.5S42.6,11.3,41.8,11.3z"
        />
        <path
            class="st0"
            :fill="color"
            d="M41.8,21.4H22.2c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5h19.6c0.8,0,1.5,0.7,1.5,1.5S42.6,21.4,41.8,21.4z"
        />
    </svg>
</template>

<script>
import baseSvgImg from './baseSvgImg';
export default {
    extends: baseSvgImg,
    props: {
        color: {
            type: String,
            default: '#007aff'
        },
        width: {
            type: String,
            default: '15px'
        },
        height: {
            type: String,
            default: '15px'
        }
    }
};
</script>

<style scoped>
svg {
    display: block;
}
</style>
