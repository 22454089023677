<template>
    <div
        class="combo-box-picker"
        :style="{ top: top + 'px', left: left + 'px', width: width + 'px', height: height }"
        @mousedown.stop
        @mousewheel.stop
        @DOMMouseScroll.stop
    >
        <scroll :ops="{ scrollPanel: { scrollingX: false } }" :innerPane="true">
            <ul>
                <li v-for="item in propItems" :key="item.value" @click.stop="clickHandle(item)">
                    <checkbox :propChecked="item.checked" class="checkbox" v-if="propMultiple" />
                    {{ item.text }}
                </li>
            </ul>
        </scroll>
    </div>
</template>

<script>
import Gikam from 'gikam';
import checkbox from '../../checkbox/checkbox';

export default {
    components: { checkbox },

    props: {
        propItems: Array,
        propTop: Number,
        propLeft: Number,
        propWidth: Number,
        propMultiple: Boolean
    },

    computed: {
        height() {
            return this.items.length * 32 + 2 + 'px';
        }
    },

    inject: {
        combobox: {
            default: null
        }
    },

    mounted() {
        Gikam.comboboxPicker = this;
    },

    watch: {
        propItems(value) {
            this.items = Gikam.isEmpty(value) ? [{ text: '', value: '' }] : value;
        }
    },

    data() {
        return {
            items: this.propItems,
            top: this.propTop,
            left: this.propLeft,
            width: this.propWidth
        };
    },

    methods: {
        clickHandle(item) {
            if (this.propMultiple) {
                this.$set(item, 'checked', !item.checked);
                let value = Gikam.isNotEmpty(this.combobox.value) ? this.combobox.value.split(',') : [];
                if (item.checked) {
                    value.push(item.text);
                    this.combobox.value = value.join(',');
                } else {
                    this.combobox.value = value.filter(text => text !== item.text).join(',');
                }
            } else {
                this.combobox.value = item.text;
                this.combobox.cleanPicker();
            }
        }
    }
};
</script>

<style scoped>
@keyframes slide-bottom {
    from {
        transform: translate3d(0, 20px, 0);
        opacity: 0.3;
    }
    to {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

@keyframes slide-top {
    from {
        transform: translate3d(0, -20px, 0);
        opacity: 0.3;
    }
    to {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}
.combo-box-picker {
    position: absolute;
    z-index: 11;
    margin-top: 1px;
    animation: slide-bottom 0.2s;
    max-height: 200px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.08);
    overflow: hidden;
}

.combo-box-picker ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    background-color: white;
    min-height: 32px;
}

.combo-box-picker ul li {
    height: 32px;
    line-height: 32px;
    font-family: 'Microsoft YaHei', serif;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.65);
    padding-left: 10px;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.combo-box-picker ul li .checkbox {
    display: inline-block;
    vertical-align: text-bottom;
}

.combo-box-picker ul li.current-value {
    color: #000;
}

.combo-box-picker ul li:hover {
    background-color: rgba(0, 122, 255, 0.08);
}
</style>
