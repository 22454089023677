<template>
    <svg
        version="1.1"
        id="图层_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 42 42"
        style="enable-background:new 0 0 42 42;"
        xml:space="preserve"
        :width="width"
        :height="height"
    >
        <path
            id="XMLID_15_"
            class="st0"
            :fill="color"
            d="M19.4,8.6L19.4,8.6c0,0.6-0.4,1-1,1h-4c-0.6,0-1-0.4-1-1v0c0-0.6,0.4-1,1-1h4
            C18.9,7.6,19.4,8,19.4,8.6z"
        />
        <path
            id="XMLID_16_"
            class="st0"
            :fill="color"
            d="M19.4,11.9L19.4,11.9c0,0.6-0.4,1-1,1h-4c-0.6,0-1-0.4-1-1v0c0-0.6,0.4-1,1-1h4
            C18.9,10.9,19.4,11.4,19.4,11.9z"
        />
        <path
            class="st0"
            :fill="color"
            d="M31.8,41H6.2c-1.1,0-2.1-0.5-2.8-1.4c-0.7-0.9-1-2-0.8-3.2l0.1-0.3L12,15.4h13v3H14L5.5,37.2
            c0,0.2,0.1,0.4,0.2,0.6C5.8,37.8,5.9,38,6.2,38h25.6c0.3,0,0.4-0.2,0.5-0.3c0.1-0.2,0.2-0.4,0.2-0.6l-2.2-4.8l2.7-1.2l2.4,5.3
            c0.2,1.1-0.1,2.3-0.8,3.2C33.9,40.5,32.9,41,31.8,41z"
        />
        <path class="st0" :fill="color" d="M26.5,18.4h-15v-17h15V18.4z M14.5,15.4h9v-11h-9V15.4z" />
        <path
            class="st0"
            :fill="color"
            d="M26.2,5H11.8c-1.1,0-2-0.9-2-2v0c0-1.1,0.9-2,2-2h14.5c1.1,0,2,0.9,2,2v0C28.2,4.1,27.3,5,26.2,5z"
        />
        <path
            class="st0"
            :fill="color"
            d="M30.3,33.2L30.3,33.2c-2.5,0-4.8-1-6.5-2.7c-3.6-3.6-3.6-9.5,0-13.1c3.6-3.6,9.5-3.6,13.1,0
            c1.7,1.7,2.7,4.1,2.7,6.5c0,2.5-1,4.8-2.7,6.5C35.1,32.3,32.7,33.2,30.3,33.2z M30.3,17.8c-1.6,0-3.2,0.6-4.4,1.8
            c-2.4,2.4-2.4,6.4,0,8.8c1.2,1.2,2.7,1.8,4.4,1.8c1.7,0,3.2-0.6,4.4-1.8c1.2-1.2,1.8-2.8,1.8-4.4c0-1.7-0.7-3.2-1.8-4.4
            C33.5,18.4,31.9,17.8,30.3,17.8z"
        />
        <path
            id="XMLID_678_"
            class="st0"
            :fill="color"
            d="M29.2,27.4c-0.6,0-1.3-0.2-1.8-0.7L26,25.3c-0.6-0.6-0.6-1.5,0-2.1c0.6-0.6,1.5-0.6,2.1,0
            l1.1,1.1l3.2-3.2c0.6-0.6,1.5-0.6,2.1,0c0.6,0.6,0.6,1.5,0,2.1L31,26.7C30.5,27.2,29.8,27.4,29.2,27.4z"
        />
    </svg>
</template>

<script>
import baseSvgImg from './baseSvgImg';
export default {
    extends: baseSvgImg,
    props: {
        color: {
            type: String,
            default: '#007aff'
        },
        width: {
            type: String,
            default: '15px'
        },
        height: {
            type: String,
            default: '15px'
        }
    }
};
</script>

<style scoped>
svg {
    display: block;
}
</style>
