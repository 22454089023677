<template>
    <svg
        version="1.1"
        id="图层_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 42 42"
        style="enable-background:new 0 0 42 42;"
        xml:space="preserve"
        :width="width"
        :height="height"
    >
        <path
            :fill="color"
            d="M20.3,40.2c-4.9,0-9.7-1.9-13.4-5.6c-7.4-7.4-7.4-19.5,0-26.9c3.6-3.6,8.4-5.6,13.4-5.6
    s9.8,2,13.4,5.6l0,0l0,0c3.6,3.6,5.6,8.4,5.6,13.4c0,5.1-2,9.8-5.6,13.4C30.1,38.4,25.2,40.2,20.3,40.2z M20.3,5.2
    C16.1,5.2,12,6.9,9,9.9c-6.2,6.2-6.2,16.4,0,22.6c6.2,6.2,16.4,6.2,22.6,0c3-3,4.7-7,4.7-11.3c0-4.3-1.7-8.3-4.7-11.3l0,0
    C28.6,6.9,24.6,5.2,20.3,5.2z"
        />
        <g>
            <path
                :fill="color"
                d="M12.5,30.8c-0.4,0-0.9-0.2-1.2-0.5c-0.7-0.7-0.7-1.7,0-2.4L27,12.2c0.7-0.7,1.7-0.7,2.4,0
        s0.7,1.7,0,2.4L13.7,30.3C13.4,30.6,12.9,30.8,12.5,30.8z"
            />
            <path
                :fill="color"
                d="M28.2,30.8c-0.4,0-0.9-0.2-1.2-0.5L11.3,14.6c-0.7-0.7-0.7-1.7,0-2.4s1.7-0.7,2.4,0
        l15.7,15.7c0.7,0.7,0.7,1.7,0,2.4C29.1,30.6,28.6,30.8,28.2,30.8z"
            />
        </g>
    </svg>
</template>
<script>
import baseSvgImg from './baseSvgImg';
export default {
    extends: baseSvgImg,
    props: {
        color: {
            type: String,
            default: '#007aff'
        }
    }
};
</script>
<style scoped>
svg {
    display: block;
}
</style>
