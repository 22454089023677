<template>
    <svg
        t="1573115017201"
        class="icon"
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="5195"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        :width="width"
        :height="height"
    >
        <path
            :fill="color"
            d="M786.416272 472.16037H551.32764V237.242402a39.252548 39.252548 0 0 0-78.67576 0v234.832636H237.563249a39.252548 39.252548 0 1 0 0 78.505097H472.737212v234.832637a39.252548 39.252548 0 0 0 78.67576 0V550.665467h235.088631a39.252548 39.252548 0 1 0 0-78.505097z"
        ></path>
        <path
            :fill="color"
            d="M874.222516 149.606821a512.50175 512.50175 0 0 0-724.038853 0.255995 510.965781 510.965781 0 0 0 0 723.100205 512.50175 512.50175 0 0 0 724.038853 0.170663 511.819097 511.819097 0 0 0 0-723.526863z m-55.636221 668.061306a433.996653 433.996653 0 0 1-547.487717 53.758924 432.716679 432.716679 0 0 1-159.655474-525.64282 433.655327 433.655327 0 0 1 834.116651 165.543356 429.986067 429.986067 0 0 1-126.97346 306.34054z"
        ></path>
    </svg>
</template>

<script>
import baseSvgImg from './baseSvgImg';
export default {
    extends: baseSvgImg,
    props: {
        color: {
            default: '#007AFF',
            type: String
        }
    }
};
</script>

<style scoped>
svg {
    display: block;
}
</style>
