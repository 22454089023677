<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="8 -8 48 48"
        style="enable-background:new 8 -8 48 48;"
        xml:space="preserve"
        :width="width"
        :height="height"
    >
        <path
            id="XMLID_136_"
            :fill="color"
            d="M51,33.5L51,33.5c0,0.8-0.7,1.5-1.5,1.5h-35c-0.8,0-1.5-0.7-1.5-1.5l0,0c0-0.8,0.7-1.5,1.5-1.5
	h35C50.3,32,51,32.7,51,33.5z"
        />
        <path
            id="XMLID_100_"
            :fill="color"
            d="M32-1.4L32-1.4c0.8,0,1.5,0.7,1.5,1.5v21.1c0,0.8-0.7,1.5-1.5,1.5l0,0c-0.8,0-1.5-0.7-1.5-1.5
	V0.1C30.5-0.7,31.2-1.4,32-1.4z"
        />
        <path
            :fill="color"
            d="M21.3,8.8c-0.4,0-0.8-0.1-1.1-0.4c-0.6-0.6-0.6-1.5,0-2.1l7.4-7.4c2.4-2.4,6.3-2.4,8.7,0l7.3,7.3
	c0.6,0.6,0.6,1.5,0,2.1c-0.6,0.6-1.5,0.6-2.1,0L34.2,1C33-0.3,31-0.3,29.8,1l-7.4,7.4C22.1,8.7,21.7,8.8,21.3,8.8z"
        />
    </svg>
</template>
<script>
import baseSvgImg from './baseSvgImg';
export default {
    extends: baseSvgImg,
    props: {
        color: {
            type: String,
            default: '#007aff'
        }
    }
};
</script>
