<template>
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="-140 27 48 48"
        style="enable-background:new -140 27 48 48;"
        xml:space="preserve"
        @mouseenter="mouseenterHandle"
        @mouseleave="mouseleaveHandle"
    >
        <path
            class="st0"
            :fill="color"
            d="M-114.9,70c-10.5,0-19-8.5-19-19s8.5-19,19-19s19,8.5,19,19S-104.4,70-114.9,70z M-114.9,35
	c-8.8,0-16,7.2-16,16s7.2,16,16,16s16-7.2,16-16S-106.1,35-114.9,35z"
        />
        <g id="XMLID_2271_">
            <path
                id="XMLID_2286_"
                class="st0"
                :fill="color"
                d="M-116.3,39.6h2.8c0.5,0,0.9,0.4,0.9,0.9l-0.7,15.2c0,0.3-0.2,0.5-0.5,0.5h-2.1
		c-0.3,0-0.5-0.2-0.5-0.5l-0.7-15.2C-117.2,40-116.8,39.6-116.3,39.6z"
            />
            <ellipse id="XMLID_2285_" class="st0" cx="-114.9" cy="61.3" rx="2.3" ry="2.3" :fill="color" />
        </g>
    </svg>
</template>

<script>
export default {
    props: {
        options: Object
    },

    methods: {
        mouseenterHandle(event) {
            this.$emit('mouseenter', event);
        },

        mouseleaveHandle(event) {
            this.$emit('mouseleave', event);
        }
    },

    data() {
        return {
            color: '#FF3B30'
        };
    }
};
</script>
